import React from 'react';
import PropTypes from 'prop-types';

import { Box, Spinner } from 'shared/components';

import { useGetText } from 'shared/hooks';
import { userHasPermission } from 'auth/actions';
import { Roles } from 'shared/constants';

import ListItem from './ListItem';

function EventsTable({ loading, onClickEvent, events }) {
  const text = useGetText();

  return (
    <Box
      title={
        <div
          style={{
            display: 'flex',
            flexFlow: 'row nowrap',
            width: '100%',
          }}
        >
          <div
            style={{
              flex: '1.2 1',
            }}
          >
            {text.getText('components.views.overviewEvents.listHeaderTitle')}
          </div>
          <div
            style={{
              flex: '1 1',
            }}
          >
            {text.getText('components.views.overviewEvents.spotStatus')}
          </div>
        </div>
      }
    >
      {loading ? (
        <Spinner />
      ) : events.length === 0 ? (
        <ListItem empty />
      ) : (
        events.map((obj) => (
          <ListItem
            key={obj.event._id}
            event={obj.event}
            spotsStatus={obj.spotsStatus}
            onClick={() => onClickEvent(obj.event._id)}
            canEdit={userHasPermission([Roles.ADMIN, Roles.EVENT_CREATOR])}
          />
        ))
      )}
    </Box>
  );
}

EventsTable.propTypes = {
  loading: PropTypes.bool.isRequired,
  onClickEvent: PropTypes.func.isRequired,
  events: PropTypes.array,
};

export default EventsTable;
