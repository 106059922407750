import React from 'react';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import { Tooltip } from '@material-ui/core';

import {
  Button,
  SelectInput,
  TextInput,
  AutoCompleteInput,
  CompanyFormModal,
} from 'shared/components/';

import { formCreator } from 'shared/form';
import { goBack } from 'shared/actions';
import { loadCompanies, loadCompany } from 'companies/actions';
import { getCompanyOptions } from 'companies/utils';

import { Roles } from 'shared/constants';
import fields from './fields';
import labelsConfig from './labels';
import rules from './rules';
import * as Constants from './constants';
import './styles.less';
import { userHasPermission } from 'auth/actions';

const enhance = compose(inject('text', 'auth', 'users', 'companies'), observer);

/**
 * UserForm class representes the detailed user profile form component.
 * @class UserForm
 * @extends {React.Component}
 */
export const UserForm = enhance(
  class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        form: null,
        currentCompany: null,
        addCompanyModalOpen: false,
      };
      this.toggleAddCompanyModalOpen = this.toggleAddCompanyModalOpen.bind(
        this
      );
      this.handleCompanyChange = this.handleCompanyChange.bind(this);
    }

    // {object} config - Form configuration getter
    get config() {
      const { onSuccess, onError, text } = this.props;
      const title = 'user';
      const labels = labelsConfig(text.get('forms.adminUsers'));
      return { title, fields, labels, rules, onSuccess, onError };
    }

    get emailInUseError() {
      const { form } = this.state;
      if (form && form.$('email').isPristine) return false;
      const emailInUse =
        this.props.users.getUserByEmail(form.$('email').value) !== undefined;
      return (
        emailInUse &&
        'This email is already in use, go back and select this account or create a user with another e-mail..'
      );
    }

    /**
     * React lifecycle method - executed everytime the component is about to be mounted in the view.
     * @return {void}
     */
    UNSAFE_componentWillMount() {
      const { companies, auth, values } = this.props;
      values && (values.phone = values.phones?.[0]?.number || '');
      const form = formCreator(this.config);
      this.setState({ form });
      form.init({ ...Constants.EMPTY_FORM, ...values }); // making sure everytime you select a new user, his data will appear

      if (companies.list.length > 0 || companies.loading) return;
      auth.profile.role !== Roles.ENERGY_USER
        ? loadCompanies()
        : loadCompany(this.props.auth.profile.companyId);
    }

    componentDidUpdate(prevProps, prevState) {
      const { companies } = this.props;
      const { currentCompany } = this.state;
      if (!companies.loading) {
        const newCurrentCompany = getCompanyOptions(companies).find(
          (option) => option._id === this.state.form.$('companyId').value
        );
        if (
          newCurrentCompany &&
          currentCompany === null &&
          prevState.currentCompany !== newCurrentCompany
        ) {
          this.setState({ currentCompany: newCurrentCompany });
        }
      }
    }

    handleCompanyChange(newCompany) {
      const { form } = this.state;
      form.$('companyId').set('value', newCompany?._id || null);
      this.setState({ currentCompany: newCompany || null });
      if (newCompany?._id === 0) {
        this.toggleAddCompanyModalOpen();
      }
    }

    toggleAddCompanyModalOpen() {
      this.setState({ addCompanyModalOpen: !this.state.addCompanyModalOpen });
    }

    getRoleOptions() {
      const energyUserRole = Constants.ROLES.filter(
        (role) => role.value === Roles.ENERGY_USER
      );

      if (userHasPermission(Roles.ADMIN)) {
        return Constants.ROLES;
      } else if (userHasPermission(Roles.EVENT_CREATOR)) {
        return Constants.ROLES.filter((role) => role.value !== Roles.ADMIN);
      } else if (
        userHasPermission(Roles.INQUIRY_MANAGER) ||
        userHasPermission(Roles.ENERGY_USER)
      ) {
        return energyUserRole;
      } else {
        return energyUserRole;
      }
    }

    /**
     * Renders the component view.
     * @return {React.Component}
     */
    render() {
      const {
        text,
        auth,
        auth: { profile },
        companies,
        users,
        values,
        isNew = true,
        isProfileView,
      } = this.props;
      const { form, addCompanyModalOpen, currentCompany } = this.state;
      const isAdmin = userHasPermission(Roles.ADMIN);
      const notAdminTooltip = !isAdmin
        ? text.get('forms.adminUsers.notAdminChangeRoleTooltip')
        : '';
      const canEditEmail = !(isNew || isProfileView) && !isAdmin;
      return (
        <form className="UserForm" onSubmit={form.onSubmit}>
          <h3>{text.get('forms.adminUsers.profileInfo')}</h3>

          <div className="inputRow">
            <TextInput field={form.$('name')} type="text" />
            <SelectInput
              field={form.$('language')}
              type="select"
              options={Constants.LANGUAGES}
            />
          </div>
          <div className="inputRow">
            <AutoCompleteInput
              label={text.get('forms.powerInquiries.companyName')}
              name={'companyId'}
              id="company"
              optionsLoading={companies.loading}
              getOptionLabel={(option) =>
                option._id === 0
                  ? `+ ${text.get('forms.company.addCompanyOption')}: "${
                      option.name
                    }"`
                  : option.name
              }
              options={getCompanyOptions(companies)}
              value={currentCompany}
              onChange={this.handleCompanyChange}
              addNewInputValueProp="name"
              disabled={profile.role === Roles.ENERGY_USER}
            />
            <Tooltip title={notAdminTooltip}>
              <SelectInput
                field={form.$('role')}
                type="select"
                options={this.getRoleOptions()}
                disabled={!isAdmin}
              />
            </Tooltip>
          </div>
          <div className="inputRow"></div>
          <Tooltip
            title={
              canEditEmail
                ? text.get('forms.adminUsers.changeEmailTooltip')
                : ''
            }
          >
            <TextInput
              field={form.$('email')}
              error={this.emailInUseError}
              disabled={canEditEmail}
              type="text"
            />
          </Tooltip>
          <TextInput field={form.$('phone')} type="text" />
          {profile && values && profile._id === values._id && (
            <React.Fragment>
              <h3>{text.get('forms.adminUsers.changePassword')}</h3>

              <div className="inputRow">
                <TextInput field={form.$('password')} type="password" />
                <TextInput field={form.$('repeatPassword')} type="password" />
              </div>
            </React.Fragment>
          )}

          {form.$('role').value === Roles.EVENT_CREATOR && (
            <div>
              <h3>{text.get('forms.adminUsers.credits')}</h3>
              <div className="inputRow">
                <TextInput
                  field={form.$('credits')}
                  onKeyDown={(event) => (event.keyCode === 69 ? false : true)}
                  type="number"
                  disabled={!isAdmin}
                />
              </div>
            </div>
          )}

          <h3>{text.get('forms.adminUsers.companyInfo')}</h3>

          <div className="inputRow">
            <TextInput
              value={currentCompany?.name}
              field={form.$('companyName')}
              type="text"
              disabled
            />
          </div>
          <div className="inputRow">
            <TextInput
              value={
                currentCompany
                  ? currentCompany?.address?.line1 +
                    ' ' +
                    currentCompany?.address?.line2
                  : undefined
              }
              field={form.$('address')}
              type="text"
              disabled
            />
            <TextInput
              value={currentCompany?.address?.city}
              field={form.$('city')}
              type="text"
              disabled
            />
          </div>
          <div className="inputRow">
            <TextInput
              value={currentCompany?.address?.zipCode}
              field={form.$('zipCode')}
              type="text"
              disabled
            />
            <TextInput
              value={currentCompany?.address?.country}
              field={form.$('country')}
              type="text"
              disabled
            />
          </div>

          <div className="buttonRow">
            <Button
              type="submit"
              styling="cta"
              onClick={form.onSubmit}
              disabled={!!auth.loading || !!users.loading}
            >
              {text.get('actions.save')}
            </Button>
            <Button type="button" styling="alt2" onClick={goBack}>
              {text.get('actions.cancel')}
            </Button>
          </div>
          <CompanyFormModal
            addModalOpen={addCompanyModalOpen}
            toggleAddModalOpen={this.toggleAddCompanyModalOpen}
            companyInput={currentCompany}
            handleClickAdd={this.handleCompanyChange}
          />
        </form>
      );
    }
  }
);
