import React from 'react';

import { goBack, pushRoute } from 'shared/actions';
import { Box } from 'shared/components';
import { Button } from 'shared/components/form';
import { EventForm } from '../components';
import { useGetText } from 'shared/hooks';
import { useCreateEvent } from 'events/hooks';

export function NewEventPage() {
  const text = useGetText();
  const { createEvent } = useCreateEvent();

  const handleSubmit = async (values) => {
    const { data } = await createEvent(values);
    data && pushRoute(`/events/`);
  };

  return (
    <div>
      <Button styling="text" onClick={goBack}>
        {text.getText('actions.back')}
      </Button>

      <Box
        className="boxVisible"
        title={text.getText(
          'components.views.overviewEvents.listHeaderTitleEdit'
        )}
      >
        <EventForm handleSubmit={handleSubmit} />
      </Box>
    </div>
  );
}
