import React from 'react';
import { Icon } from 'shared/components';
import './styles.less';

export const SearchInput = ({ className, ...props }) => (
  <div className={`SearchInput ${className || ''}`}>
    <Icon name="search" className="icon" />
    <input type="text" {...props} />
  </div>
);
