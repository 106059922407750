import React from 'react';
import { Chip, Tooltip } from '@material-ui/core';
import PowerIcon from '@material-ui/icons/PowerOutlined';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import BatteryChargingFullIcon from '@material-ui/icons/BatteryChargingFull';
import NightsStayOutlinedIcon from '@material-ui/icons/NightsStayOutlined';
import FlagOutlinedIcon from '@material-ui/icons/FlagOutlined';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import CheckIcon from '@material-ui/icons/Check';
import Avatar from '@material-ui/core/Avatar';
import { ReportTable } from 'shared/components';

export const EventTable = ({
  title,
  data,
  text,
  handleRowClick,
  toggleRowByConnections,
  rowByConnections,
  rowByConnectionsAction,
}) => {
  const textLocation = 'components.views.powerzones.detailedTable';

  //Render columns with chip and icon
  function renderChips(rowData, renderType, field, icon) {
    return renderType === 'row' ? (
      <div>
        {rowData[field].map(
          (type, index) =>
            type && (
              <Chip
                key={index}
                label={type}
                icon={icon && icon}
                variant="outlined"
              />
            )
        )}
      </div>
    ) : (
      rowData
    );
  }

  function renderListWithIcons(rowData, field, icon) {
    const cellContent = rowData[field];
    const tooltip = text.get(`spotTooltipTextLocation.${field || 'empty'}`);
    return (
      <Tooltip title={tooltip}>
        <div>{cellContent.map((item) => (item ? icon : ''))}</div>
      </Tooltip>
    );
  }

  return (
    <ReportTable
      title={title}
      data={data}
      onRowClick={handleRowClick}
      toggleRowByConnections={toggleRowByConnections}
      options={{
        pageSize: 20,
        exportFileName: title,
        padding: 'dense',
      }}
      customActions={[]}
      text={text}
      textLocation={textLocation}
      rowByConnections={rowByConnections}
      rowByConnectionsAction={rowByConnectionsAction}
      customColumnSettings={{
        pzName: {},
        powerInquiry: {
          lookup: { a: 454, b: 'lsdf' },
        },
        connectionName: {},
        connections: {
          render: (rowData, renderType) =>
            renderChips(
              rowData,
              renderType,
              'connections',
              <PowerIcon fontSize="small" />
            ),
        },
        meterIds: {
          render: (rowData, renderType) =>
            renderChips(rowData, renderType, 'meterIds'),
        },
        userCompany: {
          render: (rowData, renderType) =>
            renderType === 'row'
              ? rowData.userCompany && (
                  <Chip
                    label={rowData.userCompany}
                    avatar={<Avatar>{rowData.userCompany.charAt(0)}</Avatar>}
                    variant="outlined"
                  />
                )
              : rowData,
        },
        coordinate: {
          hiddenByColumnsButton: true,
          hidden: true,
        },
        powerOn: {
          grouping: rowByConnections,
          render: (rowData, renderType) =>
            renderChips(
              rowData,
              renderType,
              'powerOn',
              <AccessTimeIcon fontSize="small" />
            ),
        },
        nightPower: {
          hiddenByColumnsButton: false,
          hidden: true,
          grouping: false,
          render: (rowData) =>
            renderListWithIcons(
              rowData,
              'nightPower',
              <NightsStayOutlinedIcon color="secondary" />
            ),
        },
        upsNeeded: {
          hiddenByColumnsButton: false,
          hidden: true,
          grouping: false,
          render: (rowData) =>
            renderListWithIcons(
              rowData,
              'upsNeeded',
              <BatteryChargingFullIcon color="secondary" />
            ),
          cellStyle: {},
        },
        hasArrived: {
          hiddenByColumnsButton: true,
          hidden: true,
          grouping: false,
          render: (rowData) =>
            renderListWithIcons(
              rowData,
              'hasArrived',
              <FlagOutlinedIcon color="secondary" />
            ),
        },
        isConnected: {
          hiddenByColumnsButton: true,
          hidden: true,
          grouping: false,
          render: (rowData) =>
            renderListWithIcons(
              rowData,
              'isConnected',
              <AssignmentTurnedInOutlinedIcon color="secondary" />
            ),
        },
        estimated: {
          hiddenByColumnsButton: false,
          hidden: false,
          render: (rowData) =>
            rowData.estimated && <HelpOutlineIcon color="secondary" />,
        },
        origin: {
          hiddenByColumnsButton: true,
          hidden: true,
        },
        approved: {
          hiddenByColumnsButton: true,
          hidden: true,
          render: (rowData) =>
            rowData.approved && <CheckIcon color="primary" />,
        },
        updatedAt: {
          hiddenByColumnsButton: true,
          type: 'datetime',
        },
        _id: {
          hiddenByColumnsButton: true,
          hidden: true,
        },
        eventId: {
          hiddenByColumnsButton: true,
          hidden: true,
        },
      }}
    />
  );
};
