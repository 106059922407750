import React from 'react';
import { observer } from 'mobx-react';
import TextField from 'material-ui/TextField'; // Reference: http://www.material-ui.com/#/components/text-field
import styles from './styles';

/**
 * TextInput component
 * @param {object} props - React component properties
 * @return {React.Component}
 */
export const TextInput = observer(
  ({ field, type, hidden, placeholder, error, ...props }) => {
    if (hidden) return null;
    const fld = field.bind ? field.bind({ type }) : field;
    return (
      <TextField
        {...fld}
        hintText={placeholder || field.label}
        floatingLabelText={placeholder || field.label}
        fullWidth
        errorText={field.error || error}
        autoComplete="off"
        {...styles}
        {...props}
      />
    );
  }
);
