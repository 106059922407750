export default [
  'label',
  'cluster',
  'tags',
  'estimated',
  'layout',
  'historicItem',
  'layout.comment',
  'layout.connections',
  'layout.connections[].name',
  'layout.connections[].connectionSizeType',
  'layout.connections[].connectionSizeType.externalId',
  'layout.connections[].connectionSizeType.price',
  'layout.connections[].quantity',
  'layout.connections[].endDate',
  'layout.connections[].startDate',
  'layout.connections[].nightPower',
  'layout.connections[].hasArrived',
  'layout.connections[].isConnected',
  'layout.connections[].devices',
  'layout.connections[].devices[].name',
  'layout.connections[].devices[].ratedPower',
  'layout.connections[].devices[].quantity',
  'layout.connections[].devices[].unit',
  'layout.connections[].meterId',
  'coordinate',
];
