import { useGetUsers } from './useGetUsers';

export const useGetUser = (id) => {
  const { users, ...others } = useGetUsers();

  const user = users.find((u) => String(u._id) === String(id));

  return {
    ...others,
    user,
  };
};
