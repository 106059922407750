import { pushRoute, notify } from 'shared/actions';
import { Notifications } from 'shared/constants';

import * as Services from './service';
import { loadSpots } from 'spots/actions';

/**
 * Takes the event's id as input and load all of it's energy users
 * @param {string} eventId - Event id
 * @return {Promise}
 */
export const loadPowerInquiries = (eventId) =>
  Services.loadPowerInquiries(eventId);

/**
 * Takes an array of energy users email and spots as input and invite new energy users to the event.
 * @param {string} eventId - Event id
 * @param {object[]} invitation - Array of energy user invitation
 * @param {boolean} sendEmail - Send invitation e-mail
 * @return {Promise}
 */
export const createPowerInquiry = (eventId, powerInquiry) =>
  //This action is currently misused to allow updating a powerinquiry.
  Promise.resolve(eventId, powerInquiry)
    .then(() => Services.createPowerInquiry(eventId, powerInquiry))
    .then((result) => {
      loadPowerInquiries(eventId);
      loadSpots(eventId);
      pushRoute(`/event/${eventId}/inquiries`);
      return result;
    })
    .catch((error) =>
      notify({
        level: Notifications.Type.ERROR,
        message: error.message,
      })
    );

/**
 * Takes an array of energy users email and spots as input and invite new energy users to the event.
 * @param {string} eventId - Event id
 * @param {object[]} invitation - Array of energy user invitation
 * @param {boolean} sendEmail - Send invitation e-mail
 * @return {Promise}
 */
export const updateCreatePowerInquiry = (eventId, powerInquiry) =>
  //This is a misuse misusing the create inquiry endpoint to allow updating a powerinquiry.
  //TODO update it once BE is ready https://gitlab.com/watt-now/tool-backend/-/issues/112
  Promise.resolve(eventId, powerInquiry)
    .then(() => Services.createPowerInquiry(eventId, powerInquiry))
    .then((result) => {
      loadPowerInquiries(eventId);
      loadSpots(eventId);
      pushRoute(`/event/${eventId}/inquiries`);
      return result;
    })
    .catch((error) =>
      notify({
        level: Notifications.Type.ERROR,
        message: error.message,
      })
    );

export const updatePowerInquiry = (eventId, powerInquiry) =>
  //BE currently does not support update powerinquiry anymore https://gitlab.com/watt-now/tool-backend/-/issues/112
  Promise.resolve(eventId, powerInquiry)
    .then(() => Services.updatePowerInquiry(eventId, powerInquiry))
    .then(() => {
      loadPowerInquiries(eventId);
      pushRoute(`/event/${eventId}/inquiries`);
    })
    .catch((error) =>
      notify({
        level: Notifications.Type.ERROR,
        message: error.message,
      })
    );

/**
 * Takes an instance of energy user as input and send a request to the API to remove the user from the event.
 * @param {string} eventId - Event id
 * @param {object} user - Energy user instance
 * @return {Promise}
 */
export const removePowerInquiry = (eventId, powerInquiryId) =>
  Services.removePowerInquiry(eventId, powerInquiryId);

/**
 * Takes and xlsx file with imported accounts
 * and sends to the server for the validation
 * @param {object} data - form data
 * @param {string} eventId - Event Id
 * @return {Promise}
 */
export const parseImportPowerInquiries = (data, eventId) =>
  Services.parseImportPowerInquiries(data, eventId);

/**
 * Takes object of accountData, powerZone, areas, and spots
 * and sends to the server for the inserting to the database
 * @param {object} data - {[]AccountData, []Powerzone, []Area, []Spot}
 * @param {string} eventId - Event id
 * @return {Promise}
 */
export const applyImportPowerInquiries = (data, eventId) =>
  Services.applyImportPowerInquiries(data, eventId);

export const remindPowerInquiry = (eventId, powerInquiry) =>
  Promise.resolve(eventId, powerInquiry)
    .then(() => Services.remindPowerInquiry(eventId, powerInquiry))
    .then((result) => {
      pushRoute(`/event/${eventId}/inquiries`);
      return result;
    })
    .catch((error) =>
      notify({
        level: Notifications.Type.ERROR,
        message: error.message,
      })
    );
