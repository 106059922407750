import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { api } from 'shared/utils';
import { notify } from 'shared/actions';
import { Notifications } from 'shared/constants';
import { postApplianceMapper } from '../mapper';
import { APPLIANCES_QUERY_KEY } from 'appliances/constants';
import { getApplianceString } from 'appliances/utils';

const doCreateAppliance = async (appliance) => {
  return await api.post(`/appliancetemplates`, postApplianceMapper(appliance));
};

export const useCreateAppliance = () => {
  const queryClient = useQueryClient();
  const { mutateAsync, status, error, data, ...others } = useMutation(
    doCreateAppliance,
    {
      onSuccess: ({ data, status }, variables) => {
        if (status === 201) {
          // ✅ update the list we are currently on instantly
          queryClient.setQueryData(
            [APPLIANCES_QUERY_KEY, 'list', 'all'],
            (previous) => {
              return previous ? [...previous, data] : [data];
            }
          );

          // 🥳 invalidate all the lists, but don't refetch the active one
          queryClient.invalidateQueries({
            queryKey: [APPLIANCES_QUERY_KEY, 'list', 'all'],
            refetchActive: false,
          });

          //Notify the user
          notify({
            message: `Successfully created appliance: ${getApplianceString(
              data
            )}`,
            level: Notifications.Type.SUCCESS,
          });
        } else {
          notify({
            message: `Failed creating appliance:${getApplianceString(
              variables
            )}`,
            level: Notifications.Type.ERROR,
          });
        }

        return data;
      },
    }
  );

  const createAppliance = useCallback((appliance) => mutateAsync(appliance), [
    mutateAsync,
  ]);

  return {
    ...others,
    loading: status === 'loading',
    createAppliance,
  };
};
