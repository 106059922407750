import React from 'react';
import './styles.less';

/**
 * SideMenu component
 * @param {object} props - React component properties
 * @return {React.Component}
 */
export const SideMenu = ({ visible, overlay, ...props }) =>
  (visible && (
    <div className={`overlay ${overlay && 'show'}`}>
      <div {...props} />
    </div>
  )) ||
  null;
