export default (text) => ({
  name: text.name,
  language: text.language,
  email: text.email,
  role: text.role,
  'phones[].type': text.type,
  'phones[].number': text.phoneNumber,
  password: text.password,
  repeatPassword: text.repeatPassword,
  companyId: text.company,
  companyName: text.companyName,
  address: text.address,
  city: text.city,
  zipCode: text.zipCode,
  country: text.country,
  credits: text.credits,
});
