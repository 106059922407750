import React from 'react';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';

import { userHasPermission } from 'auth/actions';
import { Roles } from 'shared/constants';
import { Icon, MasterDetailsView } from 'shared/components';
import { limitText } from 'shared/utils';
import { pushRoute, confirm } from 'shared/actions';
import { isSpotFilled } from 'spots/utils';
import { saveSpotToBuffer, changeFormState } from 'spots/actions';

import './styles.less';

const enhance = compose(inject('spots', 'text'), observer);

const MAX_LABEL_SIZE = 24;

/**
 * EventSpotsMobile class represents the Desktop event spots management view.
 * @class EventSpotsDesktop
 * @extends {React.Component}
 */
export default enhance(
  class EventSpotsDesktop extends React.Component {
    /**
     * Renders the icon which best describes layout completion state.
     * @return {React.Component}
     * @param spot
     */
    renderStatusIcon = (spot) =>
      isSpotFilled(spot) && !spot.unSaved ? (
        <Icon name="check" className="icon active" />
      ) : (
        <Icon name="error-outline" className="icon" />
      );

    /**
     * Renders the correct path depends on unsaved active form data(from store) and mode(edit)
     * @return {React.Component}
     * @param spot
     */
    renderCorrectAction = (spot) => {
      const {
        text,
        spots: { isUnsavedData },
        editedSpot,
        onChangeModifySpot,
      } = this.props;

      const isSpotAndSavedData = !isSpotFilled(spot) && !isUnsavedData;
      if (isSpotAndSavedData) {
        onChangeModifySpot(null);
        pushRoute(`/event/${spot.eventId}/spots`);
      } else {
        if (isUnsavedData) {
          confirm(text.get('actions.confirmLeaveUnsaved'), () => {
            saveSpotToBuffer(spot);
            changeFormState(false);
            pushRoute(`/event/${spot.eventId}/spots/${spot._id}/connections`);
            onChangeModifySpot(spot._id);
          });
        } else {
          if (editedSpot !== null && editedSpot !== spot._id) {
            saveSpotToBuffer(spot);
            changeFormState(false);
            onChangeModifySpot(spot._id);
            pushRoute(`/event/${spot.eventId}/spots`);
          }
        }
        return;
      }

      const isFailedSpotAndUnSavedData = isSpotFilled(spot) && isUnsavedData;
      if (isFailedSpotAndUnSavedData) {
        confirm(text.get('actions.confirmLeaveUnsaved'), () => {
          changeFormState(false);
          pushRoute(`/event/${spot.eventId}/spots/${spot._id}/connections`);
          saveSpotToBuffer(spot);
          onChangeModifySpot(spot._id);
        });
        return;
      }

      const isFailedSpotAndEditedSpot =
        isSpotFilled(spot) && editedSpot !== null && editedSpot !== spot._id;
      if (isFailedSpotAndEditedSpot) {
        saveSpotToBuffer(spot);
        pushRoute(`/event/${spot.eventId}/spots/${spot._id}/connections`);
        onChangeModifySpot(spot._id);
        return;
      }

      saveSpotToBuffer(spot);
      pushRoute(`/event/${spot.eventId}/spots`);
    };

    /**
     * Renders the master list item
     *
     * @param {object} spot - Spot instance
     * @param {any} selected - Tells wether the element is selected or not
     * @return {React.Component}
     */
    renderListItem(spot, selected) {
      const { text } = this.props;
      return (
        <div
          onClick={() => this.renderCorrectAction(spot)}
          className={`masterItem ${selected && 'active'}`}
        >
          {userHasPermission([
            Roles.ADMIN,
            Roles.EVENT_CREATOR,
            Roles.INQUIRY_MANAGER,
          ]) && <h3 className="label">{limitText(spot.companyName, 32)}</h3>}
          <h4 className="label">{limitText(spot.label, MAX_LABEL_SIZE)}</h4>
          <div className="row">
            {this.renderStatusIcon(spot)}
            <span className="message">
              {isSpotFilled(spot) && !spot.unSaved
                ? text.get('actions.saved')
                : text.get('actions.notSaved')}
            </span>
          </div>
        </div>
      );
    }

    /**
     * Renders the component view.
     * @return {React.Component}
     */
    render() {
      const { renderDetailsView, spots } = this.props;
      // only show spots with meters for admins and event creators, because they only need this page for viewing energy data
      let spotList = [];
      if (userHasPermission([Roles.ADMIN, Roles.EVENT_CREATOR])) {
        spotList = spots.list.filter((spot) =>
          spot.layout.connections.some(
            (connection) => connection.meterId !== ''
          )
        );
      } else {
        spotList = spots.list;
      }

      return (
        <MasterDetailsView
          loading={spots.loading}
          masterItems={spotList}
          masterItemView={(obj, _, selected) =>
            this.renderListItem(obj, selected)
          }
          detailsView={(spot) => renderDetailsView(spot)}
        />
      );
    }
  }
);
