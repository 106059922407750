import React from 'react';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';

import './styles.less';

class DateTimePicker extends React.Component {
  state = {
    date: null,
    time: null,
    focused: null,
  };

  setData = () => {
    const { date } = this.props;

    this.setState({
      date: date ? moment(date) : null,
      time: date ? moment(date) : null,
    });
  };

  componentDidMount() {
    this.setData();
  }

  componentDidUpdate(prevPros) {
    if (prevPros !== this.props) {
      this.setData();
    }
  }

  isInclusivelyAfterDay = (minDate, a, b) => {
    if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
    return !this.isBeforeDay(minDate, a, b);
  };

  isBeforeDay = (minDate, a, b) => {
    if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
    if (a > minDate) {
      const aYear = a.year();
      const aMonth = a.month();

      const bYear = b.year();
      const bMonth = b.month();

      const isSameYear = aYear === bYear;
      const isSameMonth = aMonth === bMonth;

      if (isSameYear && isSameMonth) return a.date() <= b.date();
      if (isSameYear) return aMonth < bMonth;
      return aYear < bYear;
    }
  };

  handleDateChange = (date) => {
    const { time } = this.state;

    if (time) {
      date.hour(time.hour());
      date.minute(time.minute());
    }

    this.setState({
      date,
      time: date, // keep date same
    });

    this.props.onDateChange(date && date.toISOString());
  };

  handleTimeChange = (time) => {
    this.setState({
      time: time,
      date: time, // keep hh:mm same
    });

    this.props.onDateChange(time && time.toISOString());
  };

  render() {
    let { id, minDate, maxDate, timeText, time: st, ...rest } = this.props;
    const { date, time } = this.state;
    maxDate = maxDate ? moment(maxDate) : null;
    minDate = minDate ? moment(minDate) : null;

    return (
      <div className="DateTimePicker">
        <div className="dateBox">
          <SingleDatePicker
            {...rest}
            id={id}
            withPortal
            onDateChange={this.handleDateChange}
            onFocusChange={({ focused }) => this.setState({ focused })}
            focused={this.state.focused}
            enableOutsideDays={false}
            date={date}
            isOutsideRange={(day) =>
              this.isInclusivelyAfterDay(minDate, day, maxDate)
            }
            displayFormat="DD/MM/YYYY"
            numberOfMonths={1}
            readOnly
            noBorder
            small
          />
        </div>
        <span className="separator">/</span>
        <div className="timesBox">
          <TimePicker
            placeholder={timeText}
            showSecond={false}
            minuteStep={15}
            value={time}
            onChange={this.handleTimeChange}
            defaultOpenValue={moment(new Date(0, 0, 0, 8, 0, 0, 0))}
            focusOnOpen
          />
        </div>
      </div>
    );
  }
}

export { DateTimePicker };
