import { action, extendObservable } from 'mobx';

/**
 * UsersStore is the Mobx global data storage for the users data.
 * @class UsersStore
 */
function UsersStore() {
  extendObservable(this, {
    list: [],
    loading: false,
    ids: [],
    get filteredUsers() {
      return this.list.filter(
        (user) => (this.ids || []).indexOf(user._id) === -1
      );
    },
    getUser(userId) {
      const result = this.list.find((obj) => obj._id === parseInt(userId, 10));
      return result;
    },
    getUserByEmail(email) {
      return this.list.find((obj) => obj.email === email);
    },
    getUsersByRole(roleIds) {
      const ids = roleIds instanceof Array ? roleIds : [roleIds];
      return this.list.filter((obj) => ids.includes(obj.role));
    },
    getUsersByCompany(companyIds) {
      const ids = companyIds instanceof Array ? companyIds : [companyIds];
      return this.list.filter((obj) => ids.includes(obj.companyId));
    },
    /** returns users with provided role and filtered by company if companyId(s) are provided */
    getUsers(roleIds, companyIds) {
      //make sure we get arrays
      const roleArr = roleIds instanceof Array ? roleIds : [roleIds];
      const compArr = companyIds instanceof Array ? companyIds : [companyIds];
      let result = [];
      if (roleIds) {
        result = result.filter((user) => roleArr.includes(user.role));
      }
      if (companyIds) {
        result = result.filter((user) => compArr.includes(user.companyId));
      }
      return result;
    },
    isLoading: action((value) => {
      this.loading = !!value;
    }),
    registerUsers: action((users) => {
      this.list = users;
    }),
    registerNewUser: action((user) => {
      if (this.getUser(user._id)) {
        this.updateUser(user);
      } else {
        this.list.push(user);
      }
    }),
    updateUser: action((user) => {
      const index = this.list.findIndex((obj) => obj.uri === user.id);
      if (index > -1) this.list[index] = user;
    }),
    removeUser: action((userId) => {
      const index = this.list.findIndex(
        (obj) => obj._id === parseInt(userId, 10)
      );
      this.list.splice(index, 1);
    }),
    setIds: action((ids) => {
      this.ids = ids;
    }),
  });
}
export const users = new UsersStore();
