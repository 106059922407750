import { useCallback } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import { api } from 'shared/utils';
import { AREA_QUERY_KEY } from '../constants';
import { areas as areasStore } from '../store';
import areaMapper from '../mapper';

const doUpdateArea = async (area) => {
  const { data } = await api.put(area.uri, areaMapper(area.eventId)(area));
  return data;
};
export const useUpdateArea = () => {
  const queryClient = useQueryClient();
  const { mutate, status, ...others } = useMutation(doUpdateArea, {
    onSuccess: (response, variables) => {
      // Make sure area list will be refetched
      queryClient.invalidateQueries([
        AREA_QUERY_KEY,
        'eventId',
        variables.eventId,
      ]);
      // TODO: remove when no mobx anymore
      areasStore.updateArea(variables);
      return response;
    },
  });

  const updateArea = useCallback((area) => mutate(area), [mutate]);

  return {
    ...others,
    loading: status === 'loading',
    updateArea,
  };
};
