import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Grid, Typography } from '@material-ui/core';

import { useGetText } from 'shared/hooks';
import { Button } from 'shared/components/form';

import ConnectionsList from './ConnectionsList';
import * as Constants from './constants';
import { getDefaultValues } from './defaultValues';
import schema from './schema';

export default function ConnectionSizeForm({
  onSuccess,
  initialValues,
  stepBack,
}) {
  const text = useGetText();
  const { handleSubmit, control, isError, watch } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: getDefaultValues(initialValues),
  });

  const formValues = watch();

  function handleSuccess(connectionObj) {
    const connectionArr = Object.entries(connectionObj).reduce(
      (result, [key, item]) => {
        //Todo why are we getting one obj = undefined in connectionObj as a result from the react hook form onSubmit?
        if (item.selected) {
          //Leave out connections that do not have matching externalId
          if (Constants.CONNECTIONS_EXTERNAL_ID_REGEX.test(key))
            result.push({
              externalId: key,
              price: item.price,
            });
          else
            console.error(
              `Connection externalId (${key}) does not match pattern 32A-1P-230V-CEE, connection is not added to event`
            );
        }
        return result;
      },
      []
    );
    onSuccess(connectionArr);
  }

  return (
    <>
      <form onSubmit={handleSubmit(handleSuccess)}>
        <Grid container spacing={5} direction="row">
          <Grid
            item
            container
            spacing={2}
            direction="column"
            style={{ mt: 12 }}
          >
            <Typography
              variant="h6"
              style={{ textAlign: 'center', marginBottom: '8px' }}
            >
              {text.getText('forms.spot.1phase')}
            </Typography>
            <ConnectionsList
              connections={Constants.CONNECTIONS_ONE_PHASE}
              control={control}
              formValues={formValues}
            />
          </Grid>
          <Grid
            item
            container
            spacing={2}
            direction="column"
            style={{ mt: 12 }}
          >
            <Typography
              variant="h6"
              style={{ textAlign: 'center', marginBottom: '8px' }}
            >
              {text.getText('forms.spot.3phase')}
            </Typography>
            <ConnectionsList
              connections={Constants.CONNECTIONS_THREE_PHASE}
              control={control}
              formValues={formValues}
            />
          </Grid>
        </Grid>
        <div className="buttonRow">
          <Button
            type="submit"
            styling="cta"
            disabled={isError}
            children={text.getText('actions.save')}
          />
          <Button
            styling="text"
            onClick={stepBack}
            disabled={isError}
            children={text.getText('actions.cancel')}
          />
        </div>
      </form>
    </>
  );
}
