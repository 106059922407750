import { api } from 'shared/utils';

import { spots as spotsStore } from './store';
import spotMapper from './mapper';
import { loadPowerInquiries } from 'powerinquiries/service';

/**
 * Takes an event id as input and downloads all the spots
 * in the current event from the API.
 * @param {string} eventId - Event id
 * @return {Promise}
 */
export const loadSpots = (eventId, userId = '') =>
  Promise.resolve(spotsStore.isLoading(true))
    .then(() =>
      api.get(`/events/${eventId}/spots${userId && `?userId=${userId}`}`)
    )
    .then(({ status, data }) => {
      if (status === 200) {
        const spots = data.map(spotMapper());
        spotsStore.registerSpots(spots);
        return spots;
      }
    })
    .then((spots) => {
      spotsStore.isLoading(false);
      return spots;
    });

/**
 * Takes an event id as input and downloads all the spots
 * in the current event from the API.
 * @param {string} eventId - Event id
 * @param userId
 * @return {Promise}
 */
export const getEventUserSpots = (eventId, userId = '') =>
  api
    .get(`/events/${eventId}/spots${userId && `?userId=${userId}`}`)
    .then(({ status, data }) => {
      if (status === 200) {
        return data.map(spotMapper(eventId));
      }
    });

/**
 * Takes a company id as input and downloads all the spots
 * in the current event from the API.
 * @param {string} companyId - Event id
 * @return {Promise}
 */
export const getCompanySpots = (companyId) =>
  Promise.resolve(spotsStore.isLoading(true))
    .then(() => api.get(`/companies/${companyId}/spots`))
    .then(({ status, data }) => {
      if (status === 200) {
        const spots = data.map((spot) => spotMapper(spot));
        spotsStore.registerSpots(spots);
        return spots;
      }
    })
    .then((spots) => {
      spotsStore.isLoading(false);
      return spots;
    });

/**
 * Takes a spot data object and sends it to the API to create in the database.
 * @param {object} spot - Spot instance
 * @param {string} eventId - Event id
 * @return {Promise}
 */
export const createSpot = (spot, eventId) =>
  Promise.resolve(spotsStore.isLoading(true))
    .then(() => api.post(`/events/${eventId}/spots`, spotMapper(eventId)(spot)))
    .then(({ status, data }) => {
      if (status === 201) {
        const newSpot = spotMapper(eventId)({
          ...data,
          ...spot,
        });
        spotsStore.registerNewSpot(newSpot);
        return newSpot;
      }
    })
    .then((newSpot) => {
      spotsStore.isLoading(false);
      return newSpot;
    });

/**
 * Takes a spot data object and sends it to the API to update in the database.
 * @param {object} spot - Spot instance
 * @return {Promise}
 */
export const updateSpot = (data) => {
  const spot = spotMapper(data.eventId)(data);
  return Promise.resolve(spotsStore.isLoading(true))
    .then(() => api.put(spot.uri, spot))
    .then(({ status }) => {
      if (status === 200) {
        const tmp = spotMapper(spot.eventId)(spot);
        spotsStore.updateSpot(tmp);
        return tmp;
      }
    })
    .then((tmp) => {
      spotsStore.isLoading(false);
      return tmp;
    });
};

/**
 * Takes a spot data object and sends it's approval status to the API to update in the database.
 * @param {object} spot - Spot instance
 * @return {Promise}
 */
export const updateSpotApproval = (spot) =>
  Promise.resolve(spotsStore.isLoading(true))
    .then(() => api.put(`${spot.uri}/approved`, { approved: spot.approved }))
    .then(({ status }) => {
      if (status === 200) {
        const tmp = spotMapper(spot.eventId)(spot);
        spotsStore.updateSpot(tmp);
      }
    })
    .then(() => spotsStore.isLoading(false));

/**
 * Takes a spot data object and send a request with it's ID to the API to remove it from the database.
 * @param {object} spot - Spot instance
 * @return {Promise}
 */
export const removeSpot = (spot, powerInquiry) =>
  Promise.resolve(spotsStore.isLoading(true))
    .then(() => api.delete(`/events/${spot.eventId}/spots/${spot._id}`))
    .then(({ status, data }) => {
      if (status === 200) {
        spotsStore.removeSpot(spot);
        if (powerInquiry) loadPowerInquiries(spot.eventId);
      }
    })
    .then((spot) => {
      spotsStore.isLoading(false);
      return spot;
    });

/**
 * Takes a state of active form with unsaved data
 * @param {boolean} state - is form changed
 * @return {Promise}
 */
export const changeFormState = (state) => spotsStore.isDirtyForm(state);
