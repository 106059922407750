import React from 'react';
import PropTypes from 'prop-types';
import { SidePane } from 'shared/components';
import { pushRoute } from 'shared/actions';

//TODO move the components imported below to their dedicated domain folders
import AreaDetails from 'areas/components/areaDetails';
import SpotDetails from '../../spots/components/SidePaneSpotDetails';

import { PowerzoneDetails } from './PowerzoneDetails';
/**
 * DetailsPane class represents the view component responsible for handling the sidepane
 * in the Treeview, which is presented when clicking on the tree node to present details.
 * @class DetailsPane
 * @extends {React.Component}
 */
export default class DetailsPane extends React.Component {
  /**
   * Changes side pane visibility state
   * @param {boolean} visible - Toggles visibility on/off
   * @return {void}
   */

  //TODO use the MUI internal prop from the 'Drawer' component
  toggleVisibility = (visible) => this.refs.pane.toggleVisibility(visible);

  /**
   * Action triggered when the 'pencil' button is clicked in the details pane.
   * @param {object} item - Node instance
   * @param {string} eventId - Event id
   * @return {void}
   */
  onClickEdit(item, eventId) {
    if (!item) return null;
    else if (item.uri.includes('spots'))
      pushRoute(`/event/${eventId}/powerzones/${item._id}/editspot`);
    else if (this.props.onClickEdit) this.props.onClickEdit(item);
  }

  /**
   * Action triggered when user deletes the spot
   * @param {object} item - Node instance
   * @return {void}
   */
  removeSpot = (item) => {
    this.props.removeSpot(item);
    this.refs.pane.toggleVisibility(false);
  };

  /**
   * Renders the correct view depending on the model received as input.
   * @param {object} item - Item instance
   * @return {React.Component}
   */
  renderContent(item) {
    if (!item) return null;
    else if (item.uri.includes('areas')) return <AreaDetails item={item} />;
    else if (item.uri.includes('powerzones'))
      return <PowerzoneDetails item={item} />;
    else if (item.uri.includes('spots'))
      return (
        <SpotDetails
          userId={item.userId}
          showSpotForm={this.props.showSpotForm}
          removeSpot={(item) => this.removeSpot(item)}
          eventId={item.eventId}
          item={item}
        />
      );
  }

  /**
   * Renders the component view.
   * @return {React.Component}
   */
  render() {
    const { item, text } = this.props;
    let editHandler = null;
    if (item && !item.uri.includes('spots')) {
      editHandler = () => this.onClickEdit(item, item.eventId);
    }
    return (
      <SidePane
        ref="pane"
        item={item}
        text={text}
        children={this.renderContent(item)}
        editHandler={editHandler}
      />
    );
  }
}

DetailsPane.propTypes = {
  selectedNode: PropTypes.shape({
    children: PropTypes.arrayOf(PropTypes.object),
  }),
};
