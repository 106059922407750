import React from 'react';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';

/**
 * Confirm class represents the component responsible for the confirm alert box.
 *
 * @export
 * @class Confirm
 * @extends {React.Component}
 */
export class ConfirmDialog extends React.Component {
  // {object} state - Component state holds information about visibility, message and what to do next.
  state = { visible: false, message: '', callback: () => {} };

  // {React.Component[]} actions - List of buttons to present in the box
  get actions() {
    const { text } = this.props;
    return [
      <FlatButton
        label={text.get('actions.cancel')}
        primary={true}
        onClick={() => this.close(false)}
      />,
      <FlatButton
        label={text.get('actions.ok')}
        primary={true}
        onClick={() => this.close(true)}
      />,
    ];
  }

  /**
   * Shows a confirmation box with the given message. Calls the given callback if
   * the user confirm.
   *
   * @param {string} message - Message to present
   * @param {function} callback - Function to call on confirmation
   * @return {void}
   */
  show(message, callback) {
    this.setState({ visible: true, message, callback });
  }

  /**
   * Close the view.
   * @param {boolean} response - User clicked ok/cancel
   * @return {void}
   */
  close(response) {
    if (response) this.state.callback();
    this.setState({ visible: false, message: '', callback: () => {} });
  }

  /**
   * Renders the component view.
   * @return {React.Component}
   */
  render() {
    return (
      <Dialog
        modal
        actions={this.actions}
        open={this.state.visible}
        children={this.state.message}
      />
    );
  }
}
