import * as yup from 'yup';
import { ALL_CONNECTIONS } from './constants';

const schema = () => {
  const validationObj = {};
  Object.entries(ALL_CONNECTIONS).forEach((item) => {
    validationObj[item.externalId] = yup
      .object({
        selected: yup.boolean(),
        price: yup.number().positive().nullable(true),
      })
      .required();
  });
  return yup.object(validationObj);
};

export default schema();
