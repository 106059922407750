import React from 'react';
import { observer } from 'mobx-react';
import TextField from 'material-ui/TextField'; // Reference: http://www.material-ui.com/#/components/text-field
import moment from 'moment';
import styles from './styles';

/**
 * DateInput component
 * @param {object} props - React component properties
 * @return {React.Component}
 */
export const DateInput = observer(({ field, type, value, ...props }) => {
  if (value instanceof moment) field.set(value && value.toISOString());
  else if (value) field.set(moment(value).toISOString());

  return (
    <div>
      <TextField
        hintText={field.label}
        floatingLabelText={field.label}
        fullWidth
        errorText={field.error}
        {...styles}
        value={(value && moment(value).format('DD/MM/YYYY')) || ''}
        {...props}
      />
    </div>
  );
});
