import React from 'react';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';

import { flattenChildren } from 'powerzones/utils';
import DetailsTable from 'powerzones/components/NodeDetailsTable';

import PowerzoneOverviewBox from './powerzoneOverviewBox';
import './styles.less';
import { toJS } from 'mobx';

const enhance = compose(inject('text'), observer);

/**
 * PowerzoneDetails class component represents the view which presents the details
 * of the selected powerzone in the sidepane.
 * @export
 * @class PowerzoneDetails
 * @extends {React.Component}
 */
export const PowerzoneDetails = enhance(({ item, text }) => {
  const spots = flattenChildren(item.children);

  //cast Mobx observable values to JS so that we do not depend on Mobx
  let powerzone = {};
  Object.entries(item).forEach(([key, value]) => {
    powerzone[key] = toJS(value);
  });

  return (
    <div className="EventPowerzoneDetails">
      <div className="powerzoneHeading">
        <div className="left column">
          <span
            className="title screen"
            children={text.get('forms.powerzone.formTitle')}
          />
          <span className="title print" children={item.label} />
        </div>
      </div>
      <PowerzoneOverviewBox
        className="OverviewBox"
        powerzone={powerzone}
        text={text}
        spots={spots}
      />
      <header className="print-header">
        <img
          src={`${window.location.origin}/assets/logos/logo-greener.svg`}
          alt="logo greener"
        />
      </header>
      <DetailsTable className="details" spots={spots} />
    </div>
  );
});
