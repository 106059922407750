import React from 'react';

import { updateSpot } from 'spots/actions';

import { SpotDetails } from 'spots/components';

import './styles.less';

/**
 * SpotDetails class component controls the details view for spots.
 * @class SpotDetails
 * @extends {React.Component}
 */

//TODO can we user SpotDetails directly without using PowerzoneSpotDetails?
export function PowerzoneSpotDetails({
  item,
  eventId,
  userId,
  removeSpot,
  showSpotForm,
}) {
  return (
    <div className="SpotDetails padding">
      <SpotDetails
        showSpotForm={showSpotForm}
        eventId={eventId}
        spot={item}
        userId={userId}
        //removeSpot={removeSpot} //todo this prop is undefined, add it to allow deleting spot from sidepane  https://gitlab.com/watt-now/tool-frontend/-/issues/361
        onSpotUpdate={updateSpot}
      />
    </div>
  );
}
