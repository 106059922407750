import { action, extendObservable } from 'mobx';

/**
 * TemplatesStore is the Mobx global data storage for the Templates data.
 * @class TemplatesStore
 */
function TemplatesStore() {
  extendObservable(this, {
    list: [],
    loading: false,
    getTemplate(uri) {
      return this.list.find((obj) => obj.uri === uri);
    },
    getTemplateById(id) {
      return this.list.find((obj) => obj._id === parseInt(id, 10));
    },
    isLoading: action((value) => {
      this.loading = Boolean(value);
    }),
    registerTemplates: action((templates) => {
      this.list = templates;
    }),
    registerNewTemplate: action((template) => {
      this.list.push(template);
    }),
    removeTemplate: action((template) => {
      const index = this.list.findIndex((obj) => obj.uri === template.uri);
      if (index > -1) this.list.splice(index, 1);
    }),
    updateTemplate: action((template) => {
      const index = this.list.findIndex((obj) => obj.uri === template.uri);
      if (index > -1) this.list[index] = template;
    }),
  });
}
export default new TemplatesStore();
