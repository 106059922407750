// {object} EMPTY_FORM - Empty form object
export const EMPTY_FORM = {
  name: null,
  phones: null,
  line1: null,
  line2: null,
  city: null,
  state: null,
  country: null,
  zipCode: null,
  canSeeApplianceTemplates: false,
};
