import { Notifications } from 'shared/constants';
import { pushRoute, notify } from 'shared/actions';
import { api } from 'shared/utils';

import { text as textStore } from 'shared/stores';
import { users as userStore } from 'users/store';

/**
 * Takes a user profile data object as input and sends it to the API to save it's information
 * into the database.
 * @param {object} profile - Profile data object
 * @return {Promise}
 */
export const updateProfile = (profile) =>
  Promise.resolve(userStore.isLoading(true))
    .then(() => api.patch('/users/makeprofile', profile))
    .then(({ status }) => {
      if (status === 200) {
        pushRoute('/events');
        userStore.updateUser(profile);
        notify({
          message: textStore.get('forms.profile.formSuccess'),
          level: Notifications.Type.SUCCESS,
        });
      }
    })
    .then(() => userStore.isLoading(false));
