// {number} ADMIN - Admin role value
export const ADMIN = 1;

// {number} EVENT_CREATOR - Event Creator role value
export const EVENT_CREATOR = 2;

// {number} POWER_SUPPLIER - Power Supplier role value
export const POWER_SUPPLIER = 4;

// {number} ENERGY_USER - Energy User role value
export const ENERGY_USER = 8;

// {number} INQUIRY_MANAGER - Inquiry Manager role value
export const INQUIRY_MANAGER = 16;

// {number} ALL - Sum of all roles value
export const ALL =
  ADMIN + EVENT_CREATOR + POWER_SUPPLIER + ENERGY_USER + INQUIRY_MANAGER;

// export const ALL = [ADMIN, EVENT_CREATOR, POWER_SUPPLIER, ENERGY_USER, INQUIRY_MANAGER]

export const roleIds = [1, 2, 4, 8, 16];
