import React from 'react';
import { Table, TableBody, TableRow, TableRowColumn } from 'material-ui/Table';
import ReactTooltip from 'react-tooltip';
import { Box as MuiBox, Typography, CircularProgress } from '@material-ui/core';
import { Doughnut } from 'react-chartjs-2';

import { Box } from 'shared/components';
import { Spots } from 'spots/constants';
import { formatDate } from 'shared/utils';
import { calculateTotalTheoreticalPower } from 'spots/utils';

import { flattenChildren } from 'powerzones/utils';
import { DEMAND_PARAMETERS } from 'powerzones/constants';
import './styles.less';

/**
 * Returns the powerzone demand table component.
 * @return {React.Component}
 */
export default class DemandTable extends React.Component {
  // {object} state - State object holds information about the current element generator size and it's id
  state = {
    spots: [],
    powerzoneId: null,
    all: [],
    isAllSpotsEmpty: false,
    widgetData: {
      approved: null,
      estimated: null,
      energyUser: null,
      powerPlanner: null,
      historicItem: null,
      empty: null,
      imported: null,
    },
  };

  get theoreticalPower() {
    return calculateTotalTheoreticalPower(this.state.spots);
  }

  get predictedPeakLoad() {
    return (
      DEMAND_PARAMETERS.maximumEstimatedSimultaneityFactor *
      this.theoreticalPower
    );
  }

  get predictedPeakCurrent() {
    return (
      (this.theoreticalPower /
        DEMAND_PARAMETERS.AVG_RATED_POWER_FACTOR /
        DEMAND_PARAMETERS.RATED_VOLTAGE_1P /
        3) *
      1000 *
      DEMAND_PARAMETERS.maximumEstimatedSimultaneityFactor
    ).toFixed(1);
  }

  spotOrigin() {
    const { spots } = this.state;
    let approved = 0,
      estimated = 0,
      energyUser = 0,
      powerPlanner = 0,
      empty = 0,
      historicItem = 0,
      imported = 0;
    spots.forEach((item) => {
      if (item.approved) {
        approved += 1;
      }
      if (item.estimated) {
        estimated += 1;
      }
      if (item.historicItem) {
        historicItem += 1;
      }
      if (
        item.layout &&
        item.layout.connections &&
        item.layout.connections.length > 0
      ) {
        if (item.origin === 'energyUser') {
          energyUser += 1;
        }
        if (item.origin === 'powerPlanner') {
          powerPlanner += 1;
        }
        if (item.origin === 'imported') {
          imported += 1;
        } else {
          empty += 1;
        }
      }
    });

    this.setState({
      all: spots.length,
      widgetData: {
        approved: approved,
        estimated: estimated,
        energyUser: energyUser,
        powerPlanner: powerPlanner,
        empty: empty,
        historicItem: historicItem,
        imported: imported,
      },
      isAllSpotsEmpty: ![
        approved,
        estimated,
        energyUser,
        powerPlanner,
        empty,
        historicItem,
      ].some((element) => element > 0)
        ? 1
        : 0,
    });
  }

  /**
   * Changes the state of currentpowerzone
   * @return {void}
   */
  changePowerZone = (id, children) => {
    const spots = flattenChildren(children);
    this.setState({ spots, powerzoneId: id }, () => this.spotOrigin());
  };

  /**
   * React lifecycle method - executed everytime the component is about to be mounted in the view.
   * @return {void}
   */
  UNSAFE_componentWillMount() {
    const {
      powerzone: { _id, children },
    } = this.props;
    this.changePowerZone(_id, children);
  }

  /**
   * React lifecycle method - executed everytime the component is receiving new props
   * @param {object} newProps - New props received
   * @return {void}
   */
  UNSAFE_componentWillReceiveProps({ powerzone: { _id, children } }) {
    if (this.state.powerzoneId !== _id) this.changePowerZone(_id, children);
  }

  /**
   * Returns the list of approved spots.
   * @return {Array}
   */
  getApprovedSpots = () =>
    this.state.spots.filter((spot) => spot.approved === true);

  /**
   * Renders the spots progressBar.
   * @return {React.Element}
   */
  renderSpotsProgressBar = () => {
    const percentageApproved =
      Math.round(
        (100 / this.state.spots.length) * this.getApprovedSpots().length
      ) || 0;

    return (
      <MuiBox position="relative" display="inline-flex">
        <CircularProgress
          size={54}
          variant="determinate"
          value={percentageApproved}
        />
        <MuiBox
          top={0}
          left={0}
          bottom={-5}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="caption" component="div" color="textSecondary">
            {`${percentageApproved} %`}
          </Typography>
        </MuiBox>
      </MuiBox>
    );
  };

  /**
   * Renders the component view.
   * @return {React.Component}
   */
  render() {
    const { text, spotDates } = this.props;
    const {
      all,
      isAllSpotsEmpty,
      widgetData: {
        empty,
        historicItem,
        powerPlanner,
        approved,
        energyUser,
        estimated,
        imported,
      },
      spots,
    } = this.state;
    const title = (
      <span>{text.get('components.views.powerzones.demand.title')}</span>
    );

    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      cutoutPercentage: '80',
      title: {
        display: false,
        fontFamily: 'Arial',
        text: `Spots: ${spots.length}`,
        fontStyle: 'normal',
        fontColor: '#000',
        fontSize: 15,
      },
      legend: { display: false },
      tooltips: false,
    };

    const widgetProps = {
      labels: [
        `${text.get('components.views.powerzones.demand.empty')}`,
        `${text.get('components.views.powerzones.demand.estimated')}`,
        `${text.get('components.views.powerzones.demand.powerPlanner')}`,
        `${text.get('components.views.powerzones.demand.energyUser')}`,
        `${text.get(
          'components.views.powerzones.demand.estimatedBasedOnHistory'
        )}`,
        `${text.get('components.views.powerzones.demand.imported')}`,
        'spots list is empty',
      ],
      datasets: [
        {
          data: [
            approved === spots.length ? approved : 0,
            empty,
            estimated,
            powerPlanner,
            energyUser,
            historicItem,
            isAllSpotsEmpty,
            imported,
          ],
          backgroundColor: [
            approved === spots.length ? Spots.colors.ALL_EMPTY : '',
            Spots.colors.EMPTY,
            Spots.colors.ESTIMATED,
            Spots.colors.POWER_PLANER,
            Spots.colors.ENERGY_USER,
            Spots.colors.HISTORIC,
            Spots.colors.ALL_EMPTY,
            Spots.colors.IMPORTED,
          ],
          hoverBackgroundColor: [
            approved === spots.length ? Spots.colors.ALL_EMPTY : '',
            Spots.colors.EMPTY,
            Spots.colors.ESTIMATED,
            Spots.colors.POWER_PLANER,
            Spots.colors.ENERGY_USER,
            Spots.colors.HISTORIC,
            Spots.colors.ALL_EMPTY,
            Spots.colors.IMPORTED,
          ],
        },
      ],
    };

    return (
      <div className="OverviewBox">
        <ReactTooltip
          id="powerzone-tooltip"
          aria-haspopup="true"
          effect="solid"
        >
          <p>Categories:</p>
          <ul className="spot-categories">
            <li className="all">
              <span>{`${text.get(
                'components.views.powerzones.demand.all'
              )}: ${all}`}</span>
            </li>
            <li className="empty">
              <span>{`${text.get(
                'components.views.powerzones.demand.empty'
              )}: ${empty}`}</span>
            </li>
            <li className="estimated">
              <span>{`${text.get(
                'components.views.powerzones.demand.estimated'
              )}: ${estimated}`}</span>
            </li>
            <li className="powerPlanner">
              <span>{`${text.get(
                'components.views.powerzones.demand.powerPlanner'
              )}: ${powerPlanner}`}</span>
            </li>
            <li className="energyUser">
              <span>{`${text.get(
                'components.views.powerzones.demand.energyUser'
              )}: ${energyUser}`}</span>
            </li>
            <li className="historic">
              <span>{`${text.get(
                'components.views.powerzones.demand.estimatedBasedOnHistory'
              )}: ${historicItem}`}</span>
            </li>
            <li className="imported">
              <span>{`${text.get(
                'components.views.powerzones.demand.imported'
              )}: ${imported}`}</span>
            </li>
          </ul>
        </ReactTooltip>
        <Box title={title}>
          <Table className="DemandTable" selectable={false}>
            <TableBody key="summary-table-body" displayRowCheckbox={false}>
              <TableRow displayBorder={false}>
                <TableRowColumn className="header-col">
                  <span>
                    {text.get('components.views.powerzones.demand.spotOrigin')}
                  </span>
                </TableRowColumn>
                <TableRowColumn className="chart-col">
                  <span
                    className="spots-title"
                    data-for="powerzone-tooltip"
                    data-tip
                  >
                    Spots: {this.state.spots.length}
                  </span>
                  <div className="pie-chart-container">
                    <Doughnut
                      width={85}
                      options={chartOptions}
                      data={widgetProps}
                    />
                  </div>
                </TableRowColumn>
              </TableRow>
              <TableRow displayBorder={false}>
                <TableRowColumn className="header-col">
                  <span>
                    {text.get(
                      'components.views.powerzones.demand.spotsApproved'
                    )}
                  </span>
                </TableRowColumn>
                <TableRowColumn className="chart-col">
                  {this.renderSpotsProgressBar()}
                </TableRowColumn>
              </TableRow>
              <TableRow displayBorder={false}>
                <TableRowColumn className="header-col">
                  <span>
                    {text.get(
                      'components.views.powerzones.demand.predictedSimultaneityFactor'
                    )}
                  </span>
                </TableRowColumn>
                <TableRowColumn className="chart-col">55 %</TableRowColumn>
              </TableRow>
              <TableRow displayBorder={false}>
                <TableRowColumn className="header-col">
                  <span>
                    {text.get(
                      'components.views.powerzones.demand.predictedPeakCurrent'
                    )}
                  </span>
                </TableRowColumn>
                <TableRowColumn className="chart-col">
                  {Math.ceil(this.predictedPeakCurrent)} A
                </TableRowColumn>
              </TableRow>
              <TableRow displayBorder={false}>
                <TableRowColumn className="header-col">
                  <span>
                    {text.get(
                      'components.views.powerzones.demand.predictedPeakPower'
                    )}
                  </span>
                </TableRowColumn>
                <TableRowColumn className="chart-col">
                  {Math.ceil(this.predictedPeakLoad)} kW
                </TableRowColumn>
              </TableRow>
              <TableRow displayBorder={false}>
                <TableRowColumn className="header-col">
                  <span>
                    {text.get(
                      'components.views.powerzones.demand.earliestStartDate'
                    )}
                  </span>
                </TableRowColumn>
                <TableRowColumn className="chart-col">
                  {formatDate(spotDates.earliestStartDate)}
                </TableRowColumn>
              </TableRow>
              <TableRow displayBorder={false}>
                <TableRowColumn className="header-col">
                  <span>
                    {text.get(
                      'components.views.powerzones.demand.latestEndDate'
                    )}
                  </span>
                </TableRowColumn>
                <TableRowColumn className="chart-col">
                  {formatDate(spotDates.latestEndDate)}
                </TableRowColumn>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </div>
    );
  }
}
