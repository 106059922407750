import React from 'react';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';

import { Spinner } from 'shared/components';
import { updatePowerInquiry, loadPowerInquiries } from 'powerinquiries/actions';
import { loadEvents } from 'events/actions';
import { PowerInquiryForm } from 'powerinquiries/components';

import { createSpot, updateSpot } from 'spots/actions';
import { loadUsers } from 'users/actions';

import { loadCompanies } from 'companies/actions';

const enhance = compose(
  inject('users', 'companies', 'events', 'powerInquiries', 'text'),
  observer
);

/**
 * PowerInquiryEditPage class represents the view which contains a form to add a new energy user
 * to the event.
 * @class PowerInquiryEditPage
 * @extends {React.Component}
 */
export const PowerInquiryEditPage = enhance(
  class extends React.Component {
    onSuccess = (form) => {
      const {
        params: { eventId },
      } = this.props;
      const powerInquiry = form.values();

      powerInquiry.spots.map((spot) => {
        spot.powerInquiryId = powerInquiry._id;
        if (!spot._id) {
          createSpot(spot, eventId);
        } else {
          updateSpot(spot);
        }
      });
      updatePowerInquiry(eventId, powerInquiry);
    };

    componentDidMount() {
      const {
        powerInquiries,
        events,
        params: { eventId, powerInquiryId },
      } = this.props;

      //load the events to the store if not already present
      if (!events.getEvent(eventId)) {
        loadEvents();
      }
      loadUsers();
      loadCompanies();
      const powerInquiryStatus = powerInquiries.getPowerInquiry(powerInquiryId);
      if (!powerInquiryStatus && !powerInquiries.loading) {
        loadPowerInquiries(eventId);
      }
    }

    addNewSpot = (spot) => {
      const {
        params: { userId, eventId },
      } = this.props;

      createSpot({ ...spot, userId: +userId }, eventId).then(() => {
        loadPowerInquiries(eventId).then(this.onNewSpotAdded);
      });
    };

    onSpotUpdate = (spot) => {
      updateSpot(spot);
    };

    onNewSpotAdded = () => {
      const {
        params: { powerInquiryId },
        powerInquiries,
      } = this.props;
      const powerInquiryStatus = powerInquiries.getPowerInquiry(powerInquiryId);
      if (powerInquiryStatus) this.setState({ powerInquiryStatus });
    };

    /**
     * Renders the component view.
     * @return {React.Component}
     */
    render() {
      const {
        powerInquiries,
        companies,
        events,
        users,
        params: { eventId, powerInquiryId },
      } = this.props;
      const powerInquiryStatus = powerInquiries.getPowerInquiry(powerInquiryId);
      if (!powerInquiryStatus) return null;
      const event = events.getEvent(eventId);
      return event ? (
        <div className="PowerInquiryEditPage">
          <PowerInquiryForm
            powerInquiryStatus={powerInquiryStatus}
            companies={companies}
            users={users}
            companiesLoading={companies.loading}
            usersLoading={users.loading}
            onSuccess={this.onSuccess}
            //onSpotUpdate={this.onSpotUpdate}
            addNewSpot={this.addNewSpot}
            event={event}
          />
        </div>
      ) : (
        <Spinner />
      );
    }
  }
);
