export default {
  user: {
    value: 'required|number',
  },
  company: {
    value: 'number',
  },
  emailTitle: 'required|string',
  message: 'string',
};
