import React from 'react';
import { Button } from 'shared/components/form';
import 'react-dropzone-uploader/dist/styles.css';
import '../styles.less';

/**
 * ModalHeaderLine component represents the import new accounts view
 * @class ModalHeaderLine
 * @extends {React.Component}
 */
const ModalHeaderLine = ({ text, step, hideConfirm, showConfirmModal }) => (
  <>
    {showConfirmModal && (
      <div className="confirm-window">
        <h2>{text.get('components.views.users.quitMessage')}</h2>
        <div className="confirm-footer">
          <Button size="small" styling="text" onClick={hideConfirm}>
            {text.get('actions.cancel')}
          </Button>
          <Button
            size="small"
            styling="cta"
            onClick={(e) => hideConfirm(e, true)}
          >
            {text.get('actions.confirm')}
          </Button>
        </div>
      </div>
    )}
    <div className="modalHeader">
      {text.get('components.views.users.import')}:{' '}
      {text.get('components.views.users.step')} {step}/3
    </div>
  </>
);

export default ModalHeaderLine;
