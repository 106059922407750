import React from 'react';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';

import { registerLoggedInUser, loadUser } from 'auth/actions';

import { Box } from 'shared/components';
import { UserForm } from 'users/components';
import { createOrUpdateUser } from 'users/actions';

const enhance = compose(inject('text', 'auth'), observer);

/**
 * ProfileEdit class represents the view with the user profile modification form.
 * @class ProfileEdit
 * @extends {React.Component}
 */
export const ProfileEditPage = enhance(
  class extends React.Component {
    /**
     * Form submission success handler.
     * @param {object} form - Form instance
     * @return {void}
     */
    onSuccess(form, token) {
      const user = form.values();
      user.phones = [{ type: 'work', number: user?.phone }];
      delete user.phone;
      createOrUpdateUser(user);
      registerLoggedInUser(user, token);
    }

    /**
     * Renders the component view.
     * @return {React.Component}
     */
    render() {
      const { text, auth } = this.props;
      return (
        <div className="padding editProfile">
          <Box title={text.get('components.views.profile.editHeader')}>
            <UserForm
              onSuccess={(form) => this.onSuccess(form, auth.token)}
              values={loadUser()}
              isProfileView
            />
          </Box>
        </div>
      );
    }
  }
);
