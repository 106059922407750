import React from 'react';

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(errorInfo, error) {
    // You can also log the error to an error reporting service
    //notify({message: errorInfo, level: Notifications.Type.ERROR});
    console.log(`message:`, errorInfo);
    console.log(`error:`, error);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <h1>
            Sorry! Something went wrong. Please send an e-mail to{' '}
            <a href="mailto:powerplan@greener.nl">powerplan@greener.nl</a>{' '}
          </h1>
          <iframe
            title="weAreSorry"
            src="https://giphy.com/embed/3ohA2ZD9EkeK2AyfdK"
            width="480"
            height="260"
            frameBorder="0"
            allowFullScreen
          ></iframe>
          <p>
            <a href="https://giphy.com/gifs/filmeditor-home-alone-christmas-movies-3ohA2ZD9EkeK2AyfdK">
              via GIPHY
            </a>
          </p>
        </div>
      );
    }

    return this.props.children;
  }
}
