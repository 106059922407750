import React from 'react';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';

import { Metadata } from 'shared/components';

const enhance = compose(inject('text'), observer);

/**
 * PowerzonesBase class represents the base event powerzones view component.
 * @class PowerzonesBase
 * @extends {React.Component}
 */
export const PowerzonesBase = enhance(({ children, text }) => (
  <div className="PowerzonesBase">
    <Metadata meta={text.get('components.views.powerzones.meta')} />
    {children}
  </div>
));
