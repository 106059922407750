import React from 'react';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import {
  getSpotStartAndEndDate,
  calculateTotalTheoreticalPower,
} from 'spots/utils';

import DetailsPane from 'powerzones/components/DetailsPane';
import { DEMAND_PARAMETERS, POWER_SOURCE_TYPES } from 'powerzones/constants';
import {
  getNestedSpots,
  getSubAreas,
  getPowerzoneAreas,
  calcRatedSupplyPower,
} from 'powerzones/utils';
import './styles.less';
import { PowerzoneTable } from './PowerzoneTable';

const enhance = compose(inject('text'), observer);

/**
 * SummaryTable summary power zones overview.
 * @export
 * @class PowerzoneSummaryTable
 * @extends {React.Component}
 */
export const PowerzoneSummaryTable = enhance(
  class extends React.Component {
    // {object} state - Component state holding information about component state.
    constructor(props) {
      super(props);
      this.state = {
        selectedNode: null,
        powerZoneList: [],
        spotList: [],
      };
    }

    handleRowClick = (event, rowData) => {
      //set selectednodestate
      const powerzone = this.props.powerzones.getPowerzoneById(rowData._id);
      powerzone.children = rowData.children;
      this.setState({ selectedNode: powerzone });

      //set pane visibility in state
      this.refs.tablesidepanel.toggleVisibility(true);
    };

    /**
     * calculates the theoretical power
     * @param {array} spots - list of spot objects
     * @return {Number}
     */
    getTheoreticalPower = (spots) => {
      return calculateTotalTheoreticalPower(spots);
    };

    /**
     * calculated the max peak load
     * @param {object} - object with theoretical power and
     * number of connections values
     * @return {Number}
     */
    getMaxPeakLoad = ({ theoreticalPower, numberOfConnections }) => {
      if (numberOfConnections === 0) {
        numberOfConnections = 1;
      }
      return (
        theoreticalPower * DEMAND_PARAMETERS.maximumEstimatedSimultaneityFactor
      ).toFixed(1);
    };

    /**
     * gather data for the power zones summary
     * @return {Array}
     */
    getAreaSpots(area, arr = []) {
      const { areas, spots } = this.props;
      const areaSpots = getNestedSpots(area, spots.list);

      if (areaSpots.length > 0) arr.push(...areaSpots);
      const subAreas = getSubAreas(area, areas.list);

      if (subAreas.length > 0) {
        subAreas.map((sub) => this.getAreaSpots({ ...sub }, arr));
      }
      return arr;
    }

    getPowerzoneSpots(powerzone, powerzoneAreas) {
      const { spots } = this.props;

      let areaSpots = [];
      powerzoneAreas.forEach((area) => {
        areaSpots.push(...this.getAreaSpots(area));
      });

      const powerzoneSpots = getNestedSpots(powerzone, spots.list);
      return [...areaSpots, ...powerzoneSpots];
    }

    getPowerzoneSummaryData = () => {
      let powerZoneList = [];
      const { powerzones, areas } = this.props;

      powerzones.list.map((zone) => {
        const powerSourcesArr = [...zone.powerSources];
        const powerSourceObjects = Object.values(POWER_SOURCE_TYPES).reduce(
          (acc, type) => {
            acc[type] = powerSourcesArr.filter((ps) => ps.type === type);
            return acc;
          },
          {}
        );

        const totalSupplyPower = calcRatedSupplyPower(powerSourcesArr);
        const powerzoneAreas = getPowerzoneAreas(zone, areas.list);
        let spotStrings = [];
        let theoreticalPower = 0;

        const allSpots = this.getPowerzoneSpots(zone, powerzoneAreas);

        allSpots.forEach((spot) => {
          const spotPower = spot.totalPower;
          spotStrings.push(`${spot.label} (${spotPower.toFixed(1)} kW)`); // create spot string to show in table column
          theoreticalPower += spotPower; // set total pz rated power
        });

        const dates = getSpotStartAndEndDate(allSpots);

        let powerZoneSumItem = {
          label: zone.label,
          spotStrings,
          areas: powerzoneAreas.map((area) => area.tag),
          theoreticalPower,
          maxPeakLoad: '',
          ...powerSourceObjects,
          totalSupplyPower: totalSupplyPower ? totalSupplyPower.toFixed(1) : '',
          powerOnDate: zone.powerOnDate,
          powerOffDate: zone.powerOffDate,
          remarks: zone.comment,
          _id: zone._id,
          children: allSpots, //name has to be children here for Detailspane
        };

        //round numbers
        powerZoneSumItem.theoreticalPower = powerZoneSumItem.theoreticalPower.toFixed(
          1
        );

        powerZoneSumItem.earliestStartDate = dates.earliestStartDate || null;
        powerZoneSumItem.latestEndDate = dates.latestEndDate || null;
        powerZoneSumItem.maxPeakLoad = this.getMaxPeakLoad(powerZoneSumItem);

        //add powersource name as property for each power source
        Object.keys(zone.powerSources).forEach((source) => {
          zone.powerSources[source].forEach((item) => {
            powerZoneSumItem[source].push(item);
          });
        });

        return powerZoneList.push(powerZoneSumItem);
      });
      return powerZoneList;
    };

    /**
     * Renders the component view.
     * @return {React.Component}
     */
    render() {
      const { text, eventLabel } = this.props;
      const title = `${eventLabel} ${text.get(
        'components.views.powerzones.summaryTable.name'
      )}`;
      const { selectedNode } = this.state;
      const data = this.getPowerzoneSummaryData();
      return (
        <div className="detailed-table-wrapper">
          <PowerzoneTable
            title={title}
            data={data}
            text={text}
            handleRowClick={this.handleRowClick}
          />
          <DetailsPane
            showSpotForm={true}
            ref="tablesidepanel"
            item={selectedNode}
            text={text}
          />
        </div>
      );
    }
  }
);

PowerzoneSummaryTable.propTypes = {
  eventLabel: PropTypes.string,
  text: PropTypes.object,
  powerzones: PropTypes.object,
  spots: PropTypes.object,
};
