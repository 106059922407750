import React from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { compose } from 'recompose';
import {
  Typography,
  Box,
  Button as MuiButton,
  CircularProgress,
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import { Button, SelectInput, TextInput } from 'shared/components/form';
import { Spinner } from 'shared/components';
import * as Action from 'shared/actions';
import { formCreator } from 'shared/form';
import { Roles } from 'shared/constants';
import { Modal } from 'shared/components';
import { loadUsers } from 'users/actions';
import { EVENT_TYPES, EMPTY_EVENT, EVENT_COMPANY_TYPES } from '../constants';
import fields from './fields';
import labelsConfig from './labels';
import rules from './rules';
import RangeDateFields from './RangeDateFields';
import './styles.less';
import Editors from './editors';
import EditorListItem from './editors/EditorListItem';
import EventCompaniesTypeField from './EventCompaniesTypeField';
import AddEventCompanyTypeMenu from './AddEventCompanyTypeMenu';

const enhance = compose(inject('text', 'users', 'auth'), observer);

/**
 * EventForm class represents the event form component.
 * @class EventForm
 * @extends {React.Component}
 */
export const EventDetailForm = enhance(
  class extends React.Component {
    // {obaject} state - Component state holds the form instance and dates information.
    constructor(props) {
      super(props);
      this.handleNextStep = this.handleNextStep.bind(this);
      this.getHandleEventCompanyChange = this.getHandleEventCompanyChange.bind(
        this
      );
      this.addEventCompanyType = this.addEventCompanyType.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.state = {
        form: null,
        preselectedEditors: [],
        eventCompanies: [],
        //Initialize as a set so that when we add a new eventCompanyType, it will be unique
        eventCompanyTypes: new Set([
          EVENT_COMPANY_TYPES.organiser,
          EVENT_COMPANY_TYPES.producer,
        ]),
        showAddEventCompanyTypeMenu: false,
      };
    }

    // {object} config - Form configuration getter
    get config() {
      const { onSuccess, onError, text } = this.props;
      const title = 'event';
      const labels = labelsConfig(text.get('forms.event'));
      return { title, fields, labels, rules, onSuccess, onError };
    }

    /**
     * Prepares the form fields with the given values, in case there was.
     * @param {object} values - Form fields values
     * @return {object}
     */
    prepareForm(values) {
      if (!values) return EMPTY_EVENT;
      return { ...EMPTY_EVENT, ...values };
    }

    /**
     * React lifecycle method - executed everytime the component is mounted in the view.
     * @return {void}
     */
    componentDidMount() {
      const { values, companies } = this.props;

      const rolesIds = [
        Roles.ADMIN,
        Roles.EVENT_CREATOR,
        Roles.INQUIRY_MANAGER,
        Roles.POWER_SUPPLIER,
      ];

      //load set of users that are not energy users
      loadUsers(rolesIds);

      const form = formCreator(this.config);
      form.init(this.prepareForm(values)); // making sure everytime you select a new spot, it's data will appear
      this.setState({ form });

      //add the company names to the eventCompanies
      const eventCompaniesWithDetails =
        (values &&
          values.eventCompanies.map((eventCompany) => {
            //set the list of eventCompanyTypes so that all autocomplete fields are shown
            this.addEventCompanyType(eventCompany.type);
            const companyDetails = companies.find(
              (company) => eventCompany.companyId === company._id
            );
            const { name, _id } = companyDetails;
            return { name, _id, type: eventCompany.type };
          })) ||
        [];

      this.setState({ eventCompanies: eventCompaniesWithDetails });
    }

    addEventCompanyType(newEventCompanyType) {
      const { eventCompanyTypes } = this.state;
      this.setState({
        eventCompanyTypes: eventCompanyTypes.add(newEventCompanyType),
      });
    }

    showEditorList = () => {
      this.showModal();
    };

    showModal() {
      this.modal.toggleModal(true);
    }

    hideModal() {
      this.modal.toggleModal(false);
    }

    onSelectEditors = (ids) => {
      this.setState({ preselectedEditors: ids });
    };

    onCancelSelectEditors = () => {
      this.hideModal();
      this.setState({ preselectedEditors: [] });
    };

    getEditorOptions(eventCompanyIds) {
      const { users, auth } = this.props;
      const editorOptions = users
        .getUsersByCompany(eventCompanyIds)
        //filter for role in case store contains more roles
        .filter((user) => user.role !== Roles.ENERGY_USER);
      editorOptions.push(auth.profile);
      return editorOptions;
    }

    onSaveSelectEditors = () => {
      const { preselectedEditors, form } = this.state;
      this.hideModal();

      form.$('editors').update([...preselectedEditors]);
      this.props.users.setIds([
        ...preselectedEditors,
        this.props.auth.profile._id,
      ]);

      this.setState({ preselectedEditors: [] });
    };

    deleteEditor = (id) => {
      const { form } = this.state;
      form.$('editors').del(id);
      this.props.users.setIds([
        ...form.$('editors').value,
        this.props.auth.profile._id,
      ]);
    };

    getHandleEventCompanyChange = (fieldName) => (
      event,
      newCompanies,
      reason,
      detail
    ) => {
      const value = detail.option;
      const { eventCompanies } = this.state;

      if (!value?._id) {
        return;
      } else if (reason === 'select-option') {
        const newCompany = {
          _id: value._id,
          name: value.name,
          type: fieldName,
        };
        const newEventCompanies = [...eventCompanies, newCompany];
        this.setState({
          eventCompanies: newEventCompanies,
        });
      } else if (reason === 'remove-option') {
        const newEventCompanies = eventCompanies.filter(
          (option) => option._id !== value._id
        );
        this.setState({
          eventCompanies: newEventCompanies,
        });
      } else {
        console.error('unknown reason in MUI component');
      }
    };

    handleSubmit(e) {
      const { form } = this.state;
      e.preventDefault();

      //add the eventCompanies from state to the form values
      const values = this.getFormAndStateValues();

      //submit the form and pass the values to the onSuccess callback
      form.submit({
        onSuccess: () => {
          this.props.onSuccess(values);
        },
      });
    }

    handleNextStep(e) {
      const { form } = this.state;
      e.preventDefault();

      //add the eventCompanies from state to the form values
      const values = this.getFormAndStateValues();

      //submit the form and pass the values to the onSuccess callback
      form.submit({
        onSuccess: () => {
          this.props.onNextStep(values);
        },
      });
    }

    getFormAndStateValues() {
      const { form, eventCompanies } = this.state;

      //remove the name and set the right property names
      const newEventCompanies = eventCompanies.map((eventCompany) => ({
        companyId: eventCompany._id,
        type: eventCompany.type,
      }));

      //Add the eventCompanies from the state to the result values
      return {
        ...form.values(),
        eventCompanies: newEventCompanies,
      };
    }

    /**
     * Renders the component view.
     * @return {React.Component}
     */
    render() {
      const { text, users, companies, companiesLoading } = this.props;
      const {
        form,
        eventCompanies,
        eventCompanyTypes,
        preselectedEditors,
      } = this.state;
      if (!form) return <Spinner />;

      const eventCompanyIds = eventCompanies.map((c) => c._id);
      const { startDate: buildUpStartDate, endDate: buildUpEndDate } = form.$(
        'buildUpDates'
      ).value[0];
      const isBuildUpFilled = buildUpStartDate && buildUpEndDate;
      const { startDate: showStartDate, endDate: showEndDate } = form.$(
        'showDates'
      ).value[0];
      const isShowFilled = showStartDate && showEndDate;
      return (
        <React.Fragment>
          <form className="EventForm">
            <div className="inputRow">
              <div className="inputColumn">
                <TextInput field={form.$('festival')} type="text" />
                <Typography type="body1">
                  {text.get('forms.event.eventCompanies')}
                </Typography>
                {[...eventCompanyTypes].map((eventCompanyType) => (
                  <Box
                    style={{ marginTop: 12, marginBottom: 12 }}
                    key={`autoComplete.${eventCompanyType}`}
                  >
                    <EventCompaniesTypeField
                      eventCompanyType={eventCompanyType}
                      optionsLoading={companiesLoading}
                      options={companies}
                      value={eventCompanies.filter(
                        (option) => option.type === eventCompanyType
                      )}
                      handleEventCompanyChange={this.getHandleEventCompanyChange(
                        eventCompanyType
                      )}
                    />
                  </Box>
                ))}
                <AddEventCompanyTypeMenu
                  onMenuItemClick={this.addEventCompanyType}
                />
                {form.$('editors').value.length > 0 && (
                  <div className="title">
                    {text.get('forms.event.assignedEventEditors')}
                  </div>
                )}
                {form.$('editors').map((field) => {
                  const editor = users.list.find(
                    (el) => el._id === field.value
                  );

                  if (!editor) return null;

                  return (
                    <EditorListItem
                      key={field.key}
                      editor={editor}
                      onPress={() => {
                        this.deleteEditor(field.key);
                      }}
                      text={text}
                      isSelected
                    />
                  );
                })}

                <MuiButton
                  startIcon={
                    users.loading ? (
                      <CircularProgress size={24} thickness={5} />
                    ) : (
                      <AddCircleOutlineIcon />
                    )
                  }
                  onClick={this.showEditorList}
                  disabled={users.loading}
                >
                  {text.get('forms.event.assignEventEditors')}
                </MuiButton>
              </div>
              <div className="inputColumn">
                <TextInput field={form.$('visitors')} type="number" />
                <SelectInput
                  field={form.$('eventType')}
                  options={EVENT_TYPES}
                />
                <RangeDateFields
                  field={form.$('buildUpDates')}
                  label={text.get('forms.event.buildUpDates')}
                  id="buildUp"
                />
                {isBuildUpFilled && (
                  <RangeDateFields
                    minDate={buildUpStartDate}
                    maxDate={new Date(2100, 1, 1).toISOString()}
                    field={form.$('showDates')}
                    label={text.get('forms.event.showDates')}
                    id="showDates"
                    initialVisibleMonth={() => moment(buildUpEndDate)}
                  />
                )}
                {isShowFilled && (
                  <RangeDateFields
                    minDate={showStartDate}
                    maxDate={new Date(2100, 1, 1).toISOString()}
                    field={form.$('breakDownDates')}
                    label={text.get('forms.event.breakDownDates')}
                    id="breakDown"
                    initialVisibleMonth={() => moment(showEndDate)}
                  />
                )}
              </div>
            </div>
            <div className="buttonRow">
              <Button
                onClick={this.handleNextStep}
                styling="alt"
                children={text.get('forms.event.addAvailableConnections')}
              />
              <Button
                onClick={this.handleSubmit}
                styling="cta"
                children={text.get('actions.save')}
              />
              <Button
                styling="text"
                onClick={() => Action.pushRoute('/events')}
                children={text.get('actions.cancel')}
              />
            </div>
          </form>

          <Modal
            ref={(modal) => {
              this.modal = modal;
            }}
          >
            <div className="modalView EditorsModal">
              <div className="modalHeader">
                {text.get('forms.event.selectEventEditor')}
              </div>
              <div className="modalBody scrollContent">
                <Editors
                  editorOptions={this.getEditorOptions(eventCompanyIds)}
                  selectedEditorIds={[
                    ...preselectedEditors,
                    ...form.$('editors').value,
                  ]}
                  onChange={this.onSelectEditors}
                />
              </div>
              <div className="modalFooter">
                <Button
                  type="button"
                  size="small"
                  onClick={this.onCancelSelectEditors}
                  children={text.get('actions.cancel')}
                />
                <Button
                  type="button"
                  size="small"
                  disabled={users.filteredUsers.length === 0}
                  onClick={this.onSaveSelectEditors}
                  children={text.get('actions.save')}
                />
              </div>
            </div>
          </Modal>
        </React.Fragment>
      );
    }
  }
);
