import { useCallback } from 'react';
import { useQueryClient, useMutation } from 'react-query';

import { api } from 'shared/utils';

import { saveToken, saveUser } from '../actions';
import { auth as authStore } from '../store';

import { ME_QUERY_KEY } from './useGetMe';

export const TOKEN_QUERY_KEY = 'token-key';

const doLogin = ({ email, password }) =>
  api.post('/users/auth', { email, password }).then(({ data }) => data);

export const useLogin = () => {
  const queryClient = useQueryClient();
  const { mutate, data, status, ...others } = useMutation(doLogin, {
    onSuccess: ({ token, user }) => {
      queryClient.setQueryData(ME_QUERY_KEY, user);
      queryClient.setQueryData(TOKEN_QUERY_KEY, token.key);
      // TODO: remove this line when no other places are injecting the authStore
      authStore.registerLoginData(user, token.key);
      saveUser(user);
      saveToken(token.key);
    },
  });

  const login = useCallback(
    ({ email, password }) => mutate({ email, password }),
    [mutate]
  );

  return {
    ...others,
    loading: status === 'loading',
    login,
    data,
  };
};
