import React from 'react';
import './styles.less';

/**
 * MasterDetailsView Component class represents the 'master-details' view structure,
 * which presents a list of items called 'master items' in a side and the 'details' view
 * with information related to the selected item in the other list.
 * @class MasterDetailsView
 * @extends {React.Component}
 */
export class MasterDetailsView extends React.Component {
  // {object} state - The component state only holds the index of the currently selected master item.
  state = { selectedItem: 0 };

  /**
   * Tells if the item is selected.
   * @param {object} item - Item data object
   * @param {number} index - Item index in the list
   * @return {boolean}
   */
  isSelected(item, index) {
    const { selected } = this.props;
    return selected ? selected(item) : this.state.selectedItem === index;
  }

  /**
   * Selects an item.
   * @param {number} selectedItem - Selected item index
   * @return {void}
   */
  selectItem(selectedItem) {
    this.setState({ selectedItem });
  }

  /**
   * Renders the master items list.
   * @param {object[]} items - Master items list
   * @return {*[]}
   */
  renderMaster(items) {
    if (!this.props.loading && items.length === 0)
      return <div className="emptyListItem">No items.</div>;
    return items.map((item, index) => {
      const isSelected = this.isSelected(item, index);
      return (
        <div key={index} onClick={() => this.selectItem(index)}>
          {this.props.masterItemView(item, index, isSelected)}
        </div>
      );
    });
  }

  /**
   * Render the details view.
   * @param {object[]} masterItems - Master items list
   * @param {number} selectedItem - Selected item index
   * @return {React.Component}
   */
  renderDetails(masterItems, selectedItem) {
    if (masterItems.length === 0) return null;
    const index = selectedItem > -1 ? selectedItem : 0;
    return this.props.detailsView(masterItems[index]);
  }

  /**
   * Renders the component view.
   * @return {React.Component}
   */
  render() {
    const { masterItems, masterHeader } = this.props;
    const { selectedItem } = this.state;
    return (
      <div className="MasterDetails">
        <div className="master">
          {masterHeader || null}
          {this.renderMaster(masterItems)}
        </div>
        <div className="details scrollContent">
          {this.renderDetails(masterItems, selectedItem)}
        </div>
      </div>
    );
  }
}
