import { action, extendObservable } from 'mobx';
import get from 'lodash/get';

import intl from 'shared/strings';
/**
 * TextStore is the Mobx global data storage for the text data.
 * @class TextStore
 */
function TextStore() {
  extendObservable(this, {
    strings: intl(''),
    language: '',
    get(path) {
      return get(this.strings, path) || path;
    },
    changeLanguage: action((language) => {
      this.strings = intl(language);
    }),
  });
}
export default new TextStore();
