import React from 'react';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';

import { Card, Metadata, Spinner } from 'shared/components';

import { userPending, getUserProfile } from 'auth/actions';
import { updateProfile } from 'profile/actions';
import { RegisterForm } from 'auth/components';

import './styles.less';

const enhance = compose(inject('text', 'auth'), observer);

/**
 * RegisterPage class represents the view with the user registration form.
 * @class RegisterPage
 * @extends {React.Component}
 */
export const RegisterPage = enhance(
  class extends React.Component {
    /**
     * React lifecycle method - executed everytime the component is about to be mounted in the view.
     * @return {void}
     */
    UNSAFE_componentWillMount() {
      const {
        params: { uuid },
      } = this.props;
      userPending(uuid);
      getUserProfile(uuid);
    }

    /**
     * Form submission success handler.
     * @param {object} form - Form instance
     * @return {void}
     */
    onSuccess(form) {
      const {
        params: { uuid },
      } = this.props;
      const tmp = form.values();
      tmp.phone && (tmp.phones = [{ type: 'work', number: tmp.phone }]);
      delete tmp.phone;
      const profile = { ...tmp, pendingUUID: uuid };
      updateProfile(profile);
    }

    /**
     * Renders the component view.
     * @return {React.Component}
     */
    render() {
      const { text, auth } = this.props;
      if (auth.loading || !auth.registeredUser)
        return <Spinner className="RegisterView" />;
      return (
        <section className="RegisterView">
          <Metadata meta={text.get('components.views.register.meta')} />
          <h1>{text.get('components.views.register.pageTitle')}</h1>
          <Card className="content">
            <p>{text.get('components.views.register.youHaveBeenInvited')}</p>
            <p>{text.get('components.views.register.whatsInItForYou')}</p>
            <RegisterForm
              values={auth.registeredUser}
              onSuccess={(form) => this.onSuccess(form)}
            />
          </Card>
        </section>
      );
    }
  }
);
