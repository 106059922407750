import React from 'react';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';

import { Icon } from 'shared/components';

import './styles.less';

const enhance = compose(inject('text'), observer);

/**
 * ListItem class represents an item containing an energy user invitation in the energy users
 * new energy user invites list.
 * @class ListItem
 * @extends {React.Component}
 */
export const ListItem = enhance(
  ({ text, invite, onClickRemove, onClickView }) => (
    <div className="PowerInquiryInviteListItem clickable">
      <div className="column" onClick={() => onClickView(invite)}>
        <Icon
          name="person"
          className={`icon ${
            invite.user.pendingUUID?.length === 0 ? 'active' : ''
          }`}
        />
        <div className="content">
          <span className="title" children={invite.user.name} />
          <span className="subtitle" children={invite?.company?.name} />
        </div>
      </div>
      <div className="column" onClick={() => onClickView(invite)}>
        <p>{invite.spots.map((x) => x.label || x).join(' • ')}</p>
      </div>
      <div className="column">
        <a
          onClick={() => onClickRemove(invite)}
          title={text.get('actions.remove')}
        >
          <Icon name="close" className="action icon" />
        </a>
      </div>
    </div>
  )
);
