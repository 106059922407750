import { Notifications } from 'shared/constants';

let notificationHandler = null;
let confirmHandler = null;

/**
 * Takes an instance of the confirm component as input and use it to handle the confirm actions.
 * @param {object} ref - confirm component instance
 * @return {void}
 */
export const setConfirmHandler = (ref) => (confirmHandler = ref);

/**
 * Presents the confirmation alert in the screen.
 * @param {string} message - Message to present in the box
 * @param {function} callback - Function to execute when the confirm box is closed
 * @return {void}
 */
export const confirm = (message, callback) =>
  confirmHandler.show(message, callback);

/**
 * Takes an instance of the notification manager as input and use it to handle the notification actions.
 * @param {object} ref - notification manager instance
 * @return {void}
 */
export const setNotificationHandler = (ref) => (notificationHandler = ref);

/**
 * Presents a notification on the screen.
 * @param {object} config - object containing { message[, level, dismiss, position] }
 * @return {void}
 */
export const notify = ({
  message,
  level = Notifications.Type.INFO,
  dismiss = 10,
  position = Notifications.Position.BOTTOM_CENTER,
}) =>
  notificationHandler.addNotification({ message, level, dismiss, position });

/**
 * Clears all notifications from the screen.
 * @return {void}
 */
export const clearNotifications = () =>
  notificationHandler.clearNotifications();
