import React from 'react';
import { Icon } from '../icon';
import IconButton from 'material-ui/IconButton';
import IconMenu from 'material-ui/IconMenu';
import MenuItem from 'material-ui/MenuItem';
import Slider from 'material-ui/Slider';
import './styles.less';

/**
 * SidePane Component class represents the side pane view.
 * @class SidePane
 * @extends {React.Component}
 */
export class SidePane extends React.Component {
  // {object} state - Component state holds information about the pane visibility state.
  state = { visible: false, sidePanelStyles: {} };

  /**
   * Toggle side pane visibility.
   * @param {boolean} visible - Toggle side pane on/off
   * @return {void}
   */
  toggleVisibility = (visible) =>
    this.setState({ visible, sidePanelStyles: {} });

  /**
   * Generates the pdf from power zone
   * overview table.
   * @return {void}
   */
  generatePdf = () => {
    console.log('generating pdf');
    setTimeout(() => window.print(), 1000);
  };

  handleSliderChange = (event, newValue) => {
    this.setState({
      sidePanelStyles: { width: `${newValue}%` },
    });
  };

  /**
   * Renders the component view.
   * @return {React.Component}
   */
  render() {
    const {
      contentClassName,
      className,
      editHandler,
      text,
      item,
      side,
      ...props
    } = this.props;
    const { visible, sidePanelStyles } = this.state;
    return (
      <div
        style={sidePanelStyles}
        className={`SidePane ${className || ''} ${side || 'right'} ${
          visible && 'visible'
        }`}
      >
        <div className="buttonBar">
          <Icon
            name="close"
            className="clickable"
            onClick={() => this.toggleVisibility(false)}
          />
          {item && !item.uri.includes('powerzones') ? (
            editHandler &&
            !item.uri.includes('areas') && (
              <Icon name="edit" className="clickable" onClick={editHandler} />
            )
          ) : (
            <IconMenu
              className="sidePanel-menu"
              iconButtonElement={
                <IconButton>
                  <Icon name="more" />
                </IconButton>
              }
              targetOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              {editHandler && (
                <MenuItem
                  primaryText={text.get('actions.rename')}
                  onClick={editHandler}
                />
              )}
              <MenuItem
                primaryText={text.get('actions.generatePdf')}
                onClick={this.generatePdf}
              />
            </IconMenu>
          )}
        </div>
        <div className={`content ${contentClassName || ''}`} {...props} />
        <div className="slider-row">
          <p>{text.get('components.views.powerzones.sliderLabel')}</p>
          <Slider
            label={'Change the width of the side panel'}
            className="slider"
            value={50}
            min={50}
            max={100}
            onChange={this.handleSliderChange}
          />
        </div>
      </div>
    );
  }
}
