import * as Services from './service';

/**
 * Downloads a single user from the API and loads it to the application's store.
 * @return {Promise}
 */
export const loadUser = (userId) => Services.loadUser(userId);

/**
 * Downloads the list of users from the API and loads it to the application's store.
 * @return {Promise}
 */
export const loadUsers = (roleIds) => Services.loadUsers(roleIds);

/**
 * Takes an user instance as input and sends it to the API so it can be saved.
 * @param {object} user - User instance
 * @return {void}
 */
export const createNewUser = (user, avoidRedirect = false) =>
  Services.createNewUser(user, avoidRedirect);

/**
 * Takes an user instance as input and sends it to the API so it can be saved (case it does not exist)
 * or update it (case it already exists).
 * @param {object} user - User instance
 * @return {void}
 */
export const createOrUpdateUser = (user, avoidRedirect = false) =>
  Services.createOrUpdateUser(user, avoidRedirect);
