import { action, extendObservable } from 'mobx';

/**
 * AuthStore is the Mobx global data storage for the user data.
 * @class AuthStore
 */
function AuthStore() {
  extendObservable(this, {
    token: null,
    profile: null,
    registeredUser: null,
    loading: false,
    get isLoggedIn() {
      return this.token && this.profile;
    },
    isLoading: action((value) => {
      this.loading = Boolean(value);
    }),
    registerLoginData: action((user, token) => {
      this.updateToken(token);
      this.updateUser(user);
    }),
    updateUser: action((user) => {
      this.profile = user;
    }),
    setRegisteredUser: action((user) => {
      this.registeredUser = user;
    }),
    updateToken: action((token) => {
      this.token = token;
    }),
    logoff: action(() => {
      this.token = null;
      this.profile = null;
    }),
  });
}
export const auth = new AuthStore();
