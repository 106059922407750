import React from 'react';
import Typography from '@material-ui/core/Typography';
import customColors from 'shared/styles/colors';
import Card from '@material-ui/core/Card';

export default function HighlightedDataPanel({
  title,
  value,
  style,
  textColor = customColors['brand-color'],
  ...rest
}) {
  return (
    <Card
      style={{
        padding: '5px 10px',
        whiteSpace: 'noWrap',
        ...style,
      }}
      {...rest}
    >
      <Typography variant="body2">
        <strong>{title}</strong>
      </Typography>
      <Typography style={{ color: textColor }}>{value}</Typography>
    </Card>
  );
}
