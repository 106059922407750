import Math from 'math';

import { add } from 'shared/utils';

import areaMapper from 'areas/mapper';
import { updateArea } from 'areas/actions';

import spotMapper from 'spots/mapper';
import { updateSpot } from 'spots/actions';
import { DEMAND_PARAMETERS } from './constants';

/**
 * Takes a Powerzone instance as input and returns the sub-array of areas associated to this
 * Powerzone.
 * @param {object} powerzone - Powerzone instance
 * @return {object[]}
 */
export const getPowerzoneAreas = (powerzone, areas) => {
  if (areas.length === 0) return [];
  return areas.filter(
    ({ associatedResource }) => associatedResource === powerzone.uri
  );
};

/**
 * Takes an Area instance as input and returns the sub-array of areas associated to this
 * Area.
 * @param {object} item - Area/Powerzone instance
 * @return {object[]}
 */
export const getSubAreas = (item, areas) => {
  if (areas.length === 0) return [];
  return areas.filter(
    ({ associatedResource }) => associatedResource === item.uri
  );
};

/**
 * Takes an Area or Powerzone instance as input and returns the sub-array of spots associated to this
 * Area.
 * @param {object} item - Area instance
 * @return {object[]}
 */
export const getNestedSpots = (item, spots) => {
  if (spots.length === 0) return [];
  return spots.filter(
    ({ associatedResource }) => associatedResource === item.uri
  );
};

/**
 * Climbs up the tree elements and visit every antecessor of the given element recursively
 * and then returns an array with the parents names in decrescent order.
 * @param {object} item - Current item instance
 * @param {object[]} areas - List of available areas
 * @param {object[]} powerzones - List of available powerzones
 * @param {string[]} parents - List of parents names
 * @return {object} parents{ powerzone: string, areas: []string}
 */
export const getParentLeaves = (item, areas, powerzones, parents = {}) => {
  if (!item || !item.associatedResource) return [];
  if (item.associatedResource.includes('areas')) {
    const parentArea = areas.find(
      (area) => area.uri === item.associatedResource
    );
    parents.areaNames
      ? parents.areaNames.unshift(`${parentArea.tag}, `)
      : (parents.areaNames = [parentArea?.tag]);
    return getParentLeaves(parentArea, areas, powerzones, parents);
  }
  if (item.associatedResource.includes('powerzones')) {
    const parentPowerzone = powerzones.find(
      (pz) => pz.uri === item.associatedResource
    );
    parents.pzName = parentPowerzone?.label;
    return parents;
  }
};

/**
 * Calculates the sum of children's total power.
 * @param {object[]} children - Node children list
 * @return {number}
 */
export const getChildrenTotalPower = (children) => {
  if (children.length === 0) return 0;
  return children.map((child) => child.totalPower).reduce(add, 0);
};

/**
 * Flatten a list of nodes with children nodes.
 * @param {object[]} children - Children list
 * @return {object[]}
 */
export const flattenChildren = (children) =>
  children instanceof Array &&
  children
    .map((item) => (!item.children ? item : flattenChildren(item.children)))
    .reduce((prev, next) => prev.concat(next), []);

/**
 * Get a list with all spot object data within an area including subareas.
 * @param {object[]} children - Children list
 * @return {object[]}
 */
export const getAreaSpots = (area, areas, spots) => {
  let arr = [];
  const areaSpots = getNestedSpots(area, spots.list);
  if (areaSpots.length > 0) arr.push(...areaSpots);
  const subAreas = getSubAreas(area, areas.list);
  if (subAreas.length > 0) {
    subAreas.map((sub) => this.getAreaSpots({ ...sub }, arr));
  }
  return arr;
};

export const disassociateNodes = (nodes) => {
  nodes.forEach((node) => {
    node.associatedResource = '';
    node.uri.includes('areas') && updateArea(areaMapper(node.eventId)(node));
    node.uri.includes('spots') && updateSpot(spotMapper(node.eventId)(node));
  });
};

/**
 * Calculates the maximum simultaneity factor that the currently chosen power sources can handle.
 * @param {number} totalRatedPowerKw
 * @param {array} spots - Array of spots
 * @returns Number
 */
export function getAnticipatedSimultaneityFactor(
  totalRatedPowerKw,
  totalRatedSpotPowerKw
) {
  const sF = totalRatedPowerKw / totalRatedSpotPowerKw;
  return totalRatedSpotPowerKw > 0 ? sF * 100 : null;
}

export const getPowerFromRatedCurrent = (
  ratedCurrent,
  pF = DEMAND_PARAMETERS.AVG_RATED_POWER_FACTOR,
  ratedVoltage3P = DEMAND_PARAMETERS.RATED_VOLTAGE_3P,
  ratedVoltage1P = DEMAND_PARAMETERS.RATED_VOLTAGE_1P,
  phases = 3
) => {
  switch (phases) {
    case 1:
      return ((ratedCurrent * ratedVoltage1P) / 1000) * pF;
    case 3:
      return (Math.sqrt(3) * ratedCurrent * ratedVoltage3P * pF) / 1000;
    default:
      return null;
  }
};

/**
 *
 * @param {Array} powerSources - Array of power sources object with property ratedPowerKw or ratedCurrentA
 * @returns
 */
export const calcRatedSupplyPower = (powerSources) =>
  powerSources.reduce((prev, ps) => {
    if (ps.ratedPowerKw) return prev + ps.ratedPowerKw;
    else return prev + getPowerFromRatedCurrent(ps.ratedCurrentA);
  }, 0);

/**
 * Retreives the powerzone form values from a powerzone object
 * @param {*} powerzone - powerzone object
 * @returns
 */
export const getPowerZoneFormValues = (powerzone) => ({
  comment: powerzone.comment,
  startShow: powerzone.startShow,
  endShow: powerzone.endShow,
  eventId: powerzone.eventId,
  label: powerzone.label,
  createdAt: powerzone.createdAt,
  updatedAt: powerzone.updatedAt,
  powerSources: powerzone.powerSources,
  powerOnDate: powerzone.powerOnDate,
  powerOffDate: powerzone.powerOffDate,
  uri: powerzone.uri,
  _id: powerzone._id,
});
