import React from 'react';
import { Button } from 'shared/components/form';
import { DialogModal } from 'shared/components';
import { useGetText } from 'shared/hooks';
import { useForm } from 'react-hook-form';
import { TextInput } from 'shared/components/functionalForm/';
import { useCreateArea } from 'areas/hooks';
import PropTypes from 'prop-types';
import schema from './schema';
import { yupResolver } from '@hookform/resolvers/yup';

function CreateAreaModalForm({
  closeModal,
  isOpen,
  customClass,
  eventId,
  parentUri,
}) {
  const text = useGetText();
  const { handleSubmit, control, reset, formState } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });
  const { createArea } = useCreateArea();

  const handleSub = (data) => {
    createArea({
      area: { ...data, associatedResource: parentUri },
      eventId,
    });
    closeModal();
    reset();
  };

  const handleCancel = () => {
    reset();
    closeModal();
  };

  return (
    <DialogModal title={text.getText('forms.area.formTitle')} open={isOpen}>
      <form
        className={`${customClass} form`}
        onSubmit={handleSubmit(handleSub)}
      >
        <TextInput
          name="tag"
          label={text.getText('forms.area.tag')}
          control={control}
        />
        <div
          style={{
            display: 'flex',
            padding: '1em',
          }}
        >
          <Button type="submit" disabled={!formState.isValid}>
            {text.getText('actions.submit')}
          </Button>
          <Button type="button" onClick={handleCancel} styling="borderless">
            {text.getText('actions.cancel')}
          </Button>
        </div>
      </form>
    </DialogModal>
  );
}

export default CreateAreaModalForm;

CreateAreaModalForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  eventId: PropTypes.string.isRequired,
  parentUri: PropTypes.string,
  customClass: PropTypes.string,
};
