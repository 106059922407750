/**
 * Checks if the current field has the same value of the 'target'
 * @param {any} target - Target field name
 * @return {bool}
 */
export function shouldBeEqualTo(target) {
  return ({ field, form }) => {
    const fieldsAreEquals = form.$(target).value === field.value;
    return [
      fieldsAreEquals,
      `The ${field.label} field should be equals to ${form.$(target).label}`,
    ];
  };
}

/**
 * Checks if the current field value is a valid e-mail.
 * @param {any} target - Target field name
 * @return {bool}
 */
export function isEmail({ field }) {
  const isValid = field.value.indexOf('@') > 0;
  return [isValid, `The ${field.label} should be an email address.`];
}

/**
 * Checks if the current field has nested fields.
 * @param {any} target - Target field name
 * @return {bool}
 */
export function hasNested({ field }) {
  const hasNested = field.size > 0;
  return [hasNested, `It should have nested fields.`];
}
