import React from 'react';

import { Box } from 'shared/components';
import { updatePowerzone } from 'powerzones/actions';
import { getPowerZoneFormValues } from 'powerzones/utils';

import PowerzoneForm from './PowerzoneForm';

/**
 * Returns the powerzone supply table component.
 * @return {React.Component}
 */
export default function SupplyTable({ event, powerzone, text }) {
  /**
   * Submit powerzone form
   * @param {Object} powerzone - Powerzone form values
   * @return {void}
   */
  const onSubmitPowerzone = (powerzone) => {
    updatePowerzone(powerzone);
  };

  const title = (
    <span>{text.get('components.views.powerzones.supply.title')}</span>
  );

  const powerZoneFormValues = getPowerZoneFormValues(powerzone);

  return (
    <Box title={title}>
      <PowerzoneForm
        onSuccess={onSubmitPowerzone}
        event={event}
        values={powerZoneFormValues}
        text={text}
        totalRatedPowerDemandKw={powerzone.calculated.totalPower}
      />
    </Box>
  );
}
