/**
 * Limits the given text and adds '...' to the end when it's length is greater then
 * the maximal length.
 * @param {string} text - Evaluated text
 * @param {number} maxSize - Max text size
 * return {string}
 */
export const limitText = (text, maxSize) => {
  if (!text) return '';
  if (!maxSize || text.length <= maxSize) return text;
  return `${text.slice(0, maxSize)}...`;
};
