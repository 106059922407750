import React from 'react';
import IconButton from 'material-ui/IconButton';

import { getRoleNamesArrFromId } from 'users/utils';
import { Icon } from 'shared/components';
import { useGetText } from 'shared/hooks';

const EditorListItem = ({ editor, isSelected, onClick, showModalView }) => {
  const text = useGetText();
  return (
    <div className="EditorItem">
      <div className="info">
        <div className="name">{editor.name}</div>
        <div className="info">
          <span>{text.getText('forms.event.company')}: </span>
          {editor.companyName}
        </div>
        <div className="info">
          <span>{text.getText('forms.event.role')}: </span>
          {getRoleNamesArrFromId(editor.role, text)
            .map((role) => role)
            .join(', ')}
        </div>
        <div className="info">
          <span>{text.getText('forms.adminUsers.email')}: </span> {editor.email}
        </div>
      </div>
      <div className="buttonBox">
        <IconButton onClick={() => onClick(editor)}>
          {isSelected && !showModalView && (
            <Icon className="icon" name="close-circle" />
          )}
          {!isSelected && <Icon className="icon" name="add-circle" />}
        </IconButton>
      </div>
    </div>
  );
};

export default EditorListItem;
