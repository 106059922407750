import React from 'react';
import { inject, observer } from 'mobx-react';
import { uniqueId } from 'lodash';
import 'react-dropzone-uploader/dist/styles.css';
import { compose } from 'recompose';

import { notify } from 'shared/actions';
import { Button } from 'shared/components/form';
import { Notifications } from 'shared/constants';

import {
  parseImportPowerInquiries,
  loadPowerInquiries,
  applyImportPowerInquiries,
} from 'powerinquiries/actions';
import { loadSpots } from 'spots/actions';

import ModalHeaderLine from './modal/modalHeaderLine';
import FirstStepOfUploadingAccount from './steps/firstStep';
import SecondStepOfUploadingAccount from './steps/secondStep';
import ThirdStepOfUploadingAccount from './steps/thirdStep';
import { uploadMapper } from '../../../mapper';
import './styles.less';

const enhance = compose(inject('text', 'events'), observer);

/**
 * uploadAccounts class represents the import new accounts view
 * @class UploadAccounts
 * @extends {React.Component}
 */
export default enhance(
  class UploadAccounts extends React.Component {
    constructor(props) {
      super(props);
      this.importPowerInquiryEventData =
        this.importPowerInquiryEventData.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.state = {
        step: 1,
        imagePreview: '',
        importResult: null,
        imageFile: null,
        checked: true,
        checkedAll: false,
        accounts: [],
        newCompanies: [],
        docType: 'Greener Default',
        showConfirmModal: false,
      };
    }

    /**
     * Submits the form.
     * @params {object} file - file instance
     * @return {void}
     */
    async handleSubmit(file) {
      let formData = new FormData();
      formData.append('import', file);

      parseImportPowerInquiries(formData, this.props.eventId).then(
        ({ status, data }) => {
          if (status === 400) {
            // in case of field or header errors we should not proceed
            this.setState({
              importResult: uploadMapper(data),
              step: 2,
              image_file: null,
              accounts: [],
            });
          }
          if (status === 200) {
            // in case of only warnings we can proceed
            data.data.accounts.map((item) => {
              item.id = uniqueId();
              return item;
            });

            this.setState({
              importResult: uploadMapper(data),
              step: 2,
              imageFile: file,
              accounts: data.data.accounts,
              newCompanies: data.data.companies,
            });
          }
          if (status === 403 || status === 500) {
            Object.values(data).map((err) =>
              notify({
                message: err.Msg,
                level: Notifications.Type.ERROR,
              })
            );
          }
        }
      );
    }

    prepareEmail() {
      const { text, events, eventId } = this.props;
      this.state.accounts.map((item) => {
        item.email = {
          subject: text
            .get('forms.powerInquiries.emailTitle')
            .replace('{festival}', events.getEvent(eventId).festival),
          body: text
            .get('forms.powerInquiries.defaultMessage')
            .replace('{festival}', events.getEvent(eventId).festival)
            .replace('{name}', item.user.name.trim()),
          btnText: text.get('forms.powerInquiries.defaultBtn'),
        };
        return item;
      });
    }

    /**
     * Adjusts the call to the server with ability to import accounts to the DB
     * @return {void}
     */
    importPowerInquiryEventData() {
      const {
        importResult: { data },
      } = this.state;
      const { eventId, hideModal } = this.props;
      applyImportPowerInquiries(data, eventId).then(() => {
        hideModal();
        loadPowerInquiries(eventId);
        loadSpots(eventId);
        this.setState({ step: 1 });
      });
    }

    /**
     * Shows confirm modal
     * @return {void}
     */
    showConfirm = () => this.setState({ showConfirmModal: true });

    /**
     * Hides confirm modal
     * @param {object} e - onclick event
     * @param {boolean} hideModal - modal state
     * @return {void}
     */
    hideConfirm = (e, hideModal = false) => {
      e.preventDefault();
      this.setState({ showConfirmModal: false }, () => {
        if (hideModal) {
          this.setState({ step: 1, imageFile: null });
          this.props.hideModal();
        }
      });
    };

    /**
     * Changes the number of step after submiting second step
     * @params {number} step - number of step
     * @return {void}
     */
    changeStep = (numb) => this.setState({ step: numb });

    /**
     * Save file for back to first step
     * @params {object} file - file instance
     * @return {void}
     */
    onChangeInputValue = (file) => this.setState({ file });

    /**
     * Renders the steps of modal form
     * @return {React.Component}
     */
    renderFormSteps = () => {
      const { hideModal, text } = this.props;
      const {
        step,
        file,
        showConfirmModal,
        importResult,
        checkedAll,
        accounts,
        newCompanies,
      } = this.state;
      switch (step) {
        case 1:
          return (
            <FirstStepOfUploadingAccount
              handleSubmit={this.handleSubmit}
              onChangeInputValue={this.onChangeInputValue}
              showConfirm={this.showConfirm}
              {...{ text, file, hideModal, showConfirmModal }}
            />
          );
        case 2:
          return (
            <SecondStepOfUploadingAccount
              handleSubmit={this.changeStep}
              backToPrevStep={this.changeStep}
              showConfirm={this.showConfirm}
              {...{
                text,
                importResult,
                step,
                hideModal,
                showConfirmModal,
                accounts,
              }}
            />
          );
        case 3:
          this.prepareEmail();
          return (
            <ThirdStepOfUploadingAccount
              handleSubmit={this.importPowerInquiryEventData}
              backToPrevStep={this.changeStep}
              showConfirm={this.showConfirm}
              toggleCheckedItems={this.toggleCheckedItems}
              onToggleCheckBox={this.onToggleCheckBox}
              {...{
                step,
                checkedAll,
                importResult,
                text,
                showConfirmModal,
                accounts,
                newCompanies,
              }}
            />
          );
        default:
          return (
            <div className="modalView">
              <div className="modalHeader">header</div>
              <div className="modalBody">body</div>
              <Button
                type="button"
                className="modalFooter"
                onClick={hideModal}
                children={text.get('actions.ok')}
              />
            </div>
          );
      }
    };

    /**
     * Toggles item of account list
     * @params {object} item - item of account list
     * @return {void}
     */
    onToggleCheckBox = (item) => {
      const { accounts } = this.state;
      let updatedList = accounts;
      accounts.map((account, index) => {
        if (account.id.toString() === item.id.toString()) {
          return (updatedList[index] = {
            ...updatedList[index],
            shouldInvite: item.shouldInvite,
          });
        }
        return account;
      });

      this.setState({ accounts: updatedList });
    };

    /**
     * Toggles items of account list
     * @return {void}
     */
    toggleCheckedItems = () => {
      this.setState({
        checkedAll: !this.state.checkedAll,
        accounts: this.state.accounts.map((item) => {
          item.shouldInvite = !this.state.checkedAll;
          return item;
        }),
      });
    };

    /**
     * Renders the component view.
     * @return {React.Component}
     */
    render() {
      const { step, showConfirmModal } = this.state;
      const { text } = this.props;
      return (
        <div className="UploadAccounts">
          <div className="modalView">
            {step && (
              <ModalHeaderLine
                {...{ showConfirmModal, step, text }}
                hideConfirm={this.hideConfirm}
              />
            )}
            {this.renderFormSteps()}
          </div>
        </div>
      );
    }
  }
);
