/********************************************************
 GENERAL
 ********************************************************/

// {string} CONTACT_EMAIL - Support e-mail address
export const CONTACT_EMAIL = 'powerplan@greener.nl';

/********************************************************
 ENVIRONMENT VARIABLES
 ********************************************************/

// build variables
// {string} ENVIRONMENT - Environment name
export const ENVIRONMENT = process.env.NODE_ENV;

// {string} API_URL - Backend API HTTP address
export const API_URL = process.env.REACT_APP_API_URL;

// {bool} PRODUCTION - Tells if in production mode.
export const PRODUCTION = ENVIRONMENT === 'development';

//allow debug mode in development
const ALLOW_DEBUG = false;

// {bool} DEBUG - Tells if in debug mode.
export const DEBUG = ENVIRONMENT === 'development' && ALLOW_DEBUG;

// {bool} TEST - Tells if in test mode.
export const TEST = ENVIRONMENT === 'test';

// {string} RELEASE_TAG - Application version or commit hash
export const RELEASE_TAG = process.env.REACT_APP_RELEASE_TAG;

// SENTRY VARIABLES

// {string} SENTRY_DNS - Sentry url to capture errors
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

// {bool} SENTRY_ENABLED - Enable Sentry browserTracing by adding the API_URL to the browserTracing config.
//Make sure to allow CORS for the sentry url on the server
export const SENTRY_TRACING_ORIGINS_ENABLED = !!parseInt(
  process.env.REACT_APP_SENTRY_TRACING_ORIGINS_ENABLED
);

/********************************************************
 DATES
 ********************************************************/

// {string} NO_DATE - Moment js string for no date value set
export const NO_DATE = null;

// {string} NO_DATE - Date-fns js string for date and time
export const DATE_TIME_FORMAT = 'dd/MM/yyyy hh:mm';

// {string} NO_DATE - Date-fns js string for date
export const DATE_FORMAT = 'dd/MM/yyyy';
