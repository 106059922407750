import React from 'react';
import { inject, observer } from 'mobx-react';
import IconButton from 'material-ui/IconButton';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

import { Button, DateTimeRangePicker } from 'shared/components/form';
import { Icon } from 'shared/components';

const enhance = compose(inject('text'), observer);

export default enhance(
  class RangeDateFields extends React.Component {
    static propTypes = {
      minDate: PropTypes.string,
      maxDate: PropTypes.string,
    };

    state = {
      focusedInput: null,
    };

    onDatesChange = (props, key) => {
      const { field } = this.props;
      field.$(key).set({
        ...props,
      });
      //validate the field so that the field validation gets updated after change
      field.validate();
    };

    addEmpty = () => {
      this.props.field.add({
        startDate: null,
        endDate: null,
        startTime: null,
        endTime: null,
      });
    };

    deleteDate = (index) => {
      this.props.field.del(index);
    };

    render() {
      const {
        text,
        field,
        id,
        label,
        minDate,
        maxDate,
        initialVisibleMonth,
      } = this.props;
      const { focusedInput } = this.state;

      const datesSize = field.values().length;

      return (
        <div className="dataRangeSection">
          <label>{label}</label>
          {field.map((field) => {
            const {
              value: { startDate, endDate, startTime, endTime },
              key,
            } = field;
            const focused =
              focusedInput === `startDate-${key}`
                ? 'startDate'
                : focusedInput === `endDate-${key}`
                ? 'endDate'
                : null;
            const errors = !field.isValid ? field.errors() : {};
            return (
              <React.Fragment key={`${id}-${key}`}>
                <div className="selectDateTimeRow">
                  <DateTimeRangePicker
                    small
                    onDatesChange={(data) => {
                      this.onDatesChange(data, key);
                    }}
                    onFocusChange={(focusedInput) =>
                      this.setState({
                        focusedInput: `${focusedInput}-${key}`,
                      })
                    }
                    startDateId={`${id}-StartDate-${key}`}
                    endDateId={`${id}-EndDate-${key}`}
                    focusedInput={focused}
                    startDate={startDate}
                    endDate={endDate}
                    startTime={startTime}
                    endTime={endTime}
                    startTimeText={text.get('forms.event.startTime')}
                    endTimeText={text.get('forms.event.endTime')}
                    minDate={minDate}
                    maxDate={maxDate}
                    initialVisibleMonth={initialVisibleMonth}
                  />
                  {datesSize > 1 && (
                    <div>
                      <IconButton
                        onClick={() => {
                          this.deleteDate(key);
                        }}
                      >
                        <Icon className="icon" name="close" />
                      </IconButton>
                    </div>
                  )}
                </div>
                {!field.isValid && (
                  <div className="errorMsg">
                    {errors.startDate ||
                      errors.endDate ||
                      errors.startTime ||
                      errors.endTime}
                  </div>
                )}
              </React.Fragment>
            );
          })}

          <div className="buttonBox">
            <Button
              size="small"
              type="button"
              styling="alt2"
              onClick={this.addEmpty}
            >
              {text.get('forms.event.addMore')}
            </Button>
          </div>
        </div>
      );
    }
  }
);
