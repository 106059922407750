import React from 'react';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';

export const Home = (props) => (
  <HomeOutlinedIcon
    fill="#000000"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  ></HomeOutlinedIcon>
);
