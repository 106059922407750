import React from 'react';
import './styles.less';

/**
 * Button component
 * @param {object} props - React component properties
 * @return {React.Component}
 */
export const Button = ({
  className,
  href,
  roundIcon,
  size,
  styling,
  ...props
}) => {
  const classes = `Button ${roundIcon && 'round'} ${
    href && 'button-link'
  } button-size-${size || 'default'} button-style-${styling || 'default'} ${
    className || ''
  }`;
  return !href ? (
    <button {...props} className={classes} />
  ) : (
    <a {...props} className={classes}>
      {props.children}
    </a>
  );
};
