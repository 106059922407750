import React from 'react';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';

const enhance = compose(inject('text'), observer);

/**
 * NotFound component represents the 'not found' page.
 * @class NotFound
 * @extends {React.Component}
 */
export const NotFoundPage = enhance(({ text }) => (
  <h1>{text.get('components.views.notFound.pageTitle')}</h1>
));
