/* eslint-disable no-use-before-define */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Typography, TextField, Chip, Box } from '@material-ui/core/';
import { useController } from 'react-hook-form';

import { useGetText } from 'shared/hooks';
import { AutoCompleteMultipleInput } from '../form';

export function AutocompleteMultipleInput({
  control,
  name,
  fullWidth = true,
  variant = 'standard',
  label,
  size = 'small',
  options,
  disabled,
  readOnly = false,
  renderGroupTextLocation = null,
  groupOptionBy = null,
  getOptionSelected,
  renderOption,
  ...rest
}) {
  const {
    field: { ref, onBlur, onChange, value, ...inputProps },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
  });
  const text = useGetText();

  const existingGroupTypes = useCallback(() => {
    if (!groupOptionBy) {
      return [];
    } else {
      const typeSet = new Set([
        ...value.map((option) => option[groupOptionBy]),
      ]);
      return [...typeSet];
    }
  }, [groupOptionBy, value]);

  let optionalProps = {};

  if (groupOptionBy) {
    function groupBy(option) {
      return option[groupOptionBy];
    }

    optionalProps.groupBy = groupBy;

    if (renderGroupTextLocation) {
      const customRenderGroup = (params) => {
        return [
          <div key={params.key} style={{ margin: '12px' }}>
            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
              {text.getText(`${renderGroupTextLocation}.${params.group}`)}
            </Typography>
          </div>,
          params.children,
        ];
      };

      const handleDelete = (optionToRemove) => {
        if (!getOptionSelected) return undefined;

        //find the first index that matches the option to remove
        const firstIndex = value.findIndex((val) => {
          return getOptionSelected(val, optionToRemove);
        });

        if (firstIndex > -1) {
          const newValues = [...value];
          newValues.splice(firstIndex, 1); //remove the index with the matching externalIdentifier
          onChange(newValues);
        }
      };

      const renderTags = (tagValue, getTagProps) => {
        return existingGroupTypes().map((group, groupIndex) => (
          <div key={group}>
            <Box style={{ width: '100%', padding: '6px' }}>
              <Typography variant="caption">
                {text.getText(`${renderGroupTextLocation}.${group}`)}
              </Typography>
            </Box>
            {tagValue
              .filter((val) => val[groupOptionBy] === group)
              .map((option, index) => {
                const tagIndex = groupIndex * 10 + index; //create a number index for the tag
                return (
                  <Chip
                    //include the group name in the key so that we have a unique key for each key for each tag in order for the onDelete function to work
                    {...getTagProps({ index: tagIndex })}
                    {...(getOptionSelected && {
                      //only overwrite the onDelete function from getTagProps if the getOptionSelected function is defined
                      onDelete: () => {
                        handleDelete(option);
                      },
                    })}
                    label={option.label}
                  />
                );
              })}
          </div>
        ));
      };

      optionalProps = {
        ...optionalProps,
        renderTags,
        renderGroup: customRenderGroup,
      };
    }
  }

  //log the error since normally a faulty option is a developer error, not a user error. For custom options validation
  //should take place in a create option form
  error && console.error('Autocomplete field error(s):', JSON.stringify(error));

  return (
    <Autocomplete
      id={`autocomplete-${label}`}
      multiple={true}
      value={value}
      options={options}
      disabled={disabled}
      renderOption={renderOption}
      autoHighlight
      onChange={(event, newValue, reason, detail) => onChange(newValue)}
      {...optionalProps}
      {...inputProps}
      getOptionSelected={getOptionSelected}
      renderInput={(params) => (
        <TextField
          {...params}
          inputRef={ref}
          name={name}
          label={label}
          error={invalid}
          variant={variant}
          fullWidth={fullWidth}
          inputProps={{
            ...params.inputProps,
            readOnly: readOnly,
            autoComplete: 'disabled', // disable autocomplete and autofill
          }}
          size={size}
        />
      )}
      {...rest}
    />
  );
}

AutoCompleteMultipleInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  control: PropTypes.object.isRequired,
  fullWidth: PropTypes.bool,
  variant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  renderGroupTextLocation: null,
  groupOptionBy: (props, propName, componentName) => {
    if (
      !!props['renderGroupTextLocation'] &&
      (props[propName] === undefined || typeof props[propName] != 'string')
    ) {
      return new Error(
        'Please provide a groupOptionBy property if renderGroupTextLocation is provided'
      );
    } else {
      return PropTypes.string;
    }
  },
  renderOption: PropTypes.func,
  onChange: PropTypes.func,
  transformValueFn: PropTypes.func,
  getOptionSelected: (props, propName, componentName) => {
    if (
      !!props['renderGroupTextLocation'] &&
      (props[propName] === undefined || typeof props[propName] != 'function')
    ) {
      return new Error(
        'Please provide a getOptionSelected property if renderGroupTextLocation is provided'
      );
    } else {
      return PropTypes.func;
    }
  },
};
