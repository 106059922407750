import React, { createRef } from 'react';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import { Tooltip, Typography } from '@material-ui/core';
import { UserFormModal, SpotList, Checkbox } from 'shared/components';
import {
  Button,
  RichTextInput,
  AutoCompleteInput,
  TextInput,
} from 'shared/components/form';
import { formCreator } from 'shared/form';
import { SpotModalForm } from 'spots/components';
import { Roles } from 'shared/constants';

import { getUserOptions } from 'users/utils';
import fields from './fields';
import labelsConfig from './labels';
import rules from './rules';
import './styles.less';
import { loadUser } from 'auth/actions';

const enhance = compose(inject('text', 'users', 'companies'), observer);

function makeid() {
  var text = '';
  var possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (var i = 0; i < 30; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
}

/**
 * PowerInquiryInvitationForm class representes the energy user form component.
 * @class PowerInquiryInvitationForm
 * @extends {React.Component}
 */
export const PowerInquiryInvitationForm = enhance(
  class extends React.Component {
    // {object} state - Component state holds the form instance.
    constructor(props) {
      super(props);
      this.state = {
        form: null,
        spots: [],
        addUserModalOpen: false,
      };
      this.message = createRef();
      this.handleUserChange = this.handleUserChange.bind(this);
      this.handleOwnerChange = this.handleOwnerChange.bind(this);
      this.onSubmit = this.onSubmit.bind(this);
      this.toggleAddUserModalOpen = this.toggleAddUserModalOpen.bind(this);
    }

    // {object} config - Form configuration getter
    get config() {
      const { onSuccess, onError, text } = this.props;
      const title = 'powerInquiryInvitationForm';
      const labels = labelsConfig(text.get('forms.powerInquiries'));
      return {
        title,
        fields,
        labels,
        rules,
        onSuccess: (form) => {
          const template = form.$('message').value;
          form.$('message').onChange(template);
          onSuccess(form, this.state.spots);
        },
        onError,
      };
    }

    get empty() {
      const { text, event, invite, spots } = this.props;
      let inviteOwner = null;
      if (invite?.owner) {
        inviteOwner = invite.owner;
      } else {
        const me = loadUser();
        inviteOwner = me;
      }
      const emailTitle = text
        .get('forms.powerInquiries.emailTitle')
        .replace('{festival}', event.festival);
      let spotList = [];
      spots && spots.map((item) => spotList.push({ label: item.label }));
      this.setState({ spots: spotList });
      let form = {
        user: invite?.user || null, //{ label: "", value: 0 },
        emailTitle,
        message: '',
        spots: spotList || [],
        owner: inviteOwner,
        canSeeConnectionPrices: true,
        _id: null,
      };
      return form;
    }

    get userField() {
      return document.getElementById('user');
    }

    /**
     * React lifecycle method - executed everytime the component is about to be mounted in the view.
     * @return {void}
     */
    componentDidMount() {
      if (this.props.isReminder)
        this.message.current.update(this.props.inviteMessage);
      const { values } = this.props;
      const form = formCreator(this.config);
      this.setState({ form });
      form.init({ ...this.empty, ...values }); // making sure everytime you select a new user
    }

    updateForm(values) {
      const { form } = this.state;
      const messageRef = this.message.current;
      form.init({ ...this.empty, ...values }); // making sure everytime you select a new user, his data will appear
      if (!this.props.isReminder) {
        const spots = values.spots.map((spot) => {
          if (!spot.label) {
            return { label: spot, id: makeid() };
          }
          return spot;
        });
        messageRef && messageRef.update(values.message);

        this.setState({
          spots,
        });
      }
    }

    handleUserChange(newUser) {
      const { text } = this.props;
      const { form } = this.state;
      form.$('user').set('value', newUser || null);
      const messageRef = this.message.current;
      //update message with user
      let current = text.get('forms.powerInquiries.defaultMessage');
      if (form.$('message').value) {
        current = this.state.form.$('message').value;
      }
      if (newUser?._id === 0) {
        //ToDo use this when modal rerender is fixed
        this.toggleAddUserModalOpen();
      }
      messageRef && messageRef.update(current);
    }

    handleOwnerChange(newOwner) {
      const { text } = this.props;
      const { form } = this.state;
      form.$('owner').set('value', newOwner || null);
      const messageRef = this.message.current;
      //update message with user
      let current = text.get('forms.powerInquiries.defaultMessage');
      if (form.$('message').value) {
        current = this.state.form.$('message').value;
      }
      if (newOwner?._id === 0) {
        //ToDo use this when modal rerender is fixed
        this.toggleAddUserModalOpen();
      }
      messageRef && messageRef.update(current);
    }

    toggleAddUserModalOpen() {
      const newState = !this.state.addUserModalOpen;
      this.setState({ addUserModalOpen: newState });
    }

    removeSpot = (spot) => {
      this.setState({
        spots: [...this.state.spots.filter((sp) => spot.label !== sp.label)],
      });
    };
    onClickNewSpot = () => {
      this.refs.spotModal.wrappedInstance.updateForm({});
      this.refs.spotModal.wrappedInstance.showForm();
    };
    onClickEditSpot = (item) => {
      this.setState({ editItem: item });
      this.refs.spotModal.wrappedInstance.updateForm(item);
      this.refs.spotModal.wrappedInstance.showForm();
    };
    onSaveSpot = (form) => {
      const { editItem, spots } = this.state;
      let useFullSpots = spots;
      if (editItem) {
        useFullSpots = useFullSpots.filter(
          (item) => item.label !== editItem.label
        );
      }
      const data = form.values();
      this.setState({ spots: [...useFullSpots, data] });
      this.refs.spotModal.wrappedInstance.hideForm();
    };

    getOwnerOptions(event, users, eventCompanyIds, companiesLoading) {
      if (companiesLoading) return [];
      return getUserOptions(
        users,
        [Roles.ADMIN, Roles.EVENT_CREATOR, Roles.INQUIRY_MANAGER],
        eventCompanyIds
      );
    }

    onSubmit() {
      const { onSuccess } = this.props;
      const { form, spots } = this.state;
      onSuccess(form, spots);
      setTimeout(() => {
        this.setState({ spots: [] });
        form.reset();
        this.message.current.reset();
      }, 0);
    }

    /**
     * Renders the component view.
     * @return {React.Component}
     */
    render() {
      const { text, usersLoading, event, companies, users } = this.props;
      const { form, spots, addUserModalOpen } = this.state;
      if (!form) return null;

      const eventHasConnectionPrices =
        event.availableConnections &&
        event.availableConnections.filter((connection) => connection.price > 0)
          .length > 0;

      const connectionPricesTooltip = eventHasConnectionPrices
        ? text.get(
            'forms.powerInquiries.eventConnectionPricesTooltip.eventHasPrices'
          )
        : text.get(
            'forms.powerInquiries.eventConnectionPricesTooltip.noPrices'
          );

      const eventCompanyIds = event.eventCompanies.map((c) => c.companyId);

      return (
        <>
          <form
            autoComplete="new-password"
            className="PowerInquiryInvitationForm"
          >
            <input type={'hidden'} value={makeid()} />

            <SpotModalForm
              ref="spotModal"
              onSuccess={this.onSaveSpot}
              cancelHandler={() => {
                this.refs.spotModal.wrappedInstance.hideForm();
                this.setState({ editItem: null });
              }}
            />

            {!this.props.isReminder && (
              <React.Fragment>
                <div className="inputRow">
                  <AutoCompleteInput
                    field={form.$('user')}
                    value={form.$('user').value}
                    label={text.get('forms.powerInquiries.user')}
                    name={'user'}
                    id="user"
                    optionsLoading={usersLoading}
                    options={getUserOptions(users, [Roles.ENERGY_USER])}
                    onChange={this.handleUserChange}
                    getOptionLabel={(option) =>
                      option._id === 0
                        ? `+ ${text.get('forms.adminUsers.addUserOption')}: "${
                            option.name
                          }"`
                        : option.email
                        ? `${option.name} <${option.email || 'No email'}> - ${
                            option.companyName
                          }`
                        : ''
                    }
                    addNewInputValueProp="name"
                    freeSolo={false}
                  />
                </div>
                <div style={{ marginTop: '18px' }}>
                  <Tooltip enterDelay={100} title={connectionPricesTooltip}>
                    <div>
                      <Checkbox
                        fullWidth
                        field={form.$('canSeeConnectionPrices')}
                        disabled={!eventHasConnectionPrices}
                        label={
                          <Typography>
                            {text.get(
                              'forms.powerInquiries.canSeeConnectionPrices'
                            )}
                          </Typography>
                        }
                      />
                    </div>
                  </Tooltip>
                </div>
                <div className="inputRow">
                  <div className="row">
                    <SpotList
                      removeAble
                      spots={spots || []}
                      onEdit={(item) => this.onClickEditSpot(item)}
                      onRemove={(item) => this.removeSpot(item)}
                      text={text}
                    />
                    {!this.props.isReminder && (
                      <span
                        className="link"
                        onClick={this.onClickNewSpot}
                        children={text.get('forms.powerInquiries.addSpot')}
                      />
                    )}
                  </div>
                </div>
                <div className="formItem">
                  <Typography variant="h6">
                    {text.get('forms.powerInquiries.owner')}
                  </Typography>
                  <AutoCompleteInput
                    field={form.$('owner')}
                    value={form.$('owner').value}
                    label={text.get('forms.powerInquiries.owner')}
                    name={'owner'}
                    id="owner"
                    optionsLoading={usersLoading}
                    getOptionLabel={(option) =>
                      `${option.name} <${option.email || 'No email'}> ${
                        option.companyName
                      }`
                    }
                    options={this.getOwnerOptions(
                      event,
                      users,
                      eventCompanyIds,
                      companies.loading
                    )}
                    onChange={this.handleOwnerChange}
                  />
                </div>
                <div className="inputRow">
                  <TextInput
                    field={form.$('emailTitle')}
                    type="text"
                    autoComplete="new-password"
                  />
                </div>
              </React.Fragment>
            )}

            <div className="inputRow messageBox">
              <RichTextInput ref={this.message} field={form.$('message')} />
            </div>
            <div className="buttonRow" name="save button">
              <Button
                title={text.get('forms.powerInquiries.noSpotHover')}
                type="button"
                styling="cta"
                size="small"
                onClick={this.onSubmit}
                disabled={form.hasError}
                children={text.get('actions.save')}
              />
            </div>
          </form>
          <UserFormModal
            addModalOpen={addUserModalOpen}
            toggleAddModalOpen={this.toggleAddUserModalOpen}
            userInput={form.$('user').value}
            handleClickAdd={this.handleUserChange}
          />
        </>
      );
    }
  }
);
