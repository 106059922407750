import React from 'react';
import { Button } from 'shared/components/form';
import { useGetText } from 'shared/hooks';
import './styles.less';

const HistoricalEventSpotModal = ({
  spot,
  festival,
  hideModal,
  onClickSpot,
}) => {
  const { getText } = useGetText();

  return (
    <div className="confirmModal">
      <div className="modalHeader">
        <p>{getText('forms.spot.chooseHistoricalSpotHeader')}</p>
      </div>
      <div className="modalBody">
        <p>
          {getText('forms.spot.chooseHistoricalSpotDesc', {
            spotname: spot.label,
            festival: festival,
          })}
        </p>
      </div>
      <div className="modalFooter">
        <Button size="small" styling="text" onClick={hideModal}>
          {getText('actions.cancel')}
        </Button>
        <Button size="small" styling="cta" onClick={onClickSpot}>
          {getText('actions.confirm')}
        </Button>
      </div>
    </div>
  );
};

export default HistoricalEventSpotModal;
