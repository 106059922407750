import React from 'react';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';

import { Spinner } from 'shared/components';
import { Button } from 'shared/components/form';
import { loadPowerzones } from 'powerzones/actions';
import { loadAreas } from 'areas/actions';
import { loadSpots } from 'spots/actions';

import PowerplanTree from './powerplanTree';
import './styles.less';

const enhance = compose(
  inject('areas', 'events', 'powerzones', 'spots', 'text'),
  observer
);

/**
 * PowerzonesOverviewPage class represents the event powerzones overview page, which
 * contains a tree visualization of all the Powerzones, with nested areas, sub-areas and spots.
 * @class PowerzonesOverviewPage
 * @extends {React.Component}
 */
export const PowerzonesOverviewPage = enhance(
  class extends React.Component {
    /**
     * React lifecycle method - executed everytime the component was just mounted in the view.
     * @return {void}
     */
    UNSAFE_componentWillMount() {
      const {
        params: { eventId },
        powerzones,
      } = this.props;
      if (
        powerzones.list.length === 0 ||
        powerzones.list[0].eventId !== parseInt(eventId, 10)
      ) {
        this.loadNodes(eventId);
      }
    }

    /**
     * Load the powerzones, areas and spots to create the tree.
     * @param {string} eventId - Event id
     * @return {void}
     */
    loadNodes(eventId) {
      loadPowerzones(eventId);
      loadAreas(eventId);
      loadSpots(eventId);
    }

    /**
     * Renders the component view.
     * @return {React.Component}
     */
    render() {
      const {
        events,
        params: { eventId },
        text,
      } = this.props;
      const event = events.getEvent(eventId);
      if (!event) return <Spinner />;
      return (
        <div className="PowerzonesOverviewPage padding">
          <h1>{`${event.festival} • ${text.get(
            'components.views.powerzones.overview'
          )}`}</h1>
          <div className="buttonRow left">
            <Button
              onClick={() =>
                this.refs.powerplanTree.wrappedInstance.addPowerzone()
              }
              children={text.get('components.views.powerzones.newPowerzone')}
              size="small"
            />
          </div>
          <PowerplanTree ref="powerplanTree" eventId={eventId} />
        </div>
      );
    }
  }
);
