// {object[]} LANGUAGES - Available system languages
// TODO: import it from a central location
export const LANGUAGES = [
  { value: 'en_US', label: 'English' },
  { value: 'nl_NL', label: 'Dutch' },
  { value: 'pt_BR', label: 'Portuguese' },
];

// {object[]} ROLES - Available system roles
// TODO: import it from a central location
export const ROLES = [
  { value: 1, label: 'Admin' },
  { value: 2, label: 'Power Planner' },
  { value: 4, label: 'Power Supplier' },
  { value: 16, label: 'Inquiry Manager' },
  { value: 8, label: 'Energy User' },
];

// {object[]} PHONE_TYPES - Available phone types
export const PHONE_TYPES = [
  { value: 'invitations', label: 'Invitations' },
  { value: 'general', label: 'General' },
  { value: 'home', label: 'Home' },
  { value: 'mobile', label: 'Mobile' },
  { value: 'work', label: 'Work' },
];

// {object} EMPTY_PHONE - Empty phone object
export const EMPTY_PHONE = {
  type: '',
  number: '',
};

// {object} EMPTY_FORM - Empty form object
export const EMPTY_FORM = {
  name: '',
  language: '',
  email: '',
  role: '',
  phone: '',
  password: '',
  repeatPassword: '',
  companyId: '',
  companyName: '',
  address: '',
  city: '',
  zipCode: '',
  country: '',
  credits: '',
};
