import React from 'react';
import PropTypes from 'prop-types';

import Chip from '@material-ui/core/Chip';
import PowerIcon from '@material-ui/icons/PowerOutlined';
import BatteryChargingFullIcon from '@material-ui/icons/BatteryChargingFull';
import LocalGasStationOutlinedIcon from '@material-ui/icons/LocalGasStationOutlined';
import TabUnselectedIcon from '@material-ui/icons/TabUnselected';
import { ReportTable } from 'shared/components';

export const PowerzoneTable = ({ title, data, text, handleRowClick }) => {
  const textLocation = 'components.views.powerzones.summaryTable';

  //Render columns with chip and icon
  function renderChips(rowData, renderType, field, icon) {
    return renderType === 'row' ? (
      <div>
        {rowData[field].map((type, index) => (
          <Chip key={index} label={type} icon={icon} variant="outlined" />
        ))}
      </div>
    ) : (
      rowData
    );
  }

  function renderPowerSources(rowData, renderType, field, icon) {
    return renderType === 'row' ? (
      <div>
        {rowData[field].map((ps, index) => (
          <Chip key={index} label={ps.label} icon={icon} variant="outlined" />
        ))}
      </div>
    ) : (
      rowData
    );
  }

  return (
    <ReportTable
      title={title}
      data={data}
      onRowClick={handleRowClick}
      options={{
        pageSize: 20,
        exportFileName: title,
        padding: 'dense',
      }}
      customActions={[]}
      customLocalization={{
        body: {
          emptyDataSourceMessage: text.get(
            'components.views.powerzones.summaryTable.emptyDataSourceMessage'
          ),
        },
      }}
      text={text}
      textLocation={textLocation}
      customColumnSettings={{
        theoreticalPower: {
          render: (rowData) =>
            rowData.theoreticalPower > 0 ? rowData.theoreticalPower : '',
        },
        maxPeakLoad: {
          render: (rowData) =>
            rowData.maxPeakLoad > 0 ? rowData.maxPeakLoad : '',
          hidden: true,
        },
        gridConnections: {
          render: (rowData, renderType) =>
            renderPowerSources(
              rowData,
              renderType,
              'gridConnections',
              <PowerIcon fontSize="small" />
            ),
        },
        batteries: {
          render: (rowData, renderType) =>
            renderPowerSources(
              rowData,
              renderType,
              'batteries',
              <BatteryChargingFullIcon fontSize="small" />
            ),
        },
        dieselGenerators: {
          render: (rowData, renderType) =>
            renderPowerSources(
              rowData,
              renderType,
              'dieselGenerators',
              <LocalGasStationOutlinedIcon fontSize="small" />
            ),
        },
        areas: {
          render: (rowData, renderType) =>
            renderChips(
              rowData,
              renderType,
              'areas',
              <TabUnselectedIcon fontSize="small" />
            ),
        },
        powerOnDate: {
          type: 'datetime',
        },
        powerOffDate: {
          type: 'datetime',
        },
        earliestStartDate: {
          type: 'datetime',
        },
        latestEndDate: {
          type: 'datetime',
        },
        _id: {
          hidden: true,
          hiddenByColumnsButton: true,
        },
        spotStrings: {
          render: (rowData, renderType) =>
            renderChips(rowData, renderType, 'spotStrings'),
          hidden: true,
        },
        children: {
          hidden: true,
          hiddenByColumnsButton: true,
        },
      }}
    />
  );
};

PowerzoneTable.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  onRowClick: PropTypes.func,
  text: PropTypes.object,
};
