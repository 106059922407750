export default (text) => ({
  label: text.label,
  cluster: text.cluster,
  tags: text.tags,
  estimated: text.estimated,
  'layout.comment': text.comment,
  'layout.connections[].name': text.name,
  'layout.connections[].connectionSizeType.externalId':
    text.connectionSizeType.externalId,
  'layout.connections[].connectionSizeType.price':
    text.connectionSizeType.price,
  'layout.connections[].quantity': text.quantity,
  'layout.connections[].nightPower': text.nightpower,
  'layout.connections[].upsNeeded': text.upsNeeded,
  'layout.connections[].hasArrived': text.hasArrived,
  'layout.connections[].isConnected': text.isConnected,
  'layout.connections[].devices[].name': text.deviceName,
  'layout.connections[].devices[].ratedPower': text.ratedPower,
  'layout.connections[].devices[].quantity': text.quantity,
  'layout.connections[].devices[].unit': text.unit,
  'layout.connections[].meterId': text.meterId,
  coordinate: text.coordinate,
});
