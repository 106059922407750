import React from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { compose } from 'recompose';
import IconButton from 'material-ui/IconButton';

import { Box, Icon } from 'shared/components';
import { Toggle } from 'shared/components/form';
import { confirm } from 'shared/actions';
import { calculateTotalTheoreticalPower } from 'spots/utils';
import { updateSpotApproval } from 'spots/actions';

import { SpotAdminForm } from '../SpotAdminForm';
import './styles.less';

const enhance = compose(inject('text'), observer);

const SpotActions = ({ spot, removeNode, text }) =>
  !spot.approved ? (
    <IconButton
      onClick={() =>
        confirm(text.get('actions.confirmRemove'), () => removeNode(spot))
      }
    >
      <Icon name="delete" className="rowIcon" />
    </IconButton>
  ) : (
    <div style={{ width: 20, height: 40 }}></div>
  );

/**
 * SpotDetails represents the Spot overview with possibility to edit component.
 * @class {SpotDetails}
 * @extends {React.Component}
 */
export const SpotDetails = enhance(
  class extends React.Component {
    /**
     * Handles the form submission action.
     * @param {object} spot - Spot instance
     * @return {void}
     */
    onSuccess(form) {
      const spot = form.values();

      const { eventId } = this.props;
      this.props.onSpotUpdate({ ...spot, eventId });
    }

    // {object} state - Component state holds the form instance.
    state = {
      approved: false,
      spotId: '',
      expanded: true,
    };

    /**
     * React lifecycle method - executed everytime the component is about to be mounted in the view.
     * @return {void}
     */
    UNSAFE_componentWillMount() {
      const {
        spot: { approved, _id },
      } = this.props;
      this.setState({ approved, spotId: _id });
    }

    /**
     * React lifecycle method - executed everytime the component is about to be mounted in the view.
     * @return {void}
     */
    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
      const {
        spot: { approved, _id },
      } = nextProps;
      if (_id !== this.state.spotId) {
        this.setState({ approved, spotId: _id });
      }
    }

    onToggleApproved = (event, spot) => {
      const { approved } = this.state;
      const newApprovedState = !approved;
      updateSpotApproval({ ...spot, approved: newApprovedState }).then(() => {
        this.setState({ approved: newApprovedState });
      });
    };

    getTitle(spot) {
      const { text, removeSpot } = this.props;
      const theoreticalPower = calculateTotalTheoreticalPower([spot]).toFixed(
        1
      );

      return (
        <div
          className="spotTitle"
          onClick={() => this.setState({ expanded: !this.state.expanded })}
        >
          <span>
            {spot.label} -{' '}
            <span className="featured">{theoreticalPower || 0} kW</span>
          </span>
          {!!this.props.removeSpot && (
            <div className="actions">
              <Icon
                name={this.state.expanded ? 'arrow-up' : 'arrow-down'}
                className="rowIcon"
              />
              <SpotActions removeNode={removeSpot} text={text} spot={spot} />
            </div>
          )}
          <span className="date">
            {text.get('forms.spot.updatedAt')}:{' '}
            {(spot.updatedAt && moment(spot.updatedAt).format('d-M-Y hh:mm')) ||
              ''}
          </span>
          <span className="approved">
            <Toggle
              labelPosition="right"
              toggled={this.state.approved}
              onToggle={(e) => this.onToggleApproved(e, spot)}
              label={
                this.state.approved
                  ? text.get('forms.spot.approvedYes')
                  : text.get('forms.spot.approvedNo')
              }
            />
          </span>
        </div>
      );
    }

    render() {
      const { spot, historyResourceId, showSpotForm } = this.props;
      const title = this.getTitle(spot);
      const { approved, expanded } = this.state;
      return (
        <Box title={title}>
          {showSpotForm && expanded && (
            <SpotAdminForm
              values={spot}
              historyResourceId={historyResourceId}
              onSuccess={(form) => this.onSuccess(form)}
              isApproved={approved}
            />
          )}
        </Box>
      );
    }
  }
);
