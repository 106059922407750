// {object[]} UNITS - Available connection energy units
export const UNITS = [
  { value: 'W', label: 'W' },
  { value: 'A', label: 'A' },
];

export const EMPTY_DEVICE = {
  name: '',
  brand: '',
  modelNumber: '',
  quantity: 1,
  unit: 'W',
  ratedPower: '',
};
