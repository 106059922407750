import React from 'react';
import { observer, inject } from 'mobx-react';
import { compose } from 'recompose';

import { pushRoute } from 'shared/actions';
import { recoverSpotFromBuffer, saveSpotToBuffer } from 'spots/actions';
import { SpotExtraForm } from 'spots/components';

import './styles.less';

const enhance = compose(inject('events'), observer);

/**
 * SpotExtraPage class represents the view of the 2nd step of the spot modification process.
 * It presents the form where the user should fill some extra information about the spot
 * connections.
 * @class SpotExtraPage
 * @extends {React.Component}
 */
export const SpotExtraPage = enhance(
  class extends React.Component {
    // {object} state - State object stores information about the current spot
    state = { spot: null };
    /**
     * React lifecycle method - executed everytime the component is about to be mounted in the view.
     * @return {void}
     */
    UNSAFE_componentWillMount() {
      const {
        params: { eventId },
      } = this.props;
      const spot = recoverSpotFromBuffer();
      if (!spot) return pushRoute(`/event/${eventId}/spots`);
      this.setState({ spot });
    }

    /**
     * Form submission success handler.
     * @param {object} form - Form instance
     * @return {void}
     */
    onSuccess(form) {
      const {
        params: { eventId, spotId },
      } = this.props;
      saveSpotToBuffer(form.values());
      pushRoute(`/event/${eventId}/spots/${spotId}/revision`);
    }

    /**
     * Renders the component view.
     * @return {React.Component}
     */
    render() {
      const {
        events,
        params: { eventId },
      } = this.props;
      const { spot } = this.state;
      const event = events.getEvent(eventId);
      if (!spot) return null;
      return (
        <div className="ExtraConnectionsView">
          <SpotExtraForm
            onSuccess={(form) => this.onSuccess(form)}
            values={spot}
            event={event}
          />
        </div>
      );
    }
  }
);
