import { useCallback } from 'react';
import { useQueryClient, useMutation } from 'react-query';

import { notify } from 'shared/actions';
import { Notifications } from 'shared/constants';
import { api } from 'shared/utils';

import { eventKeys } from 'shared/utils/api/queryKeys';

const doRemoveEvent = async (eventId) => await api.delete(`/events/${eventId}`);

export const useRemoveEvent = () => {
  const queryClient = useQueryClient();
  const { mutateAsync, status, ...others } = useMutation(doRemoveEvent, {
    onSuccess: ({ status }, eventId) => {
      if (status === 200) {
        queryClient.refetchQueries(eventKeys.all());

        //notify the user
        notify({
          message: `Successfully removed event with Id: ${eventId}`,
          level: Notifications.Type.SUCCESS,
        });
      } else {
        notify({
          message: `Failed removing event: ${eventId}`,
          level: Notifications.Type.ERROR,
        });
      }
    },
  });

  const removeEvent = useCallback((eventId) => mutateAsync(eventId), [
    mutateAsync,
  ]);

  return {
    ...others,
    loading: status === 'loading',
    removeEvent,
  };
};
