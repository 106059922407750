import moment from 'moment';
import { EN, NL } from './shared/strings';

/**
 * Internationalization controller.
 *
 * Used to choose which language to present for each selected locale.
 * The system locales are NL, EN and PT, for now.
 * The others are default browser locales.
 * In case the current locale is not mentioned here, the system will present with
 * the default flag.
 */
export default (locale) => {
  switch (locale) {
    default:
    case 'NL':
    case 'nl_BE':
    case 'nl_NL':
      moment.locale('nl'); // Setting up momentjs locale
      return NL;

    case 'EN':
    case 'en_US':
    case 'en_EN':
      moment.locale('en'); // Setting up momentjs locale
      return EN;
  }
};
