import { useCallback } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import { api } from 'shared/utils';
import { notify } from 'shared/actions';
import { Notifications } from 'shared/constants';

import { APPLIANCES_QUERY_KEY } from '../constants';
import { putApplianceMapper } from '../mapper';
import { getApplianceString } from '../utils';

const doUpdateAppliance = async (appliance) => {
  return await api.put(
    `/appliancetemplates/${appliance._id}`,
    putApplianceMapper(appliance)
  );
};
export const useUpdateAppliance = () => {
  const queryClient = useQueryClient();
  const { mutateAsync, status, error, ...others } = useMutation(
    doUpdateAppliance,
    {
      onSuccess: ({ data, status }, variables) => {
        if (status === 200) {
          //ToDo: we have to wait for BE to return new appliance, now {} is returned
          // // ✅ update the list we are currently on instantly
          // queryClient.setQueryData(
          //   [APPLIANCES_QUERY_KEY, 'list', 'all'],
          //   (previous) => {
          //     return previous.map((app) => (app.id === data._id ? data : app));
          //   }
          // );

          // 🥳 invalidate all the lists, (can add 'refetchActive: true when we do update the cache in the previous step)
          queryClient.invalidateQueries({
            queryKey: [APPLIANCES_QUERY_KEY, 'list', 'all'],
          });

          //Notify the user
          notify({
            message: `Successfully updated appliance: ${getApplianceString(
              variables
            )}`,
            level: Notifications.Type.SUCCESS,
          });
        } else {
          notify({
            message: `Failed updating appliance: ${getApplianceString(
              variables
            )}`,
            level: Notifications.Type.ERROR,
          });
        }

        return data;
      },
    }
  );

  const updateAppliance = useCallback((appliance) => mutateAsync(appliance), [
    mutateAsync,
  ]);

  return {
    ...others,
    loading: status === 'loading',
    updateAppliance,
    error,
  };
};
