import React from 'react';
import { observer } from 'mobx-react';
import SelectField from 'material-ui/SelectField'; // Reference: http://www.material-ui.com/#/components/select-field
import MenuItem from 'material-ui/MenuItem'; // Reference: http://www.material-ui.com/#/components/menu
import styles from './styles';

/**
 * SelectInput component
 * @param {object} props - React component properties
 * @return {React.Component}
 */
export const SelectInput = observer(
  ({ field, label, onChange, formSubmit, options, hidden, ...props }) =>
    !hidden && (
      <SelectField
        hintText={label || (field && field.label)}
        floatingLabelText={label || (field && field.label)}
        errorText={field && field.error}
        {...styles}
        {...((field && field.bind(this)) || {})}
        onChange={(event, index, newValue) => {
          onChange && onChange(newValue);
          field && field.onChange(newValue);
          field && field.validate();
          formSubmit && formSubmit(event);
        }}
        {...props}
      >
        {options.map(({ value, label }, index) => (
          <MenuItem key={index} value={value} primaryText={label} />
        ))}
      </SelectField>
    )
);
