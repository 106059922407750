import React from 'react';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';

import { Spinner } from 'shared/components';
import { Button } from 'shared/components/form';

import { updatePowerzone, createPowerzone } from 'powerzones/actions';
import { loadSpots } from 'spots/actions';
import { loadAreas } from 'areas/actions';
import { loadPowerzones } from 'powerzones/actions';
import {
  PowerzoneSummaryTable,
  PowerzoneModalForm,
} from 'powerzones/components/';

import './styles.less';


const enhance = compose(
  inject('areas', 'events', 'powerzones', 'spots', 'text'),
  observer
);

/**
 * PowerzoneSummaryPage represents powerzones
 * tables overview.
 * @export
 * @class PowerzoneSummaryPage
 * @extends {React.Component}
 */
export const PowerzoneSummaryPage = enhance(
  class extends React.Component {
    componentDidMount() {
      const {
        params: { eventId },
      } = this.props;
      loadPowerzones(eventId);
      loadAreas(eventId);
      loadSpots(eventId);
    }

    // {boolean} isLoading - Tells if the node are still loading or not.
    get isLoading() {
      const { areas, powerzones, spots } = this.props;
      return (
        (powerzones.loading && !powerzones.list.length) ||
        (areas.loading && !areas.list.length) ||
        (spots.loading && !spots.list.length)
      );
    }

    /**
     * Shows the modal with the powerzone form, to add a new one.
     * @return {void}
     */
    addPowerzone() {
      this.refs.pzModal.wrappedInstance.updateForm({});
      this.refs.pzModal.wrappedInstance.modalRef.current.showForm();
    }

    /**
     * Triggered by the form submit success event. It receives the form data,
     * closes the modal and deliver to the method responsible for sending the
     * data to the API.
     * @param {object} form - Form instance
     * @return {void}
     */

    onSaveSuccess(form) {
      const data = form.values();
      this.closeModal();
      if (!data.associatedResource && data.associatedResource !== '')
        this.savePowerzone(data);
      else if (data.uri) {
        data.uri.includes('areas') && this.saveArea(data);
        data.uri.includes('spots') && this.saveSpot(data);
      } else {
        data.tag && this.saveArea(data);
        data.label && this.saveSpot(data);
      }
    }

    /**
     * Triggered by the Powerzone form 'submit' event when it's successfully.
     * @param {object} powerzone - Powerzone form data
     * @return {void}
     */
    savePowerzone(powerzone) {
      const eventId = parseInt(this.props.params.eventId);
      const pzone = { ...powerzone, eventId };
      if (powerzone.uri) updatePowerzone(pzone);
      // is editing
      else createPowerzone(pzone, eventId);
    }

    /**
     * Closes all the modals.
     * @return {void}
     */
    closeModal() {
      this.refs.pzModal.wrappedInstance.modalRef.current.hideForm();
    }

    /**
     * Renders the component view.
     * @return {React.Component}
     */
    render() {
      const {
        powerzones,
        areas,
        spots,
        text,
        events,
        params: { eventId },
      } = this.props;
      const eventLabel =
        events.getEvent(eventId) && events.getEvent(eventId).festival;

      const loading = areas.loading || spots.loading || powerzones.loading;

      return (
        <div className="PowerzonesOverviewPage padding">
          <div className="buttonRow left">
            <Button
              onClick={() => this.addPowerzone()}
              children={text.get('components.views.powerzones.newPowerzone')}
              size="small"
            />
          </div>

          {loading ? (
            <Spinner />
          ) : (
            <PowerzoneSummaryTable
              eventLabel={eventLabel}
              text={text}
              powerzones={powerzones}
              areas={areas}
              spots={spots}
            />
          )}

          <PowerzoneModalForm
            ref="pzModal"
            customClass="small"
            onSuccess={(form) => this.onSaveSuccess(form)}
            cancelHandler={() => this.closeModal()}
          />
        </div>
      );
    }
  }
);
