import React, { forwardRef } from 'react';
import { Spinner, ReportTable } from 'shared/components';
import { DeleteOutline } from '@material-ui/icons';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import Chip from '@material-ui/core/Chip';

import { Button } from 'shared/components/form';
import { pushRoute, confirm as confirmAction } from 'shared/actions';
import { getRoleIdsArrFromId } from 'users/utils';
import { Roles } from 'shared/constants';
import { userHasPermission } from 'auth/actions';

import { ROLE_NAMES } from '../../constants';
import { useGetText } from 'shared/hooks';
import { useGetUsers } from 'users/hooks';
import { useRemoveUser } from 'users/hooks';

export function UsersOverviewPage(props) {
  const text = useGetText();
  const { users, loading, error } = useGetUsers();

  const { removeUser } = useRemoveUser();
  const title = text.getText('components.views.adminUsers.pageTitle');
  const filteredUsers = users.map((user) => ({
    name: user.name,
    email: user.email,
    companyId: user.companyId,
    role: user.role,
    credits: user.credits,
    phones: user.phones,
    pendingUUID: user.pendingUUID,
    _id: user._id,
  }));

  function handleClickEditUser(event, rowData) {
    pushRoute(`/users/${rowData._id}/edit`);
  }

  function handleClickRemoveUser(event, rowData) {
    confirmAction(text.getText('actions.confirmRemoveUser'), () =>
      removeUser(rowData._id)
    );
  }

  function getRoleNameFromId(roleName) {
    return text.getText(`components.views.users.${roleName}`);
  }

  function renderRoleArray(rowData) {
    let arr = getRoleIdsArrFromId(rowData['role']);
    return (
      <div>
        {arr?.map((roleId) => (
          <Chip
            label={getRoleNameFromId(ROLE_NAMES[roleId])}
            variant="outlined"
            key={`role-${roleId}`}
          />
        ))}
      </div>
    );
  }

  const notAdmin = !userHasPermission(Roles.ADMIN);
  const removeTooltip = notAdmin
    ? text.getText('actions.cantRemove')
    : text.getText('actions.remove');

  return (
    <div className="UsersOverview">
      <div className="buttonWrapper">
        <div className="buttonRow left">
          <Button onClick={() => pushRoute(`/users/add`)}>
            {text.getText('actions.addNewUser')}
          </Button>
        </div>
      </div>
      {error && <span>{error.message}</span>}
      {loading ? (
        <Spinner />
      ) : (
        <ReportTable
          title={title}
          data={filteredUsers}
          onRowClick={handleClickEditUser}
          options={{
            pageSize: 20,
            exportFileName: title,
            padding: 'dense',
            actionsColumnIndex: 1000,
          }}
          text={text}
          textLocation={'forms.adminUsers'}
          customActions={[
            {
              icon: forwardRef((props, ref) => (
                <DeleteOutline {...props} ref={ref} />
              )),
              tooltip: removeTooltip,
              onClick: handleClickRemoveUser,
              disabled: notAdmin,
            },
          ]}
          customColumnSettings={{
            phones: {
              render: (rowData) => (
                <>
                  {' '}
                  {rowData.phones.map((data) => (
                    <div key={`phone-${data.number}`}>
                      {' '}
                      {data.type}: {data.number}
                    </div>
                  ))}
                </>
              ),
            },
            pendingUUID: {
              hiddenByColumnsButton: true,
              hidden: true,
              render: (rowData) =>
                rowData.pendingUUID ? (
                  <ClearOutlinedIcon color="secondary" />
                ) : (
                  <CheckOutlinedIcon color="secondary" />
                ),
            },
            updatedAt: {
              hiddenByColumnsButton: true,
              hidden: true,
            },
            credits: {
              hiddenByColumnsButton: true,
              hidden: true,
            },
            companyId: {
              hiddenByColumnsButton: true,
              hidden: true,
            },
            role: {
              render: (rowData) => renderRoleArray(rowData),
            },
          }}
        />
      )}
    </div>
  );
}
