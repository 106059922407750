import { useQuery } from 'react-query';

import { api } from 'shared/utils';
import { getCompanyMapper } from '../mapper';
import { companies as companiesStore } from '../store';

export const COMPANIES_QUERY_KEY = 'companies';

export const loadCompanies = async () => {
  const { data, status } = await api.get(`/companies`);
  return status === 200 ? getCompanyMapper(data) : [];
};

export const useGetCompanies = () => {
  const { data, status, isLoading, ...others } = useQuery(
    [COMPANIES_QUERY_KEY],
    loadCompanies,
    {
      // TODO remove whole onSuccess-part when mobx removed
      onSuccess: (data) => {
        if (data && data.length > 0) {
          companiesStore.registerCompanies(data);
        }
      },
    }
  );

  return {
    ...others,
    loading: isLoading,
    data: data || [],
  };
};
