import React from 'react';
import { inject, observer } from 'mobx-react';
import ReactTooltip from 'react-tooltip';
import { compose } from 'recompose';

import { Icon, Modal } from 'shared/components';
import { Button } from 'shared/components/form';
import { isSpotFilled } from 'spots/utils';

import SpotDetails from './spotDetails';
import HistoricalEventSpotModal from './HistoricalEventSpotModal';
import './styles.less';

const enhance = compose(inject('text', 'events'), observer);

/**
 * ListItem class represents the item presented in the events list containing the event information.
 * @class ListItem
 * @extends {React.Component}
 */
export default enhance(
  class ListItem extends React.Component {
    state = {
      expanded: false,
      isModalVisible: false,
    };

    renderEmpty() {
      const { text } = this.props;
      return (
        <div className="EventsOverviewListItem">
          <div className="textColumn">
            <span className="itemHeader">
              {text.get('components.views.overviewEvents.noItems')}
            </span>
          </div>
        </div>
      );
    }

    /**
     * Handles the toggling of connection
     * @return {void}
     */
    toggleExpansion = () => {
      this.props.changeActiveSpot(this.props.spot._id);
      this.setState({ expanded: !this.state.expanded });
    };

    /**
     * Presents the options powerInquiriesModal.
     * @return {void}
     */
    toogleModal = (event, visibility) => {
      !!event && event.preventDefault();
      this.historicalEventSpotModal.toggleModal(visibility);
    };

    /**
     * Renders the component view.
     * @return {React.Component}
     */
    render() {
      if (this.props.empty) return this.renderEmpty();
      const {
        text,
        spot,
        key,
        festivalName,
        activeSpotId,
        onClickSpot,
      } = this.props;
      const active = 'active';
      const iconTooltip =
        this.state.expanded &&
        activeSpotId === spot._id &&
        !this.historicalEventSpotModal.state.visible
          ? text.get('components.views.overviewEvents.hideSpotDetails')
          : text.get('components.views.overviewEvents.showSpotDetails');

      return (
        <div className="masterItem" key={key}>
          <ReactTooltip id="historic-spots-tooltip" effect="solid" />
          <div className="row" onClick={this.toggleExpansion}>
            <div className="title">
              <Icon
                name="power"
                className={`icon ${this.state.expanded && active}`}
              />
              <h4 className="label">{spot.label}</h4>
            </div>
            <div className="power">
              <span className="message">
                {isSpotFilled(spot) ? `${spot.totalPower.toFixed(1)} kW` : 0}
              </span>
            </div>
            <div data-for="historic-spots-tooltip" data-tip={iconTooltip}>
              <Icon
                className="toggle-icon"
                name={
                  this.state.expanded && activeSpotId === spot._id
                    ? 'arrow-up'
                    : 'arrow-down'
                }
              />
            </div>
          </div>
          <Modal
            lockHideOnBlur={true}
            ref={(ref) => {
              this.historicalEventSpotModal = ref;
            }}
          >
            <HistoricalEventSpotModal
              {...{ text, festival: festivalName, spot }}
              onClickSpot={(e) => {
                onClickSpot({ ...spot, unSaved: true });
                this.toogleModal(e, false);
              }}
              hideModal={(e) => this.toogleModal(e, false)}
            />
          </Modal>
          {this.state.expanded &&
            this.props.activeSpotId === this.props.spot._id && (
              <div className="event-details">
                <SpotDetails spot={spot} />
                <Button
                  size="small"
                  styling="cta"
                  className="button-size-small button-style-default select-btn"
                  onClick={(e) => this.toogleModal(e, true)}
                  children={text.get('forms.spot.select')}
                />
              </div>
            )}
        </div>
      );
    }
  }
);
