import { browserHistory } from 'react-router';
import { userHasPermission } from 'auth/actions';
import { General } from 'shared/constants';
import { auth as authStore } from 'auth/store';

/**
 * Takes a role, or a list of, as input and checks if the current signed user has it
 * and use this information to decide if he can access the requested route or not.
 * @param {number|number[]} roles - Role value or list of Roles
 * @return {*}
 */
export const allowedFor = (roles) => (nextState, replace) => {
  const { pathname } = nextState.location;
  const { profile } = authStore;
  if (
    !profile &&
    !pathname.includes('login') &&
    !pathname.includes('forget-password')
  ) {
    General.DEBUG &&
      console.log('User is not authenticated. Redirecting to /login ...');
    return replace({ pathname: '/login' });
  }
  if (profile && !roles) {
    General.DEBUG && console.log('No roles were set. Redirecting to / ...');
    return replace({ pathname: '/' });
  }
  if (profile && !userHasPermission(roles)) {
    General.DEBUG &&
      console.log(
        'User does not have permission to access the route. Redirecting to /400 ...'
      );
    return replace({ pathname: '/400' });
  }
};

/**
 * Takes a route path as input and navigates to it.
 * @param {string} route - Route path
 * @return {void}
 */
export const pushRoute = (route) => browserHistory.push(route);

/**
 * Takes a route path as input and navigates to it replacing the navigation stack.
 * @param {string} route - Route path
 * @return {void}
 */
export const replaceRoute = (route) => browserHistory.replace(route);

/**
 * Takes a route path as input and redirects to it.
 * @param {string} route - Route path
 * @return {void}
 */
export const redirectTo = (route) => (window.location = route);

/**
 * Pops out the current route and goes back to the last route.
 * @return {void}
 */
export const goBack = () => browserHistory.goBack();
