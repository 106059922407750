import React from 'react';

import { Spinner } from 'shared/components';
import { useGetText } from 'shared/hooks';

import { useGetEvent } from '../hooks';

export const EventOverviewPage = ({ params }) => {
  const { eventId } = params;
  const { data: event } = useGetEvent(eventId);
  const text = useGetText();

  return !event ? (
    <Spinner />
  ) : (
    <div className="EventOverviewPage padding">
      <h1>{`${event.festival} • ${text.getText(
        'components.views.event.overview'
      )}`}</h1>
      <div />
      EventOverview
    </div>
  );
};
