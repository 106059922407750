import React from 'react';
import { observer, inject } from 'mobx-react';
import IconButton from 'material-ui/IconButton';
import { Tooltip, Paper } from '@material-ui/core';
import { compose } from 'recompose';

import { Icon } from 'shared/components';
import { TextInput, Button } from 'shared/components/form';
import { changeFormState } from 'spots/actions';

import { calculateSingleConnectionPowerFromField } from 'spots/utils';
import ConnectionPowerCheckIcon from '../../connection/ConnectionPowerCheckIcon';
import { EMPTY_DEVICE } from 'spots/constants/devices';
import './styles.less';
import DevicesTable from 'spots/components/devices/devicesTable';
import SelectSizeInput from 'spots/components/connection/SelectSizeInput';
import { ALL_CONNECTIONS } from 'spots/components/connection/ConnectionSizeForm/constants';

const enhance = compose(inject('text'), observer);

/**
 * ConnectionRowDesktop class represents the Desktop view of the 'ConnectionRow' component used in
 * the SpotForm.
 * @class ConnectionRowDesktop
 * @extends {React.Component}
 */
export default enhance(
  class ConnectionRow extends React.Component {
    /**
     * Adds a new empty device to the connection.
     * @param {object} e
     * @return {void}
     */
    addDevice(e) {
      e.preventDefault();
      changeFormState(true);
      this.props.field.$('devices').add(EMPTY_DEVICE);
      this.forceUpdate(); // Forces the react component to update itself in the view after modifying the nested element state.
    }

    /**
     * Removes the given device from the connection.
     * @param {object} device - Device instance
     * @return {void}
     */
    removeDevice(device) {
      this.props.field.del(device.path);
      changeFormState(true);
      this.forceUpdate(); // Forces the react component to update itself in the view after modifying the nested element state.
    }

    /**
     * Renders the component view.
     * @return {React.Component}
     */
    render() {
      const { field, text, removeConnection } = this.props;
      const devicesField = field.$('devices').fields;
      const deviceValues = devicesField.values();
      const totalPower = calculateSingleConnectionPowerFromField(deviceValues);

      //get the connection rated Power
      const connectionCap = ALL_CONNECTIONS.find(
        ({ externalId }) =>
          externalId === field.$('connectionSizeType').$('externalId').value
      )?.ratedPowerKva;

      return (
        <Paper>
          <div className="ConnectionRow edit">
            <div className="connectionRowHeader">
              <div className="inputRow mainContentRow">
                <div className="inputColumn connectionQty">
                  <Tooltip title={text.get('forms.spot.tooltip.connectionQty')}>
                    <TextInput
                      min={1}
                      max={99}
                      field={field.$('quantity')}
                      type="number"
                    />
                  </Tooltip>
                </div>
                <TextInput field={field.$('name')} />
                <div className="inputColumn">
                  <SelectSizeInput
                    field={field.$('connectionSizeType')}
                    title={text.get('forms.spot.selectInputHeader')}
                  />
                </div>
                <div className="powerIconField">
                  <ConnectionPowerCheckIcon
                    connectionCap={connectionCap}
                    ratedPower={totalPower}
                  />
                </div>
                <div className="header">
                  <Tooltip title={text.get('actions.removeConnection')}>
                    <IconButton onClick={() => removeConnection(field)}>
                      <Icon name="close" className="rowIcon" />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </div>
            <div className="connectionRowBody">
              {devicesField.values().length > 0 && (
                <DevicesTable
                  devices={devicesField.values()}
                  removeDevice={(device) => this.removeDevice(device)}
                />
              )}
              <div className="addButtonRow">
                <Button
                  className="button"
                  size="small"
                  styling="default"
                  onClick={(e) => this.addDevice(e)}
                >
                  {`+ ${text.get('forms.spot.addDevice')}`}
                </Button>
              </div>
            </div>
          </div>
        </Paper>
      );
    }
  }
);
