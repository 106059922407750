import * as Services from 'shared/services';

/**
 * Loads all the templates from the API to the application's store so it can be
 * accessible in all the application.
 * @param {string} userId - User id (Optional)
 * @return {Promise}
 */
export const loadTemplates = (userId) => Services.loadTemplates(userId);

/**
 * Takes an Template instance as input and sends it to the API so it can be saved.
 * @param {object} template - Template instance
 * @return {Promise}
 */
export const createTemplate = (template) => Services.createTemplate(template);

/**
 * Takes an Template instance as input  and sends it to the API so it can be updated.
 * @param {void} template - Template instance
 * @return {Promise}
 */
export const updateTemplate = (template) => Services.updateTemplate(template);

/**
 * Takes an Template instance as input and removes it from the templates store.
 * @param {void} template - Template instance
 * @return {Promise}
 */
export const removeTemplate = (template) => Services.removeTemplate(template);
