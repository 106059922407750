export default (text, textLocation) => ({
  name: {
    label: text.get(`${textLocation}.name`),
    rules: 'string|required',
  },
  phones: {
    label: text.get(`${textLocation}.phones`),
    rules: 'string',
  },
  canSeeApplianceTemplates: {
    rules: 'bool',
  },
  address: {
    line1: {
      label: text.get(`${textLocation}.address`),
      rules: 'string',
    },
    line2: {
      label: text.get(`${textLocation}.line2`),
      rules: 'string',
    },
    city: {
      label: text.get(`${textLocation}.city`),
      rules: 'string',
    },
    state: {
      label: text.get(`${textLocation}.state`),
      rules: 'string',
    },
    country: {
      label: text.get(`${textLocation}.country`),
      rules: 'string',
    },
    zipCode: {
      label: text.get(`${textLocation}.zipCode`),
      rules: 'string',
    },
  },
});
