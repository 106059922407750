import React, { useEffect, useState } from 'react';

import { Box } from 'shared/components';
import { decimals } from 'shared/utils';
import { SelectInput } from 'shared/components/form';
import * as Areas from 'areas/constants';
import { calculateTotalTheoreticalPower } from 'spots/utils';
import { useGetText } from 'shared/hooks';
import { useUpdateArea } from 'areas/hooks';

import { DEMAND_PARAMETERS } from 'powerzones/constants';
import './styles.less';
import { useMemo } from 'react';
import EditableField from 'shared/components/functionalForm/editableField';
import { AVAILABLE_METER_IDS } from 'spots/constants/connections';
import { AutoCompleteInput } from 'shared/components/form';

/**
 * AreaOverviewBox represents the Area overview box component.
 */
function AreaOverviewBox({ spots, area }) {
  const [cableSize, setCableSize] = useState(area.cableSize);
  const [areaId, setAreaId] = useState(area._id);
  const [meterId, setMeterId] = useState(area.meterId);
  const text = useGetText();
  const { updateArea } = useUpdateArea();

  // {number} theoreticalPower - Calculated Area theoretical power
  const theoreticalPower = useMemo(() => {
    return Math.ceil(calculateTotalTheoreticalPower(spots));
  }, [spots]);

  // {number} peakCurrent - Calculated Area peak current
  const peakCurrent = useMemo(() => {
    const phasesNumber = Areas.PHASES_NUMBER[cableSize];
    return Math.ceil(
      theoreticalPower /
        DEMAND_PARAMETERS.AVG_RATED_POWER_FACTOR /
        DEMAND_PARAMETERS.RATED_VOLTAGE_1P /
        phasesNumber /
        1000
    );
  }, [cableSize, theoreticalPower]);

  // {number} maxLoadFactor - Calculated Area max load factor
  const maxLoadFactor = useMemo(() => {
    if (!cableSize) return 0;
    const size = Areas.SIZE_VALUE[cableSize];
    return decimals((size / peakCurrent) * 100, 2);
  }, [cableSize, peakCurrent]);

  useEffect(() => {
    if (area._id !== areaId) {
      setCableSize(area.cableSize);
      setAreaId(area._id);
      setMeterId(area.meterId);
    }
  }, [area, areaId]);

  /**
   * Updates the area in the database when the cable size value changes.
   * @param {string} cableSize - Cable size selected value
   * @return {void}
   */
  const onChangeCableSize = (cableSize) => {
    updateArea({ ...area, cableSize });
    setCableSize(cableSize);
  };

  const handleTitleChange = (name) => {
    updateArea({ ...area, tag: name });
  };

  const handleChangeMeterId = (value) => {
    setMeterId(value)
    updateArea({ ...area, meterId: value });
  };

  const typoStyle = {
    minHeight: '32px',
    paddingTop: '0.5em',
  };

  const titleStyle = {
    display: 'flex',
    alignItems: 'center',
    verticalAlign: 'middle',
  };

  const title = (
    <div className="title" style={titleStyle}>
      <EditableField
        value={area.tag}
        handleSubmit={handleTitleChange}
        style={typoStyle}
      />
      <span className="featured" style={typoStyle}>
        {theoreticalPower} kW
      </span>
    </div>
  );

  return (
    <div className="AreaOverviewBox">
      <Box title={title}>
        <div className="row">
          <SelectInput
            value={cableSize}
            options={Areas.CABLE_SIZES}
            label={text.getText('components.views.powerzones.cableSize')}
            onChange={(size) => onChangeCableSize(size)}
          />
        </div>
        <div className="row">
          <span className="title">
            {text.getText('components.views.powerzones.maxLoadFactor')}
          </span>
          <span className="featured">{maxLoadFactor}%</span>
        </div>
        <div className="row">
          <span className="title">
            {text.getText('components.views.powerzones.peakCurrent')}
          </span>
          <span className="featured">{peakCurrent} A</span>
        </div>
        <div className="row">
          <span className="title">
            {text.getText('components.views.powerzones.theoreticalPower')}
          </span>
          <span className="featured">{theoreticalPower} kW</span>
        </div>
        <div className="row">
          <span className="title">
            {text.getText('components.views.powerzones.energyMeter')}
          </span>
          <AutoCompleteInput
            placeholder={text.getText('forms.spot.meterId')}
            options={AVAILABLE_METER_IDS}
            getOptionLabel={(option) => option}
            onChange={handleChangeMeterId}
            name={'meterId'}
            value={meterId}
          />
        </div>
      </Box>
    </div>
  );
}

export default AreaOverviewBox;
