import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { matchSorter } from 'match-sorter';
import WidePopper from './WidePopper';

/**
 * SelectInput component
 * @param {object} props - React component properties
 * @return {React.Component}
 */
export const AutoCompleteInput = ({
  value,
  label,
  name,
  onChange,
  formSubmit,
  options,
  variant = 'standard',
  hidden,
  optionsLoading,
  addNewInputValueProp,
  placeholder,
  inputFieldProps,
  renderOption,
  onHandleInputChange,
  inputValue,
  onInputChange,
  widePopper,
  fuzzySearch,
  fuzzySearchKeys,
  getOptionLabel,
  fullWidth = true,
  ...props
}) => {
  const defaultFilterOptions = fuzzySearch
    ? (options, state) => {
        return matchSorter(options, value, {
          keys: fuzzySearchKeys,
        });
      }
    : createFilterOptions();

  function getFilterOptions() {
    if (optionsLoading) {
      return () => [];
    }
    return addNewInputValueProp
      ? (options, state) => {
          const results = defaultFilterOptions(options, state);
          if (
            results &&
            results.length < 6 &&
            !results.includes(state.inputValue)
          ) {
            const newObj = { _id: 0 };
            newObj[`${addNewInputValueProp}`] = state.inputValue;
            return [...results, newObj];
          }
          return results;
        }
      : defaultFilterOptions;
  }

  function renderInput(params) {
    const inputValue = params?.inputProps?.ref?.current?.value;
    if (onHandleInputChange && inputValue.length > 0) {
      onHandleInputChange(params?.inputProps?.ref?.current?.value);
    }
    return (
      <TextField
        {...params}
        inputProps={{
          ...params.inputProps,
          autoComplete: 'disabled', // disable autocomplete and autofill
        }}
        name={name}
        label={label}
        variant={variant}
        placeholder={placeholder}
        {...inputFieldProps}
      />
    );
  }

  return (
    !hidden && (
      <Autocomplete
        id={`autocomplete-${name}`}
        renderInput={renderInput}
        autoHighlight
        autoComplete
        value={value}
        onChange={(event, newValue, reason) => {
          onChange(newValue, event);
        }}
        inputValue={inputValue}
        onInputChange={onInputChange}
        loading={optionsLoading}
        options={options}
        fullWidth={fullWidth}
        filterOptions={getFilterOptions()}
        renderOption={renderOption}
        PopperComponent={WidePopper}
        getOptionLabel={getOptionLabel}
        {...props}
      />
    )
  );
};

AutoCompleteInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array,
  parentEntityName: PropTypes.string,
  onHandleInputChange: PropTypes.func,
  renderOption: PropTypes.func,
  fuzzySearchKeys: PropTypes.any,
  multiple: (props) => {
    // this component should only be used as a single value auto complete so the multiple prop is not allowed
    if (props.multiple !== undefined) {
      return new Error(
        'You are not allowed to use the multiple property on this component. This component is strictly used to select one value.'
      );
    }
  },
  fuzzySearch: (props) => {
    // This component cannot be used with fuzzySearch icw the getOptionLabel prop
    if (props.getOptionLabel !== undefined && props.fuzzySearch) {
      return new Error(
        'You are not allowed to use the fuzzySearch property on this component. We need to come up with a way to use fuzzySearch in combination with object options. Please check https://gitlab.com/watt-now/tool-frontend/-/issues/449 for more info.'
      );
    }
  },
};
