import { logoff } from 'auth/actions';
import { notify } from 'shared/actions';
import { General, Notifications } from 'shared/constants';
import * as Sentry from '@sentry/react';

/**
 * Shows a notification with the error message.
 * @param {object} response - HTTP response object
 * @return {object}
 */
const notifyError = (response) => {
  const {
    data: { error },
  } = response;
  General.DEBUG && console.error('Error:', error);
  !General.TEST && notify({ message: error, level: Notifications.Type.ERROR });
  return response;
};

/**
 * Informs the user is not allowed.
 * @param {object} response - HTTP response object
 * @return {object}
 */
const handleNotAuthenticated = (response) => {
  General.DEBUG && console.error('The user authentication failed.');
  !General.TEST &&
    notify({
      message: response.data.error,
      level: Notifications.Type.INFO,
    });
  logoff();
  return response;
};

/**
 * Informs the user is not allowed.
 * @param {object} response - HTTP response object
 * @return {object}
 */
const handleNotAllowed = (response) => {
  General.DEBUG &&
    console.error('The user has no permission to execute this operation.');
  !General.TEST &&
    notify({
      message: 'You have no permission to execute this operation.',
      level: Notifications.Type.ERROR,
    });
  return response;
};

/**
 * Renders the error it the condition is met.
 * @param {boolean} condition
 * @param {...string[]} messages - Sequence of message inputs
 * @return {void}
 */
const renderErrorMessage = (condition, ...messages) =>
  condition && console.error(...messages);

/**
 * Handles the bad request errors.
 * @param {object} response - HTTP response object
 * @return {object}
 */
const handleBadRequest = (response) => {
  const {
    data: { error, errors },
  } = response;
  renderErrorMessage(General.DEBUG && error, 'Error:', error);
  renderErrorMessage(General.DEBUG && errors, 'Errors:', errors);
  !General.TEST &&
    error &&
    notify({ message: error, level: Notifications.Type.ERROR });
  // eslint-disable-next-line
  for (let error in errors) {
    !General.TEST &&
      notify({
        message: errors[error],
        level: Notifications.Type.ERROR,
      });
  }
  return response;
};

/**
 * Takes an object with an errored HTTP response object and handle the error.
 * @param {object} param0 - Object with response property
 * @return {object}
 */
export const errorHandler = ({ response }) => {
  Sentry.captureException({
    //restructure the response object because deeply nested objects are not shown in sentry
    message: response?.data?.message,
    errors: response?.data?.errors,
    status: response?.status,
    config: response?.config,
    headers: response?.config?.headers,
  }); //send error to sentry service
  switch (response?.status) {
    case 400:
      return handleBadRequest(response);
    case 401:
      return handleNotAuthenticated(response);
    case 403:
      return handleNotAllowed(response);
    case 404:
      return response;
    default:
      if (response?.data?.error) {
        return notifyError(response);
      } else if (response?.status > 404) {
        return notifyError('An unknown error has occurred.');
      } else {
        const noInternet =
          'We may not getting a response from the backend. Are we connected tot he internet?';
        console.error(noInternet, `response: ${response}`);
        notify({ message: noInternet, level: Notifications.Type.ERROR });
      }
      return response;
  }
};
