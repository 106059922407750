import { action, extendObservable } from 'mobx';

/**
 * CompaniesStore is the Mobx global data storage for the companies data.
 * @class CompaniesStore
 */
function CompaniesStore() {
  extendObservable(this, {
    list: [],
    loading: false,
    ids: [],
    get filteredCompanies() {
      return this.list.filter(
        (company) => (this.ids || []).indexOf(company._id) === -1
      );
    },
    getCompany(companyId) {
      return this.list.find((obj) => obj._id === parseInt(companyId, 10));
    },
    getCompanyByEmail(email) {
      return this.list.find((obj) => obj.email === email);
    },
    isLoading: action((value) => {
      this.loading = !!value;
    }),
    updateCompany: action((company) => {
      const index = this.list.findIndex((obj) => obj.uri === company.id);
      if (index > -1) this.list[index] = company;
    }),
    registerNewCompany: action((company) => {
      if (this.getCompany(company._id)) {
        this.updateCompany(company);
      } else {
        this.list.push(company);
      }
    }),
    registerCompanies: action((companies) => {
      this.list = companies;
    }),
    removeCompany: action((companyId) => {
      const index = this.list.findIndex(
        (obj) => obj._id === parseInt(companyId, 10)
      );
      this.list.splice(index, 1);
    }),
    setIds: action((ids) => {
      this.ids = ids;
    }),
  });
}
export const companies = new CompaniesStore();
