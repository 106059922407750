import React from 'react';
import './styles.less';

/**
 * Box component
 * @param {object} props - React component properties
 * @return {React.Component}
 */
export const Box = ({ title, className, ...props }) => (
  <div className="Box">
    <div className="boxHeader">
      <span className="boxHeaderTitle">{title}</span>
    </div>
    <div className={`boxBody ${className || ''}`} {...props} />
  </div>
);
