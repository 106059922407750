import React from 'react';
import { inject, observer } from 'mobx-react';
import NotificationCenter from 'react-notification-system'; // Reference: https://github.com/igorprado/react-notification-system
import { compose } from 'recompose';

import { ConfirmDialog, Header } from 'shared/components';
import { setConfirmHandler, setNotificationHandler } from 'shared/actions';

import './styles.less';

const enhance = compose(inject('text'), observer);

/**
 * Layout component is the base component for the whole system. It sets up the notification system
 * and builds the main UI structure.
 * @return {React.Component}
 */
export const BaseLayout = enhance(({ text, children }) => (
  <div className="Layout">
    <NotificationCenter ref={setNotificationHandler} />
    <ConfirmDialog ref={setConfirmHandler} text={text} />
    <Header hamburgerMenuVisible />
    <div className="container"> {children} </div>
  </div>
));
