import React from 'react';

import IconButton from 'material-ui/IconButton';
import IconMenu from 'material-ui/IconMenu';
import MenuItem from 'material-ui/MenuItem';
import { LinearProgress, Box, Typography } from '@material-ui/core';
import { Icon } from 'shared/components';
import SportsHandballIcon from '@material-ui/icons/SportsHandball';
import { confirm as confirmAction, pushRoute } from 'shared/actions';
import { styled } from '@material-ui/core/styles';
import Math from 'math';
import { useGetText } from 'shared/hooks';

import { useRemoveEvent } from '../../../hooks';
import './styles.less';

const StyledLinearProgress = styled(LinearProgress)({
  'background-color': '#F0F3F7',
});

/**
 * ListItem component represents the item presented in the events list containing the event information.
 * @extends {React.Component}
 */
export default function ListItem({
  event,
  onClick,
  spotsStatus,
  canEdit,
  empty,
}) {
  const text = useGetText();
  const { removeEvent } = useRemoveEvent();
  /**
   * Handles the edit button click.
   * @return {void}
   */
  function onClickEdit() {
    pushRoute(`/events/${event._id}/edit`);
  }

  /**
   * Handles the remove button click.
   * @return {void}
   */
  function onClickRemove() {
    removeEvent(event._id);
  }

  /**
   * Renders the action menu when allowed.
   * @return {React.Component}
   */
  function renderExtendedMenu() {
    return (
      canEdit && (
        <IconMenu
          iconButtonElement={
            <IconButton>
              <Icon name="more" />
            </IconButton>
          }
          targetOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem
            primaryText={text.getText('actions.edit')}
            onClick={() => onClickEdit()}
          />
          <MenuItem
            primaryText={text.getText('actions.remove')}
            onClick={() =>
              confirmAction(text.getText('actions.confirmRemove'), () =>
                onClickRemove()
              )
            }
          />
        </IconMenu>
      )
    );
  }

  function renderEmpty() {
    return (
      <div className="EventsOverviewListItem clickable">
        <div className="textColumn">
          <span className="itemHeader">
            {text.getText('components.views.overviewEvents.noItems')}
          </span>
          <a href="mailto:powerplan@greener.nl">powerplan@greener.nl</a>
        </div>
      </div>
    );
  }

  if (empty) return renderEmpty();
  const { festival, organisation } = event;

  return (
    <div className="EventsOverviewListItem clickable">
      {event.eventType === 'sports_event' && (
        <SportsHandballIcon className="icon" onClick={onClick} />
      )}
      {event.eventType !== 'sports_event' && (
        <Icon
          name="music-note"
          className="icon"
          width={24}
          height={24}
          onClick={onClick}
        />
      )}
      {/* eslint-disable-next-line */}
      <div className="textColumn" onClick={onClick}>
        <span className="itemHeader">{festival}</span>
        <span className="subheader">{organisation}</span>
      </div>
      <div className="textColumn">
        <Box minWidth={50}>
          <Typography variant="body2" color="textSecondary">
            {`${text.getText('forms.spot.approved')}: ${spotsStatus.approved} 
                ${text.getText('forms.spot.filled')}: ${spotsStatus.filled}`}
            &nbsp;&nbsp;&nbsp;&nbsp; • &nbsp;&nbsp;&nbsp;&nbsp;
            {`${text.getText('forms.spot.spotsTotal')} ${spotsStatus.total}`}
          </Typography>
        </Box>
        <Box className="subheader" display="flex" alignItems="center">
          <Box width="100%" mr={1}>
            <StyledLinearProgress
              variant="buffer"
              value={(spotsStatus.approved / spotsStatus.total) * 100 || null}
              valueBuffer={(spotsStatus.filled / spotsStatus.total) * 100}
            />
          </Box>
          <Box minWidth={60}>
            <Typography variant="body2" color="textSecondary">
              {spotsStatus.total === 0
                ? '-'
                : `${
                    Math.round(
                      (spotsStatus.approved / spotsStatus.total) * 100
                    ) || 0
                  }
                      -
                      ${
                        Math.round(
                          (spotsStatus.filled / spotsStatus.total) * 100
                        ) || 0
                      }`}{' '}
              %
            </Typography>
          </Box>
        </Box>
      </div>
      {renderExtendedMenu()}
    </div>
  );
}
