/*******************************************
 * DEMAND RELATED PARAMETERS
 *******************************************/

export const DEMAND_PARAMETERS = {
  maximumEstimatedSimultaneityFactor: 0.58,
  AVG_RATED_POWER_FACTOR: 0.8,
  RATED_VOLTAGE_3P: 400,
  RATED_VOLTAGE_1P: 230,
};

/*******************************************
 * POWER SOURCES
 *******************************************/

export const POWER_SOURCE_TYPES = {
  gridConnections: 'gridConnections',
  batteries: 'batteries',
  dieselGenerators: 'dieselGenerators',
};

// {object[]} GENERATOR_SIZES - Available generator size types
export const DIESEL_GENERATORS = [
  {
    externalIdentifier: '30kVA-24kW',
    ratedPowerKva: 30,
    ratedPowerKw: 24,
    label: '30 kVA / 24 kW',
  },
  {
    externalIdentifier: '50kVA-40kW',
    ratedPowerKva: 50,
    ratedPowerKw: 40,
    label: '50 kVA / 40 kW',
  },
  {
    externalIdentifier: '70kVA-56kW',
    ratedPowerKva: 70,
    ratedPowerKw: 56,
    label: '70 kVA / 56 kW',
  },
  {
    externalIdentifier: '100kVA-80kW',
    ratedPowerKva: 100,
    ratedPowerKw: 80,
    label: '100 kVA / 80 kW',
  },
  {
    externalIdentifier: '125kVA-100kW',
    ratedPowerKva: 125,
    ratedPowerKw: 100,
    label: '125 kVA / 100 kW',
  },
  {
    externalIdentifier: '150kVA-120kW',
    ratedPowerKva: 150,
    ratedPowerKw: 120,
    label: '150 kVA / 120 kW',
  },
  {
    externalIdentifier: '175kVA-140kW',
    ratedPowerKva: 175,
    ratedPowerKw: 140,
    label: '175 kVA / 140 kW',
  },
  {
    externalIdentifier: '200kVA-160kW',
    ratedPowerKva: 200,
    ratedPowerKw: 160,
    label: '200 kVA / 160 kW',
  },
  {
    externalIdentifier: '225kVA-180kW',
    ratedPowerKva: 225,
    ratedPowerKw: 180,
    label: '225 kVA / 180 kW',
  },
  {
    externalIdentifier: '250kVA-200kW',
    ratedPowerKva: 250,
    ratedPowerKw: 200,
    label: '250 kVA / 200 kW',
  },
  {
    externalIdentifier: '275kVA-220kW',
    ratedPowerKva: 275,
    ratedPowerKw: 220,
    label: '280 kVA / 220 kW',
  },
  {
    externalIdentifier: '300kVA-240kW',
    ratedPowerKva: 300,
    ratedPowerKw: 240,
    label: '300 kVA / 240 kW',
  },
  {
    externalIdentifier: '325kVA-260kW',
    ratedPowerKva: 325,
    ratedPowerKw: 260,
    label: '330 kVA / 260 kW',
  },
  {
    externalIdentifier: '350kVA-280kW',
    ratedPowerKva: 350,
    ratedPowerKw: 280,
    label: '350 kVA / 280 kW',
  },
  {
    externalIdentifier: '400kVA-320kW',
    ratedPowerKva: 400,
    ratedPowerKw: 320,
    label: '400 kVA / 320 kW',
  },
  {
    externalIdentifier: '500kVA-400kW',
    ratedPowerKva: 500,
    ratedPowerKw: 400,
    label: '500 kVA / 400 kW',
  },
  {
    externalIdentifier: '600kVA-480kW',
    ratedPowerKva: 600,
    ratedPowerKw: 480,
    label: '600 kVA / 480 kW',
  },
  {
    externalIdentifier: '750kVA-600kW',
    ratedPowerKva: 750,
    ratedPowerKw: 600,
    label: '750 kVA / 600 kW',
  },
  {
    externalIdentifier: '1000kVA-800kW',
    ratedPowerKva: 1000,
    ratedPowerKw: 800,
    label: '1000 kVA / 800 kW',
  },
  {
    externalIdentifier: '1250kVA-1000kW',
    ratedPowerKva: 1250,
    ratedPowerKw: 1000,
    label: '1250 kVA / 1000 kW',
  },
  {
    externalIdentifier: '1500kVA-1200kW',
    ratedPowerKva: 1500,
    ratedPowerKw: 1200,
    label: '1500 kVA / 1200 kW',
  },
  {
    externalIdentifier: '2000kVA-1600kW',
    ratedPowerKva: 2000,
    ratedPowerKw: 1600,
    label: '2000 kVA / 1600 kW',
  },
];

// {object[]} GRID_SIZES - Available grid size types
export const GRID_CONNECTIONS = [
  { externalIdentifier: '16A', ratedCurrentA: 16, label: '16 A' },
  { externalIdentifier: '32A', ratedCurrentA: 32, label: '32 A' },
  { externalIdentifier: '63A', ratedCurrentA: 63, label: '63 A' },
  { externalIdentifier: '80A', ratedCurrentA: 80, label: '80 A' },
  { externalIdentifier: '125A', ratedCurrentA: 125, label: '125 A' },
  { externalIdentifier: '160A', ratedCurrentA: 160, label: '160 A' },
  { externalIdentifier: '250A', ratedCurrentA: 250, label: '250 A' },
  { externalIdentifier: '400A', ratedCurrentA: 400, label: '400 A' },
  { externalIdentifier: '600A', ratedCurrentA: 600, label: '600 A' },
  { externalIdentifier: '1000A', ratedCurrentA: 1000, label: '1000 A' },
  { externalIdentifier: '2000A', ratedCurrentA: 2000, label: '2000 A' },
  { externalIdentifier: '3000A', ratedCurrentA: 3000, label: '3000 A' },
];

// {object[]} BATTERY_SIZES - Available battery size types
export const BATTERIES = [
  {
    externalIdentifier: '15kVA-12kW-20kWh-Li',
    ratedPowerKw: 12,
    ratedPowerKva: 15,
    storageCapacityKwh: 20,
    label: '15 kVA / 12 kW / 20 kWh',
  },
  {
    externalIdentifier: '30kVA-24kW-30kWh-Li',
    ratedPowerKw: 24,
    ratedPowerKva: 30,
    storageCapacityKwh: 30,
    label: '30 kVA / 24 kW / 30 kWh',
  },
  {
    externalIdentifier: '50kVA-40kW-120kWh-Li',
    ratedPowerKw: 40,
    ratedPowerKva: 50,
    storageCapacityKwh: 120,
    label: '50 kVA / 40 kW / 120 kWh',
  },
  {
    externalIdentifier: '100kVA-80kW-120kWh-Li',
    ratedPowerKw: 80,
    ratedPowerKva: 100,
    storageCapacityKwh: 120,
    label: '100 kVA / 80 kW / 120 kWh',
  },
  {
    externalIdentifier: '277kVA-250kW-336kWh-Li',
    ratedPowerKw: 250,
    ratedPowerKva: 277,
    storageCapacityKwh: 336,
    label: '277 kVA / 250 kW / 336 kWh',
  },
  {
    externalIdentifier: '600kVA-480kW-600kWh-Li',
    ratedPowerKw: 480,
    ratedPowerKva: 600,
    storageCapacityKwh: 600,
    label: '600 kVA / 480 kW / 600 kWh',
  },
  {
    externalIdentifier: '600kVA-480kW-1200kWh-Li',
    ratedPowerKw: 480,
    ratedPowerKva: 600,
    storageCapacityKwh: 1200,
    label: '600 kVA / 480 kW / 1200 kWh',
  },
  {
    externalIdentifier: '100kVA-80kW-2000kWh-Li',
    ratedPowerKw: 80,
    ratedPowerKva: 100,
    storageCapacityKwh: 2000,
    label: '100 kVA / 80 kW / 2 MWh',
  },
];

// {object} SIZE_VALUE - Object with the generator sizes as the key and it's numeric value as key
export const SIZE_VALUE = {
  '35 kVA': 35,
  '50 kVA': 50,
  '60 kVA': 60,
  '70 kVA': 70,
  '100 kVA': 100,
  '125 kVA': 125,
  '150 kVA': 150,
  '175 kVA': 175,
  '200 kVA': 200,
  '225 kVA': 225,
  '250 kVA': 250,
  '300 kVA': 300,
  '335 kVA': 335,
  '350 kVA': 350,
  '400 kVA': 400,
};

function getPowerSourceObj(powerSources, type) {
  return {
    [type]: powerSources.map((powerSource) => ({ ...powerSource, type })),
  };
}

export const POWER_SOURCES_OBJ = {
  ...getPowerSourceObj(DIESEL_GENERATORS, 'dieselGenerators'),
  ...getPowerSourceObj(BATTERIES, 'batteries'),
  ...getPowerSourceObj(GRID_CONNECTIONS, 'gridConnections'),
};

function getPowerSourceArr(powerSources, type) {
  return powerSources.map((powerSource) => ({ ...powerSource, type }));
}

export const POWER_SOURCES_ARR = [
  ...getPowerSourceArr(DIESEL_GENERATORS, 'dieselGenerators'),
  ...getPowerSourceArr(BATTERIES, 'batteries'),
  ...getPowerSourceArr(GRID_CONNECTIONS, 'gridConnections'),
];

/*******************************************
 * FORMS
 *******************************************/

export const EMPTY_POWERZONE = {
  comment: '',
  endDate: null,
  endShow: null,
  eventId: null,
  label: '',
  createdAt: null,
  updatedAt: null,
  powerSources: {},
  powerOn: null,
  powerOff: null,
  uri: '',
  _id: null,
};
