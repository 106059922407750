// References:
// https://foxhound87.github.io/mobx-react-form/
// https://github.com/skaterdav85/validatorjs

import MobxReactForm from 'mobx-react-form';
import dvr from 'validatorjs';
import bindings from './bindings';

/**
 * MobxReactForm instance pre-configured to use 'validatorjs' to validate
 * the fields.
 *
 * @class DefaultForm
 * @extends {MobxReactForm}
 */
class DefaultForm extends MobxReactForm {
  plugins() {
    return { dvr };
  }

  onSuccess(form) {
    this.successHandler(form);
  }

  onError(form) {
    this.errorHandler(form);
  }

  constructor(onSuccess = () => {}, onError = () => {}) {
    super();
    this.successHandler = onSuccess;
    this.errorHandler = onError;
  }
}

/**
 * Takes an object of properties as input to create a new form instance.
 * @param {object} properties - Form configuration
 * @return {MobxReactForm}
 */
export const formCreator = ({ title, onSuccess, onError, ...props }) => {
  DefaultForm.prototype.setup = () => ({ ...props });
  DefaultForm.prototype.bindings = () => bindings;
  return new DefaultForm(onSuccess, onError);
};
