import { lifecycle, compose } from 'recompose';
import { inject } from 'mobx-react';
import { loadPowerInquiries } from 'powerinquiries/actions';
/*
    HOC to load the company name from a spot. Requires energy users to be already loaded!!
*/
export const withCompanyName = compose(
  inject('powerInquiries'),
  lifecycle({
    componentDidMount() {
      let { spot, item } = this.props;
      spot = spot || item;
      if (!spot) return;
      this.setState({ companyName: 'Loading' });
      if (!spot.eventId) return;
      loadPowerInquiries(spot.eventId).then(() => {
        const user = this.props.powerInquiries.getPowerInquiry(spot.userId);
        if (!user) {
          console.warn(`Can't find user with id ${spot.userId} using --- `);
          this.setState({ companyName: ' --- ' });
          return;
        }
        this.setState({ companyName: user.companyName });
      });
    },
  })
);
