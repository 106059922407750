import { useCallback } from 'react';
import { useQueryClient, useMutation } from 'react-query';

import { api } from 'shared/utils';

import { putCompanyMapper } from '../mapper';

import { COMPANIES_QUERY_KEY } from './useGetCompanies';

const doCreateCompany = async (company) => {
  const { data } = await api.post('/companies', putCompanyMapper(company));
  return data;
};

export const useCreateCompany = () => {
  const queryClient = useQueryClient();
  const { mutate, status, ...others } = useMutation(doCreateCompany, {
    onSuccess: (newCompany) => {
      if (newCompany) {
        //Add new company response to cache
        queryClient.setQueryData(
          [COMPANIES_QUERY_KEY, 'id', newCompany._id],
          newCompany
        );

        //Update companies cash and invalidate
        queryClient.setQueryData([COMPANIES_QUERY_KEY], (oldCompanies) => {
          if (oldCompanies) {
            return [...oldCompanies, newCompany];
          } else {
            return [newCompany];
          }
        });
        queryClient.invalidateQueries(COMPANIES_QUERY_KEY);
      }
    },
  });

  const createCompany = useCallback((company) => mutate(company), [mutate]);

  return {
    ...others,
    loading: status === 'loading',
    createCompany,
  };
};
