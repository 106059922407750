import moment from 'moment';
import { format } from 'date-fns';
import { PropTypes } from 'prop-types';
import {
  NO_DATE,
  DATE_FORMAT,
  DATE_TIME_FORMAT,
} from 'shared/constants/general';

/**
 * Validates a date value.
 * @param {any} date - Content to validate
 * @return {boolean}
 */
export function isValidDate(date) {
  if (!date || date === NO_DATE) return false;
  try {
    const tmp = moment(date);
    return tmp.isValid();
  } catch (e) {
    return false;
  }
}

export function calcEventStartEndDates(event, version) {
  const categories = ['buildUpDates', 'showDates', 'breakDownDates'];
  let startDates = [];
  let endDates = [];

  if (!event) return { start: null, end: null };

  categories.forEach((cat) => {
    event[cat].forEach((dateObj) => {
      startDates.push(moment(dateObj.startDate));
      endDates.push(moment(dateObj.endDate));
    });
  });

  const startDate = moment.min(startDates).startOf('day');
  const endDate = moment.max(endDates);

  let result;

  switch (version) {
    case 'start':
      result = startDate;
      break;
    case 'end':
      result = endDate;
      break;
    default:
      result = { start: startDate, end: endDate };
  }
  return result;
}

calcEventStartEndDates.propTypes = {
  event: PropTypes.object.isRequired,
  version: PropTypes.oneOf(['start', 'end']).isRequired,
};

/**
 * Gets the earliest startDate and latest endDate from an array of items
 * When items do not contain dates, startSDate end endDate returns 'null'
 * @param {Array} items - Array of items with a startDate and endDate property
 */
export const getDateExtremes = (itemArray) => {
  let startDateList = [],
    endDateList = [];
  itemArray.forEach((item) => {
    item.layout.connections.forEach((connection) => {
      startDateList.push(connection.startDate);
      endDateList.push(connection.endDate);
    });
  });

  startDateList = startDateList.sort(
    (a, b) => moment(a).format('YYYYMMDD') - moment(b).format('YYYYMMDD')
  );
  endDateList = endDateList.sort(
    (a, b) => moment(a).format('YYYYMMDD') - moment(b).format('YYYYMMDD')
  );

  const isStartDate = startDateList.length > 0;

  return {
    startDate: isStartDate ? moment(startDateList[0]).utc() : null,
    endDate: isStartDate
      ? moment(endDateList[endDateList.length - 1]).utc()
      : null,
  };
};

export const formatDate = (date, noDateValue = '-') => {
  if (!date || date === '') {
    return noDateValue;
  }
  return format(new Date(date), DATE_FORMAT);
};

export const formatDateTime = (date, noDateValue = '-') => {
  if (!date || date === '') {
    return noDateValue;
  }
  return format(new Date(date), DATE_TIME_FORMAT);
};

export function prepareDateContent(startDate, endDate) {
  if (startDate && endDate) {
    const start = moment(startDate).format('DD/MM/YYYY');
    const end = moment(endDate).format('DD/MM/YYYY');
    return start === end ? start : `${start} → ${end}`;
  }
  return '';
}
