import React from 'react';
import * as Icons from './components';
import LocationSearchingIcon from '@material-ui/icons/LocationSearching';

/**
 * Icon component
 * @param {object} props - React component properties
 * @return {React.Component}
 */
export const Icon = ({ name, ...props }) => {
  switch (name) {
    case 'add-circle':
      return <Icons.AddCircle {...props} />;
    case 'arrow-back':
      return <Icons.ArrowBack {...props} />;
    case 'arrow-down':
      return <Icons.ArrowDown {...props} />;
    case 'arrow-dropdown':
      return <Icons.ArrowDropdown {...props} />;
    case 'arrow-up':
      return <Icons.ArrowUp {...props} />;
    case 'calendar':
      return <Icons.Calendar {...props} />;
    case 'check':
      return <Icons.Check {...props} />;
    case 'close':
      return <Icons.Close {...props} />;
    case 'delete':
      return <Icons.Delete {...props} />;
    case 'close-circle':
      return <Icons.CloseCircle {...props} />;
    case 'drag':
      return <Icons.Drag {...props} />;
    case 'edit':
      return <Icons.Edit {...props} />;
    case 'edit-location':
      return <Icons.EditLocation {...props} />;
    case 'event-light':
      return <Icons.EventLight {...props} />;
    case 'error-outline':
      return <Icons.ErrorOutline {...props} />;
    case 'file-download':
      return <Icons.FileDownload {...props} />;
    case 'flashlight':
      return <Icons.Flashlight {...props} />;
    case 'history':
      return <Icons.History {...props} />;
    case 'home':
      return <Icons.Home {...props} />;
    case 'info':
      return <Icons.Info {...props} />;
    case 'mail-outline':
      return <Icons.MailOutline {...props} />;
    case 'menu':
      return <Icons.Menu {...props} />;
    case 'menu-node':
      return <Icons.Menu {...props} />;
    case 'more':
      return <Icons.More {...props} />;
    case 'music-note':
      return <Icons.MusicNote {...props} />;
    case 'node-diagram':
      return <Icons.NodeDiagram {...props} />;
    case 'people':
      return <Icons.People {...props} />;
    case 'person':
      return <Icons.Person {...props} />;
    case 'person-line':
      return <Icons.PersonLine {...props} />;
    case 'power':
      return <Icons.Power {...props} />;
    case 'public':
      return <Icons.Public {...props} />;
    case 'playlist':
      return <Icons.Playlist {...props} />;
    case 'remove':
      return <Icons.Remove {...props} />;
    case 'settings':
      return <Icons.Settings {...props} />;
    case 'search':
      return <Icons.Search {...props} />;
    case 'visibility':
      return <Icons.Visibility {...props} />;
    case 'report':
      return <Icons.Report {...props} />;
    case 'spot':
      return <Icons.Power {...props} />;
    case 'powerzone':
      return <Icons.PowerZone {...props} />;
    case 'area':
      return <Icons.Area {...props} />;
    case 'pointer':
      return <LocationSearchingIcon {...props} />;

    default:
      return null;
  }
};
