import React, { useEffect } from 'react';

import { goBack } from 'shared/actions';
import { Box, Spinner } from 'shared/components';
import { useGetText } from 'shared/hooks';

import { UserForm } from 'users/components';
import { useGetUser, useUpdateUser } from 'users/hooks';
import userMapper from 'users/mapper';

import './styles.less';

export const UsersEditPage = ({ params: { userId } }) => {
  const text = useGetText();
  const { user, loading } = useGetUser(userId);
  const { updateUser, data } = useUpdateUser();

  const handleFormSuccess = (form) => {
    const user = form.values();
    user.credits = parseInt(user.credits, 10);
    user.phones = user.phone ? [{ number: user.phone, type: 'work' }] : [];
    delete user.phone;
    updateUser(userMapper(user));
  };

  useEffect(() => {
    if (data) {
      goBack();
    }
  }, [data]);

  return (
    <div className="UserEditPage">
      <Box title={text.getText('components.views.adminUsers.listHeaderEdit')}>
        {loading ? (
          <Spinner />
        ) : (
          <UserForm onSuccess={handleFormSuccess} values={user} />
        )}
      </Box>
    </div>
  );
};
