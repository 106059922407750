import React from 'react';

import { userHasPermission } from 'auth/actions';
import { pushRoute } from 'shared/actions/';
import { Roles } from 'shared/constants';
import { Icon } from 'shared/components';
import { useGetText } from 'shared/hooks';

import './styles.less';

/**
 * Menu component represents the default event view sidemenu.
 * @returns {React.Component}
 */
export default function Menu({ eventId, pathname }) {
  const text = useGetText();

  /**
   * Renders the item component for the given properties
   * @param {string} name - Icon name
   * @param {string} label - Item label
   * @param {function} action - Click handler
   * @param {boolean} active - Item is active or not
   * @return {React.Component}
   */
  function renderItem(name, label, action = () => {}, active = false) {
    return (
      <a title={label}>
        <Icon
          name={name}
          onClick={action}
          className={`menuItem ${active && 'active'}`}
        />
      </a>
    );
  }

  return (
    <div className="Sidemenu">
      <div className="menuItem">
        {userHasPermission(Roles.ALL) &&
          renderItem('home', text.getText('actions.back'), () =>
            pushRoute('/events')
          )}
      </div>
      <div className="menuItem">
        {userHasPermission([Roles.ADMIN, Roles.EVENT_CREATOR]) &&
          renderItem(
            'person-line',
            text.getText('components.views.event.powerInquiries'),
            () => pushRoute(`/event/${eventId}/inquiries`),
            pathname.includes(`/event/${eventId}/inquiries`)
          )}
        {userHasPermission([Roles.ADMIN, Roles.EVENT_CREATOR]) &&
          renderItem(
            'flashlight',
            text.getText('components.views.powerzones.summary'),
            () => pushRoute(`/event/${eventId}/powerzonesummary`),
            pathname.includes(`/event/${eventId}/powerzonesummary`)
          )}
        {userHasPermission([Roles.ADMIN, Roles.EVENT_CREATOR]) &&
          renderItem(
            'node-diagram',
            text.getText('components.views.powerzones.tree'),
            () => pushRoute(`/event/${eventId}/powerzonetree`),
            pathname.includes(`/event/${eventId}/powerzonetree`)
          )}
        {userHasPermission([Roles.ADMIN, Roles.EVENT_CREATOR]) &&
          renderItem(
            'report',
            text.getText('components.views.powerzones.overviewTables'),
            () => pushRoute(`/event/${eventId}/powerzoneoverview`),
            pathname.includes(`/event/${eventId}/powerzoneoverview`)
          )}
        {userHasPermission([Roles.ADMIN, Roles.EVENT_CREATOR]) &&
          renderItem(
            'pointer',
            text.getText('components.views.spots.overview'),
            () => pushRoute(`/event/${eventId}/spots`),
            pathname.includes(`/event/${eventId}/spots`)
          )}
      </div>
    </div>
  );
}
