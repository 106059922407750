import React from 'react';
import { observer, inject } from 'mobx-react';
import { compose } from 'recompose';

import { Button } from 'shared/components/form';
import * as Constants from 'shared/constants';
import {
  toggleHamburgerMenu,
  setToggleHamburgerMenuHandler,
  replaceRoute,
} from 'shared/actions';
import { Roles } from 'shared/constants';
import { userHasPermission } from 'auth/actions';

import { Icon } from '../icon';
import ProfileMenu from './profileMenu';
import ManageMenu from './ManageMenu';
import Logo from './logo';
import './styles.less';

const enhance = compose(inject('auth', 'view'), observer);

/**
 * Header Component class represents the header bar in the top of the application's view.
 * @class Header
 * @extends {React.Component}
 */
export const Header = enhance(
  class extends React.Component {
    state = {
      showMenu: false,
    };
    componentDidMount() {
      setToggleHamburgerMenuHandler(() => {
        this.setState({ showMenu: true });
      });
    }

    componentWillUnmount() {
      setToggleHamburgerMenuHandler(null);
    }
    /**
     * Returns hamburger menu button when not viewing from desktop.
     * @return {React.Component}
     */
    renderHamburgerMenu(visibilityCondition) {
      return (
        visibilityCondition && (
          <Button
            styling="alt2"
            className="hamburgerMenu"
            onClick={toggleHamburgerMenu}
          >
            <Icon name="menu" />
          </Button>
        )
      );
    }

    /**
     * Renders the component view.
     * @return {React.Component}
     */
    render() {
      const { hamburgerMenuVisible, auth, view, ...props } = this.props;
      return (
        <header {...props} className="Header">
          {this.renderHamburgerMenu(!view.isDesktop)}
          <div
            className={`logoWrapper ${
              hamburgerMenuVisible ? 'hamburgerVisible' : ''
            }`}
          >
            <Logo className="logo" />
          </div>
          <div className="headerMenu">
            <a onClick={() => `mailto:${Constants.General.CONTACT_EMAIL}`}>
              <Icon name="mail-outline" className="icon" />
            </a>
            {auth.profile && (
              <a onClick={() => replaceRoute('/events')}>
                <Icon name="home" className="icon" />
              </a>
            )}
            {userHasPermission([
              Roles.ADMIN,
              Roles.EVENT_CREATOR,
              Roles.INQUIRY_MANAGER,
            ]) && <ManageMenu />}
            <ProfileMenu />
          </div>
        </header>
      );
    }
  }
);
