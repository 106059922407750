import { action, extendObservable } from 'mobx';

/**
 * AreasStore is the Mobx global data storage for the areas data.
 * @class areasStore
 */
function AreasStore() {
  extendObservable(this, {
    list: [],
    loading: false,
    get notAppointedList() {
      return this.list.filter((area) => !area.associatedResource);
    },
    getArea(uri) {
      return this.list.find((obj) => obj.uri === uri);
    },
    registerAreas: action((areas) => {
      this.list = areas;
    }),
    registerNewArea: action((area) => {
      this.list.push(area);
    }),
    isLoading: action((loading) => {
      this.loading = Boolean(loading);
    }),
    removeArea: action((area) => {
      const index = this.list.findIndex((obj) => obj.uri === area.uri);
      if (index > -1) this.list.splice(index, 1);
    }),
    updateArea: action((area) => {
      const index = this.list.findIndex((obj) => obj.uri === area.uri);
      if (index > -1) this.list[index] = area;
    }),
    toggleVisibility: action((area, visible) => {
      const index = this.list.findIndex((obj) => obj.uri === area.uri);
      if (index > -1) this.list[index].expanded = visible;
    }),
  });
}

export const areas = new AreasStore();
