import { notify } from 'shared/actions';
import { Notifications } from 'shared/constants';

import * as Services from './service';
import { powerzones as powerzonesStore } from './store';
import { cloneArea } from 'areas/actions';
import { cloneSpot } from 'spots/actions';

/**
 * Loads all the powerzones from the API to the application's store so it can be
 * accessible in all the application.
 * @return {Promise}
 */
export const loadPowerzones = (eventId) => Services.loadPowerzones(eventId);

/**
 * Takes a powerzone instance as input and sends it to the API so it can be saved.
 * @param {object} powerzone - Powerzone instance
 * @param {string} eventId - Event id
 * @return {Promise}
 */
export const createPowerzone = (powerzone, eventId) =>
  Services.createPowerzone(powerzone, eventId);

/**
 * Takes a powerzone instance as input  and sends it to the API so it can be updated.
 * @param {void} powerzone - Powerzone instance
 * @return {Promise}
 */
export const updatePowerzone = (powerzone) =>
  Services.updatePowerzone(powerzone);

/**
 * Takes a powerzone instance as input and removes it from the powerzones store.
 * @param {void} powerzone - Powerzone instance
 * @return {Promise}
 */
export const removePowerzone = (powerzone) =>
  Services.removePowerzone(powerzone);

/**
 * Toggles the powerzone node children visibility.
 * @param {object} powerzone - Powerzone instance
 * @param {boolean} visible - on/off state
 * @return {void}
 */
export const togglePowerzone = (powerzone, visible) =>
  powerzonesStore.toggleVisibility(powerzone, visible);

/**
 * @param {object} node -> Node to Clone
 * @param {parent} string -> Parent URI
 */
export const duplicateNode = async (node, parentNode, user, silent) => {
  const type = node.uri.includes('spot')
    ? 'spot'
    : node.uri.includes('area')
    ? 'area'
    : 'powerzone';

  if (!silent)
    notify({
      message: `Duplicated ${type} ${node.label || node.tag} into ${
        parentNode.label
      }`,
      level: Notifications.Type.SUCCESS,
    });
  if (node.uri.includes('spots')) await cloneSpot(node, parentNode.uri, user);
  if (node.uri.includes('areas')) {
    await cloneArea(node, parentNode.uri, user);
  }
};
