import * as Services from './service';

/**
 * Load all events
 * @return {Promise}
 */
export const loadEvents = (userId) => Services.loadEvents(userId);

/**
 * Takes the new event's data as input and send it to the API so it can be saved, and stores it in the
 * local state storage.
 * @param {object} event - Event data
 * @return {Promise}
 */
export const saveEvent = (event) => Services.createEvent(event);

/**
 * Takes the updated event's data as input and send it to the API so it can be updated, and stores it in the
 * local state storage.
 * @param {object} event - Event data
 * @return {Promise}
 */
export const modifyEvent = (event) => Services.updateEvent(event);

/**
 * Takes the event's id as input and send it to the API so it can be removed.
 * @param {string} eventId - Event id
 * @return {Promise}
 */
export const removeEvent = (eventId) => Services.removeEvent(eventId);
