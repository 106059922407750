import React from 'react';
import './styles.less';
import PropTypes from 'prop-types';

/**
 * Modal Component class represents the popover modal view.
 * @class Modal
 * @extends {React.Component}
 */
export class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.handleMouse = this.handleMouse.bind(this);
    this.escFunction = this.escFunction.bind(this);
    // {object} state - Component state holds information about the modal visibility state.
    this.state = { visible: false };
  }

  UNSAFE_componentWillMount() {
    const { visible } = this.props;
    if (visible) {
      this.setState({ visible });
    }
    document.addEventListener('mousedown', this.handleMouse, false);
    document.addEventListener('keydown', this.escFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleMouse, false);
    document.removeEventListener('keydown', this.escFunction, false);
  }

  handleMouse(e) {
    const { visible } = this.state;
    const { lockHideOnBlur } = this.props;

    if (!this.container || !visible) return;

    if (this.container.contains(e.target)) return;

    if (!lockHideOnBlur) {
      this.toggleModal(false);
    }
  }

  /**
   * Toggle modal visibility.
   * @param {boolean} visible - Toggle modal on/off
   * @return {void}
   */
  toggleModal(visible) {
    this.setState({ visible });
  }

  escFunction(event) {
    const { visible } = this.state;
    if (event.keyCode === 27) {
      if (!this.container || !visible) return;

      this.toggleModal(false);
    }
  }

  /**
   * Renders the component view.
   * @return {React.Component}
   */
  render() {
    const { className, large } = this.props;
    const { visible } = this.state;
    const { children } = this.props;
    return (
      <>
        {visible && (
          <div
            className={`Modal ${className || ''} ${large && 'large'} ${
              visible && 'visible'
            }`}
          >
            <div
              className="modal-wrapper"
              ref={(e) => {
                this.container = e;
              }}
            >
              {children}
            </div>
          </div>
        )}
      </>
    );
  }
}

Modal.defaultProps = {
  lockHideOnBlur: false,
  children: null,
};

Modal.propTypes = {
  lockHideOnBlur: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
