import { pushRoute } from 'shared/actions';
import { api } from 'shared/utils';
import { Roles } from 'shared/constants';
import { loadUser, userHasPermission } from 'auth/actions';

import { getEventMapper, putEventMapper } from './mapper';
import { events as eventsStore } from './store';

/**
 * Downloads all events which the current user has access to from the API.
 * @return {Promise}
 */
export const loadEvents = (userId) =>
  Promise.resolve(eventsStore.isLoading(true))
    .then(() => {
      if (userId) {
        //get the events of the specified user (can be another user than the user that is authenticated)
        return api.get(`/events?userId=${userId}`);
      } else {
        if (!userHasPermission(Roles.ADMIN)) {
          // get the profile of the authenticated user
          const user = loadUser();
          // if the user is not an admin, get the id of the authenticated user so we can query only the events related to the user
          return api.get(`/events?userId=${user._id}`);
        }
        return api.get('/events');
      }
    })
    .then(({ status, data }) => {
      if (status !== 200) {
        return [];
      }
      const tmp = data || [];
      // eslint-disable-next-line camelcase
      const events = tmp.map(({ relationship, event, spots_status }) => ({
        relationship,
        event: getEventMapper(event),
        status: spots_status,
      }));
      eventsStore.registerEvents(events);
      return events;
    })
    .then((events) => {
      eventsStore.isLoading(false);
      return events;
    });

/**
 * Takes an event data object as input and sends it to the API to store in the database.
 * @param {object} event - Event data
 * @return {Promise}
 */
export const createEvent = (event) =>
  Promise.resolve(eventsStore.isLoading(true))
    .then(() => api.post('/events', putEventMapper(event)))
    .then(({ status }) => {
      if (status === 201) pushRoute('/events');
    })
    .then(() => eventsStore.isLoading(false));

/**
 * Takes an event data object as input and sends it to the API to update in the database.
 * @param {object} event - Event data
 * @return {Promise}
 */
export const updateEvent = (event) =>
  Promise.resolve(eventsStore.isLoading(true))
    .then(() => api.put(`/events/${event._id}`, putEventMapper(event)))
    .then(({ status }) => {
      if (status === 200) pushRoute('/events');
    })
    .then(() => eventsStore.isLoading(false));

/**
 * Takes an event id as input and sends it to the API to remove it from the database.
 * @param {string} eventId - Event id
 * @return {Promise}
 */
export const removeEvent = (eventId) =>
  Promise.resolve(eventsStore.isLoading(true))
    .then(() => api.delete(`events/${eventId}`))
    .then(({ status }) => {
      if (status === 200) eventsStore.removeEvent(eventId);
    })
    .then(() => eventsStore.isLoading(false));
