import React from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { compose } from 'recompose';
import Badge from '@material-ui/core/Badge';

import { Spinner } from 'shared/components';
import { Button, SearchInput } from 'shared/components/form';

import { loadPowerInquiries } from 'powerinquiries/actions';
import { pushRoute } from 'shared/actions';
import { General, Roles } from 'shared/constants';
import { Modal } from 'shared/components';
import { loadUser } from 'auth/actions';
import { loadUsers } from 'users/actions';

import UploadAccounts from './uploadAccounts';
import DetailsTable from './detailsTable';
import './styles.less';

const enhance = compose(inject('text', 'powerInquiries'), observer);

/**
 * PowerInquiriesOverviewPage class represents the view which presents the list of all
 * energy users associated to the event.
 * @class PowerInquiriesOverviewPage
 * @extends {React.Component}
 */
export const PowerInquiriesOverviewPage = enhance(
  class extends React.Component {
    state = {
      searchInput: '',
      sortAsc: true,
      me: loadUser(),
      isInquiryManager: loadUser().role === Roles.INQUIRY_MANAGER,
    };
    get items() {
      const { searchInput, sortAsc, me } = this.state;
      const { powerInquiries } = this.props;
      const lowerInput = searchInput.toLowerCase();
      let list = powerInquiries.list.sort((a, b) => {
        if (a.lastUpdate === General.NO_DATE) return 1;
        else if (b.lastUpdate === General.NO_DATE) return -1;
        else return moment(b.lastUpdate).diff(moment(a.lastUpdate));
      });

      //show only accounts of which I am owner for inquiry managers
      if (me.role === Roles.INQUIRY_MANAGER) {
        list = list.filter((item) => item.owner === me._id);
      }

      if (!sortAsc) list = list.reverse();

      return !searchInput
        ? list
        : list.filter(
            (item) =>
              item.companyName.toLowerCase().includes(lowerInput) ||
              item.name.toLowerCase().includes(lowerInput) ||
              item.spots
                .map((tmp) => tmp.label.toLowerCase())
                .reduce(
                  (prev, next) => prev || next.includes(lowerInput),
                  false
                )
          );
    }

    /**
     * React lifecycle method - executed everytime the component is about to be mounted in the view.
     * @return {void}
     */
    UNSAFE_componentWillMount() {
      loadPowerInquiries(this.props.params.eventId);
      loadUsers();
    }

    onChangeSearchField = ({ target }) =>
      this.setState({ searchInput: target.value });

    /**
     * Renders the energy users table.
     * @return {React.Component}
     */
    renderTable() {
      const {
        params: { eventId },
        powerInquiries,
      } = this.props;
      const { sortAsc, isInquiryManager } = this.state;
      if (powerInquiries.loading) return <Spinner />;
      //if (this.items.length === 0) return <div>No accounts found</div>;
      return (
        <DetailsTable
          eventId={eventId}
          items={this.items}
          sortAsc={sortAsc}
          onClickSort={() => this.setState({ sortAsc: !sortAsc })}
          isInquiryManager={isInquiryManager}
        />
      );
    }

    /**
     * Presents the options powerInquiriesModal.
     * @return {void}
     */
    showModal = () => {
      this.setState({ isModalVisible: true }, () =>
        this.powerInquiriesModal.toggleModal(true)
      );
    };

    /**
     * Updates the field and hides the powerInquiriesModal.
     * @return {void}
     */
    hideModal = () => {
      this.powerInquiriesModal.toggleModal(false);
      this.setState({ isModalVisible: false });
    };

    /**
     * Renders the component view.
     * @return {React.Component}
     */
    render() {
      const {
        params: { eventId },
        text,
      } = this.props;
      return (
        <div className="ManagePowerInquiriesOverview">
          <div className="navigation-container">
            <div className="buttonRow left">
              <SearchInput
                onChange={this.onChangeSearchField}
                placeholder="Search tags"
                className="search-bar"
              />
              <Badge
                className="badge"
                color="default"
                overlap="rectangular"
                badgeContent="beta"
                anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
              >
                <Button
                  size={'small'}
                  children={text.get('actions.import')}
                  onClick={this.showModal}
                  variant={'contained'}
                />
              </Badge>
              <Button
                size="small"
                children={text.get('actions.invite')}
                onClick={() => pushRoute(`/event/${eventId}/inquiries/invite`)}
              />
              <Button
                size="small"
                children={text.get('actions.addNewAccount')}
                onClick={() => pushRoute(`/event/${eventId}/inquiries/add`)}
              />
            </div>
            <div className="buttonRow right">
              <Button
                size="small"
                className="right"
                children={text.get('actions.sendReminders')}
                onClick={() =>
                  pushRoute(`/event/${eventId}/inquiries/sendReminders`)
                }
              />
            </div>
            {this.state.isModalVisible && (
              <Modal
                lockHideOnBlur={true}
                ref={(ref) => {
                  this.powerInquiriesModal = ref;
                }}
              >
                <UploadAccounts
                  eventId={eventId}
                  text={text}
                  showModal={this.showModal}
                  hideModal={this.hideModal}
                  {...this.props.params}
                />
              </Modal>
            )}
          </div>
          {this.renderTable()}
        </div>
      );
    }
  }
);
