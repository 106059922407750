import React from 'react';
import { inject, observer } from 'mobx-react';
import {
  withStyles,
  Tooltip,
  Box,
  Table,
  TableBody,
  TableRow,
  TableCell as MuiTableCell,
} from '@material-ui/core';
import PowerOutlinedIcon from '@material-ui/icons/PowerOutlined';
import { compose } from 'recompose';

import { Icon } from 'shared/components';
import {
  AutoCompleteInput,
  Checkbox,
  DateRange,
  TextInput,
} from 'shared/components/form';
import { calcEventStartEndDates } from 'shared/utils/';
import { calculateTotalConnectionsPower } from 'spots/utils';
import ConnectionPowerCheckIcon from '../../connection/ConnectionPowerCheckIcon';

import Devices from './devices';
import SelectSizeInput from 'spots/components/connection/SelectSizeInput';
import { ALL_CONNECTIONS } from 'spots/components/connection/ConnectionSizeForm/constants';
import { AVAILABLE_METER_IDS } from 'spots/constants/connections';

const enhance = compose(inject('events', 'text'), observer);

const TableCell = withStyles({
  root: {
    borderBottom: 'none',
  },
})(MuiTableCell);

/**
 * Connection component class represents a connection row in the form.
 * @class Connection
 * @extends {React.Component}
 */
export default enhance(
  class Connection extends React.Component {
    state = { expanded: false };

    /**
     * React lifecycle method - executed everytime the component is mounted in the view.
     * @return {void}
     */
    componentDidMount() {
      if (this.props.isLast) {
        this.setState({ expanded: true });
      }
    }

    /**
     * Renders the remove icon button.
     * @param {boolean} condition - Condition to show
     * @return {React.Component}
     */
    renderRemoveIcon = (condition) =>
      condition && (
        <Tooltip title={this.props.text.get('actions.removeConnection')}>
          <span>
            <Icon
              name="close"
              className="removeIcon clickable"
              onClick={(e) => this.props.removeHandler(e, this.props.field)}
            />
          </span>
        </Tooltip>
      );

    /**
     * Handles the toggling of connection
     * @return {void}
     */
    toggleExpansion = () => this.setState({ expanded: !this.state.expanded });

    /**
     * Handles the validation of the power field
     * @return {void}
     */
    validateQuantityInput = () => {
      const { field } = this.props;
      if (field.$('quantity').value < 1) {
        field.$('quantity').value = '';
      }
      if (field.$('quantity').value.toString().length > 2) {
        field.$('quantity').value = field
          .$('quantity')
          .value.toString()
          .substr(0, field.$('quantity').value.toString().length - 1);
      }
    };

    /**
     * Renders the component view.
     * @return {React.Component}
     */
    render() {
      const { canRemove, events, eventId, field, text, formSubmit, disabled } =
        this.props;
      const { expanded } = this.state;
      const event = events.getEvent(eventId);
      const upsFieldIsActive = event?.upsFieldIsActive;
      const eventHasStarted =
        new Date() > new Date(event.buildUpDates[0].startDate);
      const connectionQty =
        field.$('quantity').value > 0
          ? this.props.field.$('quantity').value
          : 1;
      const connectionPower = calculateTotalConnectionsPower(
        field.$('devices'),
        connectionQty
      );
      const connectionCap = ALL_CONNECTIONS.find(
        ({ externalId }) =>
          externalId === field.$('connectionSizeType.externalId').value
      )?.ratedPowerKva;

      const onChangeMeterId = (value, event) => {
        field.$('meterId').set(value);
        formSubmit(event); //this is done to update the state of the parent form ( ) before the form is submitted
      };

      return (
        <div className="main-row section">
          <div className="Connection">
            <div className="connectionHeader">
              <div
                className="clickable mainTitle"
                onClick={this.toggleExpansion}
              >
                <div>
                  <span>{field.$('quantity').value} x </span>
                  <PowerOutlinedIcon fontSize="small" />
                  <span className="name">{field.$('name').value}</span> -{' '}
                  <span className="connectionSizeType.externalId">
                    {field.$('connectionSizeType').$('externalId').value}
                  </span>{' '}
                  -{' '}
                  <Tooltip title={text.get('forms.spot.connectionsTooltip')}>
                    <span className="power">
                      {(connectionPower / 1000).toFixed(1)} kW
                    </span>
                  </Tooltip>
                </div>
                <div>
                  <Tooltip
                    title={
                      expanded
                        ? text.get('forms.spotAdmin.expand')
                        : text.get('forms.spotAdmin.collapse')
                    }
                  >
                    <span>
                      <Icon name={expanded ? 'arrow-up' : 'arrow-down'} />{' '}
                    </span>
                  </Tooltip>
                  {this.renderRemoveIcon(canRemove)}
                </div>
              </div>
              {expanded && ( //TODO refactor this to use MUI accordions
                <div className="Connection">
                  <Box style={{ marginBottom: '16px' }}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <TextInput
                              disabled={disabled}
                              min={1}
                              max={99}
                              onKeyUp={this.validateQuantityInput}
                              field={field.$('quantity')}
                              type="number"
                            />
                          </TableCell>
                          <TableCell>
                            <TextInput
                              disabled={disabled}
                              field={field.$('name')}
                            />
                          </TableCell>
                          <TableCell>
                            <SelectSizeInput
                              disabled={disabled}
                              formSubmit={formSubmit}
                              field={field.$('connectionSizeType')}
                            />
                          </TableCell>
                          <TableCell>
                            <div className="powerIconField">
                              <ConnectionPowerCheckIcon
                                connectionCap={connectionCap}
                                ratedPower={connectionPower}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan="2">
                            <AutoCompleteInput
                              placeholder={text.get('forms.spot.meterId')}
                              options={AVAILABLE_METER_IDS}
                              getOptionLabel={(option) => option}
                              onChange={onChangeMeterId}
                              name={'meterId'}
                              value={field.$('meterId').value}
                              label={field.$('meterId').label}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Tooltip
                              title={text.get('forms.spot.tooltip.nightPower')}
                              placement="top-start"
                              enterDelay={1000}
                            >
                              <span>
                                <Checkbox
                                  disabled={disabled}
                                  field={field.$('nightPower')}
                                  label={text.get('forms.spot.nightPower')}
                                />
                              </span>
                            </Tooltip>
                            {upsFieldIsActive && (
                              <Tooltip
                                title={text.get('forms.spot.tooltip.upsNeeded')}
                                placement="bottom-start"
                                enterDelay={1000}
                              >
                                <span>
                                  <Checkbox
                                    disabled={disabled}
                                    field={field.$('upsNeeded')}
                                    label={text.get('forms.spot.upsNeeded')}
                                  />
                                </span>
                              </Tooltip>
                            )}
                          </TableCell>
                          <TableCell>
                            {eventHasStarted && (
                              <>
                                <Tooltip
                                  title={text.get(
                                    'forms.spot.tooltip.hasArrived'
                                  )}
                                  placement="top-start"
                                  enterDelay={1000}
                                >
                                  <span>
                                    <Checkbox
                                      disabled={disabled}
                                      field={field.$('hasArrived')}
                                      label={text.get('forms.spot.hasArrived')}
                                    />
                                  </span>
                                </Tooltip>
                                <Tooltip
                                  title={text.get(
                                    'forms.spot.tooltip.isConnected'
                                  )}
                                  placement="bottom-start"
                                  enterDelay={1000}
                                >
                                  <span>
                                    <Checkbox
                                      disabled={disabled}
                                      field={field.$('isConnected')}
                                      label={text.get('forms.spot.isConnected')}
                                    />
                                  </span>
                                </Tooltip>
                              </>
                            )}
                          </TableCell>
                          <TableCell className="spotDate">
                            <DateRange
                              disabled={disabled}
                              submitForm={formSubmit}
                              field={field}
                              minDate={calcEventStartEndDates(event, 'start')}
                              maxDate={calcEventStartEndDates(event, 'end')}
                              label={text.get('forms.spot.dateInterval')}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Box>
                  <Devices
                    connectionQuantity={field.$('quantity').value}
                    formSubmit={formSubmit}
                    field={field.$('devices')}
                    disabled={disabled}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
  }
);
