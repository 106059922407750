import React from 'react';
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg'; // Reference: https://github.com/jpuri/react-draft-wysiwyg
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import './styles.less';

export class RichTextInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { editorState: EditorState.createEmpty() };
  }

  reset() {
    this.setState({ editorState: EditorState.createEmpty() });
    this.props.field.value = '';
  }

  update(value = '') {
    const contentState = ContentState.createFromBlockArray(
      convertFromHTML(value)
    );
    const editorState = EditorState.createWithContent(contentState);
    this.setState({ editorState }, () => {
      this.props.field.value = value;
      this.props.field.validate();
    });
  }

  onEditorStateChange = (editorState) => {
    const { field } = this.props;
    this.setState({ editorState }, () => {
      const value = draftToHtml(convertToRaw(editorState.getCurrentContent()));
      field.value = value.trim() === '<p></p>' ? '' : value;
    });
  };

  render() {
    const { field } = this.props;
    const { editorState } = this.state;
    return (
      <div className="RichTextInput">
        <Editor
          editorState={editorState}
          editorClassName={`editor ${field.hasError && 'error'}`}
          onEditorStateChange={this.onEditorStateChange}
          onBlur={() => this.props.field.validate()}
        />
      </div>
    );
  }
}
