import React from 'react';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import { Grid } from '@material-ui/core';
import { Metadata, Spinner } from 'shared/components';
import { Roles } from 'shared/constants';
import { loadSpots } from 'spots/actions';
import { userHasPermission } from 'auth/actions';
import { loadAreas } from 'areas/actions';

import Desktop from './desktop';
import Mobile from './mobile';
import EventSpotsOverview from './EventSpotsOverview';
import './styles.less';

const enhance = compose(
  inject('spots', 'areas', 'text', 'auth', 'view'),
  observer
);

/**
 * SpotsBase class represents the event spots management view.
 * @class SpotsBase
 * @extends {React.Component}
 */
export const SpotsBase = enhance(
  class extends React.Component {
    state = {
      visible: false,
      spotId: '',
      historicSpots: [],
      editedSpot: null,
      spot: null,
    };
    /**
     * React lifecycle method - executed everytime the component is about to be mounted in the view.
     * @return {void}
     */
    componentDidMount() {
      const {
        params: { eventId },
        auth,
      } = this.props;
      if (userHasPermission([Roles.ADMIN, Roles.EVENT_CREATOR])) {
        loadSpots(eventId);
      } else {
        const userId = auth.profile._id;
        loadSpots(eventId, userId);
      }
      if (
        userHasPermission([
          Roles.ADMIN,
          Roles.EVENT_CREATOR,
          Roles.ENERGY_USER,
          Roles.INQUIRY_MANAGER,
        ])
      )
        loadAreas(eventId);
    }

    /**
     * Changes the active spot id
     * @param {number} editedSpot - active spot id
     * @return {void}
     */
    onChangeModifySpot = (editedSpot) => this.setState({ editedSpot });

    /**
     * Renders the details view.
     * @param {object} spot - Object containing 'spot' key
     * @return {React.Component}
     */
    renderDetailsView(spot) {
      const { children, params, text, view, areas } = this.props;
      const area = areas.getArea(spot.associatedResource); //todo
      return (
        <div className=" padding eventDetails">
          {view.isDesktop && (
            <h2>
              {text
                .get('components.views.spots.manageSpot')
                .replace('{spotName}', spot.label)}
            </h2>
          )}
          <Grid container>
            {spot.coordinate && (
              <>
                <Grid item xs={2}>
                  {text.get('forms.spot.coordinate')}:
                </Grid>
                <Grid item xs={2}>
                  {spot.coordinate}
                </Grid>
              </>
            )}
            {spot.area && (
              <>
                <Grid item xs={2}>
                  {text.get('forms.spot.area')}:
                </Grid>
                <Grid item xs={2}>
                  {area?.tag || '-'}
                </Grid>
              </>
            )}
          </Grid>
          {children ? (
            children
          ) : (
            <EventSpotsOverview
              onChangeModifySpot={this.onChangeModifySpot}
              {...params}
              spot={spot}
            />
          )}
        </div>
      );
    }

    /**
     * Presents the sizes modal.
     * @param {object} event - 'click' event object
     * @return {void}
     */
    showModal = (event) => {
      !!event && event.preventDefault();
      this.modal.toggleModal(true);
    };

    /**
     * Updates the field and hides the modal
     * @param {object} event - 'click' event object
     * @return {void}
     */
    hideModal = (event) => {
      !!event && event.preventDefault();
      this.modal.toggleModal(false);
    };

    /**
     * Renders the component view.
     * @return {React.Component}
     */
    render() {
      const {
        params,
        spots,
        text,
        view: { isDesktop },
      } = this.props;
      if (spots.loading && !!spots.list.length) return <Spinner />;
      const View = isDesktop ? Desktop : Mobile;
      return (
        <div className="SpotsBase">
          <Metadata meta={text.get('components.views.spots.meta')} />
          <View
            {...params}
            onChangeModifySpot={this.onChangeModifySpot}
            editedSpot={this.state.editedSpot}
            spots={spots}
            renderDetailsView={(spot) => this.renderDetailsView(spot)}
            text={text}
          />
        </div>
      );
    }
  }
);
