import React, { useEffect, useState } from 'react';

import { Metadata, SideMenu } from 'shared/components';
import { setToggleHamburgerMenuHandler } from 'shared/actions';
import { useGetText, useGetView } from 'shared/hooks';
import { loadEvents } from 'events/actions';

import Menu from './Menu';
import './styles.less';

/**
 * EventBase component represents the base event view component, which will work as a wrapper for all the
 * event pages. It is the default layout that every event page should present.
 * @return {React.Component}
 */
export const EventBase = ({
  params: { eventId },
  location: { pathname },
  children,
}) => {
  const [sideMenuVisible, setSideMenuVisible] = useState(false);
  const text = useGetText();
  const view = useGetView();
  const toggleHamburgerMenuHandler = () => setSideMenuVisible(!sideMenuVisible);

  //load the events on mount for pages that rely on the eventsstore
  //TODO remove as soon as spot demain is refactored
  useEffect(() => {
    loadEvents();
  }, [children]);

  useEffect(() => {
    setToggleHamburgerMenuHandler(toggleHamburgerMenuHandler);
  }, []);

  return (
    <div className="EventView">
      <Metadata meta={text.getText('components.views.event.meta')} />
      <SideMenu
        visible={view.isDesktop || sideMenuVisible}
        overlay={view.isMobile}
      >
        <Menu eventId={eventId} pathname={pathname} />
      </SideMenu>
      <div className="eventContent">{children}</div>
    </div>
  );
};
