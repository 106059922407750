import { useEffect } from 'react';
import { useQuery } from 'react-query';

import { api } from 'shared/utils';

import userMapper from '../mapper';
import { users as usersStore } from '../store';

export const USERS_QUERY_KEY = 'users-key';

export const loadUsers = (roleIds) => {
  const query = roleIds ? `?role=${roleIds.join(',')}` : '';
  return api
    .get(`/users${query}`)
    .then(({ status, data }) => (status === 200 ? data.map(userMapper) : []));
};

export const useGetUsers = (roleIds) => {
  const { data, status, ...others } = useQuery([USERS_QUERY_KEY, roleIds], () =>
    loadUsers(roleIds)
  );

  //TODO: remove when none of the other modules are depending on the usersStore
  useEffect(() => {
    if (data && data.length > 0) {
      usersStore.registerUsers(data);
    }
  }, [data]);

  return {
    ...others,
    loading: status === 'loading',
    users: data || [],
  };
};
