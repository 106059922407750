import React from 'react';
import { observer, inject } from 'mobx-react';
import { compose } from 'recompose';

import { SpotForm } from 'spots/components';
import { recoverSpotFromBuffer, saveSpotToBuffer } from 'spots/actions';
import { pushRoute } from 'shared/actions';

const enhance = compose(inject('text'), observer);

/**
 * SpotConnectionsPage class represents the spot connections view, which is the 1st step of the
 * spot modification process and contains a form to modify the data related to the connections and
 * it's appliances.
 * @class SpotConnectionsPage
 * @extends {React.Component}
 */
export const SpotConnectionsPage = enhance(
  class extends React.Component {
    // {object} state - State object stores information about the current spot
    state = { spot: null };

    /**
     * React lifecycle method - executed everytime the component is about to be mounted in the view.
     * @return {void}
     */
    UNSAFE_componentWillMount() {
      const {
        params: { eventId },
      } = this.props;
      const spot = recoverSpotFromBuffer();
      if (!spot) return pushRoute(`/event/${eventId}/spots`);
      this.setState({ spot });
    }

    /**
     * React lifecycle method - executed everytime the component gets new props
     * @return {void}
     */
    UNSAFE_componentWillReceiveProps(prevProps) {
      if (prevProps.routeParams.spotId !== this.props.routeParams.spotId) {
        const spot = recoverSpotFromBuffer();
        this.setState({ spot });
      }
    }

    /**
     * Form submission success handler.
     * @param {object} form - Form instance
     * @return {void}
     */
    onSuccess(form) {
      const {
        params: { eventId, spotId },
      } = this.props;
      saveSpotToBuffer(form.values());
      pushRoute(`/event/${eventId}/spots/${spotId}/extra`);
    }

    /**
     * Renders the component view.
     * @return {React.Component}
     */
    render() {
      const { spot } = this.state;
      if (!spot) return null;

      return (
        <div>
          <SpotForm onSuccess={(form) => this.onSuccess(form)} values={spot} />
        </div>
      );
    }
  }
);
