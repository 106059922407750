import React from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { compose } from 'recompose';

import { Button } from 'shared/components/form';
import { formCreator } from 'shared/form';
import { changeFormState } from 'spots/actions';
import { notify, goBack } from 'shared/actions';
import { Notifications } from 'shared/constants';

import ConnectionRow from './connection';
import lbls from './labels';
import rules from './rules';
import fields from './fields';

const enhance = compose(inject('text'), observer);

/**
 * SpotExtraForm class represents the extra information form view.
 * @class SpotExtraForm
 * @extends {React.Component}
 */
export const SpotExtraForm = enhance(
  class extends React.Component {
    // {object} state - Component state holds the form instance.
    state = { form: null };

    // {object} config - Form configuration getter
    get config() {
      const { onSuccess, onError, text } = this.props;
      const title = 'spotExtraForm';
      const labels = lbls(text.get('forms.spot'));
      return { title, fields, rules, labels, onSuccess, onError };
    }

    /**
     * React lifecycle method - executed everytime the component is mounted in the view.
     * @return {void}
     */
    componentDidMount() {
      const { values } = this.props;
      const form = formCreator(this.config);
      this.setState({ form });
      form.init(values);
    }

    /**
     * Renders the connection field rows in the view.
     * @param {object} field - Field instance
     * @return {React.Component}
     */
    renderConnections(field) {
      const { event } = this.props;
      return field.fields
        .values()
        .map((connection, index) => (
          <ConnectionRow field={connection} event={event} key={index} />
        ));
    }

    /**
     * Submits the form.
     * @param {object} e - Onclick event
     * @return {void}
     */
    onSubmit = (e) => {
      const { event, text } = this.props;
      const eventEndDate = event.endDate;
      const eventStartDate = event.startDate;
      this.state.form
        .$('layout')
        .$('connections')
        .forEach((item) => {
          if (
            moment(item.value.startDate) < moment(eventStartDate) ||
            moment(item.value.endDate) > moment(eventEndDate)
          ) {
            notify({
              message: text.get('components.views.event.outsideDate'),
              level: Notifications.Type.ERROR,
            });
            e.preventDefault();
          } else {
            this.setState({ showError: false }, () => {
              this.state.form.onSubmit(e);
              changeFormState(false);
            });
          }
        });
      changeFormState(false);
    };

    /**
     * Renders the component view.
     * @return {React.Component}
     */
    render() {
      const { text } = this.props;
      const { form, isDisabled } = this.state;
      if (!form) return null;
      return (
        <form className="spotExtraForm" onSubmit={form.onSubmit}>
          {this.renderConnections(form.$('layout').$('connections'))}
          <div className="mainRow spot-edit">
            <div className="nav-btn backBtn">
              <Button type="button" onClick={goBack}>
                {text.get('actions.back')}
              </Button>
            </div>
            <div className="nav-btn">
              <Button
                type="submit"
                styling="cta"
                onClick={form.onSubmit}
                disabled={form.hasError || isDisabled}
              >
                {text.get('actions.next')}
              </Button>
            </div>
          </div>
        </form>
      );
    }
  }
);
