import { buffer } from 'shared/stores';

/**
 * Selects the place to store the data.
 * @param {boolean} bufferOnly - Save only in the buffer (Optional)
 * @return {object}
 */
const selectStorage = (bufferOnly = false) =>
  bufferOnly ? buffer : window.localStorage;

/**
 * Takes a key and a value as inputs and saves the 'value' content in the localStorage
 * within the 'key' namespace.
 * @param {string} key - LocalStorage namespace to save
 * @param {any} value - Content to store
 * @param {boolean} bufferOnly - Save only in the buffer (Optional)
 * @return {void}
 */
export const save = (key, value, bufferOnly = false) =>
  selectStorage(bufferOnly).setItem(key, value);

/**
 * Takes a key namespace as inputs and removes it from the localStorage.
 * @param {string} key - LocalStorage namespace to save
 * @param {boolean} bufferOnly - Save only in the buffer (Optional)
 * @return {void}
 */
export const remove = (key, bufferOnly = false) =>
  selectStorage(bufferOnly).removeItem(key);

/**
 * Takes a key namespace as inputs and reads it's content from the localStorage.
 * @param {string} key - LocalStorage namespace to save
 * @param {boolean} bufferOnly - Save only in the buffer (Optional)
 * @return {*}
 */
export const load = (key, bufferOnly = false) =>
  selectStorage(bufferOnly).getItem(key);
