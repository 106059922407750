import React from 'react';
import { observer, inject } from 'mobx-react';
import { compose } from 'recompose';

import { Box, Icon, Spinner } from 'shared/components';
import { saveSpotToBuffer, getEventUserSpots } from 'spots/actions';
import { loadTemplates, pushRoute } from 'shared/actions';
import { Button } from 'shared/components/form';
import { Roles } from 'shared/constants';
import { DialogModal } from 'shared/components';

import { HistoricEvents } from 'events/components';
import HistoricSpots from 'spots/components/historicSpots';

import './styles.less';

const enhance = compose(
  inject('templates', 'text', 'auth', 'events'),
  observer
);

/**
 * TemplatesBox class represents the box with the templates list presented in the spot overview page
 * when the spot is not filled yet.
 * @class TemplatesBox
 * @extends {React.Component}
 */
export default enhance(
  class TemplatesBox extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        showHistoricEvents: false,
        historicSpots: [],
        modalOpen: false,
      };
      this.toggleModal = this.toggleModal.bind(this);
    }

    // {object} emptyTemplate - Empty form instance
    get emptyTemplate() {
      const { text } = this.props;
      return {
        uri: '',
        name: text.get('forms.spot.emptyTemplate'),
        createdAt: Date.now(),
        updatedAt: Date.now(),
        layout: { connections: [], comment: '' },
      };
    }

    /**
     * React lifecycle method - executed everytime the component is just mounted in the view.
     * @return {void}
     */
    componentDidMount() {
      const { auth } = this.props;
      loadTemplates(auth.profile._id);
      this.showHistoricEvents();
    }

    /**
     * Template list item click handler
     * @param {object} template - Selected instance
     * @return {void}
     */
    onClickTemplate(template) {
      const { eventId, spotId } = this.props;
      saveSpotToBuffer(template);
      pushRoute(`/event/${eventId}/spots/${spotId}/connections`);
    }

    /**
     * Renders the templates list.
     * @param {object[]} templates - Templates list
     * @return {React.Component}
     */
    renderTemplates() {
      const { templates } = this.props;
      if (templates.loading) return <Spinner />;
      return templates.list
        .concat([this.emptyTemplate])
        .map((template, index) => (
          <div
            className="template"
            key={index}
            onClick={() => this.onClickTemplate(template)}
          >
            <Icon name="add-circle" className="icon" />
            <span className="title" children={template.name} />
          </div>
        ));
    }

    showHistoricEvents = () => {
      this.setState({ showHistoricEvents: true });
    };

    onEventPress = (eventId) => {
      const {
        auth: { profile },
      } = this.props;

      getEventUserSpots(eventId, profile._id).then((historicSpots) =>
        this.setState(
          {
            historicSpots,
          },
          () => this.toggleModal()
        )
      );
    };

    fillSpotData = (spot) => {
      this.toggleModal();

      const connections = spot.layout.connections.map((conection) => ({
        ...conection,
        startDate: null,
        endDate: null,
        nightPower: false,
      }));
      let template = this.emptyTemplate;
      template.layout.connections = connections;
      this.onClickTemplate(template);
    };

    toggleModal() {
      this.setState({ modalOpen: !this.state.modalOpen });
    }

    /**
     * Renders the component view.
     * @return {React.Component}
     */
    render() {
      const { text, events, eventId, auth } = this.props;
      const { modalOpen, showHistoricEvents, historicSpots } = this.state;
      const showTemplates = true;

      const allowAddHistoricSpots =
        auth.profile._id !== Roles.ADMIM ||
        auth.profile._id !== Roles.EVENT_CREATOR;

      if (!events) return null;

      return (
        <React.Fragment>
          {showTemplates && (
            <div className="TemplatesBox">
              <Box
                title={text.get('components.views.spots.chooseTemplate')}
                children={this.renderTemplates()}
              />
            </div>
          )}
          <div>
            <br />
            {allowAddHistoricSpots && !showHistoricEvents && (
              <div className="">
                <Button
                  type="button"
                  styling="alt"
                  onClick={this.showHistoricEvents}
                >
                  {text.get('forms.spot.selectFromHistory')}
                </Button>
              </div>
            )}
          </div>

          {showHistoricEvents && (
            <HistoricEvents
              onClickEvent={this.onEventPress}
              eventId={eventId}
              userId={auth.profile._id}
            />
          )}

          <DialogModal
            open={modalOpen}
            bodyPadding={false}
            handleClose={this.toggleModal}
            title={text.get('forms.spot.selectSpotData')}
            text={text}
          >
            <HistoricSpots
              onClickSpot={this.fillSpotData}
              spots={historicSpots}
              hideModal={this.hideHistoricSpotsModal}
              festivalName={events.getEvent(eventId)?.festival || 'Anonymous'}
            />
          </DialogModal>
        </React.Fragment>
      );
    }
  }
);
