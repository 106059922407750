import * as Stores from 'shared/stores';

/**
 * Changes the application's language.
 * @param {string} language - Language identifier
 * @return {void}
 */
export const changeLanguage = (language) => {
  if (!Stores.text) setTimeout(() => changeLanguage(language), 1);
  // If the store's not ready, call recursively until it's ready.
  else Stores.text.changeLanguage(language);
};
