export default (text) => ({
  email: {
    label: text.get('forms.login.username'),
    rules: 'required|email|string',
  },
  password: {
    label: text.get('forms.login.password'),
    rules: 'required|string',
  },
});
