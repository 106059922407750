import React from 'react';

export const PowerZone = (props) => (
  <svg
    fill="#000000"
    height="32"
    viewBox="0 0 32 32"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M7 2v11h3v9l7-12h-4l3-8z" />
  </svg>
);
