export default {
  name: 'required|string',
  language: 'required|string',
  email: 'required|email',
  password: 'string',
  repeatPassword: 'string',
  role: 'required|numeric',
  phone: 'string',
  'phones[].type': 'string',
  'phones[].number': 'string',
  companyId: '',
  credits: 'numeric',
};
