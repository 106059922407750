import { action, extendObservable } from 'mobx';

/**
 * BufferStore is the Mobx global data storage for the spots data.
 *
 * @class BufferStore
 */
function BufferStore() {
  extendObservable(this, {
    buffer: {},
    getItem: (key) => this.buffer[key] || null,
    setItem: action((key, value) => {
      this.buffer[key] = value;
    }),
    removeItem: action((key) => {
      delete this.buffer[key];
    }),
  });
}

export default new BufferStore();
