import React, { createRef } from 'react';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import debounce from 'lodash/debounce';

import { Button, TextInput } from 'shared/components/form';

import { ModalForm } from 'shared/components';
import { formCreator } from 'shared/form';

import fields from 'spots/components/SpotAdminForm/fields';
import lbls from 'spots/components/SpotAdminForm/labels';
import rules from 'spots/components/SpotAdminForm/rules';

const enhance = compose(inject('text'), observer);

/**
 * SpotModalForm class represents the form where the user should give the new spot tag.
 * @class SpotModalForm
 * @extends {React.Component}
 */
export const SpotModalForm = enhance(
  class extends React.Component {
    constructor(props) {
      super(props);
      // {object} state - Component state holds the form instance.
      this.state = { form: null };
      this.modalRef = createRef();
    }

    /**
     * React lifecycle method - executed everytime the component is about to be mounted in the view.
     * @return {void}
     */

    UNSAFE_componentWillMount() {
      const form = formCreator(this.config);
      this.setState({ form });
    }

    // {object} config - Form configuration getter
    get config() {
      const { onSuccess, onError, text } = this.props;
      const labels = lbls(text.get('forms.spot'));
      return {
        title: 'spotModalForm',
        fields,
        labels,
        rules,
        onSuccess,
        onError,
      };
    }

    hideForm() {
      this.modalRef.current.hideForm();
    }

    showForm() {
      this.modalRef.current.showForm();
    }

    /**
     * React lifecycle method - executed everytime the component is mounted in the view.
     * @return {void}
     */
    updateForm(values) {
      const empty = { label: '', coordinate: '', cluster: '' };
      this.state.form.init({ ...empty, ...values }); // making sure everytime you select a new powerzone, his data will appear
    }

    handleFormChange = debounce(() => {
      this.props.onSuccess(this.state.form);
    }, 1000);

    /**
     * Renders the component view.
     * @return {React.Component}
     */
    render() {
      const { text, cancelHandler } = this.props;
      const { form } = this.state;
      if (!form) return null;
      return (
        <ModalForm ref={this.modalRef}>
          <form className="small form">
            <div className="modalHeader">{text.get('forms.spot.spot')}</div>
            <div className="modalBody padding">
              <TextInput field={form.$('label')} type="text" />
            </div>
            <div className="modalBody padding">
              <TextInput field={form.$('coordinate')} type="text" />
            </div>
            <div className="modalFooter padding">
              <Button
                styling="cta"
                onClick={form.onSubmit}
                disabled={form.isEmpty}
              >
                {text.get('actions.submit')}
              </Button>
              <Button
                type="button"
                onClick={cancelHandler}
                styling="borderless"
              >
                {text.get('actions.cancel')}
              </Button>
            </div>
          </form>
        </ModalForm>
      );
    }
  }
);
