import {
  isValid,
  format,
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
} from 'date-fns';

const createDatesArray = (startDate, endDate) => {
  const result = [];
  // From unix ts in milliseconds to seconds, making sure the dates passed are a Date object
  let s = new Date(startDate).getTime();
  let e = new Date(endDate).getTime();

  // number of milliseconds in a day
  const oneDay = 1000 * 60 * 60 * 24;
  if (e - s > oneDay) {
    // Set the startDate to a time multiple of 10 minutes and continue it with intervals of 10 minutes to prevent performance issues
    while (new Date(s).getMinutes() % 10 !== 0) {
      s++;
    }

    for (let i = s; i <= e; i += 600000) {
      result.push(i);
    }
  } else {
    for (let i = s; i <= e; i += 1000) {
      result.push(i);
    }
  }

  return result;
};

export const determineXTicks = (range) => {
  const result = [];
  const rangeInHours = differenceInHours(range.to, range.from);

  const rangeInDays = differenceInDays(range.to, range.from);

  const datesArray = createDatesArray(range.from, range.to);

  if (rangeInHours <= 1) {
    return;
  } else if (rangeInHours > 1 && rangeInHours <= 2) {
    datesArray.forEach(
      (date) => new Date(date).getMinutes() % 10 === 0 && result.push(date)
    );
  } else if (rangeInHours > 2 && rangeInHours <= 12) {
    datesArray.forEach(
      (date) =>
        (new Date(date).getMinutes() === 0 ||
          new Date(date).getMinutes() === 30) &&
        result.push(date)
    );
  } else if (rangeInHours > 12 && rangeInHours <= 24) {
    datesArray.forEach(
      (date) => new Date(date).getMinutes() === 0 && result.push(date)
    );
  } else if (rangeInHours > 24 && rangeInDays <= 3) {
    datesArray.forEach(
      (date) =>
        new Date(date).getHours() % 8 === 0 &&
        new Date(date).getMinutes() === 0 &&
        new Date(date).getSeconds() === 0 &&
        result.push(date)
    );
  } else if (rangeInDays > 3) {
    datesArray.forEach(
      (date) =>
        new Date(date).getHours() === 0 &&
        new Date(date).getMinutes() === 0 &&
        result.push(date)
    );
  }
  return result;
};

/**
 * Formats a graph's x axis' ticks
 *
 * @param {date || string} value - date that is to be formatted before it is rendered in the graph
 * @returns {date}
 */
export const formatXAxisTicks = (value, range) => {
  const date = new Date(value);
  if (!value || !isValid(date)) return;
  if (!range.from || !range.to) return;

  const rangeInMinutes = differenceInMinutes(range.to, range.from);

  if (date.getHours() === 0) return format(date, 'd MMM HH:mm');

  if (rangeInMinutes <= 10) {
    return format(date, 'HH:mm:ss');
  } else {
    return format(date, 'HH:mm');
  }
};

export function calculateGroupingTime(width = 1280, timeRange) {
  const maxAmountOfPointsPerPixel = 1;
  const timeRangeInSeconds = timeRange / 1000;
  const groupByInSeconds =
    timeRangeInSeconds / (maxAmountOfPointsPerPixel * width);

  if (width >= timeRangeInSeconds) {
    return '3s';
  } else if (Math.floor(groupByInSeconds) <= 1) {
    return '3s';
  } else {
    return `${Math.floor(groupByInSeconds)}s`;
  }
}
