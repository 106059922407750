export default (text) => ({
  email: {
    label: text.get('forms.powerInquiries.email'),
    rules: 'email',
  },
  companyId: {
    label: text.get('forms.powerInquiries.company'),
    rules: 'number',
  },
  userId: {
    label: text.get('forms.powerInquiries.user'),
    rules: 'number',
  },
  owner: {
    label: text.get('forms.powerInquiries.owner'),
    rules: 'number',
  },
  canSeeConnectionPrices: {
    label: text.get('forms.powerInquiries.canSeeConnectionPrices'),
    rules: 'boolean',
  },
});
