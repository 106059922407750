import React, { useEffect } from 'react';

import { goBack, notify } from 'shared/actions';
import { Box } from 'shared/components';
import { useGetText } from 'shared/hooks';
import { Notifications } from 'shared/constants';

import { UserForm } from 'users/components';
import { useCreateUser } from 'users/hooks';
import './styles.less';

export const UsersAddPage = () => {
  const text = useGetText();
  const { createUser, data } = useCreateUser();

  const handleFormSuccess = (form) => {
    const user = form.values();
    user.credits = parseInt(user.credits, 10);
    createUser(user);
  };

  //Handles the notification after the data has successfuly been stored
  useEffect(() => {
    if (data) {
      notify({
        message: `The user ${data._id} was created successfully.`,
        level: Notifications.Type.SUCCESS,
        dismiss: 5,
      });
      goBack();
    }
  }, [data]);

  return (
    <div className="UsersAdd">
      <Box title={text.getText('components.views.adminUsers.listHeaderAdd')}>
        <UserForm onSuccess={handleFormSuccess} isNew />
      </Box>
    </div>
  );
};
