// the fields that are present in the influx database
export const ENERGY_METER_FIELDS = {
  IRMS_A_L1_MEAN: 'Irms_A_L1_Mean',
  IRMS_A_L1_MIN: 'Irms_A_L1_Min',
  IRMS_A_L1_MAX: 'Irms_A_L1_Max',
  IRMS_A_L2_MEAN: 'Irms_A_L2_Mean',
  IRMS_A_L2_MIN: 'Irms_A_L2_Min',
  IRMS_A_L2_MAX: 'Irms_A_L2_Max',
  IRMS_A_L3_MEAN: 'Irms_A_L3_Mean',
  IRMS_A_L3_MIN: 'Irms_A_L3_Min',
  IRMS_A_L3_MAX: 'Irms_A_L3_Max',
  LOAD_W_TOTAL_MEAN: 'Load_W_Total_Mean',
  LOAD_W_TOTAL_MIN: 'Load_W_Total_Min',
  LOAD_W_TOTAL_MAX: 'Load_W_Total_Max',
  LOAD_VA_TOTAL_MEAN: 'Load_VA_Total_Mean',
  LOAD_VA_TOTAL_MIN: 'Load_VA_Total_Min',
  LOAD_VA_TOTAL_MAX: 'Load_VA_Total_Max',
  VRMS_V_L1_MEAN: 'Vrms_V_L1_Mean',
  VRMS_V_L2_MEAN: 'Vrms_V_L2_Mean',
  VRMS_V_L3_MEAN: 'Vrms_V_L3_Mean',
  FREQ_HZ_MIN: 'Freq_Hz_Min',
  FREQ_HZ_MAX: 'Freq_Hz_Max',
  ENERGY_USAGE_WH_L1: 'Energy_usage_Wh_L1',
  ENERGY_USAGE_WH_L2: 'Energy_usage_Wh_L2',
  ENERGY_USAGE_WH_L3: 'Energy_usage_Wh_L3',
  ENERGY_USAGE_WH_TOTAL: 'Energy_usage_Wh_Total',
  LOCATION_LA: 'Location_la',
  LOCATION_LO: 'Location_lo',
  LOCATION_AL: 'Location_al',
  SIGNAL_SSTR: 'Signal_sstr',
};

export const AGGREGATORS = {
  COUNT: 'count',
  DISTINCT: 'distinct',
  INTEGRAL: 'integral',
  MEAN: 'mean',
  MEDIAN: 'median',
  MODE: 'mode',
  SPREAD: 'spread',
  STDDEV: 'stddev',
  SUM: 'sum',
  FIRST: 'first',
  LAST: 'last',
  MAX: 'max',
  MIN: 'min',
  PERCENTILE: 'percentile',
  SAMPLE: 'sample',
};

export const TIME_COLUMN_NAME = 'time';
