import Math from 'math';
import { ROLE_NAMES } from 'users/constants';

export function getUserOptions(users, roleIds = [], companyIds = []) {
  const roleArr = roleIds instanceof Array ? roleIds : [roleIds];
  const compArr = companyIds instanceof Array ? companyIds : [companyIds];
  let options = users.list.slice();

  if (roleArr[0]) {
    options = options.filter((user) => roleArr.includes(user.role));
  }
  if (compArr[0]) {
    options = options.filter((user) => compArr.includes(user.companyId));
  }
  return options;
}

/**
 * Takes the single roleId of a user from the backend as an input and retreives the included role Ids from the available roles
 * @param {integer} max The maximum value that the next available roleId can have
 * @param {Array} resultArr array that will be the result after the final itteration
 * @param {Array}  availableRoles with the ideas of existing roles
 * @returns arry with al the ids that the user has
 */
export function getRoleIdsArrFromId(
  max,
  resultArr = [],
  availableRoles = [0, 1, 2, 4, 8, 16],
  initialId = null
) {
  if (availableRoles.includes(max)) {
    //check if max exists in the arr and if so add it to the result and return
    resultArr.push(max);
    return resultArr;
  }
  const newArr = availableRoles.filter((n) => n < max); //remove numbers larger than the max
  const newMax = max - Math.max(...newArr);
  const remain = max - newMax;
  resultArr.push(remain);

  if (newMax > Math.min(...availableRoles)) {
    //proceed if the newMax is larger than the smallest item in the array
    return getRoleIdsArrFromId(newMax, resultArr);
  } else {
    const currentInitialId = initialId || max;
    console.error(
      `The remaining roleId does not fit to an existing role, there is an error in the roleId: ${currentInitialId}`
    );
    return null;
  }
}

export function getRoleNamesArrFromId(roleId, text) {
  const IdsArr = getRoleIdsArrFromId(roleId);
  const namesArr = IdsArr.map((id) =>
    text.getText(`components.views.users.${ROLE_NAMES[id]}`)
  );
  return namesArr;
}
