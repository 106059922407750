import { useCallback } from 'react';
import { useQueryClient, useMutation } from 'react-query';

import { api } from 'shared/utils';

import { COMPANIES_QUERY_KEY } from './useGetCompanies';

const doRemoveCompany = async (companyId) => {
  const { data } = await api.delete(`/companies/${companyId}`);
  return data;
};

export const useRemoveCompany = () => {
  const queryClient = useQueryClient();
  const { mutate, status, ...others } = useMutation(doRemoveCompany, {
    onSuccess: (response, companyId) => {
      if (response) {
        //Update companies cash and invalidate
        queryClient.setQueryData([COMPANIES_QUERY_KEY], (oldCompanies) => {
          if (oldCompanies?.length > 0) {
            return oldCompanies.filter((company) => company._id !== companyId);
          } else {
            return [];
          }
        });
        queryClient.invalidateQueries(COMPANIES_QUERY_KEY);

        //Remove single company cache
        queryClient.removeQueries([COMPANIES_QUERY_KEY, 'id', companyId]);
      }
    },
  });

  const removeCompany = useCallback((companyId) => mutate(companyId), [mutate]);

  return {
    ...others,
    loading: status === 'loading',
    removeCompany,
  };
};
