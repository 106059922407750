import React from 'react';
import './styles.less';

export function ContactBox({ companyName, name, icon, onClick }) {
  return (
    <>
      {icon}
      <div className="content">
        <span className="title">{companyName}</span>
        <p className="subtitle">{name}</p>
      </div>
    </>
  );
}
