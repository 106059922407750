const EMPTY = '#959697';
const APPROVED = '#00b991';
const ESTIMATED = '#D16277';
const HISTORIC = '#A680BC';
const ENERGY_USER = '#F7A836';
const POWER_PLANER = '#26A5C9';
const ALL_EMPTY = '#E9EAEC';
const UNDEFINED = '#959697';
const IMPORTED = '#431c9e';

export const colors = {
  EMPTY,
  APPROVED,
  ESTIMATED,
  HISTORIC,
  ENERGY_USER,
  POWER_PLANER,
  UNDEFINED,
  ALL_EMPTY,
  IMPORTED,
};

// {object[]} CLUSTER_OPTIONS - Available cluster options
export const CLUSTER_OPTIONS = [
  { value: 'bar', label: 'Bar' },
  { value: 'creative', label: 'Creative' },
  { value: 'food', label: 'Food' },
  { value: 'heating', label: 'Heating' },
  { value: 'production_village', label: 'Production Village' },
  { value: 'refrigeration', label: 'Refrigeration' },
  { value: 'sanitation', label: 'Sanitation' },
  { value: 'show_light', label: 'Show Light' },
  { value: 'site_production', label: 'Site Production' },
  { value: 'site_light', label: 'Site Light' },
  { value: 'sound', label: 'Sound' },
];
