import { loadUser } from 'auth/actions';
import { useQuery } from 'react-query';

export const ME_QUERY_KEY = 'me-key';

export const useGetMe = () => {
  const { data, status, ...others } = useQuery(ME_QUERY_KEY, async () => {
    return loadUser();
  });

  return {
    ...others,
    loading: status === 'loading',
    me: data?.profile,
  };
};
