import * as Action from 'shared/actions';
import * as Constants from 'shared/constants';
import { General } from 'shared/constants';

const loadStates = () =>
  JSON.parse(Action.load(Constants.Nodes.EXPANDED_STATES) || '{}');

export const saveNodeState = (node, visible) => {
  General.DEBUG && console.log('Saving node state:', node.uri, visible);
  const nodesStates = loadStates();
  nodesStates[node.uri] = visible;
  Action.save(Constants.Nodes.EXPANDED_STATES, JSON.stringify(nodesStates));
};

export const getNodeState = (node) => {
  const nodesStates = loadStates();
  //General.DEBUG && console.log('Loading node state:', node.uri, nodesStates[node.uri] || false);
  return nodesStates[node.uri] || false;
};
