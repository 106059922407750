import { Roles } from 'shared/constants';

// {object[]} EVENT_TYPES - Available event types
export const EVENT_TYPES = [
  { value: 'electronic', label: 'Electronic' },
  { value: 'rock_alternative', label: 'Rock & Alternative' },
  { value: 'mixed_music', label: 'Mixed Music' },
  { value: 'theatre', label: 'Theatre' },
  { value: 'food_festival', label: 'Food Festival' },
  { value: 'hip_hop', label: 'Hip Hop' },
  { value: 'harddance', label: 'Hard Dance' },
  { value: 'sports_event', label: 'Sports Event' },
  { value: 'indoor_event', label: 'Indoor Event' },
  { value: 'construction_site', label: 'Construction Site' },
];

export const EDITOR_OPTIONS_IDS = [
  Roles.ADMIN,
  Roles.EVENT_CREATOR,
  Roles.INQUIRY_MANAGER,
];

export const EMPTY_EVENT = {
  festival: '',
  visitors: '',
  eventType: '',
  editors: [],
  eventCompanies: [],
  owner: null,
  buildUpDates: [
    {
      startDate: null,
      endDate: null,
      startTime: null,
      endTime: null,
    },
  ],
  showDates: [
    {
      startDate: null,
      endDate: null,
      startTime: null,
      endTime: null,
    },
  ],
  breakDownDates: [
    {
      startDate: null,
      endDate: null,
      startTime: null,
      endTime: null,
    },
  ],
};

export const EVENT_COMPANY_TYPES = {
  organiser: 'organiser',
  producer: 'producer',
  technicalProducer: 'technicalProducer',
  siteProducer: 'siteProducer',
  officeManager: 'officeManager',
  installer: 'installer',
  powerSupplier: 'powerSupplier',
  other: 'other',
};
