import React from 'react';

import AreaDetails from 'areas/components/areaDetails';
import SpotDetails from 'spots/components/SidePaneSpotDetails';
import { PowerzoneDetails } from 'powerzones/components/PowerzoneDetails';

export default class PrintNode extends React.PureComponent {
  render() {
    const { node, text, showSpotForm, userId } = this.props;
    let content = null;
    if (!node) return null;
    if (node.uri.includes('spots')) {
      content = (
        <SpotDetails
          userId={userId}
          showSpotForm={showSpotForm}
          item={node}
          text={text}
        />
      );
    }
    if (node.uri.includes('area')) {
      content = <AreaDetails item={node} />;
    }
    if (node.uri.includes('powerzone')) {
      content = <PowerzoneDetails item={node} />;
    }
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          position: 'fixed',
          width: '100%',
        }}
      >
        {content}
      </div>
    );
  }
}
