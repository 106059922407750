import React from 'react';
import { DayPickerRangeController } from 'react-dates';
import moment from 'moment';

export default class DatePicker extends React.Component {
  static displayName = 'DatePicker';

  getInitialMonth = () => {
    const initialVisibleMonth = this.props.minDate
      ? moment(this.props.minDate)
      : moment();
    return initialVisibleMonth;
  };

  isBetweenEventDates = (minDate, day, maxDate) => {
    if (!moment.isMoment(day) || !moment.isMoment(maxDate)) return false;
    return !day.isBetween(minDate, maxDate, 'days', '[]');
  };

  render() {
    let { startDate, endDate, minDate, maxDate } = this.props;
    startDate = startDate ? moment(startDate) : startDate;
    endDate = endDate ? moment(endDate) : endDate;
    maxDate = maxDate && moment(maxDate);
    minDate = minDate && moment(minDate);

    return (
      <DayPickerRangeController
        {...this.props}
        enableOutsideDays={false}
        startDate={startDate}
        initialVisibleMonth={this.getInitialMonth}
        endDate={endDate}
        minDate={minDate}
        maxDate={maxDate}
        isOutsideRange={(day) =>
          this.isBetweenEventDates(minDate, day, maxDate)
        }
      />
    );
  }
}
