import React from 'react';
import PropTypes from 'prop-types';
import { useGetText } from 'shared/hooks';

import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
} from '@material-ui/core';

import { Button } from 'shared/components/form';

export function DialogModal(
  {
    title,
    modalText,
    handleClose,
    children,
    open,
    actions,
    dividers = false,
    fullWidth = false,
    maxWidth = false,
    bodyPadding = true,
  },
  rest
) {
  const text = useGetText();
  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      {...rest}
    >
      {title !== undefined && (
        <DialogTitle disableTypography id="form-dialog-title">
          {title}
        </DialogTitle>
      )}
      {bodyPadding || modalText ? (
        <DialogContent dividers={dividers}>
          {modalText && <DialogContentText>{modalText}</DialogContentText>}
          {children}
        </DialogContent>
      ) : (
        children
      )}
      {handleClose && (
        <DialogActions>
          <Button
            type="button"
            className="button-size-small button-style-default button"
            onClick={handleClose}
          >
            {text.getText('actions.cancel')}
          </Button>
          {actions && actions}
        </DialogActions>
      )}
    </Dialog>
  );
}

DialogModal.propTypes = {
  //Optional
  title: PropTypes.string,
  bodyText: PropTypes.string,
  handleClose: PropTypes.func,
  modalText: PropTypes.object,

  //Required
  open: PropTypes.bool.isRequired,
};
