import React from 'react';
import { inject, observer } from 'mobx-react';
import { GridList, GridTile } from 'material-ui/GridList';
import { compose } from 'recompose';

import { Button } from 'shared/components/form';
import { changeFormState } from 'spots/actions';
import { EMPTY_DEVICE } from 'spots/constants/devices';

import DevicesTable from 'spots/components/devices/devicesTable';

const enhance = compose(inject('text'), observer);

/**
 * Devices component class represents the SpotAdminForm devices section, which
 * controls all the behavior related to CRUD devices inside the form.
 * @class Devices
 * @extends {React.Component}
 */
export default enhance(
  class Devices extends React.Component {
    constructor(props) {
      super(props);
      this.removeDevice = this.removeDevice.bind(this);
      this.updateDeviceState = this.updateDeviceState.bind(this);
    }
    /**
     * Adds a new empty device in the form list.
     * @return {void}
     */
    createNewDevice() {
      const { field } = this.props;
      field.add(EMPTY_DEVICE);
      changeFormState(true);
      this.forceUpdate();
    }

    /**
     * Handles the click on the 'x' button to remove a device.
     * @param {object} device - Device field instance
     * @return {void}
     */
    removeDevice(e, device) {
      const { field, formSubmit } = this.props;
      field.del(e.path);
      changeFormState(true);
      formSubmit(e);
    }

    updateDeviceState() {
      this.forceUpdate();
      this.props.formSubmit();
    }
    /**
     * Renders the component view.
     * @return {React.Component}
     */
    render() {
      const { field, text, disabled } = this.props;
      const devices = field.fields.values();
      return (
        <React.Fragment>
          <div className="devices-table">
            <DevicesTable
              devices={devices}
              removeDevice={this.removeDevice}
              disabled={disabled}
              updateDeviceState={this.updateDeviceState}
            />
          </div>
          <GridList className="devices-end-row" cellHeight="auto" cols={2}>
            <GridTile>
              <Button
                disabled={disabled}
                type="button"
                size="small"
                styling="borderless"
                onClick={() => this.createNewDevice()}
                children={`+ ${text.get('forms.spot.addDevice')}`}
              />
            </GridTile>
          </GridList>
        </React.Fragment>
      );
    }
  }
);
