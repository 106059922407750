import React from 'react';
import { TableRow, TableRowColumn } from 'material-ui/Table';
import Chkbx from 'material-ui/Checkbox';

/**
 * ListItem class represents accounts list item
 * @class ListItem
 * @extends {React.Component}
 */
export default class ListItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: this.props.item.shouldInvite,
    };
  }

  onToggleCheckbox = (account) => {
    this.setState(
      {
        checked: !this.state.checked,
      },
      () => {
        this.props.onToggleCheckbox({
          ...account,
          shouldInvite: this.state.checked,
        });
      }
    );
  };

  /**
   * React lifecycle method - executed everytime the component is receiving new props
   * @param {object} nextProps - New props received
   * @param {object} nextContext - New context received
   * @return {void}
   */
  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.checkedAll !== this.props.checkedAll) {
      this.setState({
        checked: nextProps.checkedAll,
      });
    }
  }

  /**
   * Renders the component view.
   * @return {React.Component}
   */
  render() {
    const {
      index,
      item,
      item: { user },
    } = this.props;

    return (
      <TableRow key={index}>
        <TableRowColumn>
          <span>{item.companyName}</span>
        </TableRowColumn>
        <TableRowColumn>
          <span>{user.email}</span>
        </TableRowColumn>
        <TableRowColumn>
          <span>
            {!!user.spots &&
              user.spots.map((spot, index) => {
                return index + 1 !== user.spots.length ? (
                  <span>{spot}, </span>
                ) : (
                  <span>{spot}</span>
                );
              })}
          </span>
        </TableRowColumn>
        <TableRowColumn>
          {user.email && (
            <Chkbx
              checked={this.state.checked}
              onCheck={() => this.onToggleCheckbox(item)}
            />
          )}
        </TableRowColumn>
      </TableRow>
    );
  }
}
