import * as Service from 'shared/services';

/**
 * Takes the event's id as input and downloads it's report from the API.
 * @param {string} eventId - Event id
 * @return {Promise}
 */
export const downloadFile = (eventId, eventLabel) =>
  Service.downloadFile(eventId, eventLabel);

/**
 * Downloads xlsx template for importing acconts.
 * @return {Promise}
 */
export const downloadTemplate = () => Service.downloadTemplate();
