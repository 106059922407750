import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import get from 'lodash/get';

import intl from '../strings';

import { loadUser } from 'auth/actions';

const STRING_MAP_KEY = 'string_map';

export const useGetText = () => {
  const [isLoading, setIsLoading] = useState(true);
  //const { me } = useGetMe(); //this does not work properly we need a user context or another way to maintain the user state
  const me = loadUser();
  const defaultLanguage = 'nl';
  const { data: stringMap } = useQuery(STRING_MAP_KEY, async () => {
    return me ? await intl(me.language) : await intl(defaultLanguage);
  });

  /**
   *
   * @param {*string} path
   * @param {*object} interpolation
   *  key: the key in string you want to substitute
   *  value: the value you want to interpolate
   * @returns {*string} translation string
   */
  const getText = (path, interpolation) => {
    const string = get(stringMap, path, path);
    if (interpolation) {
      return Object.keys(interpolation).reduce((string, key) => {
        return string.replace(`{${key}}`, interpolation[key]);
      }, string);
    }
    return string;
  };

  useEffect(() => {
    stringMap !== undefined && setIsLoading(false);
  }, [stringMap]);

  return {
    isLoading,
    getText,
  };
};
