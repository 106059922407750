import React from 'react';
import { useGetText } from 'shared/hooks';
import ConnectionSizeDetail from 'shared/components/ConnectionSizeDetail';
import { Grid, Tooltip, Typography } from '@material-ui/core';
import {
  CONNECTIONS_ONE_PHASE,
  CONNECTIONS_THREE_PHASE,
} from '../../ConnectionSizeForm/constants';
import { Spinner } from 'shared/components';
import { useGetEvent } from 'events/hooks';
import { loadUser } from 'auth/actions';
import { useCallback } from 'react';

import './styles.less';

/**
 * ConnectionSizes component represents the content of
 * set connection connectionSizeType modal
 */
const ConnectionSizes = ({ handleClick, showPrice = true }) => {
  const { getText } = useGetText();
  const me = loadUser();

  const eventId = window.location.pathname.split('/')[2];
  const { loading, data: event } = useGetEvent(eventId);

  const processConnections = (allConnections, availableConnections) => {
    return availableConnections?.length
      ? allConnections
          .map((item) => {
            const connection = availableConnections.find(
              (connection) => connection.externalId === item.externalId
            );
            if (connection) return { ...connection, ...item };
            else return null;
          })
          .filter((item) => item !== undefined)
      : allConnections;
  };

  const canSeePrice = useCallback(() => {
    return (
      showPrice ||
      (event &&
        //only check for canSeeConnection prices flag when used without the showPrice flag
        //When this component is rendered from the spotAdmin page, we don't have the
        event.powerInquiryInvitations.find((inv) => inv.userId === me._id)
          .canSeeConnectionPrices)
    );
  }, [event]);

  const renderConnectionGrid = (allConnections, availableConnections) => {
    const connections = processConnections(
      allConnections,
      availableConnections
    );
    return connections.map((item, index) => {
      if (!item) return null;
      return (
        <Grid
          item
          container
          direction="row"
          key={index}
          className="connectionBlock"
        >
          <ConnectionSizeDetail
            item={item}
            key={index}
            handleClick={handleClick}
          />
          {canSeePrice() && item?.price && (
            <Tooltip title={getText('forms.connection.priceTooltip')}>
              <Typography className="price">
                {'€ ' + item.price.toFixed(2)}
              </Typography>
            </Tooltip>
          )}
        </Grid>
      );
    });
  };

  return (
    <div className="modalView">
      {loading ? (
        <Spinner />
      ) : (
        <div className="modalBody selectSizeModal">
          <div>
            <div className="columnHeader"> {getText('forms.spot.1phase')}</div>
            {renderConnectionGrid(
              CONNECTIONS_ONE_PHASE,
              event.availableConnections
            )}
          </div>
          <div>
            <div className="columnHeader"> {getText('forms.spot.3phase')}</div>
            {renderConnectionGrid(
              CONNECTIONS_THREE_PHASE,
              event.availableConnections
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ConnectionSizes;
