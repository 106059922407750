import React from 'react';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';

import { Spinner } from 'shared/components';
import { loadUsers } from 'users/actions';
import { loadCompanies } from 'companies/actions';
import { createPowerInquiry } from 'powerinquiries/actions';
import { loadEvents } from 'events/actions';
import { PowerInquiryForm } from 'powerinquiries/components/PowerInquiryForm';

const enhance = compose(
  inject('users', 'powerInquiries', 'text', 'events', 'users', 'companies'),
  observer
);

/**
 * PowerInquiryEditPage class represents the view which contains a form to add a new energy user
 * to the event.
 * @class PowerInquiryEditPage
 * @extends {React.Component}
 */
export const PowerInquiryPage = enhance(
  class extends React.Component {
    onSuccess = (form) => {
      const {
        params: { eventId },
      } = this.props;
      const powerInquiry = form.values();
      createPowerInquiry(eventId, powerInquiry);
    };

    componentDidMount() {
      const {
        events,
        params: { eventId },
      } = this.props;

      //load the events to the store if not already present
      if (!events.getEvent(eventId)) {
        loadEvents();
      }
      loadUsers();
      loadCompanies();
    }

    /**
     * Renders the component view.
     * @return {React.Component}
     */
    render() {
      const {
        users,
        companies,
        events,
        params: { eventId },
      } = this.props;
      const event = events.getEvent(eventId);
      return event ? (
        <div className="PowerInquiryEditPage">
          <PowerInquiryForm
            onSuccess={this.onSuccess}
            companies={companies}
            users={users}
            event={event}
            companiesLoading={companies.loading}
            usersLoading={users.loading}
          />
        </div>
      ) : (
        <Spinner />
      );
    }
  }
);
