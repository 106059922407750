import React from 'react';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';

import { Typography } from '@material-ui/core';
import { Button, Checkbox, TextInput } from 'shared/components/form';
import { formCreator } from 'shared/form';
import { goBack } from 'shared/actions';

import fieldsInfo from './fields';
import * as Constants from './constants';
import './styles.less';

const enhance = compose(inject('text', 'auth', 'companies'), observer);

/**
 * CompanyForm class representes the detailed company profile form component.
 * @class CompanyForm
 * @extends {React.Component}
 */
export const CompanyForm = enhance(
  class extends React.Component {
    // {object} state - Component state holds the form instance.
    state = { form: null };

    // {object} config - Form configuration getter
    get config() {
      const { onSuccess, onError, text } = this.props;
      const title = 'company';
      const fields = fieldsInfo(text, 'components.views.adminCompanies');
      return { title, fields, onSuccess, onError };
    }

    /**
     * React lifecycle method - executed everytime the component is about to be mounted in the view.
     * @return {void}
     */
    UNSAFE_componentWillMount() {
      const { values } = this.props;
      const form = formCreator(this.config);
      this.setState({ form });
      const { address, ...companyRest } = values || {}; // destructure address fields
      form.init({ ...Constants.EMPTY_FORM, ...companyRest, ...address }); // making sure everytime you select a new company, his data will appear
    }

    /**
     * Renders the component view.
     * @return {React.Component}
     */
    render() {
      const { text, auth, companies } = this.props;
      const { form } = this.state;
      return (
        <form className="CompanyForm" onSubmit={form.onSubmit}>
          <h3>{text.get('forms.company.companyInfo')}</h3>
          <div className="inputRow">
            <TextInput field={form.$('name')} type="text" />
          </div>
          <div className="inputRow">
            <TextInput field={form.$('phones')} type="text" />
            <TextInput field={form.$('line1')} type="text" />
          </div>
          <div className="inputRow">
            <TextInput field={form.$('city')} type="text" />
            <TextInput field={form.$('zipCode')} type="text" />
          </div>
          <div className="inputRow">
            <TextInput
              field={form.$('country')}
              type="text"
              fullWidth={false}
            />
            <Checkbox
              fullWidth
              field={form.$('canSeeApplianceTemplates')}
              label={
                <Typography>
                  {text.get(
                    'components.views.adminCompanies.canSeeApplianceTemplates'
                  )}
                </Typography>
              }
            />
          </div>
          <div className="buttonRow">
            <Button
              type="submit"
              styling="cta"
              onClick={form.onSubmit}
              disabled={!!auth.loading || !!companies.loading || form.hasError}
            >
              {text.get('actions.save')}
            </Button>
            <Button type="button" styling="alt2" onClick={goBack}>
              {text.get('actions.cancel')}
            </Button>
          </div>
        </form>
      );
    }
  }
);
