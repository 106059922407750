import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

function EditableField({ value, handleSubmit, style }) {
  const [fieldValue, setFieldValue] = useState(value);
  const [isFieldFocussed, setIsFieldFocussed] = useState(false);

  useEffect(() => {
    setFieldValue(value);
  }, [value]);

  const handleBlur = () => {
    setIsFieldFocussed(false);
    fieldValue === ''
      ? setFieldValue(value)
      : handleSubmit && handleSubmit(fieldValue);
  };
  const handleChangeField = (event) => {
    setFieldValue(event.target.value);
  };
  const handleClick = () => setIsFieldFocussed(true);
  const handleKeyPress = (event) => {
    if (event.keyCode === 13) handleBlur();
  };

  const cursorStyle = {
    cursor: 'text',
  };

  return (
    <>
      {isFieldFocussed ? (
        <TextField
          autoFocus
          required
          value={fieldValue}
          onChange={(event) => handleChangeField(event)}
          onBlur={handleBlur}
          onKeyDown={(event) => handleKeyPress(event)}
        />
      ) : (
        <Typography
          onClick={handleClick}
          style={{ ...style, ...cursorStyle }}
          variant={'inherit'}
        >
          {fieldValue}
        </Typography>
      )}
    </>
  );
}

export default EditableField;

EditableField.propTypes = {
  value: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func,
  style: PropTypes.object, // Style object
};
