import React from 'react';
import { TextInput } from 'shared/components/form/textInput';
import { changeFormState } from 'spots/actions';
import ConnectionSizes from './ConnectionSizes';
import { DialogModal } from 'shared/components/';

import './styles.less';
import { useState } from 'react';
import { useGetText } from 'shared/hooks';

/**
 * SelectSizeInput Component represents the select input with popover modal for updating a connection
 * @extends {React.Component}
 */
const SelectSizeInput = ({ field, disabled, formSubmit }) => {
  const [selectedItem, setSelectedItem] = useState(field.value);
  const [modalOpen, setModalOpen] = useState(false);
  const { getText } = useGetText();
  const toggleModal = () => setModalOpen((prevState) => !prevState);

  /**
   * Select the given item in the internal state.
   * @param {object} item - Selected item
   * @return {void}
   */
  const selectItem = (e, item) => {
    changeFormState(selectedItem !== item); //Set the form state to dirty / unsaved when item is updated
    field.set('value', item);
    formSubmit && formSubmit(e); //this is done to update the state of the parent form (spotAdminForm) before the form is submitted
    setSelectedItem(item);
    toggleModal();
  };

  return (
    <div>
      <TextInput
        field={field.$('externalId')}
        onClick={toggleModal}
        disabled={disabled}
      />
      <DialogModal
        open={modalOpen}
        bodyPadding={false}
        handleClose={toggleModal}
        title={getText('forms.spot.selectSize')}
      >
        <ConnectionSizes
          hideModal={toggleModal}
          handleClick={(e, item) => selectItem(e, item)}
        />
      </DialogModal>
    </div>
  );
};

export default SelectSizeInput;
