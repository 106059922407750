import React from 'react';
import { string, bool } from 'prop-types';
import { useController } from 'react-hook-form';
import { Checkbox } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export function CheckboxInput({
  control,
  name,
  label,
  readOnly = false,
  gridSize = 6,
  ...rest
}) {
  const {
    field: { ref, value, ...inputProps },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  return (
    <FormControlLabel
      control={
        <Checkbox
          {...inputProps}
          checked={value}
          key={name}
          error={error}
          control={control}
          id={name}
          disabled={readOnly}
          {...rest}
        />
      }
      label={label}
      key={label}
    />
  );
}

CheckboxInput.propTypes = {
  name: string.isRequired,
  label: string,
  langPath: string,
  disabled: bool,
};
