import { action, extendObservable } from 'mobx';

/**
 * EventsStore is the Mobx global data storage for the events data.
 * @class EventsStore
 */
function EventsStore() {
  extendObservable(this, {
    list: [],
    loading: false,
    getEvent(eventId) {
      const tmp = this.list.find(
        ({ event }) => event._id === parseInt(eventId, 10)
      );
      return (tmp && tmp.event) || null;
    },
    registerEvents: action((events) => {
      this.list = events;
    }),
    isLoading: action((value) => {
      this.loading = Boolean(value);
    }),
    removeEvent: action((eventId) => {
      const index = this.list.findIndex(
        ({ event }) => event._id === parseInt(eventId, 10)
      );
      this.list.splice(index, 1);
    }),
  });
}

export const events = new EventsStore();
