import React from 'react';

import { pushRoute, goBack } from 'shared/actions';
import { Box } from 'shared/components';
import { Button } from 'shared/components/form';
import { useGetText } from 'shared/hooks';

import { EventForm } from '../components';
import { useGetEvent, useUpdateEvent } from '../hooks';

/**
 * EditEvent component represents the view with the event modification form.
 * @returns {React.Component}
 */
export function EditEventPage({ params }) {
  const { eventId } = params;
  const { updateEvent } = useUpdateEvent();
  const { data: event } = useGetEvent(eventId);
  const text = useGetText();

  const handleSubmit = async (values) => {
    const { data } = await updateEvent(values);
    data && pushRoute(`/events/`);
  };

  return (
    <div>
      <Button className="actionButton" onClick={goBack}>
        {text.getText('actions.back')}
      </Button>

      <Box
        className="boxVisible"
        title={text.getText('components.views.editEvent.listHeaderTitle')}
      >
        {event && <EventForm handleSubmit={handleSubmit} event={event} />}
      </Box>
    </div>
  );
}
