export const queryKeys = (key) => ({
  all: () => [key],
  lists: () => [key, 'list'],
  list: (filter) => [key, 'list', filter],
  details: () => [key, 'detail'],
  detail: (eventId) => [key, 'id', eventId],
});

export const spotsKey = 'spots';
export const spotKeys = queryKeys(spotsKey);

export const eventKey = 'events';
export const eventKeys = queryKeys(eventKey);
