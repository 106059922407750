import spotMapper from 'spots/mapper';

/**
 * Maps the energy user fields to guarantee consistency of the fields within the system.
 * @param {object} powerInquiry - PowerInquiry instance
 * @return {object}
 */
export const powerInquiryCreateInvitationMapper = (eventId, powerInquiry) => {
  let {
    label,
    owner,
    userId,
    companyId,
    canSeeConnectionPrices,
    spots,
    email,
  } = powerInquiry;
  spots = spots ? (spots = spots.map(spotMapper(eventId))) : null;

  const payload = {
    label: label || null,
    owner: owner || null,
    userId: userId || null,
    companyId: companyId || null,
    email: email || {},
    spots: spots || [],
    canSeeConnectionPrices: Boolean(canSeeConnectionPrices) || false,
  };
  return payload;
};

/**
 * Maps the energy user fields to guarantee consistency of the fields within the system.
 * @param {object} powerInquiry - PowerInquiry instance
 * @return {object}
 */
export const powerInquiryUpdateInvitationMapper = (powerInquiry) => {
  const {
    _id,
    uri,
    createdAt,
    updatedAt,
    eventId,
    label,
    owner,
    userId,
    companyId,
    canSeeConnectionPrices,
  } = powerInquiry;
  const payload = {
    _id,
    uri,
    createdAt,
    updatedAt,
    eventId,
    label,
    owner: owner || null,
    userId: userId || null,
    companyId: companyId || null,
    canSeeConnectionPrices: Boolean(canSeeConnectionPrices) || false,
  };
  return payload;
};

export function uploadMapper(data) {
  if (!data.validations) data.validations = { fields: {}, headers: {} };
  return data;
}

export const powerInquiryRemindInvitationMapper = (powerInquiry) => {
  const { btnText, emailTitle, message, addBcc, bccs } = powerInquiry;
  const payload = {
    btnText,
    subject: emailTitle,
    message,
    addBcc: addBcc || true,
    bccs: bccs || [],
  };
  return payload;
};
