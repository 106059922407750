import React from 'react';
import { inject, observer } from 'mobx-react';
import debounce from 'lodash/debounce';
import { ImageList, ImageListItem } from '@material-ui/core/';
import { withRouter } from 'react-router';
import { compose } from 'recompose';

import { formCreator } from 'shared/form';
import { SelectInput, TextInput } from 'shared/components/form';
import { getEventUserSpots } from 'spots/actions';
import { loadEvents } from 'events/actions';
import { goBack } from 'shared/actions';
import { Checkbox } from 'shared/components/form';
import { Modal } from 'shared/components';
import { HistoricEvents } from 'events/components';

import Connections from './connections';
import { Spots } from 'spots/constants';
import lbls from './labels';
import rules from './rules';
import fields from './fields';
import validators from './validators';
import './styles.less';
import HistoricSpots from '../historicSpots';

const enhance = compose(inject('events', 'text'), observer, withRouter);

/**
 * SpotAdminForm class represents the form where the admin or event creator are able
 * to modify the spot.
 * @class SpotAdminForm
 * @extends {React.Component}
 */
export const SpotAdminForm = enhance(
  class extends React.Component {
    // {object} state - Component state holds the form instance.
    constructor(props) {
      super(props);
      this.state = {
        form: null,
        parents: [],
        visible: false,
        hasConnections: false,
        showHistoricalSpots: false,
        historicSpots: [],
      };
      this.handleSubmitForm = this.handleSubmitForm.bind(this);
    }

    // {object} config - Form configuration getter
    get config() {
      const { onSuccess, onError, text } = this.props;
      const title = 'spotAdminForm';
      const labels = lbls(text.get('forms.spot'));
      return { title, fields, rules, labels, validators, onSuccess, onError };
    }

    // {object} empty - Empty form object
    get empty() {
      return {
        label: '',
        tags: '',
        coordinate: '',
        cluster: '',
        estimated: false,
        historicItem: false,
        layout: { details: '', comment: '', connections: [] },
      };
    }

    /**
     * React lifecycle method - executed everytime the component is mounted in the view.
     * @return {void}
     */
    UNSAFE_componentWillMount() {
      const { values } = this.props;
      if (!values) return goBack();
      this.initForm(values);
      loadEvents();
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
      let { values } = nextProps;
      if (!values) return goBack();

      if (values !== this.props.values) {
        this.initForm(values);
      }
    }

    initForm = (values) => {
      const form = formCreator(this.config);
      this.setState({
        form,
        hasConnections:
          values.layout &&
          values.layout.connections &&
          values.layout.connections.length > 0,
      });
      form.init({ ...this.empty, ...values }); // making sure everytime you select a new spot, it's data will appear
    };

    onClickEvent = (eventId) => {
      const {
        values: { userId },
      } = this.props;

      getEventUserSpots(eventId, userId).then((historicSpots) =>
        this.setState(
          {
            historicSpots,
          },
          () => this.showModal()
        )
      );
    };

    showModal = (event) => {
      !!event && event.preventDefault();
      this.modal.toggleModal(true);
    };

    hideModal = (event) => {
      !!event && event.preventDefault();
      this.modal.toggleModal(false);
    };

    fillSpotData = (spot) => {
      const { form } = this.state;
      this.modal.toggleModal(false);
      form.$('historicItem').set(true);
      spot.layout.connections.forEach((connection) =>
        form
          .$('layout')
          .$('connections')
          .add({
            ...connection,
            //Do not copy and properties that are not relevant for the current spot
            startDate: null,
            endDate: null,
            nightPower: false,
            upsNeeded: false,
            hasArrived: false,
            isConnected: false,
          })
      );
      this.setState({ hasConnections: true, showHistoricalSpots: false });
      this.handleSubmitForm();
    };

    onSelectFromHistoryPress = () => {
      this.setState({
        showHistoricalSpots: true,
      });
    };

    handleSubmitForm = debounce(() => {
      this.props.onSuccess(this.state.form);
    }, 1000);

    /**
     * Renders the component view.
     * @return {React.Component}
     */
    render() {
      const {
        events,
        userId,
        isApproved,
        params: { eventId },
      } = this.props;
      const {
        form,
        visible,
        historicSpots,
        hasConnections,
        showHistoricalSpots,
      } = this.state;
      if (!form) return null;

      const eventName = !events.loading && events.getEvent(eventId).festival;

      return (
        <div
          ref="form"
          className="SpotAdminForm"
          onChange={this.handleSubmitForm}
        >
          <ImageList rowHeight="auto" cols={2}>
            <ImageListItem>
              <TextInput
                field={form.$('label')}
                type="text"
                disabled={isApproved}
              />
            </ImageListItem>
            <ImageListItem>
              <SelectInput
                style={{ width: '100%' }}
                field={form.$('cluster')}
                options={Spots.CLUSTER_OPTIONS}
                onChange={this.handleSubmitForm}
              />
            </ImageListItem>
            <ImageListItem>
              <TextInput
                field={form.$('coordinate')}
                maxLength={6}
                type="text"
                disabled={isApproved}
              />
            </ImageListItem>
            <ImageListItem>
              <TextInput
                field={form.$('layout').$('comment')}
                disabled={isApproved}
              />
            </ImageListItem>
          </ImageList>

          <Checkbox field={form.$('estimated')} disabled={isApproved} />
          {!hasConnections && showHistoricalSpots && (
            <HistoricEvents
              onClickEvent={this.onClickEvent}
              eventId={eventId}
              userId={userId}
            />
          )}

          <Connections
            connections={form.$('layout').$('connections')}
            formSubmit={this.handleSubmitForm}
            eventId={eventId}
            spot={this.props.values}
            showSelectFromHistory={!hasConnections}
            onSelectFromHistoryPress={this.onSelectFromHistoryPress}
            disabled={isApproved}
          />

          <Modal
            visible={visible}
            ref={(ref) => {
              this.modal = ref;
            }}
          >
            <HistoricSpots
              onClickSpot={this.fillSpotData}
              spots={historicSpots}
              hideModal={this.hideModal}
              festivalName={eventName}
            />
          </Modal>
        </div>
      );
    }
  }
);
