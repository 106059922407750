import { useQuery } from 'react-query';

import { api } from 'shared/utils';

import { getApplianceMapper } from '../mapper';
import { APPLIANCES_QUERY_KEY } from 'appliances/constants';

const doGetAppliance = async (id) => {
  const { data } = await api.get(`/appliancetemplates/${id}`);
  return getApplianceMapper(data);
};

export const useGetAppliance = (id) => {
  const { data, status, ...others } = useQuery(
    [APPLIANCES_QUERY_KEY, 'id', id],
    () => doGetAppliance(id)
  );

  return {
    ...others,
    loading: status === 'loading',
    data,
  };
};
