export default {
  inputStyle: {
    marginTop: 0,
  },
  floatingLabelStyle: {
    top: 24,
  },
  underlineStyle: {
    bottom: 22,
  },
  hintStyle: {
    bottom: 22,
  },
  errorStyle: {
    bottom: 20,
  },
};
