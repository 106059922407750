import React from 'react';
import { inject, observer } from 'mobx-react';
import Chkbx from 'material-ui/Checkbox';
import { compose } from 'recompose';

import { Icon } from 'shared/components';

import './styles.less';

const enhance = compose(inject('text'), observer);

/**
 * ListItem class represents an item containing an inquiry invitation in the inquiries
 * new inquiry invites list.
 * @class ListItem
 * @extends {React.Component}
 */
export const ListItem = enhance(
  class ListItem extends React.Component {
    state = {
      checked: true,
    };

    onToggleCheckbox = (inquiry) => {
      this.setState(
        {
          checked: !this.state.checked,
        },
        () =>
          this.props.onToggleCheckbox({
            ...inquiry,
            checked: this.state.checked,
            isReminder: this.state.checked,
          })
      );
    };

    /**
     * React lifecycle method - executed everytime the component is receiving new props
     * @param {object} nextProps - New props received
     * @param {object} nextContext - New context received
     * @return {void}
     */
    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
      if (nextProps.checkedAll !== this.props.checkedAll) {
        this.setState({
          checked: nextProps.checkedAll,
        });
      }
    }

    /**
     * Renders the component view.
     * @return {React.Component}
     */
    render() {
      const { inquiry, showCheckBox } = this.props;
      return (
        <div className="PowerInquiryInviteListItem clickable">
          <div className="column">
            {inquiry.invitationSent ? (
              <Icon
                name="person"
                className={`icon ${inquiry.accountReady ? 'active' : ''}`}
              />
            ) : (
              <Icon name="person" className="icon transparent" />
            )}
            <div className="content">
              <span className="title" children={inquiry.name} />
              <span className="subtitle" children={inquiry.companyName} />
            </div>
          </div>
          <div className="column">
            <p>{inquiry.spots.map((x) => x.label || x).join(' • ')}</p>
          </div>
          <div className="column">
            {showCheckBox && (
              <Chkbx
                checked={this.state.checked}
                onCheck={() => this.onToggleCheckbox(inquiry)}
              />
            )}
          </div>
        </div>
      );
    }
  }
);
