import { 
  ensureDate,
  ensureString,
} from 'shared/utils';


/**
 * Fills the URI field.
 * @param {object} area - Area object
 * @param {string} eventId - Event id
 * @return {string}
 */
const uriMapper = (area, eventId) =>
  area.uri || `/events/${eventId}/areas/${area._id}`;

/**
 * Maps the area fields to guarantee consistency of the fields within the system.
 * @param {string} eventId - Event id
 * @return {function} (area) => {object}
 */
export default (eventId) => (area) => {
  const { _id } = area;
  const uri = uriMapper(area, eventId);
  const tag = ensureString(area.tag);
  eventId = parseInt(eventId, 10);
  const associatedResource = ensureString(area.associatedResource);
  const createdAt = ensureDate(area.createdAt);
  const updatedAt = ensureDate(area.updatedAt);
  const cableSize = ensureString(area.cableSize);
  const meterId = ensureString(area.meterId); 
  return {
    _id,
    uri,
    tag,
    associatedResource,
    eventId,
    createdAt,  
    updatedAt,
    cableSize,
    meterId,
  };
};
