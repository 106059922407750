import React from 'react';
import { observer, inject } from 'mobx-react';
import DatePicker, { SelectTypes } from '../datePicker'; // Reference: https://github.com/RafalFilipek/react-dates-range-picker
import moment from 'moment';
import 'react-dates/lib/css/_datepicker.css';
import { compose } from 'recompose';

import { isValidDate } from 'shared/utils';
import { Icon, Modal } from 'shared/components';
import { Button } from 'shared/components/form';

import './styles.less';

const enhance = compose(inject('text'), observer);

/**
 * DateRange Component class represents the text associated to the calendar modal
 * used to select a date interval.
 * @class DateRange
 * @extends {React.Component}
 */
export const DateRange = enhance(
  class extends React.Component {
    // {object} state - Component state holds information about the selected dates.
    state = {
      startDate: '',
      endDate: '',
      focusedInput: 'startDate',
      firstRun: false,
    };

    // {boolean} hasError - tells if the field contains errors.
    get hasError() {
      const { field } = this.props;
      return (
        (field.$('startDate').hasError || field.$('endDate').hasError) &&
        !this.state.firstRun
      );
    }

    /**
     * Selects the initial visible month on the calendar.
     * @return {number}
     */
    get visibleMonth() {
      const { minDate /*maxDate*/ } = this.props;
      if (!isValidDate(minDate)) return 0;
      return minDate instanceof moment
        ? minDate.diff(moment(), 'months', true)
        : moment(minDate).diff(moment(), 'months', true);
    }

    /**
     * React lifecycle method - executed everytime the component is mounted in the view.
     * @return {void}
     */
    componentDidMount() {
      const { field } = this.props;

      let startDate = field.$('startDate').value || this.props.startDate;
      let endDate = field.$('endDate').value || this.props.endDate;

      this.setState({
        startDate,
        endDate,
      });
    }

    componentDidUpdate(prevProps) {
      if (prevProps !== this.props) {
        const { field } = this.props;

        let startDate = field.$('startDate').value || this.props.startDate;

        let endDate = field.$('endDate').value || this.props.endDate;

        this.setState({
          startDate,
          endDate,
        });
      }
    }

    /**
     * Handles the click event on the text to pop up the modal with the calendar.
     * @return {void}
     */
    onClickSelectDates() {
      this.modal.toggleModal(true);
    }

    /**
     * Updates the state everytime a date is selected.
     * @param {object} obj - Action object with 'type' and 'value' attributes.
     * @return {void}
     */
    handleDateSelect({ type, value }) {
      const key = type === SelectTypes.START ? 'startDate' : 'endDate';
      const newState = { ...this.state };
      newState[key] = value;
      this.setState(newState);
    }

    /**
     * Closes the modal with the calendar.
     * @return {void}
     */
    closeModal(e, firstRun = false) {
      const { field, submitForm } = this.props;
      const { startDate } = this.state;
      let { endDate } = this.state;
      if (startDate && !endDate) endDate = startDate;
      field.$('startDate').value = startDate
        ? new Date(startDate).toISOString()
        : '';
      field.$('startDate').validate();
      field.$('endDate').value = endDate ? new Date(endDate).toISOString() : '';
      field.$('endDate').validate();
      this.modal.toggleModal(false);
      this.setState({ firstRun }, () => {
        if (!!submitForm) {
          submitForm(e);
        }
      });
    }

    /**
     * Prepares the dates to be presented as text in the view.
     *
     * @param {string} label (Optional) - Text to appear when no date is selected
     * @return {string}
     */
    renderDate(label = '') {
      const { startDate, endDate } = this.state;
      if (!startDate && !endDate) return label;
      //else if ((startDate && !endDate) || startDate.isSame(endDate))
      else if (startDate && !endDate) moment(startDate).format('DD/MM/YYYY');
      return `${moment(startDate).format('DD/MM/YYYY')} → ${moment(
        endDate
      ).format('DD/MM/YYYY')}`;
    }

    /**
     * Handles the date changing action.
     * @param {object} obj - Object containing 'startDate' and 'endDate' keys
     * @return {void}
     */
    onDatesChange = ({ startDate, endDate }) => {
      this.setState({ startDate, endDate });
    };

    /**
     * Forces the focusedInput to always be truthy so that dates are always selectable
     * @param {string} obj - String containing value of forcedInput
     * @return {void}
     */
    onFocusChange = (focusedInput) => {
      this.setState({
        focusedInput: !focusedInput ? 'startDate' : focusedInput,
      });
    };

    /**
     * Renders the component view.
     * @return {React.Component}
     */
    render() {
      const { label, text, minDate, maxDate, disabled } = this.props;
      let { startDate, endDate, focusedInput } = this.state;

      return (
        <div className="DateRange">
          <span
            onClick={() => !disabled && this.onClickSelectDates()}
            className={`link ${this.hasError && 'error'} ${
              disabled ? 'disabled' : ''
            }`}
          >
            <span>{this.renderDate(label)}</span>
            <Icon name="calendar" className="icon" />
          </span>
          <Modal
            ref={(ref) => {
              this.modal = ref;
            }}
          >
            <div className="column">
              <DatePicker
                onDatesChange={this.onDatesChange}
                onFocusChange={this.onFocusChange}
                focusedInput={focusedInput}
                startDate={moment(startDate)}
                endDate={moment(endDate)}
                minDate={minDate}
                maxDate={maxDate}
                minimumNights={0}
              />
              <div className="buttonRow">
                <Button
                  styling="cta"
                  className="button"
                  onClick={(e) => this.closeModal(e)}
                >
                  {text.get('actions.ok')}
                </Button>
              </div>
            </div>
          </Modal>
        </div>
      );
    }
  }
);
