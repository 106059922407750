import { AREA_QUERY_KEY } from 'areas/constants';
import { useCallback } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import { api } from 'shared/utils';
import areaMapper from '../mapper';
import { loadAreas } from 'areas/actions';

const doCreateArea = async ({ area, eventId }) => {
  return await api.post(`/events/${eventId}/areas`, areaMapper(eventId)(area));
};

export const useCreateArea = () => {
  const queryClient = useQueryClient();
  const { mutate, status, ...others } = useMutation(doCreateArea, {
    onSuccess: (response, variables) => {
      // update the cache after creating
      // TODO: This only works once loadAreas is used,
      // so check when powerPlanTree is rewritten
      // Make sure new area will be in the list of areas
      queryClient.invalidateQueries([
        AREA_QUERY_KEY,
        'eventId',
        variables.eventId,
      ]);

      // TODO: remove when no mobx anymore
      loadAreas(variables.eventId);
      return response;
    },
  });

  const createArea = useCallback(
    ({ area, eventId }) => mutate({ area, eventId }),
    [mutate]
  );

  return {
    ...others,
    loading: status === 'loading',
    createArea,
  };
};
