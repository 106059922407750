import React from 'react';

/**
 * Users class represents the base view with all the common elements
 * for all the user management views.
 * @class Companiesbase
 * @extends {React.Component}
 */
export function CompaniesBase({ children }) {
  return (
    <div className="padding">
      <div>{children}</div>
    </div>
  );
}
