import React, { forwardRef } from 'react';
import { Check, DeleteOutline } from '@material-ui/icons';

import { Tooltip } from '@material-ui/core';

import { Spinner, ReportTable } from 'shared/components';
import { Button } from 'shared/components/form';
import { pushRoute, confirm as confirmAction } from 'shared/actions';
import { useGetText } from 'shared/hooks';

import { useGetCompanies, useRemoveCompany } from 'companies/hooks';

import './styles.less';

export function CompaniesOverviewPage() {
  const text = useGetText();
  const { data: companies, loading, error } = useGetCompanies();
  const { removeCompany } = useRemoveCompany();
  const title = text.getText('components.views.adminCompanies.pageTitle');
  const tableCompanies = companies.map((company) => ({
    name: company.name,
    ...company.address,
    phones: company.phones,
    updatedAt: company.updatedAt,
    canSeeApplianceTemplates: company.canSeeApplianceTemplates,
    _id: company._id,
  }));

  function renderBoolField(rowData, field, icon) {
    const cellContent = rowData[field];
    const tooltip = text.getText(
      `components.views.adminCompanies.tooltip.${field}`
    );
    return (
      <Tooltip title={tooltip}>
        <div>{cellContent ? icon : ''}</div>
      </Tooltip>
    );
  }

  function handleClickEditCompany(event, rowData) {
    pushRoute(`/companies/${rowData._id}/edit`);
  }

  function handleClickAddCompany() {
    pushRoute(`/companies/add`);
  }

  function handleClickRemoveCompany(event, rowData) {
    confirmAction(text.getText('actions.confirmRemove'), () =>
      removeCompany(rowData._id)
    );
  }
  /**
   * Renders the component view.
   * @return {React.Component}
   */
  return (
    <div className="companiesOverview">
      <div className="buttonWrapper">
        <div className="buttonRow left">
          <Button onClick={handleClickAddCompany}>
            {text.getText('actions.addNewCompany')}
          </Button>
        </div>
      </div>
      {loading ? (
        <Spinner />
      ) : error ? (
        <span>{error.message}</span>
      ) : (
        <ReportTable
          title={title}
          data={tableCompanies}
          onRowClick={handleClickEditCompany}
          options={{
            pageSize: 20,
            exportFileName: title,
            padding: 'dense',
            actionsColumnIndex: 1000,
          }}
          text={text}
          textLocation={'components.views.adminCompanies'}
          customActions={[
            {
              icon: forwardRef((props, ref) => (
                <DeleteOutline {...props} ref={ref} />
              )),
              tooltip: text.getText('actions.remove'),
              onClick: handleClickRemoveCompany,
            },
          ]}
          customColumnSettings={{
            updatedAt: {
              hidden: true,
            },
            _id: {
              hidden: true,
            },
            canSeeApplianceTemplates: {
              render: (rowData) =>
                renderBoolField(
                  rowData,
                  'canSeeApplianceTemplates',
                  <Check color="secondary" />
                ),
            },
          }}
        />
      )}
    </div>
  );
}
