import download from 'react-file-download';
import { api } from 'shared/utils';

/**
 * Takes an event it as input and downloads a file related to this event from the backend.
 * @param {string} eventId - Event id
 * @return {Promise}
 */
export const downloadFile = (eventId, eventLabel) =>
  api
    .get(`/events/${eventId}/xlsx`, { responseType: 'blob' })
    .then(({ status, data, headers }) => {
      if (status === 200) download(data, `${eventLabel}.xlsx`, data.type);
    });

/**
 * Downloads xlsx template for importing acconts.
 * @return {Promise}
 */
export const downloadTemplate = () =>
  api
    .get(`/event/xlsx-template`, { responseType: 'blob' })
    .then(({ status, data, headers }) => {
      if (status === 200)
        download(data, headers['content-disposition'].split('=')[1], data.type);
    });
