export default (text) => ({
  password: {
    label: text.password,
    rules: 'required|string',
  },
  passwordRepeat: {
    label: text.passwordRepeat,
    rules: 'required|string|same:password',
  },
});
