import React from 'react';
import { Modal } from '../modal';
import './styles.less';
import { withState } from 'recompose';
import TextField from 'material-ui/TextField'; // Reference: http://www.material-ui.com/#/components/text-field

let ModalContents = ({
  filter,
  setFilter,
  filterInput,
  options,
  customClass,
  renderItem,
}) => {
  let filterOptions = options;
  if (filter) {
    filterOptions = filter(filterOptions, filterInput);
  }

  return (
    <div className={`${customClass} modalView`}>
      {filter && (
        <div
          style={{
            padding: '10px',
            textAlign: 'center',
          }}
        >
          <TextField
            value={filterInput}
            placeholder="filter options"
            onChange={(e) => setFilter(e.target.value)}
          />
        </div>
      )}
      <div className="modalBody">
        {!filterOptions.length > 0 && (
          <div
            style={{
              display: 'flex',
              padding: '5px',
              textAlign: 'center',
            }}
          >
            No results avaiable
          </div>
        )}
        {filterOptions.map((item, index) => renderItem(item, index))}
      </div>
    </div>
  );
};

ModalContents = withState('filterInput', 'setFilter', null)(ModalContents);

export class OptionsModal extends React.Component {
  showModal = () => {
    this.refs.modal.toggleModal(true);
  };

  onClick(item) {
    this.props.onOptionSelected(item);
    this.refs.modal.toggleModal(false);
  }

  renderItem = (item, index) => (
    <div
      className={`item ${!this.props.renderItem && 'defaultItem'}`}
      key={index}
      onClick={() => this.onClick(item)}
    >
      {this.props.renderItem ? this.props.renderItem(item) : item}
    </div>
  );

  render() {
    const { options, title, filter, customClass } = this.props;
    return (
      <div className="OptionsModal">
        <Modal ref="modal">
          <div className="modalView">
            {title && <div className="modalHeader">{title}</div>}
            <ModalContents
              customClass={customClass}
              renderItem={this.renderItem}
              options={options}
              filter={filter}
            />
          </div>
        </Modal>
      </div>
    );
  }
}
