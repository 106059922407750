import React from 'react';
import { observer } from 'mobx-react';
import MuiToggle from 'material-ui/Toggle'; // Reference: http://www.material-ui.com/#/components/toggle

/**
 * Toggle component
 * @param {object} props - React component properties
 * @return {React.Component}
 */
export const Toggle = observer(
  ({ hidden, ...props }) => !hidden && <MuiToggle {...props} />
);
