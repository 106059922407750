import React from 'react';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';

import { goBack, downloadFile } from 'shared/actions';
import { Spinner, Icon } from 'shared/components';
import { Button } from 'shared/components/form';
import { Roles } from 'shared/constants';

import { userHasPermission } from 'auth/actions';
import { loadSpots } from 'spots/actions';
import { loadAreas } from 'areas/actions';
import { loadUsers } from 'users/actions';
import { loadCompanies } from 'companies/actions';
import { loadPowerzones } from 'powerzones/actions';

import { EventDataTable } from '../../components';

import './styles.less';

const enhance = compose(
  inject(
    'areas',
    'events',
    'powerzones',
    'spots',
    'text',
    'users',
    'companies'
  ),
  observer
);

/**
 * PowerzoneOverviewTablesPage represents powerzones
 * tables overview.
 * @export
 * @class EventDataTablePage
 * @extends {React.Component}
 */
export const EventDataTablePage = enhance(
  class extends React.Component {
    componentDidMount() {
      const {
        params: { eventId },
      } = this.props;
      loadPowerzones(eventId);
      loadAreas(eventId);
      loadSpots(eventId);
      loadUsers();
      loadCompanies();
    }

    // {boolean} isLoading - Tells if the node are still loading or not.
    get isLoading() {
      const { areas, powerzones, spots, users, companies } = this.props;

      return (
        (powerzones.loading && !powerzones.list.length) ||
        (areas.loading && !areas.list.length) ||
        (spots.loading && !spots.list.length) ||
        (users.loading && !users.list.length) ||
        (companies.loading && !companies.list.length)
      );
    }

    /**
     * Renders the component view.
     * @return {React.Component}
     */
    render() {
      const {
        text,
        users,
        events,
        params: { eventId },
      } = this.props;
      const eventLabel =
        events.getEvent(eventId) && events.getEvent(eventId).festival;

      if (this.isLoading || users.list.length === 0) return <Spinner />;

      return (
        <div className="overview-tables-wrapper">
          <header className="print-header">
            <img
              src={`${window.location.origin}/assets/logos/logo-greener.svg`}
              alt="logo greener"
            />
          </header>
          <div className="buttonRow">
            {/* TO DO make nested class later */}
            <Button onClick={goBack} styling="text" size="small">
              {text.get('actions.back')}
            </Button>
            <Button onClick={() => window.print()} size="small" styling="cta">
              {text.get(
                'components.views.powerzones.summaryTable.generatePdfButton'
              )}
              <span>
                <Icon name="file-download" className="icon" />
              </span>
            </Button>
            {userHasPermission([Roles.ADMIN, Roles.POWER_SUPPLIER]) && (
              <Button
                onClick={() => downloadFile(eventId, eventLabel)}
                size="small"
                styling="text"
              >
                {text.get('components.views.event.export')}
                <span>
                  <Icon name="file-download" className="icon" />
                </span>
              </Button>
            )}
          </div>
          <div className="powerzones-tables">
            <div className="tables-container">
              <EventDataTable
                {...this.props}
                renderEvent={true}
                eventLabel={eventLabel}
              />
            </div>
          </div>
        </div>
      );
    }
  }
);
