import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import ErrorIcon from '@material-ui/icons/Error';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import './styles.less';
import { useGetText } from 'shared/hooks';
import { aproxCalculateKvAToW } from 'shared/utils';

export default function ConnectionPowerCheckIcon({
  connectionCap,
  ratedPower,
}) {
  const capInWatt = aproxCalculateKvAToW(connectionCap);
  const connectionMin = 10;
  const powerMightBeWrong = ratedPower <= connectionMin;
  const connectionCanBeSmaller = ratedPower < capInWatt * 0.5;
  const powerSeemsRight = ratedPower > connectionMin && ratedPower < capInWatt;
  const connectionOverLoaded = ratedPower > capInWatt;

  const { getText } = useGetText();

  return (
    <div className="powerIcons">
      {powerMightBeWrong && (
        <Tooltip
          title={getText('forms.connectionPowerCheck.powerMightBeWrong', {
            totalPower: ratedPower / 1000,
          })}
        >
          <ReportProblemOutlinedIcon color="secondary" />
        </Tooltip>
      )}
      {connectionCanBeSmaller && (
        <Tooltip
          title={getText('forms.connectionPowerCheck.connectionCanBeSmaller', {
            connectionCap: capInWatt,
            ratedPower,
          })}
        >
          <InfoOutlinedIcon color="secondary" />
        </Tooltip>
      )}
      {powerSeemsRight && (
        <Tooltip title={getText('forms.connectionPowerCheck.powerSeemsRight')}>
          <CheckOutlinedIcon color="primary" />
        </Tooltip>
      )}
      {connectionOverLoaded && (
        <Tooltip
          title={getText('forms.connectionPowerCheck.connectionOverLoaded', {
            connectionCap: capInWatt,
            ratedPower: ratedPower,
          })}
        >
          <ErrorIcon color="error" />
        </Tooltip>
      )}
    </div>
  );
}

ConnectionPowerCheckIcon.propTypes = {
  connectionCap: PropTypes.number,
  ratedPower: PropTypes.number,
};
