import React from 'react';
import {
  Tooltip,
  Table,
  TableBody,
  TableRow,
  TableCell as MuiTableCell,
  withStyles,
} from '@material-ui/core';

import { calculateTotalConnectionPowerFunctional } from 'spots/utils';
import { useGetText } from 'shared/hooks';
import DeviceRow from './deviceRow';

const TableCell = withStyles({
  root: {
    borderBottom: 'none',
  },
})(MuiTableCell);

const DevicesTable = ({
  devices,
  removeDevice,
  updateDeviceState,
  disabled,
}) => {
  const { getText } = useGetText();

  return (
    <Table>
      <TableBody size="dense">
        <TableRow className="header-row">
          <TableCell>{getText('forms.appliance.name')}</TableCell>
          <TableCell>{getText('forms.appliance.brand')}</TableCell>
          <TableCell>{getText('forms.appliance.modelNumber')}</TableCell>
          <TableCell>{getText('forms.spot.ratedPower')}</TableCell>
          <TableCell>{getText('forms.appliance.quantity')}</TableCell>
        </TableRow>
        {devices.map((device, index) => (
          <DeviceRow
            field={device}
            removeDevice={removeDevice}
            key={`DeviceRow.${index}`}
            disabled={disabled}
            updateDeviceState={updateDeviceState}
          />
        ))}
        <TableRow>
          <TableCell colSpan={5} align={'right'}>
            <Tooltip title={getText('forms.spot.connectionTooltip')}>
              <span>
                {getText('forms.spot.totalConnectionPower')}:{' '}
                {calculateTotalConnectionPowerFunctional(devices).toFixed(1)} kW
              </span>
            </Tooltip>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default DevicesTable;
