import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core/';

import { Icon } from 'shared/components';

import './styles.less';

/**
 * SpotList class represents an list of the spots assigned to an energy user with it's
 * acceptance status.
 * @class SpotList
 * @extends {React.Component}
 */
export const SpotList = (props) => {
  const { spots, className, removeAble, text } = props;
  function renderItem(item, index) {
    return (
      <Tooltip
        title={text.get(`forms.spot.tooltip.${itemCatagory(item)}`)}
        key={`${item.label}-${index}`}
      >
        <span
          className={`spot ${itemCatagory(item)} ${
            props.removeAble && 'editable'
          }`}
        >
          {item.label}
          {removeAble && (
            <Icon
              name="remove"
              className="button"
              onClick={(e) => {
                e.preventDefault();
                props.onRemove(item);
              }}
            >
              x
            </Icon>
          )}
          {removeAble && (
            <Icon
              name="edit"
              className="button edit"
              onClick={(e) => {
                e.preventDefault();
                props.onEdit(item);
              }}
            ></Icon>
          )}
        </span>
      </Tooltip>
    );
  }

  function renderSpots(spots, text) {
    return spots.length > 0 ? (
      spots.map(renderItem)
    ) : (
      <span className="placeholder">
        {text.get('forms.powerInquiries.AddASpot')}
      </span>
    );
  }

  function itemCatagory(item) {
    if (item.approved) return 'approved';
    if (item.estimated) return 'estimated';
    if (item.historicItem) return 'historicItem';
    if (item?.layout?.connections?.length > 0) {
      if (item.origin === 'energyUser') return 'energyUser';
      else if (item.origin === 'powerPlanner') return 'powerPlanner';
      else if (item.origin === 'imported') return 'imported';
      else return 'originUnknown';
    } else return 'noData';
  }

  /**
   * Renders the component view.
   * @return {React.Component}
   */

  return (
    <div
      className={`SpotList ${className || ''}`}
      placeholder="oh yeah"
      children={renderSpots(spots, text)}
    />
  );
};

SpotList.propTypes = {
  spots: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  text: PropTypes.object,
};
