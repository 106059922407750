export default [
  'name',
  'language',
  'email',
  'role',
  'phone',
  'phones[].type',
  'phones[].phoneNumber',
  'companyName',
  'companyId',
  'password',
  'repeatPassword',
  'address',
  'city',
  'zipCode',
  'country',
  'credits',
];
