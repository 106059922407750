import React from 'react';
import { useController } from 'react-hook-form';

import TextField from '@material-ui/core/TextField';
import { useGetText } from 'shared/hooks';

export function TextInput({
  control,
  name,
  type,
  fullWidth = true,
  readOnly = false,
  variant = 'standard',
  label,
  shrink = false,
  defaultValue = '',
  size = 'small',
  onBlurFn,
  ...rest
}) {
  const {
    field: { ref, onBlur, ...inputProps },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    defaultValue,
  });

  const text = useGetText();

  function handleOnBlur(e) {
    if (onBlurFn) onBlurFn(e);
    onBlur();
  }

  return (
    <TextField
      {...inputProps}
      inputRef={ref}
      onBlur={handleOnBlur}
      error={invalid}
      helperText={error && text.getText(error.message)}
      variant={variant}
      fullWidth={fullWidth}
      label={label}
      InputProps={{ readOnly }}
      InputLabelProps={{ shrink }}
      size={size}
      type={type}
      {...rest}
    />
  );
}
