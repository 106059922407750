import { useCallback } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import { notify } from 'shared/actions';
import { Notifications } from 'shared/constants';
import { api } from 'shared/utils';

import { APPLIANCES_QUERY_KEY } from '../constants';

const doRemoveAppliance = async (applianceId) => {
  const { data } = await api.delete(`/appliancetemplates/${applianceId}`);
  return data;
};
export const useRemoveAppliance = () => {
  const queryClient = useQueryClient();
  const { mutate, status, ...others } = useMutation(doRemoveAppliance, {
    onSuccess: (response, variables) => {
      // Delete cache
      queryClient.removeQueries([APPLIANCES_QUERY_KEY, 'id', variables], {
        exact: true,
      });

      // Make sure appliance is deleted from list cache
      queryClient.invalidateQueries([APPLIANCES_QUERY_KEY, 'list']);

      notify({
        message: `Successfully deleted appliance with id ${variables}`,
        level: Notifications.Type.SUCCESS,
      });
    },
    onError: (error) => {
      notify({
        message: error.message,
        level: Notifications.Type.ERROR,
      });
      return error;
    },
  });

  const removeAppliance = useCallback((appliances) => mutate(appliances), [
    mutate,
  ]);

  return {
    ...others,
    loading: status === 'loading',
    removeAppliance,
  };
};
