import { getNodeState } from 'shared/actions';
import { api } from 'shared/utils';
import { disassociateNodes } from 'powerzones/utils';

import { getPowerzoneMapper, putPowerzoneMapper } from './mapper';
import { powerzones as powerzonesStore } from './store';

/**
 * Takes an event id as input and downloads all the powerzones
 * in the current event from the API.
 * @param {string} eventId - Event id
 * @return {Promise}
 */
export const loadPowerzones = (eventId) =>
  Promise.resolve(powerzonesStore.isLoading(true))
    .then(() => powerzonesStore.registerPowerzones([]))
    .then(() => api.get(`/events/${eventId}/powerzones`))
    .then(({ status, data }) => {
      if (status === 200) {
        const powerzones = data.map(getPowerzoneMapper(eventId)).map((pz) => ({
          ...pz,
          expanded: getNodeState(pz),
        }));
        powerzonesStore.registerPowerzones(powerzones);
        return powerzones;
      }
    })
    .then((powerzones) => {
      powerzonesStore.isLoading(false);
      return powerzones;
    });

/**
 * Takes a powerzone data object and sends it to the API to create in the database.
 * @param {object} powerzone - Powerzone instance
 * @param {string} eventId - Event id
 * @return {Promise}
 */
export const createPowerzone = (powerzone, eventId) =>
  Promise.resolve(powerzonesStore.isLoading(true))
    .then(() => api.post(`/events/${eventId}/powerzones`, powerzone))
    .then(({ status, data }) => {
      if (status === 201) {
        const newPowerzone = getPowerzoneMapper(eventId)(data);
        powerzonesStore.registerNewPowerzone(newPowerzone);
      }
    })
    .then(() => powerzonesStore.isLoading(false));

/**
 * Takes a powerzone data object and sends it to the API to update in the database.
 * @param {object} powerzone - Powerzone instance
 * @return {Promise}
 */
export const updatePowerzone = (powerzone) =>
  Promise.resolve(powerzonesStore.isLoading(true))
    .then(() =>
      api.put(powerzone.uri, putPowerzoneMapper(powerzone.eventId)(powerzone))
    )
    .then(({ status, data }) => {
      if (status === 200) {
        const newPz = getPowerzoneMapper(data.eventId)(data);
        powerzonesStore.updatePowerzone(newPz);
      }
    })
    .then(() => powerzonesStore.isLoading(false));

/**
 * Takes a powerzone data object and send a request with it's ID to the API to remove it from the database.
 * @param {object} powerzone - Powerzone instance
 * @return {Promise}
 */
export const removePowerzone = (powerzone) =>
  Promise.resolve(powerzonesStore.isLoading(true))
    .then(() => api.delete(powerzone.uri))
    .then(({ status }) => {
      if (status === 200) {
        disassociateNodes(powerzone.children);
        powerzonesStore.removePowerzone(powerzone);
      }
    })
    .then(() => powerzonesStore.isLoading(false));
