import React from 'react';
import { observer } from 'mobx-react';
import Chkbx from 'material-ui/Checkbox';

/**
 * Checkbox component
 * @param {object} props - React component properties
 * @return {React.Component}
 */
export const Checkbox = observer(({ field, hidden, ...props }) => {
  const fld = field.bind ? field.bind({ type: 'checkbox' }) : field;
  return (
    !hidden && (
      <div
        children={
          <Chkbx
            {...fld}
            label={field.label}
            checked={field.value}
            onCheck={(e, checked) => (field.value = checked)}
            {...props}
          />
        }
      />
    )
  );
});
