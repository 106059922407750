import pick from 'lodash/pick';

import { save, load, remove } from 'shared/actions';

import * as Service from './service';

const BUFFER_KEY = 'das9v6dta9sodiuygabsdvuysatasuyxts76e256ewev267e4d16w1e8';

/**
 * Takes an spot or template instance as input and saves it to the buffer to continue editing later.
 * @param {void} spot - Spot instance
 * @return {Promise}
 */
export const saveSpotToBuffer = (spot) => save(BUFFER_KEY, spot, true);

/**
 * Recovers the spot instance from the buffer.
 * @return {Promise}
 */
export const recoverSpotFromBuffer = () => load(BUFFER_KEY, true);

/**
 * Removes the spot instance from the buffer.
 * @return {Promise}
 */
export const removeSpotFromBuffer = () => remove(BUFFER_KEY, true);

/**
 * Loads all the spots from the API to the application's store so it can be
 * accessible in all the application.
 * @return {Promise}
 */
export const loadSpots = (eventId, userId) =>
  Service.loadSpots(eventId, userId);

/**
 * The state of active form with unsaved data
 * @param {boolean} state - Spot instance
 * @return {void}
 */
export const changeFormState = (state) => Service.changeFormState(state);

/**
 * Loads event spots from a specific user the API to the application's store so it can be
 * accessible in all the application.
 * @return {Promise}
 */
export const getEventUserSpots = (eventId, userId) =>
  Service.getEventUserSpots(eventId, userId);

/**
 * Loads event spots from a specific user the API to the application's store so it can be
 * accessible in all the application.
 * @return {Promise}
 */
export const getCompanySpots = (companyId) =>
  Service.getCompanySpots(companyId);

/**
 * Takes an spot instance as input and sends it to the API so it can be saved.
 * @param {object} spot - Spot instance
 * @param {string} eventId - Event id
 * @return {Promise}
 */
export const createSpot = (spot, eventId) => Service.createSpot(spot, eventId);

/**
 * Takes an spot instance as input and sends it to the API so it can be updated.
 * @param {void} spot - Spot instance
 * @return {Promise}
 */
export const updateSpot = (spot) => Service.updateSpot(spot); //.then(removeSpotFromBuffer);

/**
 * Takes an spot instance as input and sends it's approval state to the API so it can be updated.
 * @param {void} spot - Spot instance
 * @return {Promise}
 */
export const updateSpotApproval = (spot) =>
  Promise.resolve(spot)
    .then((spot) => {
      if (spot.approved) {
        return Service.updateSpot(spot); //update te spot first when the spot is set to approved
      } else {
        return spot;
      }
    })
    .then((spot) => Service.updateSpotApproval(spot));

/**
 * Takes an spot instance as input and removes it from the spots store.
 * @param {void} spot - Spot instance
 * @return {Promise}
 */
export const removeSpot = (spot, powerInquiry) =>
  Service.removeSpot(spot, powerInquiry);

/**
 *  Clones a Spot - handles comments, connectivity and
 * @param {Object} node
 */
export const cloneSpot = (node, parent, user) => {
  const clonedSpot = pick(node, [
    'eventId',
    'associatedResource',
    'cluster',
    'lastUpdate',
    'subtitle',
    'userId',
    'layout.comment',
  ]);
  if (parent) clonedSpot.associatedResource = parent;
  if (user) clonedSpot.userId = user.userId;

  clonedSpot.label = `Copy of ${node.label}`;
  clonedSpot.layout.connections = node.layout.connections.map((con) => {
    const clonedConnection = pick(con, [
      'endDate',
      'name',
      'size',
      'nightPower',
      'upsNeeded',
      'startDate',
    ]);
    clonedConnection.devices = con.devices.map((device) =>
      pick(device, ['name', 'quantity', 'ratedPower', 'unit'])
    );
    return clonedConnection;
  });
  // CLone Comments and Connections & reload spots so there are shown
  createSpot(clonedSpot, clonedSpot.eventId).then(() =>
    loadSpots(clonedSpot.eventId)
  );
  // Connections and comments too?
};
