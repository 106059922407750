import { action, extendObservable } from 'mobx';

/**
 * PowerInquiryStore is the Mobx global data storage for the energy user data.
 * @class PowerInquiriesStore
 */
function PowerInquiriesStore() {
  extendObservable(this, {
    list: [],
    loading: false,
    getPowerInquiry(powerInquiryId) {
      return this.list.find((obj) => obj._id === parseInt(powerInquiryId));
    },
    registerPowerInquiries: action((powerInquiries) => {
      this.list = powerInquiries;
    }),
    isLoading: action((value) => {
      this.loading = Boolean(value);
    }),
    removePowerInquiry: action((powerInquiryId) => {
      const index = this.list.findIndex(
        (obj) => obj._id === parseInt(powerInquiryId, 10)
      );
      this.list.splice(index, 1);
    }),
  });
}

export const powerInquiries = new PowerInquiriesStore();
