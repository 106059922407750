import React from 'react';
import { Popper } from '@material-ui/core';

export default function WidePopper(props) {
  return (
    <Popper
      {...props}
      style={{
        maxWidth: 'fit-content',
      }}
      placement="bottom-start"
    />
  );
}
