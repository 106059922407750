export default {
  festival: 'required|string',
  visitors: '',
  type: '',
  buildUpDates: 'required',
  'buildUpDates[].startDate': 'required|date',
  'buildUpDates[].endDate': 'required|date',
  'buildUpDates[].startTime': '',
  'buildUpDates[].endTime': '',
  showDates: 'required',
  'showDates[].startDate': 'required|date',
  'showDates[].endDate': 'required|date',
  'showDates[].startTime': '',
  'showDates[].endTime': '',
  breakDownDates: 'required',
  'breakDownDates[].startDate': 'required|date',
  'breakDownDates[].endDate': 'required',
  'breakDownDates[].startTime': '',
  'breakDownDates[].endTime': '',
  editors: '',
  eventCompanies: '',
  'eventCompanies[].name': 'string',
  'eventCompanies[]._id': 'required|numeric',
  'eventCompanies[].type': 'string',
};
