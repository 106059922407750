import { useQuery } from 'react-query';

import { api } from 'shared/utils';

import { getEventMapper } from '../mapper';
import { eventKeys } from 'shared/utils/api/queryKeys';

export const loadEvent = (id) => {
  return api
    .get(`/events/${id}`)
    .then(({ status, data }) => (status === 200 ? getEventMapper(data) : []));
};

export const useGetEvent = (id) => {
  const { data, status, ...others } = useQuery(eventKeys.detail(id), () =>
    loadEvent(id)
  );

  return {
    ...others,
    loading: status === 'loading',
    data,
  };
};
