import React from 'react';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';

import { SelectInput } from 'shared/components/form';
import { Spinner } from 'shared/components';
import { pushRoute, confirm } from 'shared/actions';
import { isSpotFilled } from 'spots/utils';
import { saveSpotToBuffer, changeFormState } from 'spots/actions';

import './styles.less';

const enhance = compose(inject('spots', 'text'), observer);

/**
 * EventSpotsMobile class represents the mobile event spots management view.
 * @class EventSpotsMobile
 * @extends {React.Component}
 */
export default enhance(
  class EventSpotsMobile extends React.Component {
    // {object} state - State containing information about the selected spot.
    state = { spot: null };

    // {object[]} options - Select input options.
    get options() {
      const { spots } = this.props;
      return spots.list.map(({ _id, label }) => ({ label, value: _id }));
    }

    /**
     * React lifecycle method - executed everytime the component is about to be mounted in the view.
     * @return {void}
     */
    componentDidMount() {
      if (this.props.spotId) this.changeSpot(this.props.spotId);
    }

    /**
     * Handler used to select the spot from the mobile view select input.
     * @param {number} spotId - Spot id
     * @return {void}
     */
    changeSpot(spotId) {
      const { spots, text, onChangeModifySpot } = this.props;
      const spot = spots.getSpotById(spotId);
      if (spot === undefined) return true;
      if (!isSpotFilled(spot)) {
        onChangeModifySpot(null);
        pushRoute(`/event/${spot.eventId}/spots`);
      } else {
        if (spots.isUnsavedData) {
          confirm(text.get('actions.confirmLeaveUnsaved'), () => {
            saveSpotToBuffer(spot);
            changeFormState(false);
            pushRoute(`/event/${spot.eventId}/spots/${spot._id}/connections`);
          });
        } else {
          saveSpotToBuffer(spot);
          pushRoute(`/event/${spot.eventId}/spots/${spot._id}/connections`);
        }
      }
      this.setState({ spot });
    }

    /**
     * Renders the component view.
     * @return {React.Component}
     */
    render() {
      const { renderDetailsView, spots, text } = this.props;
      const { spot } = this.state;
      if (spots.loading) return <Spinner />;
      return (
        <div className="EventSpotsMobile">
          <SelectInput
            value={spot ? spot._id : null}
            options={this.options}
            className="selectField"
            label={text.get('forms.spot.spot')}
            onChange={(spotId) => this.changeSpot(spotId)}
          />
          <div className="eventDetails padding">
            {spot && renderDetailsView(spot)}
          </div>
        </div>
      );
    }
  }
);
