import React from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { compose } from 'recompose';

import { Icon } from 'shared/components';
import { getEventUserSpots } from 'spots/actions';

import './styles.less';

const enhance = compose(inject('text'), observer);

/**
 * ListItem class represents the item presented in the events list containing the event information.
 * @class ListItem
 * @extends {React.Component}
 */
export default enhance(
  class ListItem extends React.Component {
    state = {
      expanded: false,
      spots: [],
    };

    /**
     * React lifecycle method - executed everytime the component is about to be mounted in the view.
     * @return {void}
     */
    componentDidMount() {
      if (this.props.event) {
        const {
          event: { _id: eventId },
          userId,
        } = this.props;
        getEventUserSpots(eventId, userId).then((spots) =>
          this.setState({ spots })
        );
      }
    }

    renderEmpty() {
      const { text } = this.props;
      return (
        <div className="HistoricEventsListItem clickable">
          <div className="textColumn emptyItem">
            <span className="itemHeader">
              {text.get('components.views.overviewEvents.noHistoricEvents')}
            </span>
          </div>
        </div>
      );
    }

    /**
     * Handles the toggling of connection
     * @return {void}
     */
    toggleExpansion = () => this.setState({ expanded: !this.state.expanded });

    /**
     * Renders the component view.
     * @return {React.Component}
     */
    render() {
      if (this.props.empty) return this.renderEmpty();
      const {
        event: { festival, organisation, endDate, _id },
        onClick,
        text,
      } = this.props;
      let showClick = false;

      this.state.spots &&
        this.state.spots.forEach((item) => {
          if (item.layout.connections && item.layout.connections.length > 0) {
            return (showClick = true);
          }
        });

      return (
        <div className={`HistoricEventsListItem ${showClick && 'clickable'}`}>
          <ReactTooltip id="historic-events-tooltip" effect="solid" />
          {!showClick && (
            <ReactTooltip id="historic-event-item-tooltip" effect="solid" />
          )}
          <div
            data-for="historic-event-item-tooltip"
            data-tip={
              !showClick
                ? text.get('components.views.overviewEvents.emptyHistoricEvent')
                : ''
            }
            className={
              !showClick
                ? 'empty-row historic-events-row'
                : 'historic-events-row'
            }
          >
            <div
              className="event-header"
              onClick={() => showClick && onClick(_id)}
            >
              <Icon name="music-note" className="icon" width={40} height={40} />
              <div className="itemHeader">{festival}</div>
              <div className="subheader">{organisation}</div>
            </div>
            <div onClick={() => showClick && onClick(_id)}>
              {moment(endDate).format('YYYY')}
            </div>
          </div>
        </div>
      );
    }
  }
);
