import { useQuery } from 'react-query';

import { api } from 'shared/utils';

import { getCompanyMapper } from '../mapper';
import { COMPANIES_QUERY_KEY } from './useGetCompanies';

const loadCompany = async (id) => {
  const { status, data } = await api.get(`/companies/${id}`);
  return status === 200 ? getCompanyMapper(data) : [];
};

export const useGetCompany = (id) => {
  const { data, status, ...others } = useQuery(
    [COMPANIES_QUERY_KEY, 'id', id],
    () => loadCompany(id)
  );

  return {
    ...others,
    loading: status === 'loading',
    company: data,
  };
};
