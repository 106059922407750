import { useCallback } from 'react';
import { useQueryClient, useMutation } from 'react-query';

import { api } from 'shared/utils';

import { USERS_QUERY_KEY } from './useGetUsers';

const doRemoveUser = (userId) =>
  api.delete(`/users/${userId}`).then(({ data }) => data);

export const useRemoveUser = () => {
  const queryClient = useQueryClient();
  const { mutate, status, ...others } = useMutation(doRemoveUser, {
    onSuccess: (response, userId) => {
      if (response) {
        //Update users cash and invalidate
        queryClient.setQueryData([USERS_QUERY_KEY, null], (oldUsers) => {
          if (oldUsers?.length > 0) {
            return oldUsers.filter((user) => user._id !== userId);
          } else {
            return [];
          }
        });
        queryClient.invalidateQueries(USERS_QUERY_KEY);

        //Remove single user cache
        queryClient.removeQueries([USERS_QUERY_KEY, 'id', userId]);
      }
    },
  });

  const removeUser = useCallback((userId) => mutate(userId), [mutate]);

  return {
    ...others,
    loading: status === 'loading',
    removeUser,
  };
};
