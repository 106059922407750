export default {
  label: 'string',
  historicItem: 'boolean',
  cluster: 'string',
  'layout.comment': 'string',
  'layout.connections[].name': 'string',
  'layout.connections[].connectionSizeType.externalId': 'string',
  'layout.connections[].connectionSizeType.price': 'numeric',
  'layout.connections[].endDate': 'string',
  'layout.connections[].quantity': 'integer',
  'layout.connections[].startDate': 'string',
  'layout.connections[].nightPower': 'boolean',
  'layout.connections[].upsNeeded': 'boolean',
  'layout.connections[].hasArrived': 'boolean',
  'layout.connections[].isConnected': 'boolean',
  'layout.connections[].devices[].name': 'string',
  'layout.connections[].devices[].ratedPower': 'integer',
  'layout.connections[].devices[].quantity': 'integer',
  'layout.connections[].devices[].unit': 'string',
  'layout.connections[].meterId': 'string',
};
