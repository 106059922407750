export default [
  'festival',
  'visitors',
  'eventType',
  'buildUpDates',
  'showDates',
  'breakDownDates',
  'editors',
  'eventCompanies',
  'eventCompanies[].name',
  'eventCompanies[]._id',
  'eventCompanies[].type',
];
