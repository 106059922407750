import React from 'react';
import { observer, inject } from 'mobx-react';
import { compose } from 'recompose';

import { pushRoute, goBack } from 'shared/actions';
import { SpotOverviewBox } from 'spots/components/';
import { Button, TextInput } from 'shared/components/form';
import {
  changeFormState,
  updateSpot,
  recoverSpotFromBuffer,
} from 'spots/actions';
import spotMapper from 'spots/mapper';

import './styles.less';

const enhance = compose(inject('spots', 'text'), observer);

/**
 * SpotRevisionPage class represents the last view in the process of modification of a spot.
 * It shows an overview of all the latest information filled in the form so the user can do
 * a revision before sending.
 * @class SpotRevisionPage
 * @extends {React.Component}
 */
export const SpotRevisionPage = enhance(
  class extends React.Component {
    // {object} state - State object stores information about the current spot
    state = { spot: null, prevComment: '', comment: '' };

    /**
     * React lifecycle method - executed everytime the component is about to be mounted in the view.
     * @return {void}
     */
    UNSAFE_componentWillMount() {
      const {
        params: { eventId },
      } = this.props;
      const spot = recoverSpotFromBuffer();
      if (!spot) return pushRoute(`/event/${eventId}/spots`);
      this.setState({ spot, prevComment: spot.layout.comment });
    }

    /**
     * Handles the event triggered when there is change in the comment field.
     * @param {object} field - HTML field instance
     */
    onChangeComment({ target }) {
      const { spot, prevComment } = this.state;
      spot.layout.comment = target.value;
      changeFormState(prevComment !== target.value);
      this.setState({ spot });
    }

    /**
     * Handles the click on the save button
     * @param {object} spot - Spot instance
     */
    onClickSave(spot) {
      const {
        spots,
        params: { spotId, eventId },
      } = this.props;
      const { uri, name, unSaved, ...rest } = spot;
      const original = spots.getSpotById(spotId);
      const tmp = spotMapper(eventId)({ ...original, ...rest });
      updateSpot(tmp);
      changeFormState(false);
      pushRoute(`/event/${eventId}/spots`);
    }

    /**
     * Renders the component view.
     * @return {React.Component}
     */
    render() {
      const { text, spots } = this.props;
      const { spot } = this.state;
      if (!spot) return null;
      return (
        <div className="EventSpotRevision">
          <SpotOverviewBox spot={spot} />
          <div className="comment">
            <TextInput
              type="text"
              field={{
                label: text.get('forms.spot.comment'),
                value: spot.layout.comment,
                onChange: (value) => this.onChangeComment(value),
              }}
            />
          </div>
          <div className="mainRow spot-edit">
            <div className="nav-btn backBtn">
              <Button type="button" onClick={goBack}>
                {text.get('actions.back')}
              </Button>
            </div>
            <div className="nav-btn">
              <Button
                styling="cta"
                type="button"
                disabled={spots.loading}
                children={text.get('actions.save')}
                onClick={() => this.onClickSave(spot)}
              />
            </div>
          </div>
        </div>
      );
    }
  }
);
