import { api } from 'shared/utils';
import { notify } from 'shared/actions';
import { Notifications } from 'shared/constants';
import { loadSpots } from 'spots/actions';

import {
  powerInquiryCreateInvitationMapper,
  powerInquiryUpdateInvitationMapper,
  powerInquiryRemindInvitationMapper,
} from './mapper';
import { powerInquiries as powerInquiriesStore } from './store';

/**
 * Takes an event id as input and attempts to retrieve all the evenry users
 * related to the event from the API.
 * @param {string} eventId - Event id
 * @return {Promise}
 */
export const loadPowerInquiries = (eventId) =>
  Promise.resolve(powerInquiriesStore.isLoading(true))
    .then(() => api.get(`/events/${eventId}/powerinquiries`))
    .then(({ status, data }) => {
      if (status === 200) powerInquiriesStore.registerPowerInquiries(data);
    })
    .then(() => powerInquiriesStore.isLoading(false));

/**
 * Takes an array of energy user invites as input and send it to the API to invite users
 * and create spots.
 * @param {string} eventId - Event id
 * @param {object} invitation - List of energy user powerInquiry
 * @return {Promise}
 */
export const createPowerInquiry = (eventId, powerInquiry, usedForUpdate) =>
  Promise.resolve(powerInquiriesStore.isLoading(true))
    .then(() => {
      const tmp = powerInquiryCreateInvitationMapper(eventId, powerInquiry);
      return api.post(`/events/${eventId}/powerinquiries`, tmp);
    })
    .then((result) => {
      const { status } = result;
      powerInquiriesStore.isLoading(false);
      const notifyText = usedForUpdate ? 'updated' : 'created';
      if (status === 201) {
        if (result.data.notification) {
          notify({
            message: `Power inquiry ${notifyText}, sending invitation e-mail to user with ID "${powerInquiry.userId}"`,
            level: Notifications.Type.SUCCESS,
            dismiss: 15,
          });
        } else {
          notify({
            message: `Power inquiry ${notifyText} for user with ID ${powerInquiry.userId}, no e-mail will be send`,
            level: Notifications.Type.SUCCESS,
            dismiss: 15,
          });
        }
        return { type: 'success', payload: result };
      } else {
        return { type: 'error', payload: result };
      }
    });

/**
 * Takes an array of energy user invites as input and send it to the API to invite users
 * and create spots.
 * @param {string} eventId - Event id
 * @param {object} powerInquiry - List of energy user powerInquiry
 * @return {Promise}
 */
export const updatePowerInquiry = (eventId, powerInquiry, sendEmail) =>
  Promise.resolve(powerInquiriesStore.isLoading(true))
    .then(() => {
      const tmp = powerInquiryUpdateInvitationMapper(powerInquiry);
      return api.put(`/events/${eventId}/powerinquiries/${tmp._id}`, tmp);
    })
    .then(({ status }) => {
      powerInquiriesStore.isLoading(false);
      if (status === 200) {
        if (powerInquiry.email) {
          notify({
            message: `Inquiry user and or company updated, sending invitation e-mail to user with Id "${powerInquiry.userId}"`,
            level: Notifications.Type.SUCCESS,
            dismiss: 15,
          });
        } else {
          notify({
            message: `Inquiry user and or company updated`,
            level: Notifications.Type.SUCCESS,
            dismiss: 15,
          });
        }
        return { type: 'success', payload: powerInquiry };
      } else {
        return { type: 'error', payload: powerInquiry };
      }
    })
    .then(loadSpots(eventId));

/**
 * Takes an event id and an user id as input and do an API call to remove the
 * user association to the event.
 * @param {string} eventId - Event id
 * @param {string} powerInquiry - powerInquiry Id
 * @return {Promise}
 */
export const removePowerInquiry = (eventId, powerInquiryId) =>
  Promise.resolve(powerInquiriesStore.isLoading(true))
    .then(() =>
      api.delete(`/events/${eventId}/powerinquiries/${powerInquiryId}`)
    )
    .then(({ status }) => {
      if (status === 200)
        powerInquiriesStore.removePowerInquiry(powerInquiryId);
    })
    .then(() => powerInquiriesStore.isLoading(false));

export const parseImportPowerInquiries = (data, eventId) =>
  api.post(`/events/${eventId}/xlsx/parse`, data, {
    'Content-Type': 'multipart/form-data',
  });

export const applyImportPowerInquiries = (data, eventId) =>
  Promise.resolve(api.post(`/events/${eventId}/xlsx/import`, data)).then(
    ({ status }) => {
      if (status === 200) {
        notify({
          message: 'Import was processed successfully',
          level: Notifications.Type.SUCCESS,
        });
      }
      if (status === 403 || status === 403 || status === 500) {
        notify({
          message:
            'An unexpected error has occurred, please contact your software provider',
          level: Notifications.Type.ERROR,
        });
      }
    }
  );

/**
 * Takes an array of energy user invites as input and send it to the API to remind users
 * and create spots.
 * @param {string} eventId - Event id
 * @param {object} powerInquiry - List of energy user powerInquiry
 * @return {Promise}
 */
export const remindPowerInquiry = (eventId, powerInquiry) =>
  Promise.resolve(powerInquiriesStore.isLoading(true))
    .then(() => {
      const tmp = powerInquiryRemindInvitationMapper(powerInquiry);
      return api.post(
        `/events/${eventId}/powerinquiries/${powerInquiry._id}/remind`,
        tmp
      );
    })
    .then(({ status }) => {
      powerInquiriesStore.isLoading(false);
      if (status === 201) {
        notify({
          message: `Energy user reminded, sending reminder to "${powerInquiry.email}"`,
          level: Notifications.Type.SUCCESS,
          dismiss: 15,
        });
      } else {
        return { type: 'error', payload: powerInquiry };
      }
    })
    .then(loadSpots(eventId));
