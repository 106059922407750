/**
 * Maps the appliance fields to the current model.
 * @param {object} appliance - Device object
 * @return {object}
 */
export const getApplianceMapper = (appliance = {}) => {
  appliance.ratedPower = appliance.ratedPower
    ? parseFloat(appliance.ratedPower, 10)
    : 0;
  appliance.quantity = appliance.quantity
    ? parseInt(appliance.quantity, 10)
    : 0;
  return appliance;
};

/**
 * Maps the appliance fields to the current model.
 * @param {object} appliance - Device object
 * @return {object}
 */
export const putApplianceMapper = (appliance = {}) => {
  const applianceBase = getBaseAppliance(appliance);
  // applianceBase.ratedPower = appliance.ratedPower
  //   ? parseFloat(appliance.ratedPower, 10)
  //   : 0;
  const { _id, uri, createdAt, updatedAt, userCreatedId } = appliance;
  return {
    _id,
    uri,
    createdAt,
    updatedAt,
    userCreatedId,
    ...applianceBase,
  };
};

/**
 * Maps the appliance fields to the current model.
 * @param {object} appliance - Device object
 * @return {object}
 */
export const postApplianceMapper = (appliance = {}) => {
  const applianceBase = getBaseAppliance(appliance);
  // applianceBase.ratedPower = appliance.ratedPower
  //   ? parseFloat(appliance.ratedPower, 10)
  //   : 0;
  return applianceBase;
};

//get the default set of appliance properties
function getBaseAppliance(appliance) {
  const { label, brand, modelNumber, ratedPower, category } = appliance;
  return {
    label,
    brand,
    modelNumber,
    ratedPower,
    unit: 'W',
    category,
  };
}
