import React, { createRef } from 'react';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';

import { Button, TextInput } from 'shared/components/form';
import { ModalForm } from 'shared/components';
import { formCreator } from 'shared/form';

import fields from './fields';

const enhance = compose(inject('text', 'powerzones'), observer);

/**
 * NewPowerzoneForm class represents the form where the user should give the new powerzone name.
 * @class PowerzoneModalForm
 * @extends {React.Component}
 */
export const PowerzoneModalForm = enhance(
  class extends React.Component {
    constructor(props) {
      super(props);
      // {object} state - Component state holds the form instance.
      this.state = { form: null };
      this.modalRef = createRef();
    }

    /**
     * React lifecycle method - executed everytime the component is about to be mounted in the view.
     * @return {void}
     */
    UNSAFE_componentWillMount() {
      const form = formCreator(this.config);
      this.setState({ form });
    }

    // {object} config - Form configuration getter
    get config() {
      const { onSuccess, onError, text, customClass } = this.props;
      return {
        title: 'powerzoneModalForm',
        fields: fields(text),
        customClass,
        onSuccess,
        onError,
      };
    }

    /**
     * React lifecycle method - executed everytime the component is mounted in the view.
     * @return {void}
     */
    updateForm(values) {
      let powerZoneFormValues = {};
      if (values) {
        powerZoneFormValues = { label: values.label, uri: values.uri };
      } else {
        powerZoneFormValues = { label: '', uri: '' };
      }
      this.state.form.init(powerZoneFormValues); // making sure everytime you select a new powerzone, his data will appear
    }

    /**
     * Renders the component view.
     * @return {React.Component}
     */
    render() {
      const { text, cancelHandler, powerzones, customClass } = this.props;
      const { form } = this.state;
      if (!form) return null;
      return (
        <ModalForm ref={this.modalRef}>
          <form className={`${customClass} form`} onSubmit={form.onSubmit}>
            <div className="modalHeader">
              {text.get('forms.powerzone.formTitle')}
            </div>
            <div className="modalBody padding">
              <TextInput field={form.$('label')} type="text" />
            </div>
            <div className="modalFooter padding">
              <Button
                type="submit"
                onClick={form.onSubmit}
                disabled={powerzones.loading}
              >
                {text.get('actions.submit')}
              </Button>
              <Button
                type="button"
                onClick={cancelHandler}
                styling="borderless"
              >
                {text.get('actions.cancel')}
              </Button>
            </div>
          </form>
        </ModalForm>
      );
    }
  }
);
