import React, { forwardRef } from 'react';
import { DeleteOutline } from '@material-ui/icons';
import { format } from 'date-fns';

import { DATE_TIME_FORMAT } from 'shared/constants/general';
import { Spinner, ReportTable } from 'shared/components';
import { confirm as confirmAction } from 'shared/actions';
import { useGetText } from 'shared/hooks';

import {
  useCreateAppliance,
  useGetAppliances,
  useRemoveAppliance,
  useUpdateAppliance,
} from 'appliances/hooks';

import './styles.less';

export function AppliancesOverviewPage() {
  const text = useGetText();
  const { data, loading, error } = useGetAppliances();
  const { removeAppliance } = useRemoveAppliance();
  const { updateAppliance } = useUpdateAppliance();
  const { createAppliance } = useCreateAppliance();
  const title = text.getText(
    'components.views.applianceTemplates.overview.pageTitle'
  );

  function handleClickRemoveAppliance(event, rowData) {
    confirmAction(text.getText('actions.confirmRemove'), () =>
      removeAppliance(rowData._id)
    );
  }

  function renderDate(rowData) {
    return <>{format(new Date(rowData.updatedAt), DATE_TIME_FORMAT)}</>;
  }

  /**
   * Renders the component view.
   * @return {React.Component}
   */
  return (
    <div className="appliancesOverview">
      {loading ? (
        <Spinner />
      ) : error ? (
        <span>{error.message}</span>
      ) : (
        <ReportTable
          title={title}
          data={data}
          options={{
            pageSize: 20,
            exportFileName: title,
            padding: 'dense',
            actionsColumnIndex: 1000,
          }}
          text={text}
          textLocation={'components.views.applianceTemplates.overview'}
          editable={{
            onRowAdd: createAppliance,
            onRowUpdate: updateAppliance,
          }}
          customActions={[
            {
              icon: forwardRef((props, ref) => (
                <DeleteOutline {...props} ref={ref} />
              )),
              tooltip: text.getText('actions.remove'),
              onClick: handleClickRemoveAppliance,
            },
          ]}
          customColumnSettings={{
            uri: {
              hidden: true,
              readonly: true,
            },
            _id: {
              hidden: true,
              readonly: true,
            },
            userCreatedId: {
              type: 'numeric',
            },
            updatedAt: {
              render: renderDate,
              readonly: true,
            },
            ratedPower: {
              type: 'numeric',
            },
          }}
        />
      )}
    </div>
  );
}
