import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { matchSorter } from 'match-sorter';
import WidePopper from './WidePopper';

/**
 * SelectInput component
 * @param {object} props - React component properties
 * @return {React.Component}
 */
export const AutoCompleteMultipleInput = ({
  value,
  label,
  name,
  onChange,
  formSubmit,
  options,
  variant = 'standard',
  hidden,
  optionsLoading,
  addNewInputValueProp,
  placeholder,
  inputFieldProps,
  renderOption,
  onHandleInputChange,
  inputValue,
  onInputChange,
  widePopper,
  fuzzySearch,
  fuzzySearchKeys,
  ...props
}) => {
  const defaultFilterOptions = fuzzySearch
    ? (options, { inputValue }) =>
        matchSorter(options, inputValue, { keys: fuzzySearchKeys })
    : createFilterOptions();
  return (
    !hidden && (
      <Autocomplete
        id={`autocomplete-${label}`}
        renderInput={(params) => {
          onHandleInputChange &&
            onHandleInputChange(params?.inputProps?.ref?.current?.value);
          return (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'disabled', // disable autocomplete and autofill
              }}
              name={name}
              label={label}
              variant={variant}
              placeholder={placeholder}
              {...inputFieldProps}
            />
          );
        }}
        autoHighlight
        autoComplete
        value={value}
        onChange={(event, newValue, reason, detail) => {
          onChange && onChange(event, newValue, reason, detail);
        }}
        inputValue={inputValue}
        onInputChange={onInputChange}
        loading={optionsLoading}
        options={options}
        fullWidth
        filterOptions={defaultFilterOptions}
        renderOption={renderOption}
        PopperComponent={WidePopper}
        openOnFocus={false}
        multiple={true}
        {...props}
      />
    )
  );
};

AutoCompleteMultipleInput.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.array,
  parentEntityName: PropTypes.string,
  onHandleInputChange: PropTypes.func,
  renderOption: PropTypes.func,
  fuzzySearch: PropTypes.bool,
  fuzzySearchKeys: PropTypes.any,
  multiple: (props) => {
    // this component should only be used as a multiple auto complete with chips so the multiple prop is not allowed
    if (props.multiple !== undefined) {
      return new Error(
        'You are not allowed to use the multiple property on this component. This component is strictly used to select multiple values.'
      );
    }
  },
};
