// This should replace the current useTimeseriesData hook in hooks/useTimeseriesData.js

/**
 * @module useTimeseriesData
 */
import moment from 'moment';
import { useQueries } from 'react-query';
import { api } from 'shared/utils';
import * as qs from 'qs';

const UNIT_TIMESERIES_DATA_BASE_KEY = 'unitTimeseries';

function createUnitTimeseriesDataKey({
  eventId,
  meterId,
  fields,
  aggregates = [],
  timeRange,
  groupingTime = '',
}) {
  return [
    'dbKey',
    UNIT_TIMESERIES_DATA_BASE_KEY,
    'evetId',
    eventId,
    'meterId',
    meterId,
    'fields',
    fields.join(','),
    'aggregates',
    aggregates.join(','),
    'timeRange',
    `${timeRange.from} to ${timeRange.to}`,
    'groupingTime',
    groupingTime,
  ];
}

/**
 * Fetch raw data
 * @param {function} post - Function that executes the 'post' request.
 * @param {String} meterId - identifier of the unit.
 * @param {String[]} fields - List of fields to fetch.
 * @param {String[]} aggregates - List of aggregates to apply to each grouped field.
 * @param {Object} timeRange - Period of time of the data
 * @param {string} groupingTime - Period of time in which to group the data
 *
 * @returns {Object} data
 * @property {Array} data.columns - Name of the columns of the 'values' array
 * @property {Array[]} data.values
 */
async function fetchUnitTimeseriesData(
  eventId,
  meterId,
  fields,
  aggregates,
  timeRange,
  groupingTime
) {
  const path = `/events/${eventId}/timeseries`;
  const params = {
    meterId: meterId,
    from: moment(timeRange.from).toISOString(),
    to: moment(timeRange.to).toISOString(),
    fields: [fields.join(',')],
  };

  if (aggregates) {
    params.aggregates = [aggregates.join(',')];
  }
  if (groupingTime) {
    params.groupingTime = groupingTime;
  }

  const url = `${path}?${qs.stringify(params, {
    encode: false,
    arrayFormat: 'brackets',
  })}`;
  const { data } = await api.get(url);

  return data;
}

/**
 * Hook used to retrieve time series data from the backend.
 *
 * It is only possible to fetch data of specific units. You can request data for multiple units at once.
 * For each unit, a request is sent to the backend.
 *
 * @param {Object[]} dataToFetch - Object(s) specifying the data necessary to be fetched
 * @property {string} dataToFetch.meterId - External identifier (ej.: GREENER_000) of the desired unit.
 * @property {string[]} dataToFetch.fields - fields to fetch for the unit.
 * @property {string[]} dataToFetch.aggregates - functions used to aggregate data over their grouping time.
 *
 * @param {Object} timeRange - Period of time of the data
 * @property {date} timeRange.from
 * @property {date} timeRange.to
 *
 * @param {string} groupingTime - Period of time in which to group the data
 *
 * @returns {Object} Object holding unit ids as keys, with the queried results as values.
 * Each queried field/aggregator combination is present in the result as key, with an array of objects containing
 * 'time' and 'value' attributes.
 */
export function useGetEventTimeseries({
  eventId,
  dataToFetch,
  timeRange,
  groupingTime,
  enabled = true,
}) {
  //disable query if we don't have all necesaary data

  if (!Array.isArray(dataToFetch)) dataToFetch = [dataToFetch];
  // Set up the queries to execute.
  const queries = dataToFetch.map((queryData) => {
    if (
      !queryData.fields ||
      queryData.fields.length === 0 ||
      !timeRange.from ||
      !timeRange.to
    ) {
      enabled = false;
      console.error('Missing data to fetch');
    }
    return {
      queryKey: createUnitTimeseriesDataKey({
        eventId,
        meterId: queryData.meterId,
        fields: queryData.fields,
        aggregates: queryData.aggregates,
        timeRange,
        groupingTime,
      }),
      queryFn: async () => {
        // Don't send a request if any of the fields are missing
        //   if (!queryData.fields || queryData.fields.length === 0)
        //     return { [queryData.meterId]: null };
        return await fetchUnitTimeseriesData(
          eventId,
          queryData.meterId,
          queryData.fields,
          queryData.aggregates,
          timeRange,
          groupingTime
        );
      },
      enabled, // black fetching here https://react-query.tanstack.com/guides/dependent-queries
    };
  });

  // Execute the queries.
  const queryResults = useQueries(queries);

  return queryResults[0];
}
