import React from 'react';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';

import { goBack, notify } from 'shared/actions';
import { Notifications } from 'shared/constants';
import { Box, Icon, Spinner } from 'shared/components';
import { Button } from 'shared/components/form';

import { createPowerInquiry, loadPowerInquiries } from 'powerinquiries/actions';
import { PowerInquiryInvitationForm } from 'powerinquiries/components';

import { loadUsers } from 'users/actions';

import { ListItem } from './ListItem';
import './styles.less';

const enhance = compose(inject('events', 'text', 'users'), observer);

/**
 * PowerInquiryInvitePage class represents the view which contains a form to add a new energy user
 * to the event.
 * @class PowerInquiryInvitePage
 * @extends {React.Component}
 */
export const PowerInquiryInvitePage = enhance(
  class extends React.Component {
    constructor(props) {
      super(props);
      this.state = { invites: [] };
      this.onAddInvite = this.onAddInvite.bind(this);
    }

    get invites() {
      return this.state.invites.map((invite, index) => (
        <ListItem
          key={index}
          invite={invite}
          onClickRemove={this.onClickRemoveInvite}
          onClickView={this.onClickViewInvite}
        />
      ));
    }

    UNSAFE_componentWillMount() {
      const { users } = this.props;
      if (users.list.length === 0) loadUsers();
    }

    onClickRemoveInvite = (inviteToRemove) => {
      const invites = this.state.invites.filter(
        ({ user }) => user._id !== inviteToRemove.user._id
      );
      this.setState({ invites });
    };

    onClickViewInvite = (invite) => {
      this.onClickRemoveInvite(invite);
      const form = this.refs.powerInquiryForm.wrappedInstance;
      form.updateForm(invite);
    };

    sendInvitations = async () => {
      const {
        events,
        params: { eventId },
      } = this.props;
      const { invites } = this.state;
      const event = events.getEvent(eventId);

      //make sure the inquiries are posted sequentially since the BE unfortunately doesn't support parallel requests
      for (const invite of invites) {
        const payload = {
          userId: invite.user._id,
          userLabel: invite.user.label,
          companyId: invite.user.companyId,
          owner: invite.owner._id,
          email: {
            subject: invite.emailTitle,
            message: invite.message
              .replace('{festival}', event.festival)
              .replace('{name}', invite.user.name),
            isReminder: false,
          },
          spots: invite.spots,
        };
        await createPowerInquiry(eventId, payload);
      }
      this.allInvitesSent();
    };

    //TODO move this part and the promise.all to actions? now we may have soudble notifications. We can make it part of upgrading to RQ, see issue url below.
    //https://gitlab.com/watt-now/tool-frontend/-/issues/388
    inviteSent = (response) => {
      if (response?.type === 'success') {
        const { invites } = this.states;
        this.setState({ invites });
      } else if (response?.type === 'error') {
        notify({
          message: `'It was not possible to send the invitation for the user with id: ${
            response?.payload.userId || 'unknown'
          }`,
          level: Notifications.Type.ERROR,
          dismiss: 15,
        });
      }
    };

    allInvitesSent = () => {
      if (this.state.invites.length > 0) return;
      loadPowerInquiries(this.props.params.eventId).then(() => goBack());
    };

    /**
     * Form submission success handler.
     * @param {object} form - Form instance
     * @return {void}
     */
    onAddInvite = (form, spots) => {
      const { invites } = this.state;
      const invite = form.values();
      invite.spots = spots;
      invites.push(invite);
      this.setState({ invites });
      form.reset();
    };

    /**
     * Renders the component view.
     * @return {React.Component}
     */
    render() {
      const {
        events,
        params: { eventId },
        text,
        users,
      } = this.props;
      const event = events.getEvent(eventId);
      const hasInvites = this.invites.length > 0;
      return events.loading ? (
        <Spinner />
      ) : (
        <div className="EventInvitePowerInquiry">
          <Button
            size="small"
            styling="text"
            onClick={goBack}
            children={text.get('actions.back')}
          >
            <Icon name="arrow-back" className={`icon`} />
            <span>{text.get('actions.back')}</span>
          </Button>
          {hasInvites && (
            <>
              <Box title={text.get('components.views.powerInquiries.invites')}>
                {this.invites}
              </Box>
              <div className="buttonRow">
                <Button
                  size="small"
                  styling="cta"
                  onClick={this.sendInvitations}
                  title={text.get(
                    'components.views.powerInquiries.noInvitesHover'
                  )}
                >
                  <span>{text.get('actions.submit')}</span>
                  <Icon name="mail-outline" className={`icon`} />
                </Button>
              </div>
            </>
          )}
          <Box
            title={text.get(
              'components.views.powerInquiries.invitePowerInquiries'
            )}
          >
            <PowerInquiryInvitationForm
              ref="powerInquiryForm"
              onSuccess={this.onAddInvite}
              usersLoading={users.loading}
              event={event}
            />
          </Box>
        </div>
      );
    }
  }
);
