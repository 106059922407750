import React from 'react';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';

import { decimals } from 'shared/utils';
import { calculateTheoreticalPower } from 'spots/utils';
import { formatDateTime } from 'shared/utils/dates';

import { Icon } from 'shared/components';
import { withCompanyName } from 'spots/hocs';

import './styles.less';

const enhance = compose(inject('text'), observer, withCompanyName);

const CTitle = withCompanyName(({ companyName }) => (
  <div style={{ fontWeight: 'bold' }}>{companyName} </div>
));
/**
 * DetailsTable represents the Powerzone details table component.
 * @class DetailsTable
 * @extends {React.Component}
 */
export default enhance(
  class extends React.Component {
    state = { expanded: true };

    /**
     * Render spot title component.
     * @param {object} spot - Spot instance
     * @return {React.Component}
     */
    renderTitle = ({ label, comment, eventId, userId }) => (
      <div className="titles">
        <span className="spotName">
          <CTitle spot={{ eventId, userId }} /> {label}
        </span>
        <span className="spotComment">{comment}</span>
      </div>
    );

    /**
     * Render connection header component.
     * @param {object} connection - Connection instance
     * @param {number} index - Element index
     * @return {React.Component}
     */
    renderConnection = (connection, index) => (
      <div className="connection" key={`connection ${index}`}>
        <span className="connectionSize">
          {connection.connectionSizeType.externalId}
        </span>
      </div>
    );

    /**
     * Handles the toggling of connection
     * @return {void}
     */
    toggleExpansion = () => this.setState({ expanded: !this.state.expanded });

    /**
     * Render connection rated power component.
     * @param {object} connection - Connection instance
     * @param {number} index - Element index
     * @return {React.Component}
     */
    renderPower = (connection, index) => {
      const theoreticalPower = calculateTheoreticalPower(connection) / 1000;
      return (
        <div className="power" key={index}>
          <span>{decimals(theoreticalPower, 2)} kW</span>
        </div>
      );
    };

    /**
     * Render the spot table row.
     * @param {object} spot - Spot instance
     * @param {number} index - Element index
     * @return {React.Component}
     */
    renderSpot = (
      { label, coordinate, layout: { comment, connections }, eventId, userId },
      index
    ) => (
      <div className="row" key={`spot ${index}`}>
        <div>{this.renderTitle({ label, comment, userId, eventId })}</div>
        <div style={{ alignItems: 'flex-start', marginLeft: '15px' }}>
          {connections.map((item, index) => this.renderConnection(item, index))}
        </div>
        <div style={{ alignItems: 'flex-start', marginLeft: '15px' }}>
          {connections.map((connection, index) => (
            <div key={index} className="connection">
              <span className="connectionSize">{connection.quantity}</span>
            </div>
          ))}
        </div>
        <div>{coordinate || '-'}</div>
        <div style={{ alignItems: 'center' }}>
          {connections.map((connection, key) => (
            <div key={`connection ${key}`} className="connection">
              <span className="connectionSize">
                {formatDateTime(connection.startDate)}
              </span>
            </div>
          ))}
        </div>
        <div>
          {connections.map((item, index) => this.renderPower(item, index))}
        </div>
      </div>
    );

    /**
     * Renders the component view.
     * @return {React.Component}
     */
    render() {
      const { text, className, spots } = this.props;
      return (
        <div className={`DetailsTable ${className || ''}`}>
          <div onClick={this.toggleExpansion} className="head row">
            <span>{text.get('components.views.powerzones.name')}</span>
            <span style={{ alignItems: 'flex-start', marginLeft: '15px' }}>
              {text.get('components.views.powerzones.connections')}
            </span>
            <span style={{ alignItems: 'flex-start', marginLeft: '15px' }}>
              {text.get('components.views.powerzones.detailedTable.qty')}
            </span>
            <span>{text.get('components.views.powerzones.coordinate')}</span>
            <span>{text.get('components.views.powerzones.datePowerOn')}</span>
            <span>
              {text.get(
                'components.views.powerzones.theoreticalPowerShortened'
              )}
            </span>
            <span>
              <Icon
                className="toggle-icon"
                name={this.state.expanded ? 'arrow-up' : 'arrow-down'}
              />
            </span>
          </div>
          <div className="screen">
            {this.state.expanded &&
              (spots.length ? (
                spots.map((item, index) => this.renderSpot(item, index))
              ) : (
                <div className="empty-row">
                  <span>
                    {text.get('components.views.powerzones.emptyPowerzone')}
                  </span>
                </div>
              ))}
          </div>
          <div className="print">
            {spots.length ? (
              spots.map((item, index) => this.renderSpot(item, index))
            ) : (
              <div className="empty-row">
                <span>
                  {text.get('components.views.powerzones.emptyPowerzone')}
                </span>
              </div>
            )}
          </div>
        </div>
      );
    }
  }
);
