import { goBack, notify } from 'shared/actions';
import { api } from 'shared/utils';
import { Notifications } from 'shared/constants';

import userMapper from './mapper';
import { users as usersStore } from './store';

/**
 * Downloads all system's users from the API.
 * @return {Promise}
 */
export const loadUsers = (roleIds) => {
  const query = roleIds ? `?roles[]=${roleIds.join(',')}` : '';
  return Promise.resolve(usersStore.isLoading(true))
    .then(() => api.get(`/users${query}`))
    .then(({ status, data }) => {
      if (status === 200) {
        const users = data.map(userMapper);
        usersStore.registerUsers(users);
      }
    })
    .then(() => usersStore.isLoading(false));
};

/**
 * Downloads a single user from the API.
 * @return {Promise}
 */
export const loadUser = (userId) => {
  return Promise.resolve(usersStore.isLoading(true))
    .then(() => api.get(`/users/${userId}`))
    .then(({ status, data }) => {
      if (status === 200) {
        const user = userMapper(data);
        usersStore.registerNewUser(user);
        const newUser = usersStore.getUser(data._id);
        return newUser;
      }
    })
    .then((user) => {
      usersStore.isLoading(false);
      return user;
    });
};

/**
 * Takes an user data object as input and sends it to the API to store in the database.
 * @param {object} user - User data
 * @return {Promise}
 */
export const createNewUser = (user, avoidRedirect) =>
  Promise.resolve(usersStore.isLoading(true))
    .then(() => api.post('/users', userMapper(user)))
    .then(({ status, data }) => {
      usersStore.isLoading(false);
      if (status >= 200 && status < 300) {
        if (!avoidRedirect) goBack();
        if (status === 201) {
          notify({
            message: `Successfully created user with name "${user.name}"`,
            level: Notifications.Type.SUCCESS,
            dismiss: 15,
          });
          return data._id;
        }
      }
    })
    .catch((error) =>
      notify({
        level: Notifications.Type.ERROR,
        message: error.message,
      })
    );

/**
 * Takes an user data object as input and sends it to the API to store in the database.
 * @param {object} user - User data
 * @return {Promise}
 */
export const createOrUpdateUser = (user, avoidRedirect) =>
  Promise.resolve(usersStore.isLoading(true))
    .then(() => api.put(`/users/${user._id}`, userMapper(user)))
    .then(({ status }) => {
      usersStore.isLoading(false);
      if (status >= 200 && status < 300) {
        if (!avoidRedirect) goBack();
        if (user._id > 0) {
          notify({
            message: `User ${user.name} with ID: ${user._id} updated`,
            level: Notifications.Type.SUCCESS,
            dismiss: 15,
          });
        } else {
          notify({
            message: `User ${user._id} created and sending invitation to: ${user.email}`,
            level: Notifications.Type.SUCCESS,
            dismiss: 15,
          });
        }
      }
    })
    .catch((error) =>
      notify({
        level: Notifications.Type.ERROR,
        message: error.message,
      })
    );

/**
 * Takes an user id as input and do an API call to remove it from the system.
 * @param {string} userId - User id
 * @return {Promise}
 */
export const removeUser = (userId) =>
  Promise.resolve(usersStore.isLoading(true))
    .then(() => api.delete(`/users/${userId}`))
    .then(({ status }) => {
      if (status === 200) usersStore.removeUser(userId);
    })
    .then(() => usersStore.isLoading(false));
