import { goBack, notify } from 'shared/actions';
import { api } from 'shared/utils';
import { Notifications } from 'shared/constants';

import { getCompanyMapper, putCompanyMapper } from './mapper';
import { companies as companiesStore } from './store';

/**
 * Downloads a single company from the API.
 * @return {Promise}
 */
export const loadCompany = (companyId) => {
  return Promise.resolve(companiesStore.isLoading(true))
    .then(() => api.get(`/companies/${companyId}`))
    .then(({ status, data }) => {
      if (status === 200) {
        const company = getCompanyMapper(data);
        companiesStore.registerNewCompany(company);
        const newCompany = companiesStore.getCompany(data._id);
        return newCompany;
      }
    })
    .then((company) => {
      companiesStore.isLoading(false);
      return company;
    });
};

/**
 * Downloads all system's companies from the API.
 * @return {Promise}
 */
export const loadCompanies = () => {
  return Promise.resolve(companiesStore.isLoading(true))
    .then(() => api.get(`/companies`))
    .then(({ status, data }) => {
      if (status === 200) {
        const companies = data.map(getCompanyMapper);
        companiesStore.registerCompanies(companies);
      }
    })
    .then(() => companiesStore.isLoading(false));
};

/**
 * Takes an company data object as input and sends it to the API to store in the database.
 * @param {object} company - Company data
 * @return {Promise}
 */
export const createNewCompany = (company, avoidRedirect) =>
  Promise.resolve(companiesStore.isLoading(true))
    .then(() => api.post('/companies', putCompanyMapper(company)))
    .then(({ status, data }) => {
      companiesStore.isLoading(false);
      if (status >= 200 && status < 300) {
        if (!avoidRedirect) goBack();
        if (status === 201) {
          notify({
            message: `Create company '${data._id}, you can now add this company to a user or event...`,
            level: Notifications.Type.SUCCESS,
            dismiss: 15,
          });
          return data;
        }
      }
    });

/**
 * Takes an company data object as input and sends it to the API to store in the database.
 * @param {object} company - Company data
 * @return {Promise}
 */
export const createOrUpdateCompany = (company, avoidRedirect) =>
  Promise.resolve(companiesStore.isLoading(true))
    .then(() => api.put(`/companies/${company._id}`, putCompanyMapper(company)))
    .then(({ status, data }) => {
      companiesStore.isLoading(false);
      if (status >= 200 && status < 300) {
        if (!avoidRedirect) goBack();
        if (status === 201) {
          notify({
            message: `Company: '${data.name}, Succesfully updated`,
            level: Notifications.Type.SUCCESS,
            dismiss: 15,
          });
        }
        return data;
      }
    });

/**
 * Takes an company id as input and do an API call to remove it from the system.
 * @param {string} companyId - Company id
 * @return {Promise}
 */
export const removeCompany = (companyId) =>
  Promise.resolve(companiesStore.isLoading(true))
    .then(() => api.delete(`/companies/${companyId}`))
    .then(({ status }) => {
      if (status === 200) companiesStore.removeCompany(companyId);
    })
    .then(loadCompanies());
