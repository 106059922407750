import * as Action from 'shared/actions';
import * as Stores from 'shared/stores';
import { api } from 'shared/utils';

/**
 * Takes an user id as input and downloads all the user's templates
 * from the API.
 * @param {string} eventId - Event id
 * @param {string} userId - User id
 * @return {Promise}
 */
export const loadTemplates = (userId = '') =>
  Promise.resolve(Stores.templates.isLoading(true))
    .then(() => api.get(`/layouttemplates${userId && `?userId=${userId}`}`))
    .then(({ status, data }) => {
      if (status === 200) {
        Stores.templates.registerTemplates(data);
      }
    })
    .then(() => Stores.templates.isLoading(false));

/**
 * Takes a template data object and sends it to the API to create in the database.
 * @param {object} template - template instance
 * @param {string} eventId - Event id
 * @return {Promise}
 */
export const createTemplate = (template) =>
  Promise.resolve(Stores.templates.isLoading(true))
    .then(() => api.post(`/layouttemplates`, template))
    .then(({ status, data }) => {
      if (status === 201)
        Stores.templates.registerNewTemplate({
          uri: `/layouttemplates/${data._id}`,
          ...template,
        });
    })
    .then(() => Stores.templates.isLoading(false));

/**
 * Takes a template data object and sends it to the API to update in the database.
 * @param {object} template - template instance
 * @return {Promise}
 */
export const updateTemplate = (template) =>
  Promise.resolve(Stores.templates.isLoading(true))
    .then(() => api.put(template.uri, template))
    .then(({ status, data }) => {
      if (status === 200) {
        Stores.templates.updateTemplate(template);
        Action.goBack();
      }
    })
    .then(() => Stores.templates.isLoading(false));

/**
 * Takes a template data object and send a request with it's ID to the API to remove it from the database.
 * @param {object} template - template instance
 * @return {Promise}
 */
export const removeTemplate = (template) =>
  Promise.resolve(Stores.templates.isLoading(true))
    .then(() => api.delete(template.uri))
    .then(({ status, data }) => {
      if (status === 200) Stores.templates.removeTemplate(template);
    })
    .then(() => Stores.templates.isLoading(false));
