import React from 'react';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';

import { Box, Spinner } from 'shared/components';
import { loadEvents } from 'events/actions';

import ListItem from './listItem';
import './styles.less';

const enhance = compose(inject('text', 'events'), observer);

/**
 * HistoricEvents class represents the view of historic events
 * @class HistoricEvents
 * @extends {React.Component}
 */
export const HistoricEvents = enhance(
  class extends React.Component {
    /**
     * React lifecycle method - executed everytime the component was just mounted in the view.
     * @return {void}
     */
    state = {
      events: [],
    };

    componentDidMount() {
      const { userId } = this.props;
      loadEvents(userId || null).then((events) => {
        this.setState({ events });
      });
    }

    /**
     * Handles the click over a event item in the list.
     * @param {string} eventId - Id of chosen historic event
     * @return {void}
     */
    onClickEvent = (eventId) => {
      this.props.onClickEvent(eventId);
    };

    /**
     * Renders the list of events when available.
     * @return {React.Component}
     */
    renderEventsList() {
      const {
        eventId,
        events: { loading },
        userId,
      } = this.props;
      if (loading) return <Spinner />;

      const list = this.state.events;

      const gotSameEventAsCurrent =
        list.length === 1 && list[0].event._id === +eventId;

      if (list.length === 0 || gotSameEventAsCurrent) return <ListItem empty />;

      return list.map((obj, index) => {
        if (obj.event._id !== +eventId) {
          return (
            <ListItem
              key={`listitem-${index}`}
              event={obj.event}
              onClick={this.onClickEvent}
              userId={userId}
            />
          );
        } else return null;
      });
    }

    /**
     * Renders the component view.
     * @return {React.Component}
     */
    render() {
      const { text } = this.props;
      return (
        <div className="HistoricEvents">
          <Box
            title={
              <div className="historic-events-header">
                <div>
                  {text.get('components.views.overviewEvents.selectEvent')}
                </div>
                <div>{text.get('components.views.overviewEvents.year')}</div>
                <div></div>
              </div>
            }
          >
            {this.renderEventsList()}
          </Box>
        </div>
      );
    }
  }
);
