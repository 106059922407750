import React from 'react';
import { Menu, MenuItem, Button } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import { useGetText } from 'shared/hooks';

import { EVENT_COMPANY_TYPES } from '../constants';

export default function AddEventCompanyTypeMenu({ onMenuItemClick }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const text = useGetText();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleMenuItemClick(value) {
    onMenuItemClick(value);
    handleClose();
  }

  return (
    <div style={{ marginBottom: 18 }}>
      <Button startIcon={<AddCircleOutlineIcon />} onClick={handleClick}>
        {text.getText('forms.event.clickAddEventCompanyType')}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {Object.values(EVENT_COMPANY_TYPES).map((value) => (
          <MenuItem
            onClick={() => handleMenuItemClick(value)}
            key={`eventCompanyTypeMenuItem.${value}`}
          >
            {text.getText(`forms.event.eventCompanyTypes.${value}`)}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
