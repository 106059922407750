import React from 'react';
import PowerOutlinedIcon from '@material-ui/icons/PowerOutlined';
import { Tooltip } from '@material-ui/core';
import { calculateConnectionsPowerFromObject } from 'spots/utils';
import { useGetText } from 'shared/hooks/';

import './styles.less';

export default function ConnectionTitle({ connection }) {
  const text = useGetText();
  const { quantity, name, connectionSizeType } = connection;
  const connectionsPower = (
    calculateConnectionsPowerFromObject(connection) / 1000
  ).toFixed(1);
  return (
    <div className="connectionTitle">
      <span>{quantity} x </span>
      <PowerOutlinedIcon fontSize="small" />
      <span className="name">{name}</span> -{' '}
      <span className="connectionSizeType.externalId">
        {connectionSizeType.externalId}
      </span>{' '}
      <Tooltip title={text.getText('forms.spot.connectionsTooltip')}>
        <span className="power">{connectionsPower / quantity} kW</span>
      </Tooltip>
    </div>
  );
}
