export const METER_TYPES = {
  LTPRO1: 'LTPro1',
  LTPRO380: 'LTPro380',
  CRAB: 'crab',
};

export const AVAILABLE_METERS = [
  //ToDO: add the other meters
  // { id: 'CM1', name: '', owner: 'Greener', meterType: METER_TYPES.ALGODUE },
  // { id: 'CM2', name: '', owner: 'Greener', meterType: METER_TYPES.ALGODUE },
  // { id: 'CM3', name: '', owner: 'Greener', meterType: METER_TYPES.ALGODUE },
  { id: 'LT1', name: '', owner: 'F&L', meterType: METER_TYPES.LTPRO380 },
  { id: 'LT10', name: '', owner: 'F&L', meterType: METER_TYPES.LTPRO380 },
  {
    id: 'LT101',
    name: '',
    owner: 'Event Engineers',
    meterType: METER_TYPES.LTPRO1,
  },
  {
    id: 'LT102',
    name: '',
    owner: 'Event Engineers',
    meterType: METER_TYPES.LTPRO1,
  },
  {
    id: 'LT103',
    name: '',
    owner: 'Event Engineers',
    meterType: METER_TYPES.LTPRO1,
  },
  {
    id: 'LT104',
    name: '',
    owner: 'Event Engineers',
    meterType: METER_TYPES.LTPRO1,
  },
  {
    id: 'LT105',
    name: '',
    owner: 'Event Engineers',
    meterType: METER_TYPES.LTPRO1,
  },
  {
    id: 'LT106',
    name: '',
    owner: 'Event Engineers',
    meterType: METER_TYPES.LTPRO1,
  },
  {
    id: 'LT107',
    name: '',
    owner: 'Event Engineers',
    meterType: METER_TYPES.LTPRO1,
  },
  {
    id: 'LT108',
    name: '',
    owner: 'Event Engineers',
    meterType: METER_TYPES.LTPRO1,
  },
  {
    id: 'LT109',
    name: '',
    owner: 'Event Engineers',
    meterType: METER_TYPES.LTPRO1,
  },
  { id: 'LT11', name: '', owner: 'F&L', meterType: METER_TYPES.LTPRO380 },
  {
    id: 'LT110',
    name: '',
    owner: 'Event Engineers',
    meterType: METER_TYPES.LTPRO1,
  },
  {
    id: 'LT111',
    name: '',
    owner: 'Event Engineers',
    meterType: METER_TYPES.LTPRO1,
  },
  {
    id: 'LT112',
    name: '',
    owner: 'Event Engineers',
    meterType: METER_TYPES.LTPRO1,
  },
  {
    id: 'LT113',
    name: '',
    owner: 'Event Engineers',
    meterType: METER_TYPES.LTPRO1,
  },
  {
    id: 'LT114',
    name: '',
    owner: 'Event Engineers',
    meterType: METER_TYPES.LTPRO1,
  },
  {
    id: 'LT115',
    name: '',
    owner: 'Event Engineers',
    meterType: METER_TYPES.LTPRO1,
  },
  {
    id: 'LT116',
    name: '',
    owner: 'Event Engineers',
    meterType: METER_TYPES.LTPRO1,
  },
  {
    id: 'LT117',
    name: '',
    owner: 'Event Engineers',
    meterType: METER_TYPES.LTPRO1,
  },
  {
    id: 'LT118',
    name: '',
    owner: 'Event Engineers',
    meterType: METER_TYPES.LTPRO1,
  },
  {
    id: 'LT119',
    name: '',
    owner: 'Event Engineers',
    meterType: METER_TYPES.LTPRO1,
  },
  { id: 'LT12', name: '', owner: 'F&L', meterType: METER_TYPES.LTPRO380 },
  {
    id: 'LT120',
    name: '',
    owner: 'Event Engineers',
    meterType: METER_TYPES.LTPRO1,
  },
  {
    id: 'LT121',
    name: '',
    owner: 'Event Engineers',
    meterType: METER_TYPES.LTPRO1,
  },
  {
    id: 'LT122',
    name: '',
    owner: 'Event Engineers',
    meterType: METER_TYPES.LTPRO1,
  },
  {
    id: 'LT123',
    name: '',
    owner: 'Event Engineers',
    meterType: METER_TYPES.LTPRO1,
  },
  {
    id: 'LT124',
    name: '',
    owner: 'Event Engineers',
    meterType: METER_TYPES.LTPRO1,
  },
  {
    id: 'LT125',
    name: '',
    owner: 'Event Engineers',
    meterType: METER_TYPES.LTPRO1,
  },
  {
    id: 'LT126',
    name: '',
    owner: 'Event Engineers',
    meterType: METER_TYPES.LTPRO1,
  },
  {
    id: 'LT127',
    name: '',
    owner: 'Event Engineers',
    meterType: METER_TYPES.LTPRO1,
  },
  {
    id: 'LT128',
    name: '',
    owner: 'Event Engineers',
    meterType: METER_TYPES.LTPRO1,
  },
  {
    id: 'LT129',
    name: '',
    owner: 'Event Engineers',
    meterType: METER_TYPES.LTPRO1,
  },
  { id: 'LT13', name: '', owner: 'F&L', meterType: METER_TYPES.LTPRO380 },
  {
    id: 'LT130',
    name: '',
    owner: 'Event Engineers',
    meterType: METER_TYPES.LTPRO1,
  },
  { id: 'LT14', name: '', owner: 'F&L', meterType: METER_TYPES.LTPRO380 },
  { id: 'LT16', name: '', owner: 'F&L', meterType: METER_TYPES.LTPRO380 },
  { id: 'LT18', name: '', owner: 'F&L', meterType: METER_TYPES.LTPRO380 },
  { id: 'LT19', name: '', owner: 'F&L', meterType: METER_TYPES.LTPRO380 },
  { id: 'LT2', name: '', owner: 'F&L', meterType: METER_TYPES.LTPRO380 },
  { id: 'LT20', name: '', owner: 'F&L', meterType: METER_TYPES.LTPRO380 },
  { id: 'LT21', name: '', owner: 'F&L', meterType: METER_TYPES.LTPRO380 },
  { id: 'LT22', name: '', owner: 'F&L', meterType: METER_TYPES.LTPRO380 },
  { id: 'LT23', name: '', owner: 'F&L', meterType: METER_TYPES.LTPRO380 },
  { id: 'LT24', name: '', owner: 'F&L', meterType: METER_TYPES.LTPRO380 },
  { id: 'LT25', name: '', owner: 'F&L', meterType: METER_TYPES.LTPRO380 },
  { id: 'LT26', name: '', owner: 'F&L', meterType: METER_TYPES.LTPRO380 },
  { id: 'LT27', name: '', owner: 'F&L', meterType: METER_TYPES.LTPRO380 },
  { id: 'LT28', name: '', owner: 'F&L', meterType: METER_TYPES.LTPRO380 },
  { id: 'LT3', name: '', owner: 'F&L', meterType: METER_TYPES.LTPRO380 },
  { id: 'LT30', name: '', owner: 'F&L', meterType: METER_TYPES.LTPRO380 },
  { id: 'LT4', name: '', owner: 'F&L', meterType: METER_TYPES.LTPRO380 },
  { id: 'LT5', name: '', owner: 'F&L', meterType: METER_TYPES.LTPRO380 },
  { id: 'LT6', name: '', owner: 'F&L', meterType: METER_TYPES.LTPRO380 },
  { id: 'LT7', name: '', owner: 'F&L', meterType: METER_TYPES.LTPRO380 },
  { id: 'LT8', name: '', owner: 'F&L', meterType: METER_TYPES.LTPRO380 },
  { id: 'LT9', name: '', owner: 'F&L', meterType: METER_TYPES.LTPRO380 },
  { id: 'S1', name: '', owner: 'Greener', meterType: METER_TYPES.CRAB },
  { id: 'S2', name: '', owner: 'Greener', meterType: METER_TYPES.CRAB },
  { id: 'S3', name: '', owner: 'Greener', meterType: METER_TYPES.CRAB },
  { id: 'S4', name: '', owner: 'Greener', meterType: METER_TYPES.CRAB },
  { id: 'S5', name: '', owner: 'Greener', meterType: METER_TYPES.CRAB },
  { id: 'S7', name: '', owner: 'Greener', meterType: METER_TYPES.CRAB },
  { id: 'T1', name: '', owner: 'Greener', meterType: METER_TYPES.CRAB },
  { id: 'T2', name: '', owner: 'Greener', meterType: METER_TYPES.CRAB },
  { id: 'T3', name: '', owner: 'Greener', meterType: METER_TYPES.CRAB },
  { id: 'T4', name: '', owner: 'Greener', meterType: METER_TYPES.CRAB },
  { id: 'T5', name: '', owner: 'Greener', meterType: METER_TYPES.CRAB },
];

export const AVAILABLE_METER_IDS = AVAILABLE_METERS.map((meter) => meter.id);

export const ltpro1Fields = [
  'Irms_A_L1_Mean',
  'Irms_A_L1_Max',
  'Load_W_Total_Mean',
  'Load_W_Total_Max',
  'Load_VA_Total_Mean',
  'Load_VA_Total_Max',
  'Vrms_V_L1_Mean',
  'Freq_Hz_Min',
  'Freq_Hz_Max',
  'Energy_usage_Wh_Total',
  'Location_la',
  'Location_lo',
  'Location_al',
  'Signal_sstr',
];

export const ltpro380Fields = [
  'Irms_A_L1_Mean',
  'Irms_A_L1_Min',
  'Irms_A_L1_Max',
  'Irms_A_L2_Mean',
  'Irms_A_L2_Min',
  'Irms_A_L2_Max',
  'Irms_A_L3_Mean',
  'Irms_A_L3_Min',
  'Irms_A_L3_Max',
  'Load_W_Total_Mean',
  'Load_W_Total_Min',
  'Load_W_Total_Max',
  'Load_VA_Total_Mean',
  'Load_VA_Total_Min',
  'Load_VA_Total_Max',
  'Vrms_V_L1_Mean',
  'Vrms_V_L2_Mean',
  'Vrms_V_L3_Mean',
  'Freq_Hz_Min',
  'Freq_Hz_Max',
  'Energy_usage_Wh_L1',
  'Energy_usage_Wh_L2',
  'Energy_usage_Wh_L3',
  'Energy_usage_Wh_Total',
  'Location_la',
  'Location_lo',
  'Location_al',
  'Signal_sstr',
];

export const crabFields = [
  'Irms_A_L1_Mean',
  'Irms_A_L1_Min',
  'Irms_A_L1_Max',
  'Irms_A_L2_Mean',
  'Irms_A_L2_Min',
  'Irms_A_L2_Max',
  'Irms_A_L3_Mean',
  'Irms_A_L3_Min',
  'Irms_A_L3_Max',
  'Load_VA_Total_Mean',
  'Load_VA_Total_Min',
  'Load_VA_Total_Max',
  'Vrms_V_L1_Mean',
  'Location_la',
  'Location_lo',
  'Location_al',
  'Signal_sstr',
];

export const METER_TYPE_FIELD = {
  [METER_TYPES.LTPRO1]: ltpro1Fields,
  [METER_TYPES.LTPRO380]: ltpro380Fields,
  [METER_TYPES.CRAB]: crabFields,
};
