import { action, extendObservable } from 'mobx';

/**
 * PowerzonesStore is the Mobx global data storage for the powerzones data.
 * @class PowerzonesStore
 */
function PowerzonesStore() {
  extendObservable(this, {
    list: [],
    loading: false,
    getPowerzone(uri) {
      return this.list.find((obj) => obj.uri === uri);
    },
    getPowerzoneById(powerzoneId) {
      return this.list.find((obj) => obj._id === parseInt(powerzoneId, 10));
    },
    isLoading: action((value) => {
      this.loading = Boolean(value);
    }),
    registerPowerzones: action((powerzones) => {
      this.list = powerzones;
    }),
    registerNewPowerzone: action((powerzone) => {
      this.list.push(powerzone);
    }),
    updatePowerzone: action((powerzone) => {
      const index = this.list.findIndex((obj) => obj.uri === powerzone.uri);
      if (index > -1) this.list[index] = powerzone;
    }),
    removePowerzone: action((powerzone) => {
      const index = this.list.findIndex((obj) => obj.uri === powerzone.uri);
      if (index > -1) this.list.splice(index, 1);
    }),
    toggleVisibility: action((powerzone, visible) => {
      const index = this.list.findIndex((obj) => obj.uri === powerzone.uri);
      if (index > -1) this.list[index].expanded = visible;
    }),
  });
}
export const powerzones = new PowerzonesStore();
