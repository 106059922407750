// {object[]} CABLE_SIZES - Available cable size types
export const CABLE_SIZES = [
  { value: '16 A (single phase)', label: '16 A (single phase)' },
  { value: '16 A (3 phase)', label: '16 A (3 phase)' },
  { value: '32 A', label: '32 A' },
  { value: '63 A', label: '63 A' },
  { value: '125 A', label: '125 A' },
  { value: 'PL 250', label: 'PL 250' },
  { value: 'PL 400', label: 'PL 400' },
];

// {object} SIZE_VALUE - Object with the cable sizes as the key and it's numeric value as key
export const SIZE_VALUE = {
  '16 A (single phase)': 16,
  '16 A (3 phase)': 16,
  '32 A': 32,
  '63 A': 63,
  '125 A': 125,
  'PL 250': 250,
  'PL 400': 400,
};

// {object} PHASES_NUMBER - Object with the cable sizes as the key and the number of phases as value
export const PHASES_NUMBER = {
  '16 A (single phase)': 1,
  '16 A (3 phase)': 3,
  '32 A': 3,
  '63 A': 3,
  '125 A': 3,
  'PL 250': 3,
  'PL 400': 3,
};

export const AREA_QUERY_KEY = 'areas';
