export function getCompanyMapper(company) {
  if (!company._id) company._id = undefined;
  return company;
}

export function putCompanyMapper(company) {
  if (!company._id) company._id = undefined;
  const {
    line1,
    line2,
    city,
    state,
    country,
    zipCode,
    phones,
    ...rest
  } = company;
  rest.address = {
    line1,
    line2,
    city,
    state,
    country,
    zipCode,
  };
  rest.phones = phones instanceof Array ? phones : [phones];
  return rest;
}
