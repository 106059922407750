// Reference: https://github.com/igorprado/react-notification-system#creating-a-notification

// {object} Type - Notification types object
export const Type = {
  ERROR: 'error',
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
};

// {object} Position - Notification positions object
export const Position = {
  TOP_RIGHT: 'tr',
  TOP_LEFT: 'tl',
  TOP_CENTER: 'tc',
  BOTTOM_RIGHT: 'br',
  BOTTOM_LEFT: 'bl',
  BOTTOM_CENTER: 'bc',
};
