/* eslint-disable no-use-before-define */
import * as Action from 'shared/actions';
import { General } from 'shared/constants';
import { events as eventsStore } from 'events/store';
import * as Sentry from '@sentry/react';

import { auth as authStore } from './store';
import * as Services from './service';

// {string} TOKEN_KEY - Constant id for the token in the localstorage
const TOKEN_KEY = 'bx9874nt238b4xt23492atbzn2n3xn48x23t4x383';

// {string} USER_KEY -  Constant id for the user data in the localstorage
const USER_KEY = 'bc478x0187btz071nzb1x1vc17x0nbxzn0bz08zbz';

/**
 * Takes the user's email and password as input and authenticates in the API.
 * @param {string} email - User e-mail
 * @param {string} pass - User password
 * @return {Promise}
 */
export const login = (email, pass) => Services.login(email, pass);

/**
 * Destroy's the current user session and redirect to the login page.
 * @return {void}
 */
export const logoff = () => {
  destroyUser();
  destroyToken();
  authStore.logoff();
  Action.replaceRoute('/login');
  eventsStore.registerEvents([]);
  Sentry.configureScope((scope) => scope.setUser(null));
};

/**
 * Takes the user's data and token to register in the authStore so it can be accesible
 * in the whole application.
 * @param {object} user - User data
 * @param {string} token - Auth token
 * @return {void}
 */
export const registerLoggedInUser = (user, token) => {
  authStore.registerLoginData(user, token);
  saveUser(user);
  saveToken(token);
  Action.changeLanguage(user.language);
  const { _id, role, name, email, language, companyId, phones } = user;
  Sentry.setUser({ id: _id, role, name, email, language, companyId, phones });
};

/**
 * Takes the user's data and puts it to the registeredUser object
 * @param {object} user - User data
 * @return {void}
 */
export const setUpRegisteredUser = (user) => authStore.setRegisteredUser(user);

/**
 * Takes the user's data to update it in the authStore so it can be accesible
 * in the whole application.
 * @param {object} user - User data
 * @return {void}
 */
export const registerUser = (user) => authStore.updateUser(user);

/**
 * Takes a user data as input, encrypts, and save it to the localstorage so it can be loaded later.
 * @param {object} user - User data
 * @return {void}
 */
export const saveUser = (user) =>
  Action.save(USER_KEY, Action.encrypt(JSON.stringify(user)));

/**
 * Loads the user data from the localstorage and decrypts it.
 * @return {object}
 */
export const loadUser = () => {
  const tmp = Action.load(USER_KEY);
  return tmp ? JSON.parse(Action.decrypt(tmp)) : null;
};

/**
 * Removes the user data from the localstorage.
 * @return {void}
 */
export const destroyUser = () => Action.remove(USER_KEY);

/**
 * Loads the user data stored in the localstorage to the user store.
 * @return {void}
 */
export const loadActiveUser = () => {
  const profile = loadUser();
  const token = loadToken();
  if (profile && token) {
    registerLoggedInUser(profile, token);
    refreshToken(token);
  }
};

/**
 * Takes the user's token to update in the authStore so it can be accesible
 * in the whole application.
 * @param {string} token - Auth token
 * @return {void}
 */
export const registerToken = (token) => {
  authStore.updateToken(token);
  saveToken(token);
};

/**
 * Takes a token as input and send it to the API to refresh it and update the store.
 * @param {string} token - User auth token
 * @return {Promise}
 */
export const refreshToken = (token) => Services.refreshToken(token);

/**
 * Takes a token as input, encrypts, and save it to the localstorage so it can be loaded later.
 * @param {string} token - User auth token
 * @return {void}
 */
export const saveToken = (token) =>
  Action.save(TOKEN_KEY, Action.encrypt(token));

/**
 * Loads the token from the localstorage and decrypts it.
 * @return {string}
 */
export const loadToken = () => Action.decrypt(Action.load(TOKEN_KEY) || '');

/**
 * Removes the token from the localstorage.
 * @return {void}
 */
export const destroyToken = () => Action.remove(TOKEN_KEY);

/**
 * Takes the user's email so it can be sent to the API to recover the password.
 * @param {string} email - User email
 * @return {Promise}
 */
export const recoverPassword = (email) => Services.recoverPassword(email);

/**
 * Takes an object with the new password so it can be sent to the API to update the password.
 * @param {object} data - Object with new password
 * @return {Promise}
 */
export const updatePassword = (data) => Services.updatePassword(data);

/**
 * Takes an uuid code as input and sends it to the API to check if the user state is still
 * pending and needs to be filled.
 * @param {string} uuid - User uuid
 * @return {Promise}
 */
export const userPending = (uuid) => Services.userPending(uuid);

/**
 * Takes an permission value (or list of) and check if the current user permission is valid.
 * @param {number|number[]} permission - Allowed permission(s)
 * @return {boolean}
 */
export const userHasPermission = (permission) => {
  if (!authStore || !authStore.profile) return false;
  // TODO apply check that prevens next row to fail when there is no authorasation
  const userRole = parseInt(authStore.profile.role, 10);

  const permissionValue =
    permission instanceof Array
      ? permission.reduce((a, b) => a + b, 0)
      : permission;

  // eslint-disable-next-line no-unused-expressions
  General.DEBUG &&
    console.log(
      `User permission level: ${authStore.profile.role} | Required permission level: ${permissionValue}`
    );
  // eslint-disable-next-line no-bitwise
  return (userRole & permissionValue) > 0;
};

/**
 * Takes an uuid code as input and sends it to the API to get user profile
 * pending and needs to be filled.
 * @param {string} uuid - User uuid
 * @return {Promise}
 */
export const getUserProfile = (uuid) => Services.getUserProfile(uuid);
