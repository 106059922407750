import React from 'react';
import './styles.less';

/**
 * Card component
 * @param {object} props - React component properties
 * @return {React.Component}
 */
export const Card = ({ className, ...props }) => (
  <div className={`Card ${className || ''}`} {...props} />
);
