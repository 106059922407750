import React from 'react';
import { observer, inject } from 'mobx-react';
import { compose } from 'recompose';

import { Checkbox, DateRange } from 'shared/components/form';
import { calcEventStartEndDates } from 'shared/utils/';

import './styles.less';

const enhance = compose(inject('text', 'view', 'event'), observer);

/**
 * ConnectionRow class represents the spot extra form row component with the fields
 * to fill the extra information about the spot.
 * @class ConnectionRow
 * @extends {React.Component}
 */

export default enhance(
  class ConnectionRow extends React.Component {
    /**
     * Renders the mobile mode of the view.
     * @return {React.Component}
     */

    renderMobile() {
      const { event, field, text } = this.props;
      return (
        <div className="ConnectionExtraRow mobile">
          <div className="connectionExtraRowHeader">
            <span className="name">{field.$('name').value}</span>
            <DateRange
              field={field}
              maxDate={calcEventStartEndDates(event, 'end')}
              minDate={calcEventStartEndDates(event, 'start')}
              label={text.get('forms.spot.dateInterval')}
            />
            <Checkbox field={field.$('nightPower')} />
          </div>
        </div>
      );
    }

    /**
     * Renders the desktop mode of the view.
     * @return {React.Component}
     */
    renderDesktop() {
      const { event, field, text } = this.props;
      const upsFieldIsActive = event.upsFieldIsActive;
      return (
        <div className="ConnectionExtraRow">
          <div className="connectionExtraRowHeader">
            <span className="name">{field.$('name').value}</span>
            <span className="dateRange">
              <DateRange
                field={field}
                startDate={field.startDate}
                endDate={field.endDate}
                minDate={calcEventStartEndDates(event, 'start')}
                maxDate={calcEventStartEndDates(event, 'end')}
                label={text.get('forms.spot.dateInterval')}
              />
            </span>
          </div>
          <div className="inputRow">
            <div>
              <span className="checkbox">
                <Checkbox
                  field={field.$('nightPower')}
                  label={text.get('forms.spot.nightPowerUser')}
                />
                {console.warn(event)}
              </span>
              {upsFieldIsActive && (
                <span className="checkbox">
                  <Checkbox
                    field={field.$('upsNeeded')}
                    label={text.get('forms.spot.upsNeededUser')}
                  />
                  {console.warn(event)}
                </span>
              )}
            </div>
          </div>
        </div>
      );
    }

    /**
     * Renders the component view.
     * @return {React.Component}
     */
    render() {
      return this.props.view.isDesktop
        ? this.renderDesktop()
        : this.renderMobile();
    }
  }
);
