// Note: ratedPowerKva is calculated like this:
// (0.95 * 230 * ratedCurrentA) / 1000 * phase-nr

export const CONNECTIONS_ONE_PHASE = [
  {
    label: '16A / 230V Schuko',
    externalId: '16A-1P-230V-Schuko',
    connectorType: 'CEE',
    ratedCurrentA: 16,
    phases: 1,
    ratedPowerKva: 3.496,
    voltageV: 230,
    img: '16a_schuko.jpg',
  },
  {
    label: '16A / 230V CEE',
    externalId: '16A-1P-230V-CEE',
    connectorType: 'CEE',
    ratedCurrentA: 16,
    phases: 1,
    ratedPowerKva: 3.496,
    voltageV: 230,
    img: '16a_cee.jpeg',
  },
  {
    label: '32A / 230V CEE',
    externalId: '32A-1P-230V-CEE',
    connectorType: 'CEE',
    ratedCurrentA: 32,
    phases: 1,
    ratedPowerKva: 6.992,
    voltageV: 230,
    img: '32a_cee.png',
  },
  {
    label: '63A / 230V CEE',
    externalId: '63A-1P-230V-CEE',
    ratedCurrentA: 63,
    phases: 1,
    ratedPowerKva: 13.48,
    voltageV: 230,
    img: '63a_cee.jpg',
  },
];

export const CONNECTIONS_THREE_PHASE = [
  {
    label: '16A / 400V ',
    externalId: '16A-3P-400V-CEE',
    connectorType: 'CEE',
    ratedCurrentA: 16,
    phases: 3,
    ratedPowerKva: 10.0,
    voltageV: 400,
    img: '16a_3fase.png',
  },
  {
    label: '32A / 400V',
    externalId: '32A-3P-400V-CEE',
    connectorType: 'CEE',
    ratedCurrentA: 32,
    phases: 3,
    ratedPowerKva: 20.976,
    voltageV: 400,
    img: '32a.jpg',
  },
  {
    label: '63A / 400V',
    externalId: '63A-3P-400V-CEE',
    connectorType: 'CEE',
    ratedCurrentA: 63,
    phases: 3,
    ratedPowerKva: 42.29,
    voltageV: 400,
    img: '63a.png',
  },
  {
    label: '125A /400V',
    externalId: '125A-3P-400V-CEE',
    connectorType: 'CEE',
    ratedCurrentA: 125,
    phases: 3,
    ratedPowerKva: 81.93,
    voltageV: 400,
    img: '125a.png',
  },
  {
    label: 'Powerlock 250A / 400V',
    externalId: '250A-3P-400V-PowerLock',
    connectorType: 'PowerLock',
    ratedCurrentA: 250,
    phases: 3,
    ratedPowerKva: 163.875,
    voltageV: 400,
    img: 'powerlock.png',
  },
  {
    label: 'Powerlock 400A / 400V',
    externalId: '400A-3P-400V-PowerLock',
    connectorType: 'PowerLock',
    ratedCurrentA: 400,
    phases: 3,
    ratedPowerKva: 262.2,
    voltageV: 400,
    img: 'powerlock.png',
  },
];

export const ALL_CONNECTIONS = [
  ...CONNECTIONS_ONE_PHASE,
  ...CONNECTIONS_THREE_PHASE,
];

//regex check to match pattern: 400A-3P-400V-PowerLock
export const CONNECTIONS_EXTERNAL_ID_REGEX = /\d+A-[123]P-\d+V-\w+/;
