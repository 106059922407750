import { useState, useEffect } from 'react';

export const getView = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    isDesktop: () => width > 768,
    isTablet: () => width > 544 && width <= 768,
    isMobile: () => width <= 544,
    width,
    height,
  };
};

export function useGetView() {
  const [view, setView] = useState(getView());

  useEffect(() => {
    function handleResize() {
      setView(getView());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return view;
}
