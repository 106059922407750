import React from 'react';

export const Area = (props) => (
  <svg
    width="24px"
    height="24px"
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256.000000 256.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)">
        <path
          d="M1142 2270 c-104 -27 -187 -75 -263 -151 -207 -205 -241 -518 -83
-766 18 -28 87 -113 154 -190 146 -165 199 -238 254 -345 66 -130 84 -130 152
0 55 107 110 182 229 317 223 251 276 361 276 565 0 167 -52 293 -170 410
-156 155 -352 212 -549 160z m273 -303 c59 -30 104 -75 136 -137 19 -36 24
-61 24 -125 0 -91 -20 -146 -74 -208 -81 -92 -237 -120 -353 -64 -135 66 -198
216 -152 363 24 78 108 165 180 188 72 23 176 16 239 -17z"
        />
        <path
          d="M412 1163 c-25 -22 -412 -817 -412 -847 0 -12 5 -27 12 -34 17 -17
2519 -17 2536 0 7 7 12 22 12 34 0 30 -387 825 -412 847 -18 15 -43 17 -200
17 -173 0 -180 -1 -201 -23 -47 -49 -137 -149 -137 -153 0 -2 93 -4 208 -4
114 0 212 -4 219 -8 6 -4 70 -127 141 -275 l131 -267 -1029 0 -1029 0 131 267
c71 148 135 271 141 275 7 4 105 8 220 8 114 0 207 2 207 4 0 3 -12 17 -27 33
-16 16 -52 55 -82 86 l-53 57 -178 0 c-155 0 -180 -2 -198 -17z"
        />
      </g>
    </svg>
  </svg>
);
