import { ensureDate, ensureString } from 'shared/utils';
import _ from 'lodash';

/**
 * Fills the URI field.
 * @param {object} powerzone - Powerzone object
 * @param {string} eventId - Event id
 * @return {string}
 */
const uriMapper = (powerzone, eventId) =>
  powerzone.uri || `/events/${eventId}/powerzones/${powerzone._id}`;

export const powerSourceObjectToArrayMapper = (powerSources) => {
  if (!powerSources) return [];
  // return all power sources in an array with the key name as type property
  let outputArr = [];
  Object.entries(powerSources).forEach(([type, psArr]) => {
    outputArr.push(...psArr.map((ps) => ({ ...ps, type })));
  });
  return outputArr;
};

/**
 * Maps the powerzone fields to guarantee consistency of the fields within the system.
 * @param {string} eventId - Event id
 * @return {function} (powerzone) => {object}
 */
export const getPowerzoneMapper = (eventId) => (powerzone) => {
  const { _id } = powerzone;
  const uri = uriMapper(powerzone, eventId);
  const label = ensureString(powerzone.label);
  eventId = parseInt(eventId, 10);
  const createdAt = ensureDate(powerzone.createdAt);
  const updatedAt = ensureDate(powerzone.updatedAt);

  //Supply related fields
  const theoreticalPowerKw = powerzone.supply?.theoreticalPowerKw || 0;

  const powerSources = powerSourceObjectToArrayMapper(powerzone.powerSources);
  const demand = powerzone.demand || {};
  const comment = ensureString(powerzone.comment);
  const powerOnDate = ensureDate(powerzone.powerOnDate);
  const powerOffDate = ensureDate(powerzone.powerOffDate);
  const startShow = ensureDate(powerzone.startShow);
  const endShow = ensureDate(powerzone.endShow);
  return {
    _id,
    uri,
    label,
    eventId,
    createdAt,
    updatedAt,
    demand,
    supply: {
      theoreticalPowerKw,
    },
    powerSources,
    comment,
    powerOnDate,
    powerOffDate,
    startShow,
    endShow,
  };
};

export const putPowerzoneMapper = (eventId) => (powerzone) => {
  const {
    _id,
    uri,
    label,
    eventId,
    createdAt,
    updatedAt,
    powerSources,
    comment,
    powerOnDate,
    powerOffDate,
    startShow,
    endShow,
    ...rest
  } = powerzone;

  return {
    _id,
    uri,
    label,
    eventId,
    createdAt,
    updatedAt,
    powerSources: _.groupBy(powerSources, 'type'), // organise powerSources in object by type
    comment,
    powerOnDate: ensureDate(powerOnDate),
    powerOffDate: ensureDate(powerOffDate),
    startShow: ensureDate(startShow),
    endShow: ensureDate(endShow),
    ...rest,
  };
};
