import React from 'react';
import 'react-dropzone-uploader/dist/styles.css';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import ModalFooter from '../modal/modalFooter';
import '../styles.less';

/**
 * SecondStepOfUploadingAccount component represents the second step of uploading accounts
 * @class SecondStepOfUploadingAccount
 * @extends {React.Component}
 */
const SecondStepOfUploadingAccount = (props) => {
  const {
    text,
    step,
    accounts,
    handleSubmit,
    showConfirm,
    showConfirmModal,
    backToPrevStep,
    importResult: {
      summary,
      validations: { headers, fields },
    },
  } = props;

  function renderMessages(messages) {
    return (
      <ul>
        {messages.map((msg, i) => (
          <li key={i}>{msg}</li>
        ))}
      </ul>
    );
  }

  const [headerErrors, headerWarnings] = [
    headers?.errors || null,
    headers?.warnings || null,
  ];
  const [hasHeaderErrors, hasHeaderWarnings] = [
    headerErrors?.length > 0,
    headerWarnings?.length > 0,
  ];
  const [fieldErrors, fieldWarnings] = [fields?.errors, fields?.warnings];
  const [hasFieldErrors, hasFieldWarnings] = [
    fieldErrors?.length > 0,
    fieldWarnings?.length > 0,
  ];
  const isErrorsExist = hasFieldErrors || hasHeaderErrors;
  const hasValidations = isErrorsExist || hasHeaderWarnings || hasFieldWarnings;

  const textLocation = 'components.views.powerInquiries.importAccounts.';
  return (
    <>
      <div className="modalBody">
        {isErrorsExist ? (
          <div className="validation-error">
            <ErrorIcon color="error" />{' '}
            {text.get(textLocation + 'errorMessage')}
          </div>
        ) : (
          <>
            <span className="validation-header">
              {text.get(textLocation + 'summary')}
            </span>
            <ul>
              {summary.map((item, i) => (
                <li key={i}>{item}</li>
              ))}
            </ul>
          </>
        )}
        {hasValidations ? (
          <>
            {!isErrorsExist && (
              <div className="validation-warnings">
                <WarningIcon className="icon-warning" />{' '}
                {text.get(textLocation + 'warnings')}
              </div>
            )}
            <span className="validation-header">
              {text.get(textLocation + 'validation')}
            </span>
            <div className="validation-window">
              {hasHeaderErrors && (
                <>
                  <p className="error-title">
                    {text.get(textLocation + 'headerErrors')}
                  </p>
                  {renderMessages(headerErrors)}
                </>
              )}

              {hasFieldErrors && (
                <>
                  <p className="error-title">
                    {text.get(textLocation + 'fieldErrors')}
                  </p>
                  {renderMessages(fieldErrors)}
                </>
              )}

              {hasHeaderWarnings && (
                <>
                  <p className="warning-title">
                    {text.get(textLocation + 'headerWarnings')}
                  </p>
                  {renderMessages(headerWarnings)}
                </>
              )}

              {hasFieldWarnings && (
                <>
                  <p className="warning-title">
                    {text.get(textLocation + 'fieldWarnings')}
                  </p>
                  {renderMessages(fieldWarnings)}
                </>
              )}
            </div>
          </>
        ) : (
          <div className="validation-no-errors-warnings">
            <CheckCircleIcon className="icon-success" />{' '}
            {text.get(textLocation + 'noValidations')}
          </div>
        )}
      </div>
      <ModalFooter
        {...{
          text,
          step,
          showConfirm,
          showConfirmModal,
          backToPrevStep,
          isErrorsExist,
        }}
        disabledRuleForNextBtn={
          isErrorsExist || (accounts && accounts.length < 1)
        }
        handleSubmit={() => handleSubmit(isErrorsExist ? 1 : 3)}
      />
    </>
  );
};

export default SecondStepOfUploadingAccount;
