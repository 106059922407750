import { useQuery } from 'react-query';
import { api } from 'shared/utils';
import { notify } from 'shared/actions';
import { Notifications } from 'shared/constants';

import { getApplianceMapper } from '../mapper';
import { APPLIANCES_QUERY_KEY } from 'appliances/constants';

const doGetAppliances = async (searchParams) => {
  const { data } = await api.get(`/appliancetemplates`, {
    //params: searchParams,
  });
  return getApplianceMapper(data);
};

export const useGetAppliances = (searchParams) => {
  // Load the Appliances based on userId, brand and categories

  const { data, error, status, ...others } = useQuery(
    [APPLIANCES_QUERY_KEY, 'list', searchParams || 'all'],
    () => doGetAppliances(searchParams),
    {
      onError: (error, variables) => {
        notify({
          message: error.message,
          level: Notifications.Type.ERROR,
        });
        return error;
      },
    }
  );

  return {
    ...others,
    loading: status === 'loading',
    data: data || [],
    error,
  };
};
