import { useCallback } from 'react';
import { useQueryClient, useMutation } from 'react-query';

import { api } from 'shared/utils';

import userMapper from '../mapper';

import { USERS_QUERY_KEY } from './useGetUsers';

const doCreateUser = (user) =>
  api.post('/users', userMapper(user)).then(({ data }) => data);

export const useCreateUser = () => {
  const queryClient = useQueryClient();
  const { mutate, status, ...others } = useMutation(doCreateUser, {
    onSuccess: (response) => {
      if (response) {
        queryClient.refetchQueries(USERS_QUERY_KEY);
      }
    },
  });

  const createUser = useCallback((user) => mutate(user), [mutate]);

  return {
    ...others,
    loading: status === 'loading',
    createUser,
  };
};
