import { useCallback } from 'react';
import { useQueryClient, useMutation } from 'react-query';

import { api } from 'shared/utils';
import { notify } from 'shared/actions';
import { Notifications } from 'shared/constants';

import { putEventMapper } from '../mapper';
import { eventKeys } from 'shared/utils/api/queryKeys';

const doUpdateEvent = async (event) =>
  await api.put(`/events/${event._id}`, putEventMapper(event));

export const useUpdateEvent = () => {
  const queryClient = useQueryClient();
  const { mutateAsync, status, ...others } = useMutation(doUpdateEvent, {
    onSuccess: ({ data, status }, variables) => {
      if (status === 200) {
        // 🥳 invalidate all the event Queries
        queryClient.invalidateQueries({
          queryKey: eventKeys.all(),
        });

        //Notify the user
        notify({
          message: `Successfully updated event: ${variables.festival}`,
          level: Notifications.Type.SUCCESS,
        });
      } else {
        notify({
          message: `Failed updating event: ${variables.festival}`,
          level: Notifications.Type.ERROR,
        });
      }

      return data;
    },
  });

  const updateEvent = useCallback((event) => mutateAsync(event), [mutateAsync]);

  return {
    ...others,
    loading: status === 'loading',
    updateEvent,
  };
};
