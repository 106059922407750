import React, { forwardRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import LocationSearchingIcon from '@material-ui/icons/LocationSearching';
import PowerIcon from '@material-ui/icons/PowerOutlined';
import MaterialTable from 'material-table';

import { useGetText } from 'shared/hooks';
import { pushRoute } from 'shared/actions';
/**
 * ReportTable Component class represents the Spot's overview table with all the spot information.
 * The table can also be rendered to show all data from an event on connection level.
 * @class ReportTable
 * @extends {React.Component}
 * @param {object} props - React component properties
 * An example how to make this table editable: https://stackblitz.com/edit/react-tfmztw?embed=1&file=AppTable.js
 */

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => (
    <FilterList {...props} ref={ref} fontSize="small" />
  )),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export function ReportTable({
  title,
  data,
  onRowClick,
  toggleRowByConnections,
  rowByConnections,
  rowByConnectionsAction,
  options,
  customActions,
  textLocation,
  customColumnSettings,
  customLocalization,
  ...rest
}) {
  const { getText, isLoading } = useGetText();
  const [filterRowVisible, setFilterRowVisible] = useState(false);
  const [groupRowVisible, setGroupRowVisible] = useState(false);
  const [columns, setColumns] = useState(
    getColumnObjFromData(data, getText, textLocation)
  );
  const location = window.location;
  const path = location.pathname;
  var params = new URLSearchParams(location.search);
  const page = parseInt(params.get('page'));
  const searchText = params.get('searchText');

  /**
   * create columns object from spot data based on available columns
   * @param {[object]} data - List with data objects
   * @param {[object]} text - Text file
   * @return {[Object]}
   */
  function getColumnObjFromData(data, getText, textLocation) {
    let list = [];
    data.length > 0 &&
      Object.keys(data[0]).forEach((item) => {
        let obj = {
          field: item,
          title: getText(`${textLocation}.${item}`),
        };
        list.push(obj);
      });
    //add additional column attributes
    if (data.length > 0) {
      list.forEach((target) => {
        customColumnSettings[target.field] &&
          Object.assign(target, customColumnSettings[target.field]);
      });
    }
    return list;
  }

  useEffect(() => {
    !isLoading && setColumns(getColumnObjFromData(data, getText, textLocation));
  }, [isLoading, data]); //updating all deps will cause an infinite loop

  const defaultOptions = {
    rowStyle: {
      fontSize: 14,
      padding: 2,
    },
    headerStyle: {
      fontWeight: 'bold',
    },
    addRowPosition: 'first',
    exportButton: true,
    exportAllData: true,
    exportDelimiter: ';',
    columnsButton: true,
    pageSizeOptions: [20, 50, 100, 200],
    ...(searchText && { searchText: decodeURIComponent(searchText) }),
    ...(page && { initialPage: page }),
  };

  const defaultActions = [
    {
      isFreeAction: true,
      icon: FilterList,
      tooltip: 'Show/hide filter bar',
      onClick: () => setFilterRowVisible(!filterRowVisible),
    },
    {
      isFreeAction: true,
      icon: ChevronRight,
      tooltip: 'Show/hide grouping bar',
      onClick: () => setGroupRowVisible(!groupRowVisible),
    },
    rowByConnectionsAction && {
      isFreeAction: true,
      tooltip: rowByConnections
        ? 'Show one row per spot'
        : 'Show one row per connection',
      icon: rowByConnections ? LocationSearchingIcon : PowerIcon,
      onClick: () => toggleRowByConnections(),
    },
  ];

  const defaultLocalization = {};

  function handleParamChange(key, value) {
    params.set(key, encodeURIComponent(value));
    pushRoute(`${path}?${params.toString()}`);
  }

  const actions = customActions?.length
    ? defaultActions.concat(customActions)
    : defaultActions;
  const localization = { defaultLocalization, ...customLocalization };
  return (
    <MaterialTable
      isLoading={isLoading}
      title={title}
      columns={columns}
      data={JSON.parse(JSON.stringify(data))}
      onRowClick={onRowClick}
      options={{
        ...defaultOptions,
        ...options,
        filtering: filterRowVisible,
        grouping: groupRowVisible,
      }}
      icons={tableIcons}
      actions={actions}
      localization={localization}
      onChangePage={(page) => handleParamChange('page', page)}
      onSearchChange={(text) => handleParamChange('searchText', text)}
      {...rest}
    />
  );
}

//proptypes
ReportTable.propTypes = {
  //Required
  data: PropTypes.arrayOf(PropTypes.object).isRequired,

  //optional
  title: PropTypes.string,
  options: PropTypes.object,
  actions: PropTypes.array,
  onRowClick: PropTypes.func,
  toggleRowByConnections: PropTypes.func,
  rowByConnections: PropTypes.bool,
  columnsOverride: PropTypes.arrayOf(PropTypes.object),
  textLocation: PropTypes.string,
  customColumnSettings: PropTypes.object,
  customLocalization: PropTypes.object,
};
