export default (text) => ({
  'layout.connections[].name': text.name,
  'layout.connections[].connectionSizeType.externalId':
    text.connectionSizeType.externalId,
  'layout.connections[].connectionSizeType.price':
    text.connectionSizeType.price,
  'layout.connections[].nightPower': text.nightPower,
  'layout.connections[].upsNeeded': text.upsNeeded,
  'layout.connections[].hasArrived': text.hasArrived,
  'layout.connections[].isConnected': text.isConnected,
  'layout.connections[].devices[].name': text.deviceName,
  'layout.connections[].devices[].brand': text.brand,
  'layout.connections[].devices[].modelNumber': text.modelNumber,
  'layout.connections[].devices[].ratedPower': text.ratedPower,
  'layout.connections[].devices[].quantity': text.quantity,
  'layout.connections[].devices[].unit': text.unit,
});
