import { action, extendObservable } from 'mobx';

/**
 * ViewStore is the Mobx global data storage for the view data.
 * @class ViewStore
 */
function ViewStore() {
  extendObservable(this, {
    width: -1,
    height: -1,
    get isDesktop() {
      return this.width > 768;
    },
    get isTablet() {
      return this.width > 544 && this.width <= 768;
    },
    get isMobile() {
      return this.width <= 544;
    },
    updateDimensions: action(() => {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
    }),
  });
  this.updateDimensions();
  return this;
}
export default new ViewStore();
