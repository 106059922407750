import React from 'react';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';

import { SpotList } from 'shared/components';
import { Button, RichTextInput, TextInput } from 'shared/components/form';
import { formCreator } from 'shared/form';

import { SpotModalForm } from 'spots/components';

import fields from './fields';
import labelsConfig from './labels';
import rules from './rules';
import './styles.less';

const enhance = compose(inject('text', 'users'), observer);

function makeid() {
  var text = '';
  var possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (var i = 0; i < 30; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
}

/**
 * PowerInquiryInvitationForm class representes the energy user form component.
 * @class PowerInquiryInvitationForm
 * @extends {React.Component}
 */
export const PowerInquiryReminderForm = enhance(
  class extends React.Component {
    // {object} state - Component state holds the form instance.
    state = { form: null, spots: [] };

    // {object} config - Form configuration getter
    get config() {
      const { onSuccess, onError, text, event } = this.props;
      const title = 'powerInquiryInvitationForm';
      const labels = labelsConfig(text.get('forms.powerInquiries'));
      return {
        title,
        fields,
        labels,
        rules,
        onSuccess: (form) => {
          const name = form.$('name').value;
          const template = form.$('message').value;

          form.$('message').onChange(template);
          onSuccess(form, this.state.spots, name, event.festival);
        },
        onError,
      };
    }

    get empty() {
      const { text, event, user, spots } = this.props;
      const emailTitle = text
        .get('forms.powerInquiries.emailTitle')
        .replace('{festival}', event.festival);
      let spotList = [];
      spots && spots.map((item) => spotList.push({ label: item.label }));
      this.setState({ spots: spotList });
      let form = {
        userId: user && user.userId ? user.userId : '',
        emailTitle,
        message: '',
        spots: spotList || [],
        _id: null,
      };
      return form;
    }

    get emailField() {
      return document.getElementById('email');
    }

    get internalForm() {
      return this.state.form;
    }
    /**
     * React lifecycle method - executed everytime the component is about to be mounted in the view.
     * @return {void}
     */
    UNSAFE_componentWillMount() {
      const { values } = this.props;
      const form = formCreator(this.config);
      this.setState({ form });
      form.init({ ...this.empty, ...values }); // making sure everytime you select a new user, his data will appear
    }

    /**
     * React lifecycle method - executed everytime the component is about to be mounted in the view.
     * @return {void}
     */
    componentDidMount() {
      if (!this.props.isReminder) this.emailField.focus();
      if (this.props.isReminder)
        this.refs.message.update(this.props.inviteMessage);
    }

    updateForm(values) {
      const { form } = this.state;
      const { message } = this.refs;
      form.init({ ...this.empty, ...values }); // making sure everytime you select a new user, his data will appear
      if (!this.props.isReminder) {
        const spots = values.spots.map((spot) => {
          if (!spot.label) {
            return { label: spot, id: makeid() };
          }
          return spot;
        });
        message.update(values.message);

        this.setState({
          spots,
        });
      }
    }

    onBlurEmail(e, field) {
      const { text, users } = this.props;
      const { form } = this.state;
      if (!field.value) return;
      const user = users.getUserByEmail(field.value);
      if (user) {
        form.$('name').value = user.name;
        form.$('companyName').value = user.companyName;
        form.$('_id').value = user._id;
      }
      let current = text.get('forms.powerInquiries.defaultMessage');

      if (this.state.form.$('message').value) {
        current = this.state.form.$('message').value;
      }
      this.refs.message.update(current);

      field.onBlur(e);
    }

    onBlurName(e, field) {
      const { text } = this.props;
      let current = text.get('forms.powerInquiries.defaultMessage');

      if (this.state.form.$('message').value) {
        current = this.state.form.$('message').value;
      }
      this.refs.message.update(current);
      field.onBlur(e);
    }

    onSubmit = (e) => {
      if (!this.props.isReminder) {
        this.state.form.onSubmit(e);
        setTimeout(() => {
          this.setState({ spots: [] });
          this.state.form.reset();
          this.refs.message.reset();
        }, 1);
      } else {
        this.props.onSuccess(this.state.form.$('message').value);
      }
    };

    removeSpot = (spot) => {
      this.setState({
        spots: [...this.state.spots.filter((sp) => spot.label !== sp.label)],
      });
    };
    onClickNewSpot = () => {
      this.refs.spotModal.wrappedInstance.updateForm({});
      this.refs.spotModal.wrappedInstance.showForm();
    };
    onClickEdit = (item) => {
      this.setState({ editItem: item });
      this.refs.spotModal.wrappedInstance.updateForm({ label: item.label });
      this.refs.spotModal.wrappedInstance.showForm();
    };
    onSaveSpot = (form) => {
      const { editItem, spots } = this.state;
      let useFullSpots = spots;
      if (editItem) {
        useFullSpots = useFullSpots.filter(
          (item) => item.label !== editItem.label
        );
      }
      const data = form.values();
      this.setState({ spots: [...useFullSpots, data] });
      this.refs.spotModal.wrappedInstance.hideForm();
    };
    /**
     * Renders the component view.
     * @return {React.Component}
     */
    render() {
      const { text } = this.props;
      const { form } = this.state;
      if (!form) return null;

      return (
        <form
          autoComplete="new-password"
          className="PowerInquiryInvitationForm"
        >
          <input type={'hidden'} value={makeid()} />

          <SpotModalForm
            ref="spotModal"
            onSuccess={this.onSaveSpot}
            cancelHandler={() => {
              this.refs.spotModal.wrappedInstance.hideForm();
              this.setState({ editItem: null });
            }}
          />

          {!this.props.isReminder && (
            <React.Fragment>
              <div className="inputRow">
                <TextInput
                  id="email"
                  field={form.$('email')}
                  type="text"
                  onBlur={(e) => this.onBlurEmail(e, form.$('email'))}
                  autoComplete="new-password"
                />
                <TextInput
                  field={form.$('name')}
                  type="text"
                  onBlur={(e) => this.onBlurName(e, form.$('name'))}
                  autoComplete="new-password"
                />
                <TextInput
                  field={form.$('companyName')}
                  type="text"
                  autoComplete="new-password"
                />
              </div>
              <div className="inputRow">
                <div className="row">
                  <SpotList
                    removeAble
                    spots={this.state.spots || []}
                    onEdit={(item) => this.onClickEdit(item)}
                    onRemove={(item) => this.removeSpot(item)}
                    text={text}
                  />
                  {!this.props.isReminder && (
                    <span
                      className="link"
                      onClick={this.onClickNewSpot}
                      children={text.get('forms.powerInquiries.addSpot')}
                    />
                  )}
                </div>
              </div>
              <div className="inputRow">
                <TextInput
                  field={form.$('emailTitle')}
                  type="text"
                  autoComplete="new-password"
                />
              </div>
            </React.Fragment>
          )}

          <div className="inputRow messageBox">
            <RichTextInput ref="message" field={form.$('message')} />
          </div>
          <div className="buttonRow" name="save button">
            <Button
              title={text.get('forms.powerInquiries.noSpotHover')}
              type="button"
              styling="cta"
              size="small"
              onClick={this.onSubmit}
              disabled={
                form.hasError ||
                (this.state.spots.length < 1 && !this.props.isReminder) ||
                form.pristine
              }
              children={text.get('actions.save')}
            />
          </div>
        </form>
      );
    }
  }
);
