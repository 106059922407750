import getIntl from 'intl';

// All available languages must be imported here.
import NL from './nl.json';
import EN from './en.json';

// All available languages must be exported here in order to be selectable in the language selector.
export { NL, EN };

export default getIntl;
