import React, { createRef } from 'react';

import { Modal } from './modal';

export class ModalForm extends React.Component {
  constructor(props) {
    super(props);
    this.modalRef = createRef();
  }

  hideForm() {
    this.modalRef.current.toggleModal(false);
  }

  showForm() {
    this.modalRef.current.toggleModal(true);
  }

  render() {
    const { children } = this.props;
    return (
      <Modal ref={this.modalRef}>
        <div className="modalView">{children}</div>
      </Modal>
    );
  }
}
