import React from 'react';
import { AutoCompleteMultipleInput } from 'shared/components/form';
import { useGetText } from 'shared/hooks';

/**
 * This component is used to render an autoComplete input field that can render multiple companies with name, label and id which are defined by the eventCompanyType.
 * @param {string} eventCompanyType - The eventCompanyType that is used to define the name, label and id of the input field.
 * @param {array} value - Array with company object with name and id.
 * @param {function} handleEventCompanyChange - Function that is called when the value of the input field changes.
 * @param {array} options - Array with company objects with name and id.
 * @returns {React.Component}
 */
export default function EventCompaniesTypeField({
  eventCompanyType,
  options,
  optionsLoading,
  handleEventCompanyChange,
  value,
}) {
  const text = useGetText();
  return (
    <AutoCompleteMultipleInput
      label={text.getText(`forms.event.eventCompanyTypes.${eventCompanyType}`)}
      name={eventCompanyType}
      id={eventCompanyType}
      getOptionSelected={(option, value) => option._id === value._id}
      optionsLoading={optionsLoading}
      getOptionLabel={(option) => option.name}
      options={options}
      value={value}
      onChange={handleEventCompanyChange}
      addNewInputValueProp="name"
      freeSolo={false}
      disableClearable
    />
  );
}
