import React from 'react';
import { useGetText } from 'shared/hooks';

import './styles.less';

/**
 * ConnectionSizesDetail component shows the detail of connetion
 * in the selection modal
 */
const ConnectionSizeDetail = ({ handleClick, index, item }) => {
  const { getText } = useGetText();

  /**
   * Gets the fase text for each size value.
   * @param {number} num - Fase size
   * @return {string}
   */
  const getFaseName = (num) => {
    switch (num) {
      case 1:
        return getText('forms.spot.singleFase');
      case 3:
        return getText('forms.spot.tripleFase');
      default:
        return '';
    }
  };

  return (
    <>
      <div
        key={index}
        className="selectDetailsItem"
        {...(handleClick && {
          onClick: (e) => {
            const connectionObj = {
              externalId: item.externalId,
            };
            //add the price if connection price was set
            item.price && (connectionObj.price = item.price);
            handleClick(e, connectionObj);
          },
        })}
      >
        <div className="column itemImage">
          <img src={`/assets/connectors/${item.img}`} alt={item.img} />
        </div>
        <div className="column">
          <span className="value">{item.label}</span>
          <span className="title">{getFaseName(item.phases)}</span>
        </div>
        <div className="column">
          <span className="value">{Math.round(item.ratedPowerKva)} kVA</span>
          <span className="title">{getText('forms.spot.maxPower')}</span>
        </div>
      </div>
    </>
  );
};

export default ConnectionSizeDetail;
