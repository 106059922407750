import React from 'react';
import { useGetEventTimeseries } from 'shared/hooks';
import { ENERGY_METER_FIELDS } from 'shared/constants/graphs';
import { AVAILABLE_METERS, METER_TYPES } from 'spots/constants/connections';
import AreaGraph from 'shared/components/graphs/AreaGraph';
// render a view with a graph and a single stat for kilowatt hours
export default function ConnectionGraph({ connection, eventId, timeRange }) {
  // Fields and aggregates  and function are gonna be based on the type of meter
  const meterType = AVAILABLE_METERS.filter(
    (meter) => meter.id === connection.meterId
  )[0].meterType;
  const meterObj = METER_TYPE_MAP[meterType];
  // TODO: handle no meterObj

  const graphWidth = 900;

  const { data, isLoading } = useGetEventTimeseries({
    eventId: eventId,
    dataToFetch: [
      {
        meterId: connection.meterId,
        fields: meterObj.fields,
      },
    ],
    timeRange: timeRange,
  });

  const parseGraphData = ({
    data,
    transformValueFn,
    transformRowFn = null,
  }) => {
    return data.values
      .filter((row) => !row.includes(65535)) // filter our wrong values (we could replace this with a reducer)
      .map((row) => {
        if (transformRowFn) {
          return transformRowFn(row); // use the custom transform function if it exists otherwise use the default
        } else {
          // Lora nodes with PRO meters sometimes send a wrong value. We now skip this
          // ToDo: remove the wrong values from the database and makes sure the BE ignores such values in the future
          if (row.includes(65535)) {
            return null;
          }
          const rowObject = {};
          data.columns.forEach((field, index) => {
            if (field === 'time') {
              rowObject[field] = new Date(row[index]);
            } else {
              rowObject[field] = transformValueFn(row[index]); //TODO: add a multipllier to the meterObj and use that here
            }
          });
          return rowObject;
        }
      });
  };

  if (!meterObj) {
    return null;
  }

  let graphData = [];
  if (!isLoading && data?.values?.length > 0) {
    graphData = parseGraphData({
      data: data,
      transformValueFn: meterObj.transformValueFn,
      transformRowFn: meterObj.transformRowFn,
    });
  }
  return (
    <AreaGraph
      range={timeRange}
      data={graphData}
      fields={meterObj.graphFields}
      width={graphWidth}
    />
  );
}

const AtoVAFunc = (row) => {
  // TODO: we could pass the index of the current fields
  const estimatedAverageVoltage = 220; // TODO: We could query the average voltage of L1 and take that value
  const estimatedPowerFactor = 0.9;
  const totalCurrentMean = row[1] + row[2] + row[3];
  const totalCurrentMax = row[4] + row[5] + row[6];
  const factor = (estimatedPowerFactor * estimatedAverageVoltage) / 1000;
  const resultRow = {
    time: new Date(row[0]),
    Load_W_Total_Mean: totalCurrentMean * factor, // To go to hours and to go from A to W
    Load_W_Total_Max: totalCurrentMax * factor,
  };
  return resultRow;
  //TODO check this calculation
};

export const METER_TYPE_MAP = {
  [METER_TYPES.LTPRO1]: {
    fields: [
      ENERGY_METER_FIELDS.LOAD_W_TOTAL_MEAN,
      ENERGY_METER_FIELDS.LOAD_W_TOTAL_MAX,
    ],
    transformValueFn: (value) => value / 1000, // To go from W to kW
    graphFields: ['Load_W_Total_Mean', 'Load_W_Total_Max'],
  },
  [METER_TYPES.LTPRO380]: {
    fields: [
      ENERGY_METER_FIELDS.LOAD_W_TOTAL_MEAN,
      ENERGY_METER_FIELDS.LOAD_W_TOTAL_MAX,
    ],
    transformValueFn: (value) => value / 1000, // To go from W to kW
    graphFields: ['Load_W_Total_Mean', 'Load_W_Total_Max'],
  },
  [METER_TYPES.CRAB]: {
    fields: [
      ENERGY_METER_FIELDS.IRMS_A_L1_MEAN,
      ENERGY_METER_FIELDS.IRMS_A_L2_MEAN,
      ENERGY_METER_FIELDS.IRMS_A_L3_MEAN,
      ENERGY_METER_FIELDS.IRMS_A_L1_MAX,
      ENERGY_METER_FIELDS.IRMS_A_L2_MAX,
      ENERGY_METER_FIELDS.IRMS_A_L3_MAX,
    ],
    transformRowFn: AtoVAFunc,
    graphFields: ['Load_W_Total_Mean', 'Load_W_Total_Max'],
  },
};
