import * as yup from 'yup';

const nullableDate = yup
  .date()
  .nullable()
  .transform((curr, orig) => (orig === '' ? null : curr));

const nullableNumber = yup
  .number()
  .nullable()
  .transform((curr, orig) => (orig === '' ? null : curr));

const defaultPowerSourceSchema = yup.array(
  yup.object().shape({
    externalIdentifier: yup.string().required(),
    type: yup.string().required(),
    ratedPowerKva: yup.number(),
    ratedPowerKw: yup.number(),
    storageCapacityKwh: yup.number().when('type', {
      is: (val) => val === 'batteries',
      then: yup.number().required(),
    }),
  })
);

export default yup.object().shape({
  _id: nullableNumber,
  uri: yup.string(),
  label: yup.string().required(),
  comment: yup.string(),
  startShow: nullableDate,
  endShow: nullableDate,
  eventId: nullableNumber,
  powerSources: defaultPowerSourceSchema,
  powerOnDate: nullableDate,
  powerOffDate: nullableDate,
});
