import React from 'react';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';

import { Button } from 'shared/components/form';
import { formCreator } from 'shared/form';
import { changeFormState, removeSpotFromBuffer } from 'spots/actions';
import { goBack, pushRoute, confirm } from 'shared/actions';
import { DialogModal } from 'shared/components';
import { Icon } from 'shared/components';

import ConnectionRow from './ConnectionRow';
import lbls from './labels';
import rules from './rules';
import fields from './fields';
import validators from './validators';
import ConnectionSizes from '../connection/SelectSizeInput/ConnectionSizes';
import './styles.less';
import { EMPTY_DEVICE } from 'spots/constants/devices';

const enhance = compose(inject('text', 'view', 'spots'), observer);

/**
 * SpotForm class represents the spot form component where the user should insert the
 * spot information.
 * @class SpotForm
 * @extends {React.Component}
 */
export const SpotForm = enhance(
  class extends React.Component {
    // {object} state - Component state holds the form instance.
    constructor(props) {
      super(props);
      this.state = {
        form: null,
        modalOpen: false,
      };
      this.toggleModal = this.toggleModal.bind(this);
    }

    // {object} config - Form configuration getter
    get config() {
      const { onSuccess, onError, text } = this.props;
      const title = 'spotForm';
      const labels = lbls(text.get('forms.spot'));
      return { title, fields, rules, labels, validators, onSuccess, onError };
    }

    // {object} empty - Empty form instance
    get empty() {
      return { layout: { connections: [] } };
    }

    /**
     * Creates a new empty connection instance.
     * @param {number} index - Size of the connections list
     * @return {object}
     */
    createNewConnection(index = 0, newConnectionObj) {
      const { text } = this.props;
      changeFormState(true); //set FormState to dirty/unsaved data
      return {
        endDate: null,
        startDate: null,
        name: `${text.get('forms.spot.connectionName')} ${index + 1}`,
        connectionSizeType: newConnectionObj,
        quantity: 1,
        nightPower: false,
        upsNeeded: false,
        hasArrived: false,
        isConnected: false,
        visible: false,
        devices: [EMPTY_DEVICE],
      };
    }

    toggleModal() {
      this.setState({ modalOpen: !this.state.modalOpen });
    }

    /**
     * React lifecycle method - executed everytime the component is mounted in the view.
     * @return {void}
     */
    componentDidMount() {
      const { values } = this.props;
      const form = formCreator(this.config);
      this.setState({ form });
      const tmp = { ...this.empty, ...values };
      if (tmp.layout.connections.length === 0) {
        this.toggleModal();
      }
      form.init(tmp); // making sure everytime you select a new spot, it's data will appear
    }

    /**
     * React lifecycle method - executed everytime the component gets new data
     * @return {void}
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
      const form = formCreator(this.config);
      this.setState({ form });
      form.init({ ...nextProps.values });
    }

    /**
     * Handles the event triggered by the action of moving a device from a connection to another.
     * @param {object} target - Element where the device element were dropped
     * @param {any} monitor - D&D context monitor instance
     * @return {void}
     */
    moveDeviceHandler(target, monitor) {
      const { form } = this.state;
      const { field } = monitor.getItem();
      const draggedField = field.value;
      const devicePath = field.path.split('.');
      const origin = form.$('layout').$(`connections.${devicePath[2]}`);
      target.$('devices').add(draggedField);
      origin.del(field.path);
    }

    /**
     * Adds a new connection to the form.
     * @param {string} newConnectionObj - the connection object to be added
     * chosen during creation
     * @return {void}
     */
    addConnection = (newConnectionObj) => {
      const connections = this.state.form.$('layout').$('connections');
      //this.setState({ connectionSize: newConnectionObj }, () => {
      connections.add(
        this.createNewConnection(connections.size, newConnectionObj)
      ); //size is the index of the last connection
      //});
    };

    /**
     * Sets the initial connection size
     * @param {string} connectionObj
     * @return {string}
     */
    setInitialConnectionSize = (e, connectionObj) => {
      this.addConnection(connectionObj);
      this.toggleModal(e, false);
    };

    /**
     * Removes the given connection from the form.
     * @param {object} connection - Connection instance
     * @return {void}
     */
    removeConnection(connection) {
      const connections = this.state.form.$('layout').$('connections');
      connections.del(connection.path);
      changeFormState(true);
    }

    /**
     * Back to first step to empty spot of adding connections from historical event
     * @param {object} spot - 'click' event object
     * @return {void}
     */
    backToHistoricalSpot = (spot) => {
      this.props.spots.list.forEach(({ layout, ...props }) => {
        if (props._id.toString() === spot._id.toString()) {
          removeSpotFromBuffer(props);
          pushRoute(`/event/${props.eventId}/spots`);
        }
      });
    };

    /**
     * Checking the correct back btn action
     * @return {void}
     */
    checkBackBtnAction = (e) => {
      e.preventDefault();
      const {
        values,
        text,
        spots: { isUnsavedData },
      } = this.props;
      if (!values.unSaved && !isUnsavedData) {
        // return to historical events list
        goBack();
        return;
      }

      if (isUnsavedData) {
        confirm(text.get('actions.confirmLeaveUnsaved'), () => {
          changeFormState(false);
          goBack();
        });
        return;
      }

      this.backToHistoricalSpot(values);
    };

    /**
     * Renders the component view.
     * @return {React.Component}
     */
    render() {
      const { text } = this.props;
      const { form, modalOpen } = this.state;
      if (!form) return null;
      const field = form.$('layout').$('connections');
      return (
        <form onSubmit={form.onSubmit}>
          <DialogModal
            open={modalOpen}
            bodyPadding={false}
            handleClose={this.toggleModal}
            title={text.get('forms.spot.selectSize')}
            text={text}
          >
            <ConnectionSizes handleClick={this.setInitialConnectionSize} />
          </DialogModal>
          {field.fields.values().map((connection, index) => (
            <ConnectionRow
              field={connection}
              key={index}
              removeConnection={(conn) => this.removeConnection(conn)}
              moveDeviceHandler={(target, monitor) =>
                this.moveDeviceHandler(target, monitor)
              }
            />
          ))}
          <div className="spot-edit">
            <div className="buttonRow left">
              <Button type="button" styling="alt" onClick={this.toggleModal}>
                <Icon name="spot" className={`icon`} />
                {text.get('forms.spot.addConnection')}
              </Button>
            </div>
            <div className="nav-btn backBtn">
              <Button onClick={this.checkBackBtnAction}>
                {text.get('actions.back')}
              </Button>
            </div>
            <div className="nav-btn">
              <Button
                type="submit"
                styling="cta"
                onClick={form.onSubmit}
                disabled={!!form.hasError}
              >
                {text.get('actions.next')}
              </Button>
            </div>
          </div>
        </form>
      );
    }
  }
);
