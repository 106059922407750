export default {
  'layout.connections[].name': 'string',
  'layout.connections[].connectionSizeType.externalId': 'string',
  'layout.connections[].connectionSizeType.price': 'numeric',
  'layout.connections[].endDate': '',
  'layout.connections[].startDate': '',
  'layout.connections[].nightPower': '',
  'layout.connections[].upsNeeded': '',
  'layout.connections[].devices[].name': 'string',
  'layout.connections[].devices[].brand': 'string',
  'layout.connections[].devices[].modelNumber': 'string',
  'layout.connections[].devices[].ratedPower': '',
  'layout.connections[].devices[].quantity': '',
  'layout.connections[].devices[].unit': 'string',
};
