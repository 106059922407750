import React from 'react';
import { useGetEventTimeseries } from 'shared/hooks';
import { ENERGY_METER_FIELDS, AGGREGATORS } from 'shared/constants/graphs';
import HighlightedDataPanel from 'shared/components/HighligtedDataPanel';
import { AVAILABLE_METERS, METER_TYPES } from 'spots/constants/connections';

// render a view with a graph and a single stat for kilowatt hours
export default function ConnectionEnergyStats({
  connection,
  eventId,
  timeRange,
}) {
  // Fields and aggregates  and function are gonna be based on the type of meter
  const meterType = AVAILABLE_METERS.filter(
    (meter) => meter.id === connection.meterId
  )[0]?.meterType;
  const meterObj = METER_TYPE_MAP[meterType];

  const { data, isLoading } = useGetEventTimeseries({
    eventId: eventId,
    dataToFetch: [
      {
        meterId: connection.meterId,
        fields: meterObj.fields || [],
        aggregates: meterObj.aggregates || [],
      },
    ],
    timeRange,
  });

  if (!meterObj) {
    return (
      <div>
        Unknown meterid or meter type.. please check if the correct meter is
        assigned to this connection.
      </div>
    );
  }

  let energyConsumption = '-';
  if (!isLoading && data?.values?.length > 0) {
    if (meterObj.funcKwh instanceof Function) {
      energyConsumption = meterObj.funcKwh(data)?.toFixed();
    } else {
      console.error(
        `funcKwH is not a function for meter type ${meterType} and meterObj:`,
        meterObj
      );
      energyConsumption = NaN;
    }
  }

  return (
    <HighlightedDataPanel
      title={`Energie gebruik`}
      value={` ${energyConsumption} kWh`}
    />
  );
}

const whTotalFunc = (data) => {
  return data.values[0][2] - data.values[0][1]; // Last minus first
};

const vaTotalFunc = (data) => {
  // TODO: we could pass the index of the
  const estimatedAverageVoltage = 220; // TODO: We could query the average voltage of L1 and take that value
  const currentSecondsTotal =
    data.values[0][1] + data.values[0][2] + data.values[0][3];
  const estimatedPowerFactor = 0.9;
  return (
    (currentSecondsTotal / 3600 / 1000) *
    estimatedPowerFactor *
    estimatedAverageVoltage
  ); // To go to hours and to go from A to W
  //TODO check this calculation
};

export const METER_TYPE_MAP = {
  [METER_TYPES.LTPRO1]: {
    fields: [ENERGY_METER_FIELDS.ENERGY_USAGE_WH_TOTAL],
    aggregates: [AGGREGATORS.FIRST, AGGREGATORS.LAST],
    funcKwh: whTotalFunc,
  },
  [METER_TYPES.LTPRO380]: {
    fields: [ENERGY_METER_FIELDS.ENERGY_USAGE_WH_TOTAL],
    aggregates: [AGGREGATORS.FIRST, AGGREGATORS.LAST],
    funcKwh: whTotalFunc,
  },
  [METER_TYPES.CRAB]: {
    fields: [
      ENERGY_METER_FIELDS.IRMS_A_L1_MEAN,
      ENERGY_METER_FIELDS.IRMS_A_L2_MEAN,
      ENERGY_METER_FIELDS.IRMS_A_L3_MEAN,
    ],
    aggregates: [AGGREGATORS.INTEGRAL],
    funcKwh: vaTotalFunc,
  },
};
