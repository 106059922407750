import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { inject, observer } from 'mobx-react';
import SortableTree from 'react-sortable-tree'; // Reference: https://github.com/fritz-c/react-sortable-tree
import IconButton from 'material-ui/IconButton';
import IconMenu from 'material-ui/IconMenu';
import MenuItem from 'material-ui/MenuItem';
import Fuse from 'fuse-js-latest';
import { compose } from 'recompose';
import 'react-sortable-tree/style.css';

import { Icon, OptionsModal } from 'shared/components';
import { SearchInput } from 'shared/components/form';
import { Notifications } from 'shared/constants';
import { saveNodeState, notify, confirm } from 'shared/actions/';
import { duplicateNode } from 'powerzones/actions';
import {
  createPowerzone,
  updatePowerzone,
  removePowerzone,
  togglePowerzone,
} from 'powerzones/actions';
import {
  getSubAreas,
  getNestedSpots,
  getChildrenTotalPower,
} from 'powerzones/utils';
import { PowerzoneModalForm } from 'powerzones/components';
import { createArea, updateArea, removeArea, toggleArea } from 'areas/actions';
import { SpotModalForm } from 'spots/components';
import { describeConnections } from 'spots/utils';
import { createSpot, updateSpot, removeSpot, loadSpots } from 'spots/actions';
import { loadPowerInquiries } from 'powerinquiries/actions';
import { loadCompanies } from 'companies/actions';
import CreateAreaModalForm from 'areas/components/CreateAreaModalForm';

import SpotTitle from './SpotTitle';
import PrintNode from './printables/PrintableNode';
import DetailsPane from '../../../components/DetailsPane';
import './styles.less';

const NOT_APPOINTED_KEY = 'notAppointedNode';
const OPTION_SUBAREA = 'Sub area';
const OPTION_SPOT = 'Spot';

const enhance = compose(
  inject('areas', 'powerzones', 'spots', 'companies', 'text', 'powerInquiries'),
  observer
);

class Frame extends Component {
  componentDidMount() {
    this.iframeHead = this.node.contentDocument.head;
    this.iframeRoot = this.node.contentDocument.body;
    this.forceUpdate();
  }

  render() {
    const { children, head, ...rest } = this.props;
    return (
      <iframe title="printZone" {...rest} ref={(node) => (this.node = node)}>
        {this.iframeHead && ReactDOM.createPortal(head, this.iframeHead)}
        {this.iframeRoot && ReactDOM.createPortal(children, this.iframeRoot)}
      </iframe>
    );
  }
}

/**
 * PowerplanTree class represents the powerplan tree in the Powerzone overview page.
 * @export
 * @class PowerplanTree
 * @extends {React.Component}
 */
export default enhance(
  class PowerplanTree extends React.Component {
    // {object} state - Component state holding information about component state.
    state = {
      expandNotAppointedList: true,
      parentNode: null,
      selectedNode: null,
      linkToPowerInquiryCallback: null,
      activeSpotId: '',
      activePowerZone: '',
      activeArea: '',
      showSpotForm: true,
      isAreaModalOpen: false,
      parentNodeUri: null,
    };

    get availableMoveOptions() {
      const { selectedNode } = this.state;
      if (!selectedNode) return [];
      const tree = this.getTreeData();
      const flat = this.flattenTree(tree);

      const nodes = flat.filter(
        (x) =>
          x.uri.includes('area') ||
          (x.uri.includes('powerzone') && x.uri !== selectedNode.uri)
      );

      return nodes.filter((n) => n.uri !== selectedNode.uri);
    }

    componentDidMount() {
      loadPowerInquiries(this.props.eventId);
      loadCompanies();
    }

    availablePowerInquiries() {
      const powerInquiries = this.props.powerInquiries.list;
      return powerInquiries;
    }

    /**
     * Shows the modal with the powerzone form, to add a new one.
     * @return {void}
     */
    addPowerzone() {
      this.refs.pzModal.wrappedInstance.updateForm({});
      this.refs.pzModal.wrappedInstance.modalRef.current.showForm();
    }

    addSpotToPowerInquiry(powerInquiry) {
      const { parentNode } = this.state;
      this.refs.spotModal.wrappedInstance.updateForm({
        associatedResource: parentNode.uri,
        powerInquiryId: powerInquiry._id,
      });
      this.refs.spotModal.wrappedInstance.showForm();
    }

    /**
     * Triggers the action to add a subnode to the tree.
     * @param {object} parent - Node instance
     * @return {void}
     */
    addSubNode(parentNode, option = '') {
      this.toggleVisibility({ node: parentNode, expanded: true });
      if (parentNode.uri.includes('powerzones') || option === OPTION_SUBAREA) {
        // Open the Area Modal
        this.setState((state) => ({
          ...state,
          isAreaModalOpen: true,
          parentNodeUri: parentNode.uri,
        }));
      } else if (option === OPTION_SPOT) {
        this.pickPowerInquiryModal().then((powerInquiry) =>
          this.addSpotToPowerInquiry(powerInquiry)
        );
      } else {
        this.setState({ parentNode });
        this.refs.optionsModal.showModal();
      }
    }

    /* TODO: documentation */
    openMoveModal(selectedNode, operation = 'move') {
      this.setState({ showSpotForm: false }, () => {
        this.setState({ selectedNode, operation });
        this.refs.moveModal.showModal();
      });
    }

    renderMoveOptions(item, index) {
      return (
        <div key={index} className="moveOptionItem">
          <span className="title">{item.label || item.tag}</span>
          <span className="subtitle">{item.composedUri}</span>
        </div>
      );
    }

    renderUserOptions(item, index) {
      return (
        <div key={index} className="userOptionItem">
          <span className="title">{item.name}</span>
          <span className="subtitle">{item.companyName || '---'}</span>
        </div>
      );
    }

    onSelectOption(newParentNode) {
      const { selectedNode, operation } = this.state;
      switch (operation) {
        case 'move': {
          this.moveNode({
            node: selectedNode,
            prevPath: [selectedNode.associatedResource, selectedNode.uri],
            nextParentNode: newParentNode,
            nextTreeIndex: 100, //random number to not break the moveNode method
          });
          break;
        }
        case 'duplicate': {
          this.duplicateNode(selectedNode, newParentNode);
          break;
        }
        default: {
          throw new Error(
            `Invalid operation ${operation} expected [move, duplicate]`
          );
        }
      }
    }

    /**
     * Triggers the action to edit a node in the tree.
     * @param {object} node - Node instance
     * @return {void}
     */
    editNode(node) {
      if (node.uri.includes('areas')) {
        // Open the Area Modal
        this.setState((state) => ({
          ...state,
          isAreaModalOpen: true,
        }));
      } else if (node.uri.includes('powerzones')) {
        this.refs.pzModal.wrappedInstance.updateForm(node);
        this.refs.pzModal.wrappedInstance.modalRef.current.showForm();
      } else if (node.uri.includes('spots')) {
        this.refs.spotModal.wrappedInstance.updateForm(node);
        this.refs.spotModal.wrappedInstance.showForm();
      }
    }

    /**
     * Triggers the action to remove a node from the tree.
     * @param {object} node - Node instance
     * @return {void}
     */
    removeNode(node) {
      node.uri.includes('powerzones') && removePowerzone(node);
      node.uri.includes('areas') && removeArea(node);
      node.uri.includes('spots') && removeSpot(node);
    }

    pickPowerInquiryModal() {
      this.refs.powerInquiryModal.showModal();
      return new Promise((s, e) => {
        this.setState({ linkToPowerInquiryCallback: s });
      });
    }

    handleDuplicateNode(node) {
      if (!node.uri) return;
      const parent = this.getNodeFromUri(node.associatedResource);
      duplicateNode(node, parent, null);
      this.toggleVisibility({
        node: parent,
        expanded: true,
      });
    }

    getNodeFromUri(uri) {
      if (uri.includes('area')) {
        return this.props.areas.getArea(uri);
      } else if (uri.includes('powerzone')) {
        return this.props.powerzones.getPowerzone(uri);
      } else {
        notify(
          `Uri: ${uri} does not containe area or powerzone, spot duplication failed`
        );
      }
    }

    /**
     * Triggered by the node movement in the tree.
     * @param {object} { node, path } - Params object
     * @return {void}
     */
    moveNode({ node, prevPath, nextParentNode }) {
      if (!nextParentNode) return; //block second call of this function when node is moved to other tree with dnd
      const { text, powerzones, areas } = this.props;
      const notAppointedLabel = text.get(
        'components.views.powerzones.notAppointedNodeTitle'
      );
      const nodeObject = node.uri.includes('spot')
        ? node.title.props.spot
        : node;
      // get the new parent node uri from the node
      const newParentUri = nextParentNode.uri;
      const newParentName = !newParentUri
        ? notAppointedLabel
        : nextParentNode.label || nextParentNode.tag;
      const nodeType = nodeObject.uri.includes('spot')
        ? 'Spot'
        : nodeObject.uri.includes('area')
        ? 'Area'
        : 'Powerzone';

      //save new Parent Uri to node and update the node item
      const item = {
        ...nodeObject,
        associatedResource: newParentUri,
      };
      nodeObject.uri.includes('areas') && updateArea(item);
      nodeObject.uri.includes('spots') && updateSpot(item);
      this.toggleVisibility({ node: { uri: newParentUri }, expanded: true });

      // get the old parent uri from the path and the name, and notify the user of the update
      const oldParentUri = prevPath.slice(-2)[0] || NOT_APPOINTED_KEY;
      const oldParentName = this.getNodeName(
        oldParentUri,
        notAppointedLabel,
        powerzones,
        areas
      );

      notify({
        message: `${nodeType} ${
          nodeObject.label || nodeObject.tag
        } moved from ${oldParentName} into ${newParentName}`,
        level: Notifications.Type.SUCCESS,
      });
    }

    getNodeName(uri, notAppointedLabel, powerzones, areas) {
      if (uri !== NOT_APPOINTED_KEY) {
        return uri.includes('area')
          ? areas.getArea(uri).tag
          : powerzones.getPowerzone(uri).label;
      } else {
        return notAppointedLabel;
      }
    }

    /**
     * Triggered by the form submit success event. It receives the form data,
     * closes the modal and deliver to the method responsible for sending the
     * data to the API.
     * @param {object} form - Form instance
     * @return {void}
     */
    onSaveSuccess(form) {
      const data = form.values();
      this.closeModal();
      if (!data.associatedResource && data.associatedResource !== '') {
        const powerzone = this.props.powerzones.getPowerzone(data.uri);
        const newPz = { ...powerzone, label: data.label };
        this.savePowerzone(newPz);
      } else if (data.uri) {
        data.uri.includes('areas') && this.saveArea(data);
        data.uri.includes('spots') && this.saveSpot(data);
      } else {
        data.tag && this.saveArea(data);
        data.label && this.saveSpot(data);
      }
    }

    /**
     * Triggered by the Area form 'submit' event when it's successfully.
     * @param {object} area - Area form data
     * @return {void}
     */
    saveArea(area) {
      if (area.uri) updateArea(area);
      // is editing
      else createArea(area, this.props.eventId);
    }

    /**
     * Triggered by the Powerzone form 'submit' event when it's successfully.
     * @param {object} powerzone - Powerzone form data
     * @return {void}
     */
    savePowerzone(powerzone) {
      if (powerzone.uri) updatePowerzone(powerzone);
      // is editing
      else createPowerzone(powerzone, this.props.eventId);
    }

    /**
     * Triggered by the Spot form 'submit' event when it's successfully.
     * @param {object} spot - Spot form data
     * @return {void}
     */
    saveSpot(spot) {
      if (!spot.layout) spot.layout = null;
      if (spot.uri) updateSpot(spot);
      // is editing
      else {
        createSpot(spot, this.props.eventId).then(() =>
          loadSpots(this.props.eventId)
        );
      }
    }

    /**
     * Closes all the modals.
     * @return {void}
     */
    closeModal() {
      this.refs.pzModal.wrappedInstance.modalRef.current.hideForm();
      this.refs.spotModal.wrappedInstance.hideForm();
      this.setState({
        activeSpotId: null,
        activePowerZone: null,
        activeArea: null,
        isAreaModalOpen: false,
        parentNodeUri: null,
      });
      this.refs.sidepane.toggleVisibility(false);
    }

    /**
     * Toggle node's children visibility.
     * @param {object} { node, expanded } - Params object
     * @return {void}
     */
    toggleVisibility({ node, expanded }) {
      if (node.uri.includes('powerzones')) togglePowerzone(node, expanded);
      else if (node.uri.includes('areas')) toggleArea(node, expanded);
      else
        this.setState({
          expandNotAppointedList: !this.state.expandNotAppointedList,
        }); //for notAppointed tree
      saveNodeState(node, expanded);
      this.forceUpdate();
    }

    /**
     * Specifies the conditions to allow a node to be dragged through the tree.
     * @param {object} { path } - Object containing a 'path' property
     * @return {boolean}
     */
    canDrag = ({ path }) => path.length > 1;

    /**
     * Specifies the conditions to allow a node to be dropped somewhere in the tree.
     * @param {object} { node, nextParent, nextPath, prevParent } - Object with some D&D parameters
     * @return {boolean}
     */
    canDrop({ node, nextParent, nextPath, prevParent }) {
      if (node.uri.includes('spot')) {
        if (nextParent && nextParent.uri.includes('spot')) return false;
      }
      return (
        nextParent &&
        (!(
          !nextParent.uri ||
          (node.uri.includes('areas') &&
            (!nextParent || nextPath.length > 3)) ||
          (node.uri.includes('spots') && !nextParent.uri.includes('areas')) ||
          !nextParent.uri.includes('powerzones')
        ) ||
          (!node.uri.includes('powerzones') &&
            nextParent.uri !== NOT_APPOINTED_KEY))
      );
    }

    /**
     * Open sidepane with details of the given node.
     * @param {object} selectedNode - Node instance
     * @return {void}
     */
    showDetails(selectedNode) {
      this.setState({
        selectedNode,
        showSpotForm: true,
        activeArea: selectedNode.uri.includes('area') ? selectedNode._id : null,
        activePowerZone: selectedNode.uri.includes('power')
          ? selectedNode._id
          : null,
        activeSpotId: selectedNode.uri.includes('spot')
          ? selectedNode._id
          : null,
      });
      this.refs.sidepane.toggleVisibility(true);
    }

    /**
     * Prepares the powerzone data to fit in the modeling expected by the tree component.
     * @param {object} powerzone - Powerzone instance
     * @return {object}
     */
    preparePzTreeData(powerzone) {
      const { areas, text, spots } = this.props;
      const children = [].concat(
        getSubAreas(powerzone, areas.list)
          .map((pz) => this.prepareAreaTreeData({ ...pz, searchKey: pz.tag }))
          .sort(this.sortNode((node) => node.tag)),
        getNestedSpots(powerzone, spots.list)
          .map((spot) => this.prepareSpotTreeData(spot))
          .sort(this.sortNode((node) => node.label))
      );
      // we need to check for the spots that this powerzone might have as direct children
      const totalPower = getChildrenTotalPower(children);
      //set calculated values to powerzone
      powerzone.calculated = { totalPower }; //todo: remove this when the backend is ready https://gitlab.com/watt-now/tool-backend/-/tree/mustafa/issue-80
      const title = (
        <div>
          <div className="treeIcon powerzone" style={{ float: 'left' }}>
            <Icon
              name="powerzone"
              className={
                this.state.activePowerZone === powerzone._id
                  ? 'rowIcon activePowerZone'
                  : 'rowIcon'
              }
              onClick={() => this.showDetails({ ...powerzone, children })}
            />
          </div>
          <div
            className={
              this.state.activePowerZone !== '' &&
              powerzone._id !== this.state.activePowerZone
                ? 'nodeTitle clickable inActivePowerZone'
                : powerzone._id === this.state.activePowerZone
                ? 'nodeTitle clickable activePowerZone'
                : 'nodeTitle clickable'
            }
            onClick={() => this.showDetails({ ...powerzone, children })}
          >
            {powerzone.label} •{' '}
            <span className="power">{Math.ceil(totalPower || 0)} kW</span>
          </div>
        </div>
      );
      const nodeTitle = text.get(
        'components.views.powerzones.powerzoneNodeTitle'
      );
      const subtitle = `${nodeTitle} • ${powerzone.powerSources
        .map((ps) => ps.label)
        .join(', ')}`;
      return { ...powerzone, title, subtitle, children };
    }

    /**
     * Prepares the area data to fit in the modeling expected by the tree component.
     * @param {object} area - Area instance
     * @return {object}
     */
    prepareAreaTreeData(area) {
      const { areas, spots, text } = this.props;
      const children = [].concat(
        getSubAreas(area, areas.list)
          .map((area) =>
            this.prepareAreaTreeData({ ...area, searchKey: area.tag })
          )
          .sort(this.sortNode((node) => node.tag)),
        getNestedSpots(area, spots.list)
          .map((spot) => this.prepareSpotTreeData(spot))
          .sort(this.sortNode((node) => node.label))
      );

      const totalPower = getChildrenTotalPower(children);

      let subtitle = '';
      if (area.associatedResource.includes('areas')) {
        subtitle = text.get('components.views.powerzones.subareaNodeTitle');
      } else {
        subtitle = text.get('components.views.powerzones.areaNodeTitle');
      }
      if (area.cableSize) subtitle = `${subtitle} • ${area.cableSize}`;

      const title = (
        <span>
          <div className="treeIcon area" style={{ float: 'left' }}>
            <Icon
              name="area"
              className={
                area._id === this.state.activeArea
                  ? 'rowIcon activeArea'
                  : 'rowIcon'
              }
              onClick={() => this.showDetails({ ...area, children })}
            />
          </div>

          <span
            className={
              this.state.activeArea !== '' && area._id !== this.state.activeArea
                ? 'nodeTitle clickable inActiveArea'
                : area._id === this.state.activeArea
                ? 'nodeTitle clickable activeArea'
                : 'nodeTitle clickable'
            }
            onClick={() => this.showDetails({ ...area, children })}
          >
            {area.tag} •{' '}
            <span className="power">{Math.ceil(totalPower || 0)} kW</span>
          </span>
        </span>
      );
      return { ...area, title, subtitle, children, totalPower };
    }

    /**
     * Prepares the spot data to fit in the modeling expected by the tree component.
     * @param {object} spot - Spot instance
     * @return {object}
     */
    prepareSpotTreeData(spot) {
      const { text, companies } = this.props;
      const connSizes = describeConnections(spot);
      const connections = Object.keys(connSizes)
        .map((key) => `${connSizes[key]} x ${key}`)
        .join(' • ');
      let subtitle =
        text.get('components.views.powerzones.spotNodeTitle') +
        ' • ' +
        spot.label;
      if (connections) subtitle = subtitle + ` • ${connections}`;
      return {
        ...spot,
        searchKey: spot.companyName + spot.label,
        subtitle,
        title: (
          <SpotTitle
            spot={spot}
            companyName={companies.getCompany(spot.companyId)?.name || ''}
            activeSpotId={this.state.activeSpotId}
            onClick={() => this.showDetails(spot)}
          />
        ),
      };
    }

    sortNode = (mapper) => (first, second) => {
      const a = mapper(first).toLowerCase(),
        b = mapper(second).toLowerCase();
      return a < b ? -1 : a > b ? 1 : 0;
    };

    filterTree = (node) => {
      const query = this.state.search || '';
      if (query.length < 1) return true;
      // var options = {
      //     tokenize: true,
      //     threshold: 0.6,
      //     location: 0,
      //     distance: 200,
      //     maxPatternLength: 50,
      //     minMatchCharLength: 1,
      //     keys: ["uri", "label", "tag", "title", "name", "subtitle"],
      // };

      if (node.children) node.children = node.children.filter(this.filterTree);

      if (node.children && node.children.length) return true;

      if (node.searchKey.toLowerCase().includes(query.toLowerCase()))
        return true;
      return false;
    };
    /**
     * Builds the tree to set in the view.
     * @return {object[]}
     */
    getTreeData() {
      const { powerzones } = this.props;
      const tree = powerzones.list
        .map((powerzone) =>
          this.preparePzTreeData({
            ...powerzone,
            searchKey: powerzone.label,
          })
        )
        .filter(this.filterTree)
        .sort(this.sortNode((node) => node.label));
      return tree;
    }

    getNotAppointedData(expanded) {
      const { areas, spots, text } = this.props;
      const notAppointedAreas = areas.notAppointedList //gets the list with al item components that are not Appointed to a powerzone
        .map((area) =>
          this.prepareAreaTreeData({ ...area, searchKey: area.tag })
        )
        .sort(this.sortNode((node) => node.tag));
      const notAppointedSpots = spots.notAppointedList
        .map((spot) => this.prepareSpotTreeData(spot))
        .sort(this.sortNode((node) => node.label));
      let totalPower = notAppointedSpots.reduce(
        (prev, next) => prev + next.totalPower,
        0
      );

      totalPower += notAppointedAreas.reduce(
        (prev, { children }) => prev + getChildrenTotalPower(children),
        0
      );
      const notAppointed = [
        {
          expanded,
          title: (
            <span className="nodeTitle clickable">
              {text.get('components.views.powerzones.notAppointedNodeTitle')} •{' '}
              <span className="power">{Math.ceil(totalPower)} kW</span>
            </span>
          ),
          uri: '',
          children: []
            .concat(notAppointedAreas, notAppointedSpots)
            .filter(this.filterTree),
        },
      ];
      return notAppointed;
    }

    /**
     * Configures custom node properties.
     * @param {object} { node } - Object with tree node properties
     * @return {object}
     */
    getNodeProps({ node }) {
      const { text } = this.props;
      return {
        buttons: [
          !node.uri.includes('spots') && (
            <Icon
              name="add-circle"
              className="rowEndIcon"
              onClick={() => this.addSubNode(node)}
            />
          ),
          node.uri !== '' && (
            <IconMenu
              iconButtonElement={
                <IconButton>
                  <Icon name="more" className="rowEndIcon" />
                </IconButton>
              }
              targetOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              touchtapclosedelay={1}
            >
              {!node.uri.includes('powerzones') && node.uri && (
                <>
                  <MenuItem
                    primaryText={text.get('actions.duplicate')}
                    onClick={() => this.handleDuplicateNode(node)}
                  />
                  <MenuItem
                    primaryText={text.get('actions.duplicateInto')}
                    onClick={() => this.openMoveModal(node, 'duplicate')}
                  />
                </>
              )}
              {node.uri.includes('powerzones') && (
                <MenuItem
                  primaryText={text.get('actions.edit')}
                  onClick={() => this.editNode(node)}
                />
              )}
              {!node.uri.includes('powerzones') && (
                <MenuItem
                  primaryText={text.get('actions.move')}
                  onClick={() => this.openMoveModal(node, 'move')}
                />
              )}
              <MenuItem
                primaryText={text.get('actions.remove')}
                onClick={() =>
                  confirm(text.get('actions.confirmRemove'), () =>
                    this.removeNode(node)
                  )
                }
              />

              {(node.uri.includes('spots') ||
                node.uri.includes('powerzone') ||
                node.uri.includes('area')) && (
                <MenuItem
                  primaryText={text.get('actions.print')}
                  onClick={() => {
                    this.setState({
                      printable: node,
                    });
                    var content =
                      document.getElementById('printable').contentWindow;
                    setTimeout(() => {
                      const styles = [];

                      window.document
                        .querySelectorAll('link[rel=stylesheet]')
                        .forEach((link) => {
                          styles.push(
                            <link
                              type="text/css"
                              rel="stylesheet"
                              href={link.href}
                            />
                          );
                        });
                      window.document
                        .querySelectorAll('style')
                        .forEach((link) => {
                          styles.push(
                            <style type="text/css">{link.textContent}</style>
                          );
                        });
                      this.setState({ printStyles: styles });
                      setTimeout(() => {
                        content.focus();
                        content.print();
                      }, 50);
                    }, 50);
                  }}
                />
              )}
            </IconMenu>
          ),
        ],
      };
    }

    /**
     * Handles modifications on the tree.
     * @param {object[]} tree - Modified tree object
     * @return {void}
     */
    onTreeChange(tree) {
      //we do nothing here
    }

    onSelectPowerInquiry(powerInquiry) {
      const { linkToPowerInquiryCallback } = this.state;
      if (!linkToPowerInquiryCallback) {
        throw new Error('PowerInquiryCallback is null');
      }
      linkToPowerInquiryCallback(powerInquiry);
      console.log(powerInquiry, linkToPowerInquiryCallback);
    }

    flattenNode(node, parentName) {
      const name = parentName + '/' + (node.label || node.tag);
      if (!node.uri) return [];

      const self = {
        label: node.label || node.tag,
        uri: node.uri,
        composedUri: name,
      };

      const results = [self];
      if (node.children)
        node.children.map((n) => results.push(...this.flattenNode(n, name)));
      return results;
    }

    flattenTree(tree) {
      const flatten = [];
      /* eslint-disable-next-line */
      tree.forEach((node, idx) => {
        flatten.push(...this.flattenNode(node, ''));
      });
      return flatten;
    }

    /**
     * Renders the component view.
     * @return {React.Component}
     */
    render() {
      const treeData = this.getTreeData();
      const notAppointedTreeData = this.getNotAppointedData(
        this.state.expandNotAppointedList
      );

      const { eventId, text } = this.props;
      return (
        <div className="PowerplanTree">
          <SearchInput
            placeholder="Search"
            onChange={(evt) => this.setState({ search: evt.target.value })}
          />
          <div className="PowerzoneTreeWrapper">
            <div className="Tree">
              <SortableTree
                dndType={'node'}
                treeIndex={1}
                isVirtualized={true}
                reactVirtualizedListProps={{ height: 20000 }} //we need this to make virtualization work
                onChange={(tree) => this.onTreeChange(tree)}
                treeData={treeData}
                getNodeKey={({ node }) => node.uri}
                canDrag={(props) => this.canDrag(props)}
                canDrop={(props) => this.canDrop(props)}
                onMoveNode={(props) => this.moveNode(props)}
                generateNodeProps={(props) => this.getNodeProps(props)}
                onVisibilityToggle={(props) => this.toggleVisibility(props)}
              />
            </div>
            <div className="NotAppointedTree">
              <SortableTree
                dndType={'node'}
                treeIndex={2}
                isVirtualized={true}
                reactVirtualizedListProps={{ height: 20000 }}
                onChange={(tree) => this.onTreeChange(tree)}
                treeData={notAppointedTreeData}
                getNodeKey={({ node }) => node.uri}
                canDrag={(props) => this.canDrag(props)}
                canDrop={(props) => this.canDrop(props)}
                onMoveNode={(props) => this.moveNode(props)}
                onVisibilityToggle={(props) => this.toggleVisibility(props)}
                generateNodeProps={(props) => this.getNodeProps(props)}
              />
            </div>
          </div>
          <OptionsModal
            ref="optionsModal"
            customClass="small"
            title={text.get('components.views.powerzones.optionsModalTitle')}
            onOptionSelected={(option) =>
              this.addSubNode(this.state.parentNode, option)
            }
            options={[OPTION_SUBAREA, OPTION_SPOT]}
          />
          <OptionsModal
            ref="moveModal"
            title={text.get('components.views.powerzones.moveModalTitle')}
            onOptionSelected={(option) => this.onSelectOption(option)}
            options={this.availableMoveOptions}
            renderItem={this.renderMoveOptions}
            filter={(list, filterInput) => {
              var options = {
                shouldSort: true,
                tokenize: false,
                matchAllTokens: true,
                threshold: 0.3,
                location: 0,
                distance: 20,
                maxPatternLength: 32,
                minMatchCharLength: 3,
                keys: ['composedUri', 'label'],
              };
              if (!filterInput) return list;
              var fuse = new Fuse(list, options); // "list" is the item array
              return fuse.search(filterInput);
            }}
          />
          <CreateAreaModalForm
            isOpen={this.state.isAreaModalOpen}
            customClass="small"
            closeModal={() => this.closeModal()}
            eventId={this.props.eventId}
            parentUri={this.state.parentNodeUri}
          />
          <PowerzoneModalForm
            ref="pzModal"
            customClass="small"
            onSuccess={(form) => this.onSaveSuccess(form)}
            cancelHandler={() => this.closeModal()}
          />
          <SpotModalForm
            ref="spotModal"
            onSuccess={(form) => this.onSaveSuccess(form)}
            cancelHandler={() => this.closeModal()}
          />

          <OptionsModal
            ref="powerInquiryModal"
            title={text.get(
              'components.views.powerzones.optionsAsignPowerInquiry'
            )}
            onOptionSelected={(option) => {
              this.onSelectPowerInquiry(option);
            }}
            renderItem={this.renderUserOptions}
            options={this.availablePowerInquiries()}
          />
          <DetailsPane
            showSpotForm={this.state.showSpotForm}
            ref="sidepane"
            item={this.state.selectedNode}
            eventId={eventId}
            text={text}
            removeSpot={(node) => this.removeNode(node)}
            onClickEdit={(node) => this.editNode(node)}
          />

          <div style={{ display: 'none' }}>
            <Frame id="printable" head={this.state.printStyles}>
              <PrintNode
                showSpotForm={this.state.showSpotForm}
                ref={(el) => (this.printable = el)}
                node={this.state.printable}
                text={text}
                userId={
                  this.state.selectedNode && this.state.selectedNode.userId
                }
              />
            </Frame>
          </div>
        </div>
      );
    }
  }
);
