import React from 'react';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';

import { Icon } from 'shared/components';

import InquiryRowItem from '../InquiryRowItem';
import './styles.less';

const enhance = compose(inject('text'), observer);

/**
 * DetailsTable represents the Powerzone details table component.
 * @class PowerInquiriesTable
 * @extends {React.Component}
 */
export default enhance(
  class PowerInquiriesTable extends React.Component {
    /**
     * Render the item table row.
     * @param {object} item - PowerInquiry instance
     * @param {number} index - Element index
     * @return {React.Component}
     */
    renderItem = (powerInquiry, index) => (
      <InquiryRowItem
        className="row"
        powerInquiry={powerInquiry}
        key={index}
        eventId={this.props.eventId}
      />
    );

    /**
     * Renders the component view.
     * @return {React.Component}
     */
    render() {
      const {
        text,
        className,
        items,
        onClickSort,
        sortAsc,
        isInquiryManager,
      } = this.props;

      const noItemsText = isInquiryManager
        ? text.get('components.views.powerInquiries.noItemsInquiryManager')
        : text.get('components.views.powerInquiries.noItems');

      return (
        <div className={`PowerInquiriesTable ${className || ''}`}>
          <div className="head row">
            <span>{text.get('components.views.powerInquiries.contact')}</span>
            <span>{text.get('components.views.powerInquiries.spots')}</span>
            <span>{text.get('components.views.powerInquiries.owner')}</span>
            <span>{text.get('components.views.powerInquiries.power')}</span>
            <span className="clickable" onClick={onClickSort}>
              {text.get('components.views.powerInquiries.lastUpdate')}
              <Icon name={(sortAsc && 'arrow-up') || 'arrow-down'} />
            </span>
          </div>
          {items.length === 0 ? (
            <div className="noItems">{noItemsText}</div>
          ) : (
            items.map(this.renderItem)
          )}
        </div>
      );
    }
  }
);
