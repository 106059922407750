import React, { useEffect } from 'react';

import { pushRoute } from 'shared/actions';
import { Card, Metadata } from 'shared/components';
import { useGetText } from 'shared/hooks';

import { LoginForm } from 'auth/components';
import { useLogin } from 'auth/hooks';

import './styles.less';

export const LoginPage = () => {
  const text = useGetText();
  const { login, data } = useLogin();

  const handleFormSuccess = (form) => {
    const { email, password } = form.values();
    login({ email, password });
  };

  useEffect(() => {
    if (data) {
      pushRoute('/events');
    }
  }, [data]);

  return (
    <section className="LoginView">
      <Metadata meta={text.getText('components.views.login.meta')} />
      <h1>{text.getText('components.views.login.pageTitle')}</h1>
      <Card>
        <h3>{text.getText('components.views.login.cardTitle')}</h3>
        <LoginForm onSuccess={handleFormSuccess} />
        <span className="link" onClick={() => pushRoute('/forget-password')}>
          {text.getText('components.views.login.linkTextForgotPassword')}
        </span>
      </Card>
    </section>
  );
};
