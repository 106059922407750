import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import ConnectionEnergyStats from './ConnectionEnergyStats';
import ConnectionGraph from './ConnectionGraph';
import { min } from 'date-fns';
import { AVAILABLE_METER_IDS } from 'spots/constants/connections';
import { useGetText } from 'shared/hooks';

// render a view with a graph and a single stat for kilowatt hours
export function SpotData({ spot, event }) {
  const text = useGetText();
  return (
    <Grid
      item
      container
      direction="column"
      key={spot._id}
      spacing={2}
      style={{ marginTop: '1rem' }}
    >
      {spot.layout.connections.map((connection, index) => {
        const hasDates = connection.startDate && connection.endDate;
        const hasMeter = connection.meterId && connection.meterId !== '';
        const isMeterValid = AVAILABLE_METER_IDS.includes(connection.meterId);

        if (hasMeter && !isMeterValid) {
          console.log(
            `Invalid meterId in a connection. The metertype with ${connection.meterId} still has to be added to the datamodel to show meter data.`
          );
          return null;
        }

        const timeRange = hasDates
          ? {
              from: new Date(connection.startDate),
              to: min([new Date(), new Date(connection.endDate)]),
            }
          : {
              from: new Date(event.buildUpDates[0].startDate),
              to: min([new Date(), new Date(event.breakDownDates[0].endDate)]),
            };

        return (
          hasMeter && (
            <Grid container spacing={1} style={{ marginLeft: '8px' }}>
              <Grid item>
                <div>
                  <Typography variant={'subtitle1'}>
                    {text.getText(
                      'components.views.spots.connectionMeterTitle',
                      {
                        connectionName: connection.name,
                        meterId: connection.meterId,
                      }
                    )}
                  </Typography>
                </div>
              </Grid>
              <Grid item container key={connection.name}>
                <Grid item>
                  <ConnectionGraph
                    connection={connection}
                    eventId={event._id}
                    timeRange={timeRange}
                  />
                </Grid>
                <Grid item>
                  <ConnectionEnergyStats
                    connection={connection}
                    eventId={event._id}
                    timeRange={timeRange}
                  />
                </Grid>
              </Grid>
            </Grid>
          )
        );
      })}
    </Grid>
  );
}
