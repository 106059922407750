import React from 'react';
import { observer, inject } from 'mobx-react';
import IconButton from 'material-ui/IconButton';
import IconMenu from 'material-ui/IconMenu';
import MenuItem from 'material-ui/MenuItem';
import AccountCircleIcon from 'material-ui/svg-icons/action/account-circle';
import { compose } from 'recompose';
import { logoff } from 'auth/actions';

import * as Action from 'shared/actions';

const enhance = compose(inject('auth', 'text'), observer);

/**
 * ProfileMenu Component class represents the profile menu which appears inside the
 * Header bar in the top of the application view.
 * @class ProfileMenu
 * @extends {React.Component}
 */
export default enhance(
  ({ auth: { profile }, text, ...props }) =>
    profile && (
      <div>
        <IconMenu
          {...props}
          iconButtonElement={
            <IconButton>
              <AccountCircleIcon />
            </IconButton>
          }
          targetOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem
            className="menuitem"
            primaryText={text.get('actions.editProfile')}
            onClick={() => Action.pushRoute('/profile/edit')}
          />
          <MenuItem
            primaryText={text.get('actions.logout')}
            onClick={() => logoff()}
          />
        </IconMenu>
      </div>
    )
);
