import React, { useMemo } from 'react';
import { GridList, GridTile } from 'material-ui/GridList';

import { Box } from 'shared/components';
import { useGetEvent } from 'events/hooks';
import { getSpotStartAndEndDate } from 'spots/utils';
import { getChildrenTotalPower } from 'powerzones/utils';

import DemandTable from './demandTable';
import SupplyTable from './supplyTable';
import './styles.less';

/**
 * Returns the powerzone overview box component.
 * @return {React.Component}
 */
function PowerzoneOverviewBox({ powerzone, spots, text }) {
  const eventId = window.location.pathname.split('/')[2]; //Todo upgrade react router and use useParams https://gitlab.com/watt-now/tool-frontend/-/issues/415
  const { data: event, loading: eventLoading } = useGetEvent(eventId);

  //todo remove this when backend is ready: https://gitlab.com/watt-now/tool-backend/-/tree/mustafa/issue-80
  const totalPower = useMemo(() => {
    if (powerzone.calculated) {
      return powerzone.calculated.totalPower;
    } else {
      const totalPower = getChildrenTotalPower(powerzone.children);
      powerzone.calculated = totalPower;
      return totalPower;
    }
  }, [powerzone]);

  const spotDates = useMemo(() => {
    return getSpotStartAndEndDate(spots);
  }, [spots]);

  const title = (
    <>
      <span>
        {powerzone.label} -
        <span className="featured">{Math.ceil(totalPower)} kW</span>
      </span>
    </>
  );

  return (
    <div className="OverviewBox">
      <Box title={title}>
        <GridList cellHeight="auto" cols={2}>
          <GridTile>
            <DemandTable
              text={text}
              powerzone={powerzone}
              event={event}
              eventLoading={eventLoading}
              spotDates={spotDates}
            />
          </GridTile>
          <GridTile>
            <SupplyTable
              text={text}
              powerzone={powerzone}
              spots={spots}
              spotDates={spotDates}
              event={event}
              eventLoading={eventLoading}
            />
          </GridTile>
        </GridList>
      </Box>
    </div>
  );
}

export default PowerzoneOverviewBox;
