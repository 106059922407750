import { action, extendObservable } from 'mobx';

/**
 * SpotsStore is the Mobx global data storage for the spots data.
 * @class SpotsStore
 */
function SpotsStore() {
  extendObservable(this, {
    list: [],
    loading: false,
    isUnsavedData: false,
    get notAppointedList() {
      return this.list.filter((spot) => !spot.associatedResource);
    },
    getSpotById(id) {
      return this.list.find((obj) => obj._id === parseInt(id, 10));
    },
    getSpot(uri) {
      return this.list.find((obj) => obj.uri === uri);
    },
    isLoading: action((value) => {
      this.loading = Boolean(value);
    }),
    isDirtyForm: action((value) => {
      this.isUnsavedData = Boolean(value);
    }),
    registerSpots: action((spots) => {
      this.list = spots;
    }),
    registerNewSpot: action((spot) => {
      this.list.push(spot);
    }),
    removeSpot: action((spot) => {
      const index = this.list.findIndex((obj) => obj.uri === spot.uri);
      if (index > -1) this.list.splice(index, 1);
    }),
    updateSpot: action((spot) => {
      const index = this.list.findIndex((obj) => obj.uri === spot.uri);
      if (index > -1) this.list[index] = spot;
    }),
  });
}
export const spots = new SpotsStore();
