import React from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { compose } from 'recompose';

import './styles.less';

const enhance = compose(inject('text'), observer);

/**
 * SpotDetails Component class represents all connection related to the spot
 * @class SpotDetails
 * @extends {React.Component}
 */
export default enhance(
  class SpotDetails extends React.Component {
    /**
     * Prepares the date field content to be rendered.
     * @param {string} startDate - Start date string
     * @param {string} endDate - End date string
     * @return {string}
     */
    prepareDateContent(startDate, endDate) {
      if (startDate && endDate) {
        const start = moment(startDate).format('DD/MM/YYYY');
        const end = moment(endDate).format('DD/MM/YYYY');
        return start === end ? start : `${start} → ${end}`;
      }
      return '';
    }

    /**
     * Renders the devices list
     * @param {object[]} devices - Spot devices list
     * @return {Object}
     */
    renderDevices(devices) {
      return (
        devices &&
        devices.map((device, index) => (
          <div className="device" key={index}>
            <span className="quantity">{device.quantity}x</span>
            <span className="name">{device.name}</span>
            <span className="ratedPower">{device.ratedPower}</span>
            <span className="unit">{device.unit}</span>
          </div>
        ))
      );
    }

    /**
     * Renders the component view.
     * @return {React.Component}
     */
    render() {
      const { text, spot } = this.props;
      return spot.layout.connections.map((connection, index) => (
        <div className="connection" key={index}>
          <span className="title">{connection.name}</span>
          <span className="date">
            {this.prepareDateContent(connection.startDate, connection.endDate)}
          </span>
          <span className="nightpower">
            {connection.nightPower && text.get('forms.spot.nightPowerUser')}
          </span>
          {this.renderDevices(connection.devices)}
        </div>
      ));
    }
  }
);
