import React from 'react';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';

import { Metadata } from 'shared/components';

const enhance = compose(inject('text'), observer);

/**
 * Profile class represents the base structure for the profile view.
 * @class Profile
 * @extends {React.Component}
 */
export const ProfilePage = enhance(({ children, text }) => (
  <div className="ProfileView padding">
    <Metadata meta={text.get('components.views.profile.meta')} />
    {children}
  </div>
));
