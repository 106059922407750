import React from 'react';

export default (props) => (
  <img
    {...props}
    src={`${window.location.origin}/assets/logos/logo-greener-powersolutions.svg`}
    alt="logo greener"
    className="logo"
  />
);
