import React from 'react';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';

import { PowerzoneSpotDetails } from 'shared/components';
import { withCompanyName } from 'spots/hocs';

import './styles.less';

const enhance = compose(withCompanyName, inject('text'), observer);

/**
 * SpotDetailsPane class component represents the view which presents the details
 * of the selected spot in the sidepane.
 * @class SpotDetails
 * @extends {React.Component}
 */
export default enhance(({ item, text, showSpotForm, userId, eventId }) => (
  <div className="EventSpotDetails">
    <div className="spotHeading">
      <div className="left column">
        <span className="title" children={text.get('forms.spot.spot')} />
      </div>
    </div>
    <PowerzoneSpotDetails
      userId={userId}
      showSpotForm={showSpotForm}
      removeSpot={(item) => this.props.removeSpot(item)}
      eventId={eventId}
      item={item}
    />
  </div>
));
