import axios from 'axios';
import { General } from 'shared/constants';
import { auth as authStore } from 'auth/store';
import { errorHandler } from '../errors';

export const setMock = (mock) => mock(axios, General.API_URL);

/**
 * Returns the default HTTP headers
 * @return {object}
 */
const getHeaders = () => ({
  'X-Auth-Token': authStore.token || '',
});

/**
 * Backend API default configuration for making requests
 */
export const api = {
  get: (endpoint, otherHeaders = {}) =>
    axios
      .get(General.API_URL + endpoint, {
        headers: getHeaders(),
        ...otherHeaders,
      })
      .catch(errorHandler),
  post: (endpoint, body, otherHeaders = {}) =>
    axios
      .post(General.API_URL + endpoint, body, {
        headers: getHeaders(),
        ...otherHeaders,
      })
      .catch(errorHandler),

  patch: (endpoint, body, otherHeaders = {}) =>
    axios
      .patch(General.API_URL + endpoint, body, {
        headers: getHeaders(),
        ...otherHeaders,
      })
      .catch(errorHandler),

  put: (endpoint, body, otherHeaders = {}) =>
    axios
      .put(General.API_URL + endpoint, body, {
        headers: getHeaders(),
        ...otherHeaders,
      })
      .catch(errorHandler),

  delete: (endpoint, otherHeaders = {}) =>
    axios
      .delete(General.API_URL + endpoint, {
        headers: getHeaders(),
        ...otherHeaders,
      })
      .catch(errorHandler),
};
