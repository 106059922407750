import React, { useEffect } from 'react';

import { notify } from 'shared/actions';
import { Notifications } from 'shared/constants';
import { useGetText } from 'shared/hooks';
import { DialogModal, Spinner } from 'shared/components';

import { CompanyForm } from 'companies/components';
import { useCreateCompany } from 'companies/hooks';

/**
 * CompaniesEdit class represents the view with the form to modify an existing system company.
 * @class CompaniesEditPage
 * @extends {React.Component}
 */
export function CompanyFormModal({
  addModalOpen,
  toggleAddModalOpen,
  companyInput,
  handleClickAdd,
}) {
  const text = useGetText();
  const { createCompany, data: newCompanyResponse } = useCreateCompany();
  const values = companyInput;

  /**
   * Form submission success handler.
   * @param {object} form - Form instance
   * @return {void}
   */
  function onFormSuccess(form) {
    const company = form.values();
    createCompany(company);
  }

  /**
   * Finish the form when the company got successfully created
   */
  useEffect(() => {
    if (newCompanyResponse) {
      notify({
        message: `The company ${newCompanyResponse.name} was created successfully.`,
        level: Notifications.Type.SUCCESS,
        dismiss: 5,
      });
      handleClickAdd(newCompanyResponse);
      toggleAddModalOpen();
    }
  }, [newCompanyResponse]);

  return (
    <DialogModal
      open={addModalOpen}
      bodyPadding={false}
      handleClose={toggleAddModalOpen}
      title={text.getText('forms.company.addCompanyModalTitle')}
    >
      {values ? (
        <CompanyForm onSuccess={onFormSuccess} values={values} />
      ) : (
        <Spinner />
      )}
    </DialogModal>
  );
}
