import React from 'react';

export const Drag = (props) => (
  <svg
    fill="#000000"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <rect x="4" y="13" width="16" height="2" />
      <path d="M4,9v0.7c0.1,0.4,0,0.8,0,1.3v0h16V9H4z" />
      <path d="M7,7l5-5l5,5H7z" />
      <path d="M17,17l-5,5l-5-5H17z" />
    </g>
  </svg>
);
