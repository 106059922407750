import PropTypes from 'prop-types';
import { compareAsc } from 'date-fns';
import { add } from 'shared/utils';
import { Spots } from './constants';

/**
 * Tells wether a spot layout is already filled or not.
 * @param {object} spot - Spot instance
 * @return {boolean}
 */
export const isSpotFilled = (spot) =>
  (spot &&
    spot.layout &&
    spot.layout.connections &&
    spot.layout.connections.length > 0) ||
  false;

/**
 * Calculates the theoretical power of the connection
 * @param {object} connection - Connection instance
 * @return {number}
 */
export const calculateTheoreticalPower = (connection) =>
  (connection.quantity > 0 ? connection.quantity : 1) *
    (connection &&
      connection.devices &&
      connection.devices
        .map(({ ratedPower, quantity }) => ratedPower * quantity)
        .reduce(add, 0)) || 0;

/**
 * Calculates the sum of all connections 'ratedPower' field in Watts.
 * @param {object[]} devices - Device field list total times number of identical connections
 * @return {number}
 */
export function calculateTotalConnectionsPower(devices, connectionQty) {
  return (
    devices
      .map(
        (device) => device.$('ratedPower').value * device.$('quantity').value
      )
      .reduce((a, b) => a + b, 0) * connectionQty
  );
}

/**
 * Calculates the total rated power of a connection by taking the sum of all devices' 'ratedPower' fields.
 * @param {object[]} devices - list with all the devices fields of a connection
 * @return {number}
 */
export function calculateSingleConnectionPowerFromField(devices) {
  let powerCount = 0;
  devices.forEach((device) => {
    powerCount += device.$('ratedPower').value * device.$('quantity').value;
  });
  return powerCount;
}

calculateSingleConnectionPowerFromField.propTypes = {
  devices: PropTypes.object,
};

export function calculateSingleConnectionPowerFromObject(devices) {
  let powerCount = 0;
  devices.forEach((device) => {
    powerCount += device.ratedPower * device.quantity;
  });
  return powerCount;
}

calculateSingleConnectionPowerFromObject.propTypes = {
  devices: PropTypes.object,
};

export function calculateConnectionsPowerFromObject(connection) {
  let powerCount = 0;
  connection.devices &&
    connection.devices.forEach((device) => {
      powerCount += device.ratedPower * device.quantity;
    });
  return powerCount * connection.quantity;
}

calculateConnectionsPowerFromObject.propTypes = {
  connection: PropTypes.object,
  precision: PropTypes.number,
};

/**
 * Calculates the total theoretical power of a list of spots.
 * @param {object[]} spots - Spot list
 * @return {number}
 */
export const calculateTotalTheoreticalPower = (spots) =>
  (spots &&
    spots
      .map(
        ({ layout }) =>
          (layout &&
            layout.connections &&
            layout.connections.map(calculateTheoreticalPower).reduce(add, 0)) ||
          0
      )
      .reduce(add, 0) / 1000) ||
  0;

export function formatWattsToKW(watts, precision = 1) {
  return (watts / 1000).toFixed(precision);
}

formatWattsToKW.propTypes = {
  watts: PropTypes.number,
  precision: PropTypes.number,
};

/**
 * Returns an object with the total amount of occurrences of each connection size for a spot object.
 * @param {object[]} spots - Spot list
 * @return {number}
 */
export function describeConnections(spot) {
  return spot?.layout?.connections
    ?.map(({ connectionSizeType, quantity }) => ({
      connectionSizeType,
      quantity,
    }))
    .reduce((acc, item) => {
      acc[item.connectionSizeType.externalId] = acc[
        item.connectionSizeType.externalId
      ]
        ? acc[item.connectionSizeType.externalId] + item.quantity
        : item.quantity;
      return acc;
    }, {});
}

export const getSpotStatusColor = (spot) => {
  if (spot.approved) return Spots.colors.APPROVED;
  else if (spot.estimated) return Spots.colors.ESTIMATED;
  else if (spot.historicItem) return Spots.colors.HISTORIC;
  else if (
    spot.layout &&
    spot.layout.connections &&
    spot.layout.connections.length > 0
  ) {
    if (spot.origin === 'energyUser') {
      return Spots.colors.ENERGY_USER;
    } else if (spot.origin === 'powerPlanner') {
      return Spots.colors.POWER_PLANER;
    } else if (spot.origin === 'imported') {
      return Spots.colors.IMPORTED;
    }
  } else {
    return Spots.colors.EMPTY;
  }

  return Spots.colors.UNDEFINED;
};

/**
 * Calculates the sum of all devices 'ratedPower' in a single connection.
 * @param {object[]} devices - Device field list total
 * @return {number}
 */
export const calculateTotalConnectionPowerFunctional = (devices) =>
  devices
    .map((device) => device.$('ratedPower').value * device.$('quantity').value)
    .reduce((a, b) => a + b, 0) / 1000;

export function getUniqueIdFromConnectionType(connection) {
  return `${connection.ratedCurrentA}A-${connection.voltageV}V-${connection.type}`;
}

export function getConnectionImagePath(connection, connectionOptions) {
  const uniqueId = getUniqueIdFromConnectionType(connection);
  const imgPath = connectionOptions.find(
    (option) => getUniqueIdFromConnectionType(option) === uniqueId
  ).img;

  return imgPath;
}

/**
 * Return earliest and latest spot dates
 * When none of spots contain dates, startSDate end endDate returns 'null'
 */
export const getSpotStartAndEndDate = (spots) => {
  const startDateList = [],
    endDateList = [];
  spots.forEach((item) => {
    item.layout.connections.forEach((connection) => {
      startDateList.push(connection.startDate);
      endDateList.push(connection.endDate);
    });
  });

  const sortedStartDateList = startDateList
    .filter((date) => date === true)
    .map((date) => new Date(date))
    .sort(compareAsc);

  const sortedEndDateList = endDateList
    .filter((date) => date === true)
    .map((date) => new Date(date))
    .sort(compareAsc);

  const isStartDate = sortedStartDateList.length > 0;
  const isEndDate = sortedEndDateList.length > 0;

  return {
    earliestStartDate: isStartDate ? sortedStartDateList[0] : null,
    latestEndDate: isEndDate
      ? sortedEndDateList[sortedEndDateList.length - 1]
      : null,
  };
};
