import * as Services from './service';

/**
 * Downloads a single company from the API and loads it to the application's store.
 * @return {Promise}
 */
export const loadCompany = (companyId) => Services.loadCompany(companyId);

/**
 * Downloads the list of companies or a single company from the API and loads it to the application's store.
 * @return {Promise}
 */
export const loadCompanies = () => Services.loadCompanies();

/**
 * Takes an user instance as input and sends it to the API so it can be saved.
 * @param {object} company - User instance
 * @return {void}
 */
export const createNewCompany = (company, avoidRedirect = false) =>
  Services.createNewCompany(company, avoidRedirect);

/**
 * Takes an user instance as input and sends it to the API so it can be saved (case it does not exist)
 * or update it (case it already exists).
 * @param {object} company - Company instance
 * @return {void}
 */
export const createOrUpdateCompany = (company, avoidRedirect = false) =>
  Services.createOrUpdateCompany(company, avoidRedirect);

/**
 * Takes a company id as input and send a request to the API to remove the user from the system.
 * @param {string} companyId - Energy user id
 * @return {Promise}
 */
export const removeCompany = (companyId) => Services.removeCompany(companyId);
