import {
  ensureArray,
  ensureNumber,
  ensureString,
  ensureDate,
} from 'shared/utils';

//TODO do we need to combine this mapper with the regular powerinQuiry mapper? https://gitlab.com/watt-now/tool-frontend/-/issues/371

/**
 * Maps the energy user fields to guarantee consistency of the fields within the system.
 * @param {object} powerInquiry - PowerInquiry instance
 * @return {object}
 */
const eventPowerInquiryInvitationMapper = (powerInquiry) => {
  const {
    _id,
    userId,
    companyId,
    spots,
    canSeeConnectionPrices,
  } = powerInquiry;

  const payload = {
    ...powerInquiry,
    _id,
    userId: userId || null,
    companyId: companyId || null,
    spots: ensureArray(spots),
    canSeeConnectionPrices: Boolean(canSeeConnectionPrices) || false,
  };
  return payload;
};

/**
 * Maps the event fields to guarantee consistency of the fields that enter the system.
 * @param {object} event - Event instance
 * @return {object}
 */
export const getEventMapper = (event) => {
  const acts = ensureArray(event.acts);
  const battkVA = ensureNumber(event.battkVA);
  const costDiesel = ensureNumber(event.costDiesel);
  const costGen = ensureNumber(event.costGen);
  const costLabour = ensureNumber(event.costLabour);
  const costOtherHardware = ensureNumber(event.costOtherHardware);
  const costRunningHours = ensureNumber(event.costRunningHours);
  const costTotalEnergy = ensureNumber(event.costTotalEnergy);
  const costTransport = ensureNumber(event.costTransport);

  const festival = ensureString(event.festival);
  const eventCompanies = ensureArray(event.eventCompanies);
  const gridkVA = ensureNumber(event.gridkVA);
  const guestEventCreators = ensureArray(event.guestEventCreators);
  const createdAt = ensureDate(event.createdAt);
  const updatedAt = ensureDate(event.updatedAt);
  const availableConnections = ensureArray(event.availableConnections);
  const owner = ensureNumber(event.owner);
  //Convert energyUserInvitations to powerInquiryInvitations for responses entering the system
  const powerInquiryInvitations = ensureArray(
    event.energyUserInvitations
  ).map((inquiry) => eventPowerInquiryInvitationMapper(inquiry));
  const spareGenerators = ensureArray(event.spareGenerators);
  const tags = ensureArray(event.tags);
  const eventType = ensureString(event.eventType);
  const { uri } = event;
  const visitors = ensureNumber(event.visitors);
  const buildUpDates = ensureArray(event.buildUpDates);
  const showDates = ensureArray(event.showDates);
  const breakDownDates = ensureArray(event.breakDownDates);
  const editors = ensureArray(event.editors);
  const { _id } = event;
  return {
    acts,
    availableConnections,
    battkVA,
    costDiesel,
    costGen,
    costLabour,
    costOtherHardware,
    costRunningHours,
    costTotalEnergy,
    costTransport,
    eventCompanies,
    festival,
    gridkVA,
    guestEventCreators,
    createdAt,
    updatedAt,
    owner,
    powerInquiryInvitations,
    spareGenerators,
    tags,
    eventType,
    uri,
    visitors,
    _id,
    buildUpDates,
    showDates,
    breakDownDates,
    editors,
  };
};

/**
 * Maps the event fields to guarantee consistency of the fields that enter the system.
 * @param {object} event - Event instance
 * @return {object}
 */
export const putEventMapper = (event) => {
  const acts = ensureArray(event.acts);
  const battkVA = ensureNumber(event.battkVA);
  const costDiesel = ensureNumber(event.costDiesel);
  const costGen = ensureNumber(event.costGen);
  const costLabour = ensureNumber(event.costLabour);
  const costOtherHardware = ensureNumber(event.costOtherHardware);
  const costRunningHours = ensureNumber(event.costRunningHours);
  const costTotalEnergy = ensureNumber(event.costTotalEnergy);
  const costTransport = ensureNumber(event.costTransport);
  //Convert powerInquiryInvitations to energyUserInvitations for payload leaving the system
  const energyUserInvitations = ensureArray(
    event.powerInquiryInvitations
  ).map((inquiry) => eventPowerInquiryInvitationMapper(inquiry));

  const eventCompanies = ensureArray(event.eventCompanies);
  const festival = ensureString(event.festival);
  const gridkVA = ensureNumber(event.gridkVA);
  const guestEventCreators = ensureArray(event.guestEventCreators);
  const createdAt = ensureDate(event.createdAt);
  const updatedAt = ensureDate(event.updatedAt);
  const owner = ensureNumber(event.owner);
  const availableConnections = ensureArray(event.availableConnections).map(
    (item) => ({
      externalId: item.externalId,
      price: ensureNumber(item.price) || null,
    })
  );
  const spareGenerators = ensureArray(event.spareGenerators);
  const tags = ensureArray(event.tags);
  const eventType = ensureString(event.eventType);
  const { uri } = event;
  const visitors = ensureNumber(event.visitors);
  const buildUpDates = ensureArray(event.buildUpDates);
  const showDates = ensureArray(event.showDates);
  const breakDownDates = ensureArray(event.breakDownDates);
  const editors = ensureArray(event.editors);
  const { _id } = event;
  return {
    acts,
    availableConnections,
    battkVA,
    costDiesel,
    costGen,
    costLabour: costLabour,
    costOtherHardware,
    costRunningHours,
    costTotalEnergy,
    costTransport,
    energyUserInvitations,
    eventCompanies,
    festival,
    gridkVA,
    guestEventCreators,
    createdAt,
    updatedAt,
    owner,
    spareGenerators,
    tags,
    eventType,
    uri,
    visitors,
    _id,
    buildUpDates,
    showDates,
    breakDownDates,
    editors,
  };
};
