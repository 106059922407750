import moment from 'moment';
import { General } from 'shared/constants';

export const ensureArray = (value) => (value instanceof Array ? value : []);

export const ensureNumber = (value) => (value && parseFloat(value, 10)) || 0;

export const ensureString = (value) => value || '';

export const ensureDate = (value) =>
  moment(value).toISOString() || General.NO_DATE;

export const ensureUtcDate = (value) =>
  moment(value).utc().toISOString() || General.NO_DATE;
