export default (text) => ({
  festival: text.festivalName,
  visitors: text.visitors,
  eventType: text.eventType,
  'buildUpDates[].startDate': text.startDate,
  'buildUpDates[].endDate': text.endDate,
  'buildUpDates[].startTime': text.startTime,
  'buildUpDates[].endTime': text.endTime,
  'showDates[].startDate': text.startDate,
  'showDates[].endDate': text.endDate,
  'showDates[].startTime': text.startTime,
  'showDates[].endTime': text.endTime,
  'breakDownDates[].startDate': text.startDate,
  'breakDownDates[].endDate': text.endDate,
  'breakDownDates[].startTime': text.startTime,
  'breakDownDates[].endTime': text.endTime,
});
