import React from 'react';
import { SelectInput } from 'shared/components/form';
import { CircularProgress, Button } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';

import { downloadTemplate } from 'shared/actions';
import { Icon } from 'shared/components';

import { DOCUMENT_TYPES } from 'users/constants';

import ModalFooter from '../modal/modalFooter';
import '../styles.less';

/**
 * FirstStepOfUploadingAccount component represents the first step of uploading accounts
 * @class FirstStepOfUploadingAccount
 * @extends {React.Component}
 */
export default class FirstStepOfUploadingAccount extends React.Component {
  state = {
    imagePreview: '',
    isHintShown: true,
    imageFile: null,
    expanded: false,
    isLoading: false,
    docType: 'Greener Default',
  };

  /**
   * Handles the toggling of connection
   * @return {void}
   */
  toggleExpansion = () => {
    if (!this.props.showConfirmModal) {
      this.setState(
        {
          expanded: !this.state.expanded,
          isHintShown: !this.state.isHintShown,
        },
        () => {
          this.refs.scrollBlock.scrollTop = 250;
        }
      );
    }
  };

  /**
   * Changed uploaded file's status
   * @return {object} file - file instance
   * @return {object} meta - file metadata
   * @return {object} xhr - instance of XMLHttpRequest if the file is being uploaded, else undefined
   * @return {string} status -file status
   * @return {void}
   */
  handleChangeStatus = ({ meta, file, xhr }, status) => {
    if (status === 'done') {
      const image_as_base64 = URL.createObjectURL(file);
      this.setState({
        imagePreview: image_as_base64,
        imageFile: file,
      });
      this.props.onChangeInputValue(file);
    }

    if (status === 'removed') {
      this.setState({
        imagePreview: null,
        imageFile: null,
        isLoading: false,
      });
      this.props.onChangeInputValue(null);
    }
  };

  /**
   * Handles the downloading account template
   * @return {void}
   */
  downloadTemplate = () => {
    if (!this.props.showConfirmModal) downloadTemplate();
  };

  /**
   * Handles the changing of doctype select
   * @param {string} value - doctype value
   * @return {void}
   */
  onChangeDocType = (value) => this.setState({ docType: value });

  /**
   * Handles the submit of import file and change the step
   * @param {string} value - doctype value
   * @return {void}
   */
  onSubmitFile = () => {
    this.setState({ isLoading: true });
    this.props.handleSubmit(this.state.imageFile);
  };

  /**
   * Renders the component view.
   * @return {React.Component}
   */
  render() {
    const { text, handleSubmit, showConfirm, showConfirmModal } = this.props;

    const inputContent = (
      <div>
        <p>{text.get('actions.uploadFile')}...</p>
        <span className="browse">{text.get('actions.browse')}</span>{' '}
        <span>{text.get('components.views.users.noFile')}</span>
      </div>
    );

    return (
      <React.Fragment>
        <div className="modalBody" ref="scrollBlock">
          {this.state.isLoading && (
            <div className="loading-block">
              <CircularProgress color="primary" />
            </div>
          )}
          <div className="row">
            <SelectInput
              value={this.state.docType}
              className="template-select"
              options={DOCUMENT_TYPES}
              onChange={(value) => this.onChangeDocType(value)}
              label={text.get('components.views.users.selectTemplate')}
            />
          </div>
          <div className="row">
            <label className="dropzone-label">
              {text.get('components.views.users.fileUpload')} <sup>*</sup>
            </label>
            <Dropzone
              onChangeStatus={this.handleChangeStatus}
              onSubmit={() => handleSubmit(this.state.imageFile)}
              maxFiles={1}
              multiple={false}
              inputContent={inputContent}
              submitButtonContent={text.get('actions.addFile')}
              accept=".xls, .xlsx, .xlsm, .xltx"
            />
          </div>
          <div className="row expanded-block">
            <div className="info-line">
              <Button
                variant="outlined"
                endIcon={<HelpOutlineIcon />}
                onClick={this.toggleExpansion}
              >
                {text.get(
                  `components.views.users.${
                    this.state.isHintShown ? 'showHint' : 'hideHint'
                  }`
                )}
              </Button>
              <Button variant="outlined" onClick={this.downloadTemplate}>
                {text.get(
                  'components.views.powerInquiries.importAccounts.downloadTemplate'
                )}
                <Icon name="file-download" />
              </Button>
            </div>
            {this.state.expanded && (
              <p>
                <b>Mandatory fields:</b> Company name, Name, Spot <br />
                <b>Optional fields:</b> <br />
                <ul className="hint-list">
                  <li>Powerzone</li>
                  <li>e-mail</li>
                  <li>Coordinate</li>
                  <li>Area</li>
                  <li>Sub area 1</li>
                  <li>Sub area 2</li>
                  <li>Spot</li>
                  <li>Connection Qty</li>
                  <li>
                    Connection(choose from:
                    16ASchuko/230V;16ACEE/230V;16A/400V;32A/400V;63A/400V;125A/400V;250A/400V;400A/400V)
                  </li>
                  <li>Connection name</li>
                  <li>Appliance</li>
                  <li>Rated P</li>
                  <li>Appliance Qty</li>
                  <li>Rated P Total</li>
                  <li>Power on (hh:mm DD/MM/YYYY)</li>
                  <li>Power off (hh:mm DD/MM/YYYY)</li>
                  <li>Night (blank, 0 or 1)</li>
                  <li>Ups Needed</li>
                  <li> Has Arrived</li>
                  <li>Is Connected</li>
                  <li>Last update (hh:mm DD/MM/YYYY)</li>
                  <li>
                    Cluster (Choose from: Food, Bar, Heating, Production
                    Village, Refrigeration, Sanitation, Show Light, Site
                    Production, Site light, Sound)
                  </li>
                  <li>Remarks</li>
                </ul>
              </p>
            )}
          </div>
        </div>
        <ModalFooter
          {...{ text, showConfirm, showConfirmModal }}
          disabledRuleForNextBtn={!this.state.imageFile}
          handleSubmit={this.onSubmitFile}
        />
      </React.Fragment>
    );
  }
}
