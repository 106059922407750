import { useQuery } from 'react-query';

import { notify } from 'shared/actions';
import { Notifications } from 'shared/constants';
import { api } from 'shared/utils';
import { Roles } from 'shared/constants';

import { loadUser, userHasPermission } from 'auth/actions';
import { getEventMapper } from '../mapper';
import { eventKeys } from 'shared/utils/api/queryKeys';

export const loadEvents = (userId) => {
  const url = userId ? `/events?userId=${userId}` : '/events';
  return api.get(url).then(({ status, data }) =>
    status === 200 && data
      ? data.map((item) => ({
          spotsStatus: item.spotsStatus,
          event: getEventMapper(item.event),
        }))
      : []
  );
};

export const useGetEvents = (userId) => {
  let queryKey;
  let requestFn;

  if (!userId) {
    if (!userHasPermission(Roles.ADMIN)) {
      // if the user is not an admin, get the id of the authenticated user so we can query only the events related to the user
      const user = loadUser();
      userId = user._id;
    }
  }
  if (userId) {
    queryKey = eventKeys.list({ userId });
    requestFn = () => loadEvents(userId);
  } else {
    queryKey = eventKeys.all();
    requestFn = () => loadEvents();
  }
  const { data, status, ...others } = useQuery(queryKey, requestFn, {
    onError: (error, variables) => {
      notify({
        message: error.message,
        level: Notifications.Type.ERROR,
      });
      return error;
    },
  });

  return {
    ...others,
    loading: status === 'loading',
    data,
  };
};
