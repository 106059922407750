import React from 'react';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';

import { changeFormState } from 'spots/actions';
import { Button } from 'shared/components/form';
import { DialogModal } from 'shared/components';

import Connection from './connection';
import './styles.less';
import ConnectionSizes from '../../connection/SelectSizeInput/ConnectionSizes';

const enhance = compose(inject('text'), observer);

/**
 * Connections component class represents the SpotAdminForm connections section, which
 * controls all the behavior related to CRUD connections inside the form.
 * @class Connections
 * @extends {React.Component}
 */
export default enhance(
  class Connections extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        connectionSizeTypeSelected: {
          externalId: '',
          price: null,
        },
        modalOpen: false,
      };
      this.toggleModal = this.toggleModal.bind(this);
    }

    /**
     * Creates a new empty connection instance.
     * @param {number} index - Size of the connections list
     * @return {object}
     */
    createNewConnection(index = 0) {
      const { text } = this.props;
      changeFormState(true);
      return {
        endDate: null,
        startDate: null,
        name: `${text.get('forms.spot.connectionName')} ${index + 1}`,
        connectionSizeType: this.state.connectionSizeTypeSelected,
        quantity: 1,
        nightPower: false,
        upsNeeded: false,
        hasArrived: false,
        isConnected: false,
        visible: false,
        devices: [],
        meterId: null,
        price: null,
      };
    }

    /**
     * Adds a new connection to the form.
     * @param {string} connectionSize - the value
     * chosen during creation
     * @return {void}
     */
    addConnection = (connectionSizeType) => {
      const { connections, formSubmit } = this.props;
      this.setState({ connectionSizeTypeSelected: connectionSizeType }, () => {
        connections.add(this.createNewConnection(connections.size));
      });
      formSubmit();
    };

    /**
     * Sets the initial connection size
     * @param {string} connectionSizeType
     * @return {string}
     */
    setInitialConnectionSize = (e, connectionSizeType) => {
      this.addConnection(connectionSizeType, e);
      this.toggleModal(e, false);
    };

    toggleModal() {
      this.setState({ modalOpen: !this.state.modalOpen });
    }

    /**
     * Handles the click on the 'x' button to remove the connection.
     * @param {object} connection - Connection field object
     * @return {void}
     */
    removeConnection(e, connection) {
      const { connections, formSubmit } = this.props;
      connections.del(connection.path);
      formSubmit(e);
    }

    /**
     * Renders the connection list.
     * @return {React.Component}
     */
    renderConnections = (connections) => {
      return connections.map((connection, index) => {
        const isLast = index + 1 === this.props.connections.size;
        return (
          <Connection
            key={index}
            isLast={isLast}
            formSubmit={this.props.formSubmit}
            spot={this.props.spot}
            field={connection}
            eventId={this.props.eventId}
            canRemove={this.props.connections.size > 0 && !this.props.disabled}
            disabled={this.props.disabled}
            removeHandler={(e) => this.removeConnection(e, connection)}
          />
        );
      });
    };

    /**
     * Renders the component view.
     * @return {React.Component}
     */
    render() {
      const {
        connections,
        text,
        showSelectFromHistory,
        onSelectFromHistoryPress,
        disabled,
      } = this.props;
      const { modalOpen } = this.state;
      return (
        <div className="Connections">
          <DialogModal
            open={modalOpen}
            bodyPadding={false}
            handleClose={this.toggleModal}
            title={text.get('forms.spot.selectSize')}
            text={text}
          >
            <ConnectionSizes
              handleClick={this.setInitialConnectionSize}
              showPrice
            />
          </DialogModal>
          <h3 children={text.get('forms.spot.connections')} />
          {this.renderConnections(connections.fields.values())}
          <div className="btn-block">
            <div className="btn-grid">
              <div>
                <Button
                  type="button"
                  styling="borderless"
                  className="button-style-alt"
                  onClick={this.toggleModal}
                  children={text.get('forms.spot.addConnection')}
                  disabled={disabled}
                />
              </div>
              {showSelectFromHistory && (
                <React.Fragment>
                  <div className="text-cell">{text.get('forms.spot.or')}</div>
                  <div>
                    <Button
                      type="button"
                      styling="borderless"
                      className="button-style-alt"
                      onClick={onSelectFromHistoryPress}
                      children={text.get('forms.spot.selectFromHistory')}
                      disabled={disabled}
                    />
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      );
    }
  }
);
