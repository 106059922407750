import React from 'react';
import { Paper, Grid } from '@material-ui/core';
import { Box } from 'shared/components/box';
import ConnectionTitle from '../connection/ConnectionTitle';
import DeviceRowItem from '../devices/deviceRowItem';
import { calculateConnectionsPowerFromObject } from 'spots/utils';
import { useGetText } from 'shared/hooks';
import { prepareDateContent } from 'shared/utils';

import './styles.less';
/**
 * OverviewBox Component class represents the Spot's overview box with all the spot information.
 * @class OverviewBox
 * @extends {React.Component}
 */
export function SpotOverviewBox({
  spot: {
    layout: { connections },
  },
}) {
  const text = useGetText();

  return (
    <Box
      className="OverviewBox"
      title={text
        .getText('forms.spot.summaryConnections')
        .replace('{number}', connections.length)}
    >
      <Grid container>
        {connections.map((connection, index) => {
          const connectionsPower = (
            calculateConnectionsPowerFromObject(connection) / 1000
          ).toFixed(1);
          const { devices } = connection;
          const hasDevices = devices.length > 0;
          return (
            <Grid item style={{ margin: '12px' }} key={`connection-${index}`}>
              <Paper>
                <div className="connection" key={index}>
                  <ConnectionTitle connection={connection} />
                  <span className="nightpower">
                    {connection.nightPower &&
                      text.getText('forms.spot.nightPowerRevision')}
                  </span>
                  <span className="upsNeeded">
                    {connection.upsNeeded &&
                      text.getText('forms.spot.upsNeededRevision')}
                  </span>
                  <span> {text.getText('forms.spot.appliances')}: </span>
                  {hasDevices &&
                    connection.devices.map((device, index) => (
                      <DeviceRowItem
                        device={device}
                        key={`DeviceRowItem.${index}`}
                      />
                    ))}
                  <span className="total">
                    {text.getText('components.views.connections.total')}{' '}
                    {connectionsPower} kW
                  </span>
                  <span className="date">
                    {text.getText('forms.spot.dates')}:{' '}
                    {prepareDateContent(
                      connection.startDate,
                      connection.endDate
                    )}
                  </span>
                </div>
              </Paper>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
