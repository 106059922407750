import React, { useEffect } from 'react';

import { Box } from 'shared/components';
import { goBack, notify } from 'shared/actions';
import { useGetText } from 'shared/hooks';
import { Notifications } from 'shared/constants';

import { useCreateCompany } from 'companies/hooks';
import { CompanyForm } from 'companies/components';
import './styles.less';

export function CompanyAddPage() {
  const text = useGetText();
  const { createCompany, data } = useCreateCompany();
  /**
   * Form submission success handler.
   * @param {object} form - Form instance
   * @return {void}
   */
  function handleFormSuccess(form) {
    const company = form.values();
    createCompany(company);
  }

  //Handles the notification after the data has successfully been stored
  useEffect(() => {
    if (data) {
      notify({
        message: `The company ${data._id} was created successfully.`,
        level: Notifications.Type.SUCCESS,
        dismiss: 5,
      });
      goBack();
    }
  }, [data]);

  return (
    <div className="CompanyAdd">
      <Box
        title={text.getText('components.views.adminCompanies.listHeaderAdd')}
      >
        <CompanyForm onSuccess={handleFormSuccess} />
      </Box>
    </div>
  );
}
