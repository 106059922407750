import React from 'react';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';

import { Button, TextInput } from 'shared/components/form';
import { formCreator } from 'shared/form';

import fields from './fields';
import './styles.less';

const enhance = compose(inject('text', 'auth'), observer);

/**
 * LoginForm class represents the login form component.
 * @class LoginForm
 * @extends {React.Component}
 */
export const LoginForm = enhance(
  class extends React.Component {
    // {object} state - Component state holds the form instance.
    state = { form: null };

    // {object} config - Form configuration getter
    get config() {
      const { onSuccess, onError, text } = this.props;
      return {
        title: 'login',
        fields: fields(text),
        onSuccess,
        onError,
      };
    }

    /**
     * React lifecycle method - executed everytime the component is about to be mounted in the view.
     * @return {void}
     */
    UNSAFE_componentWillMount() {
      const form = formCreator(this.config);
      this.setState({ form });
      form.clear();
    }

    /**
     * Renders the component view.
     * @return {React.Component}
     */
    render() {
      const { text, auth } = this.props;
      const { form } = this.state;
      return (
        <form className="loginBox" onSubmit={form.onSubmit}>
          <div className="loginFields">
            <TextInput field={form.$('email')} type="text" />
            <TextInput field={form.$('password')} type="password" />
          </div>
          <div className="loginButtonRow">
            <Button
              type="submit"
              onClick={form.onSubmit}
              disabled={!!auth.loading}
              styling="cta"
            >
              {text.get('actions.login')}
            </Button>
          </div>
        </form>
      );
    }
  }
);
