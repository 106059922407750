import * as Mobx from 'mobx';
import 'sanitize.css/sanitize.css';
import 'react-dates/lib/css/_datepicker.css';

import * as Stores from './shared/stores';
import { loadActiveUser } from 'auth/actions';

// Forces stores to be mutate through store actions
Mobx.useStrict(true);

// Updates view store dimensions everytime the screen is resized
// eslint-disable-next-line no-undef
window.addEventListener('resize', () => Stores.view.updateDimensions());

// Updates user store with the stored data if present
loadActiveUser();
