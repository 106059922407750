import React, { useEffect } from 'react';

import { Box, Spinner } from 'shared/components';
import { goBack } from 'shared/actions';

import { CompanyForm } from 'companies/components';
import { useGetCompany, useUpdateCompany } from 'companies/hooks';
import { useGetText } from 'shared/hooks';
import './styles.less';

/**
 * Form submission success handler.
 * @param {object} form - Form instance
 * @return {void}
 */
export function CompanyEditPage({ params }) {
  const { companyId } = params;
  const text = useGetText();
  const { company, loading } = useGetCompany(companyId);
  const { updateCompany, data } = useUpdateCompany();

  async function handleFormSuccess(form) {
    const company = form.values();
    updateCompany(company);
  }

  useEffect(() => {
    if (data) {
      goBack();
    }
  }, [data]);

  return (
    <div className="CompanyEditPage">
      <Box
        title={text.getText('components.views.adminCompanies.listHeaderEdit')}
      >
        {loading ? (
          <Spinner />
        ) : (
          <CompanyForm onSuccess={handleFormSuccess} values={company} />
        )}
      </Box>
    </div>
  );
}
