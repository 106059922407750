import { ME_QUERY_KEY } from 'auth/hooks';
import { useCallback } from 'react';
import { useQueryClient, useMutation } from 'react-query';

import { Notifications } from 'shared/constants';
import { notify } from 'shared/actions';
import { api } from 'shared/utils';

import userMapper from '../mapper';

import { USERS_QUERY_KEY } from './useGetUsers';

const doUpdateUser = (user) =>
  api.put(`/users/${user._id}`, userMapper(user)).then(({ data }) => data);

export const useUpdateUser = () => {
  const queryClient = useQueryClient();
  const { mutate, status, ...others } = useMutation(doUpdateUser, {
    onSuccess: (response) => {
      if (response) {
        queryClient.refetchQueries(USERS_QUERY_KEY);
        queryClient.refetchQueries(ME_QUERY_KEY); // refetch own profile in case own profile was updated
      }
    },
    onError: (error) => {
      console.error(error);
      notify({
        message: 'test', //error,
        level: Notifications.Type.ERROR,
      });
    },
  });

  const updateUser = useCallback((user) => mutate(user), [mutate]);

  return {
    ...others,
    loading: status === 'loading',
    updateUser,
  };
};
