import React from 'react';

import { useGetText } from 'shared/hooks';
import { Spinner } from 'shared/components';
import { useGetEvent } from 'events/hooks';

/**
 * PowerInquiryBase class is the base view with all the common elements for all the
 * event energy users pages.
 * @class PowerInquiryBase
 * @extends {React.Component}
 */
export const PowerInquiryBase = ({
  children,
  location,
  params: { eventId },
}) => {
  const text = useGetText();
  const { data, loading } = useGetEvent(eventId);
  return loading ? (
    <Spinner />
  ) : (
    <div className="EventPowerInquiries padding">
      {!location.pathname.includes('add') &&
        !location.pathname.includes('edit') && (
          <h1>{`${data.festival} • ${text.getText(
            'components.views.powerInquiries.pageTitle'
          )}`}</h1>
        )}
      {children}
    </div>
  );
};
