import React from 'react';

import { Chip, Typography } from '@material-ui/core';

import './styles.less';

export default function DeviceRowItem({ device }) {
  const { quantity, name, brand, modelNumber, ratedPower, unit } = device;
  return (
    <div className="device">
      <span className="quantity">{quantity}x</span>
      <span className="name"> {name}</span>
      <span className="name">{brand}</span>
      <span className="name">{modelNumber}</span>
      <span>
        <Chip
          size="small"
          variant="outlined"
          label={
            <Typography variant="caption">
              {`${ratedPower} ${unit}`}{' '}
            </Typography>
          }
        />
      </span>
      <span className="unit"></span>
    </div>
  );
}
