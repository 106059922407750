import React, { useState } from 'react';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import { useController } from 'react-hook-form';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';

import './styles.less';

function DateTimePicker({
  name,
  minDate,
  maxDate,
  timeText,
  control,
  initialVisibleMonth,
  defaultValue,
  readOnly,
  ...rest
}) {
  const {
    field: { ref, value, onChange },
  } = useController({
    name,
    control,
    defaultValue,
  });
  const [focused, setFocused] = useState(false);

  const isInclusivelyAfterDay = (minDate, a, b) => {
    if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
    return !isBeforeDay(minDate, a, b);
  };

  const isBeforeDay = (minDate, a, b) => {
    if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
    if (a > minDate) {
      const aYear = a.year();
      const aMonth = a.month();

      const bYear = b.year();
      const bMonth = b.month();

      const isSameYear = aYear === bYear;
      const isSameMonth = aMonth === bMonth;

      if (isSameYear && isSameMonth) return a.date() <= b.date();
      if (isSameYear) return aMonth < bMonth;
      return aYear < bYear;
    }
  };

  const handleDateChange = (date) => {
    // copy the time values from the value to the new date
    if (value) {
      const valueDate = moment(value);
      date.hour(valueDate.hour());
      date.minute(valueDate.minute());
    }
    onChange(date && date.toISOString());
  };

  const handleTimeChange = (time) => {
    onChange(time && time.toISOString());
  };

  return (
    <div className="DateTimePicker">
      <div className="dateBox">
        <SingleDatePicker
          {...rest}
          ref={ref}
          id={name}
          withPortal
          onDateChange={handleDateChange}
          onFocusChange={({ focused }) => setFocused(focused)}
          focused={focused}
          enableOutsideDays={false}
          date={value ? moment(value) : null}
          isOutsideRange={(day) => isInclusivelyAfterDay(minDate, day, maxDate)}
          displayFormat="DD/MM/YYYY"
          initialVisibleMonth={initialVisibleMonth}
          numberOfMonths={1}
          readOnly
          noBorder
          small
        />
      </div>
      <span className="separator">/</span>
      <div className="timesBox">
        <TimePicker
          {...rest}
          ref={ref}
          placeholder={timeText}
          showSecond={false}
          minuteStep={15}
          value={value ? moment(value) : null}
          onChange={handleTimeChange}
          defaultOpenValue={moment(new Date(0, 0, 0, 8, 0, 0, 0))}
          focusOnOpen
        />
      </div>
    </div>
  );
}

export { DateTimePicker };
