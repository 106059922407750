import pick from 'lodash/pick';
import { duplicateNode } from 'powerzones/actions';

import * as Services from './service';
import { areas as areasStore } from './store';

/**
 * Loads all the areas from the API to the application's store so it can be
 * accessible in all the application.
 * @return {Promise}
 */
export const loadAreas = (eventId) => Services.loadAreas(eventId);

/**
 * Takes a list of areas as input and registers it to the areas' store.
 * @param {object[]} areas - List of Area instances
 * @return {void}
 */
export const registerAreas = (areas) => areasStore.registerAreas(areas);

/**
 * Takes an Area as input and add it to the areas store's list of areas.
 * @param {object} area - Area instance
 * @return {void}
 */
export const registerNewArea = (area) => areasStore.registerNewArea(area);

/**
 * Takes an Area instance as input and sends it to the API so it can be saved.
 * @param {object} area - Area instance
 * @param {string} eventId - Event id
 * @return {Promise}
 */
export const createArea = (area, eventId) => Services.createArea(area, eventId);

/**
 * Takes an Area instance as input  and sends it to the API so it can be updated.
 * @param {void} area - Area instance
 * @return {Promise}
 */
export const updateArea = (area) => Services.updateArea(area);

/**
 * Takes an Area instance as input and removes it from the areas store.
 * @param {void} area - Area instance
 * @return {Promise}
 */
export const removeArea = (area) => Services.removeArea(area);

/**
 * Toggles the area node children visibility.
 * @param {object} area - Area instance
 * @param {boolean} visible - on/off state
 * @return {void}
 */
export const toggleArea = (area, visible) =>
  areasStore.toggleVisibility(area, visible);

/**
 * @param {objct} node -> Area to Clone
 * @param {parent} string -> Parent URI
 */
export const cloneArea = async (node, parent, user) => {
  const clonedArea = pick(node, [
    'eventId',
    'associatedResource',
    'cableSize',
    'updatedAt',
    'tag',
    'userId',
    'subtitle',
    'layout.comment',
  ]);
  if (parent) clonedArea.associatedResource = parent;
  if (user) clonedArea.userId = user.userId;
  // cloned children?
  const createdArea = await createArea(clonedArea, clonedArea.eventId);
  return Promise.all(
    node.children.map((child) => duplicateNode(child, createdArea, user, true))
  );
};
