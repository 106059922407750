import React from 'react';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';

import { SpotOverviewBox, SpotData } from 'spots/components';
import { Button } from 'shared/components/form';
import { pushRoute } from 'shared/actions';
import { isSpotFilled } from 'spots/utils';
import { saveSpotToBuffer } from 'spots/actions';
import 'shared/styles/globals.less';
import { isPast } from 'date-fns';
import TemplatesBox from './templatesBox';
import './styles.less';
import { AVAILABLE_METER_IDS } from 'spots/constants/connections';
import { useGetEvent } from 'events/hooks';
import { useGetText } from 'shared/hooks';
import { Spinner } from 'shared/components';
/**
 * EventSpotOverview class represents the first view which should be presented in the details view
 * of the EventSpots view. If the spot is already filled, it shows the overview box with all the saved information.
 * Otherwise, it just presents a list of template options to start filling from.
 * @extends {React.Component}
 */
export default function EventSpotOverview({
  eventId,
  spot,
  onChangeModifySpot,
}) {
  const { data: event, loading } = useGetEvent(eventId);
  const text = useGetText();

  if (loading) return <Spinner />;

  /**
   * 'Modify' button click handler.
   * @return {React.Component}
   */
  function onClickModify(spot) {
    onChangeModifySpot(spot._id);
    saveSpotToBuffer(spot);
    pushRoute(`/event/${eventId}/spots/${spot._id}/connections`);
  }

  /**
   * Renders the comment box.
   * @param {string} comment - Text content
   * @return {React.Component}
   */
  function renderCommentBox(comment) {
    if (!comment) return null;
    return (
      <div className="comment">
        <p>{comment}</p>
      </div>
    );
  }

  if (!isSpotFilled(spot))
    return <TemplatesBox spotId={spot._id} eventId={eventId} />;

  //Todo check why the event is somethimes undefined
  const eventStartHasPassed = event
    ? isPast(new Date(event.buildUpDates[0].startDate))
    : true;

  const hasEnergyMeter = spot.layout.connections.some((c) =>
    AVAILABLE_METER_IDS.includes(c.meterId)
  );

  /**
   * Renders the component view.
   * @return {React.Component}
   */
  return (
    <div className="EventSpotOverview">
      {eventStartHasPassed &&
        (hasEnergyMeter ? (
          <SpotData spot={spot} event={event} />
        ) : (
          <div>
            Er zijn geen (online) energie meters gekoppeld aan dit stroompunt
          </div>
        ))}
      <SpotOverviewBox spot={spot} />
      {renderCommentBox(spot.layout.comment)}
      <div className="buttonRow">
        <Button
          type="button"
          styling="alt"
          disabled={spot.approved}
          onClick={() => onClickModify(spot)}
        >
          {text.getText('actions.modify')}
        </Button>
      </div>
      {spot.approved && (
        <span className="message">
          {text.getText('forms.spot.approvedMessage')}
          <a src="powerplan@greener.nl"> powerplan@greener.nl </a>
        </span>
      )}
    </div>
  );
}
