import React from 'react';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';

import { Card, Metadata } from 'shared/components';
import { pushRoute } from 'shared/actions';

import { recoverPassword } from 'auth/actions';
import { ForgetPasswordForm } from 'auth/components';

import './styles.less';

const enhance = compose(inject('text'), observer);

/**
 * ForgetPasswordPage class represents the view with the forget password form.
 * @class ForgetPasswordPage
 * @extends {React.Component}
 */
export const ForgetPasswordPage = enhance(
  class extends React.Component {
    /**
     * Form submission success handler.
     * @param {object} form - Form instance
     * @return {void}
     */
    onSuccess(form) {
      const { email } = form.values();
      recoverPassword(email);
    }

    /**
     * Renders the component view.
     * @return {React.Component}
     */
    render() {
      const { text } = this.props;
      return (
        <section className="ForgetPasswordView">
          <Metadata meta={text.get('components.views.login.meta')} />
          <h1>{text.get('components.views.login.pageTitle')}</h1>
          <Card>
            <h3>{text.get('components.views.login.requestPassword')}</h3>
            <ForgetPasswordForm onSuccess={this.onSuccess} />
            <span className="link" onClick={() => pushRoute('/login')}>
              {text.get('components.views.login.linkTextBack')}
            </span>
          </Card>
        </section>
      );
    }
  }
);
