import React from 'react';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import { Tooltip } from '@material-ui/core';
import { Button, TextInput } from 'shared/components/form';
import { formCreator } from 'shared/form';

import fields from './fields';
import lbls from './labels';
import rules from './rules';
import './styles.less';

const enhance = compose(inject('text', 'auth'), observer);

/**
 * RegisterForm class represents the new user registration form.
 * @class RegisterForm
 * @extends {React.Component}
 */
export const RegisterForm = enhance(
  class extends React.Component {
    // {object} state - Component state holds the form instance.
    state = { form: null };

    vanillaForm = {
      name: '',
      companyName: '',
      phone: '',
      repeatPassword: '',
      password: '',
    };

    // {object} config - Form configuration getter
    get config() {
      const { onSuccess, onError, text } = this.props;
      const title = 'register';
      const labels = lbls(text.get('forms.adminUsers'));

      return {
        title,
        fields,
        labels,
        rules,
        onSuccess,
        onError,
      };
    }

    /**
     * React lifecycle method - executed everytime the component is mounted in the view.
     * @return {void}
     */
    componentDidMount() {
      let { values } = this.props;
      values && (values.phone = values.phone?.[0]?.number || '');
      const form = formCreator(this.config);
      this.setState({ form });
      form.clear();
      form.init({ ...this.vanillaForm, ...values });
    }

    /**
     * Renders the component view.
     * @return {React.Component}
     */
    render() {
      const { text, auth } = this.props;
      const { form } = this.state;
      if (!form) return null;
      return (
        <form className="RegisterForm" onSubmit={form.onSubmit}>
          <div className="centeredColumn">
            <TextInput field={form.$('name')} type="text" />
            <Tooltip
              title={text.get('components.views.register.companyName.tooltip')}
              enterDelay={2000}
            >
              <TextInput field={form.$('companyName')} type="text" readOnly />
            </Tooltip>
            <TextInput field={form.$('phone')} type="text" />
            <div className="inputRow">
              <TextInput field={form.$('password')} type="password" />
              <TextInput field={form.$('repeatPassword')} type="password" />
            </div>
          </div>
          <div className="buttonRow">
            <Button
              type="submit"
              onClick={form.onSubmit}
              disabled={!!auth.loading}
            >
              {text.get('actions.submit')}
            </Button>
          </div>
        </form>
      );
    }
  }
);
