import React from 'react';
import Helmet from 'react-helmet'; // Reference: https://github.com/nfl/react-helmet

/**
 * Metadata component
 * @param {object} props - React component properties
 * @return {React.Component}
 */
export const Metadata = ({ meta: { content, name, ...props } }) => (
  <Helmet meta={[{ name, content }]} {...props} />
);
