export default (user) => {
  if (!user._id) user._id = undefined;
  if (typeof user.companyId !== 'number') user.companyId = null;
  if (!user.email) user.email = '';
  if (!user.lastReminderSent) user.lastReminderSent = null;
  if (!user.createdAt) user.createdAt = null;
  if (!user.updatedAt) user.updatedAt = null;
  //if (typeof user.companyId === 'object')user.companyId = user.companyId.value
  user.credits = parseInt(user.credits, 10);
  if (!user.phones) user.phones = [];
  return user;
};
