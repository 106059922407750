import React from 'react';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import EventListItem from './EventListItem';
import './styles.less';

const enhance = compose(inject('text'), observer);

/**
 * HistoricSpots component class represents the list
 * of historic spots
 * @class HistoricSpots
 * @extends {React.Component}
 */
export default enhance(
  class HistoricSpots extends React.Component {
    state = { activeSpotId: null };

    /**
     * Renders the active spot id to show one expanded spot
     *
     * @param {number} activeSpotId - active spot id
     * @return {React.Component}
     */
    changeActiveSpot = (activeSpotId) => this.setState({ activeSpotId });

    /**
     * Renders the master list item
     *
     * @param {object} spot - Spot instance
     * @param {any} index - Element index
     * @return {React.Component}
     */
    renderListItem(spot, index) {
      const { text, onClickSpot, eventId, festivalName } = this.props;
      return (
        <EventListItem
          changeActiveSpot={this.changeActiveSpot}
          eventId={eventId}
          onClickSpot={onClickSpot}
          activeSpotId={this.state.activeSpotId}
          spot={spot}
          key={index}
          text={text}
          festivalName={festivalName}
        />
      );
    }

    /**
     * Renders the component view.
     * @return {React.Component}
     */
    render() {
      let { text, spots } = this.props;
      return (
        <div className="historyModalView">
          <div className="modalHeader">
            <div>{text.get('forms.spot.spot')}</div>
            <div>{text.get('forms.spot.totalPower')}</div>
          </div>
          <div className="modalBody">
            {spots.map((item, index) => {
              return item.layout.connections.length
                ? this.renderListItem(item, index)
                : null;
            })}
          </div>
        </div>
      );
    }
  }
);
