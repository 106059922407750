let toggleHamburgerMenuHandler = null;

/**
 * Toggles the hamburger menu when presented in mobile view.
 * @return {void}
 */
export const toggleHamburgerMenu = () => {
  toggleHamburgerMenuHandler && toggleHamburgerMenuHandler();
};

/**
 * Takes a function as input and use it to handle the toggle hambuerger menu action
 * everytime the user clicks in the button.
 * @param {function} fn - toggle hamburger menu button handler function
 * @return {void}
 */
export const setToggleHamburgerMenuHandler = (fn) =>
  (toggleHamburgerMenuHandler = fn);
