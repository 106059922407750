import React from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import IconButton from 'material-ui/IconButton';
import IconMenu from 'material-ui/IconMenu';
import MenuItem from 'material-ui/MenuItem';
import ReactTooltip from 'react-tooltip';
import { compose } from 'recompose';
import SupervisorAccountOutlinedIcon from '@material-ui/icons/SupervisorAccountOutlined';

import { pushRoute, confirm } from 'shared/actions';
import { ContactBox } from 'shared/ui';
import { General } from 'shared/constants';
import { Icon, SpotList } from 'shared/components';
import { calculateTotalTheoreticalPower } from 'spots/utils';
import { removePowerInquiry } from 'powerinquiries/actions';

import './styles.less';

const enhance = compose(inject('text', 'users'), observer);
/**
 * ListItem class represents an item containing an energy user information in the energy users
 * list view.
 * @class ListItem
 * @extends {React.Component}
 */
export default enhance(
  class InquiryRowItem extends React.Component {
    state = {
      showSendReminderButton: false,
    };

    /**
     * React lifecycle method - executed everytime the component is about to be mounted in the view.
     * @return {void}
     */
    componentDidMount() {
      this.props.powerInquiry.spots.map(
        (item) =>
          !item.approved && this.setState({ showSendReminderButton: true })
      );
    }

    // {string} - Formatted last updated time
    get lastUserUpdate() {
      const {
        powerInquiry: { lastUpdate },
      } = this.props;
      return lastUpdate === General.NO_DATE ? (
        ' - '
      ) : (
        <span>
          <Icon name="edit" height="16" width="16" />{' '}
          {moment(lastUpdate).format('DD/MM/YYYY HH:mm')}
        </span>
      );
      //TODO create conditional where the edit icon is a mail icon when the last update was to send an invitation mail
    }

    get lastReminderSentUpdate() {
      const {
        powerInquiry: { lastReminderSent },
      } = this.props;
      let lastReminder = moment(lastReminderSent);

      lastReminder =
        lastReminder === null ||
        !lastReminderSent ||
        lastReminder.isBefore('1900', 'year')
          ? ' - '
          : lastReminder.format('DD/MM/YYYY HH:mm');

      return (
        <span>
          <Icon name="mail-outline" height="16" width="16" /> {lastReminder}
        </span>
      );
    }

    /**
     * Removes the power inquiry from the event.
     * @param {string} eventId - Event id
     * @param {string} powerInquiry - User id
     * @return {void}
     */
    removePowerInquiry = (eventId, powerInquiryId) =>
      removePowerInquiry(eventId, powerInquiryId);

    /**
     * Renders the component view.
     * @return {React.Component}
     */
    render() {
      const { className, eventId, text, users, powerInquiry } = this.props;

      const { showSendReminderButton } = this.state;
      const theoreticalPower = calculateTotalTheoreticalPower(
        powerInquiry.spots
      ).toFixed(1);
      return (
        <div className={`PowerInquiriesListItem ${className || ''}`}>
          <div
            className="column"
            onClick={() =>
              pushRoute(`/event/${eventId}/inquiries/${powerInquiry._id}/edit`)
            }
          >
            <ContactBox
              companyName={powerInquiry.companyName}
              name={powerInquiry.name}
              icon={
                powerInquiry.invitationSent ? (
                  <Icon
                    name="person"
                    className={`icon ${
                      powerInquiry.accountReady ? 'active' : ''
                    }`}
                  />
                ) : (
                  <Icon name="person" className="icon transparent" />
                )
              }
            />
          </div>
          <div
            className="column"
            onClick={() =>
              pushRoute(`/event/${eventId}/inquiries/${powerInquiry._id}/edit`)
            }
          >
            <SpotList spots={powerInquiry.spots} text={text} />
          </div>
          <div
            className="column"
            onClick={() =>
              pushRoute(`/event/${eventId}/inquiries/${powerInquiry._id}/edit`)
            }
          >
            <ContactBox
              companyName={
                !users.loading && users.getUser(powerInquiry.owner)?.companyName
              }
              name={!users.loading && users.getUser(powerInquiry.owner)?.name}
              icon={
                powerInquiry.owner ? (
                  <SupervisorAccountOutlinedIcon className="icon" />
                ) : (
                  ''
                )
              }
            />
          </div>
          <div
            style={{ marginLeft: '15px', fontSize: '75%' }}
            className="column"
            onClick={() =>
              pushRoute(`/event/${eventId}/inquiries/${powerInquiry._id}/edit`)
            }
          >
            {theoreticalPower || 0} KW
          </div>

          <div
            className="column"
            onClick={() =>
              pushRoute(`/event/${eventId}/inquiries/${powerInquiry._id}/edit`)
            }
          >
            <div>
              <span
                data-for="last-update-tooltip"
                data-tip={text.get(
                  'components.views.powerInquiries.lastUpdate'
                )}
                className="title"
                children={this.lastUserUpdate}
              />
              <span
                data-for="last-reminder-tooltip"
                data-tip={text.get(
                  'components.views.powerInquiries.lastReminderSent'
                )}
                className="title"
                children={this.lastReminderSentUpdate}
              />
            </div>
          </div>
          <div className="column">
            <IconMenu
              iconButtonElement={
                <IconButton>
                  <Icon name="more" />
                </IconButton>
              }
              targetOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem
                primaryText={text.get('actions.edit')}
                onClick={() =>
                  pushRoute(
                    `/event/${eventId}/inquiries/${powerInquiry._id}/edit`
                  )
                }
              />
              <MenuItem
                primaryText={text.get('actions.remove')}
                onClick={() => {
                  confirm(text.get('actions.confirmRemovePowerInquiry'), () =>
                    this.removePowerInquiry(eventId, powerInquiry._id)
                  );
                }}
              />
              {showSendReminderButton && powerInquiry.invitationSent && (
                <MenuItem
                  primaryText={text.get('actions.sendReminder')}
                  onClick={() => {
                    pushRoute(
                      `/event/${eventId}/inquiries/${powerInquiry._id}/sendReminder`
                    );
                  }}
                  //TODO add invite menuitem here as soon as inviting offline users works (backend)
                />
              )}
            </IconMenu>
          </div>
          <ReactTooltip id="last-update-tooltip" effect="solid" />
          <ReactTooltip id="last-reminder-tooltip" effect="solid" />
        </div>
      );
    }
  }
);
