import React from 'react';
import { Button } from 'shared/components/form';
import 'react-dropzone-uploader/dist/styles.css';
import '../styles.less';

/**
 * ModalFooter component represents the modal's footer
 * @class ModalFooter
 * @extends {React.Component}
 */
const ModalFooter = ({
  text,
  step,
  disabledRuleForNextBtn,
  handleSubmit,
  backToPrevStep,
  showConfirm,
  isErrorsExist,
  importBtn,
  showConfirmModal,
}) => {
  const nextBtnText =
    importBtn && !isErrorsExist
      ? text.get(
          'components.views.powerInquiries.importAccounts.importAndInvite'
        )
      : text.get('actions.next');

  return (
    <div className="modalFooter">
      <Button
        size="small"
        styling="text"
        disabled={showConfirmModal}
        onClick={showConfirm}
      >
        {text.get('actions.cancel')}
      </Button>
      {step > 1 && (
        <Button
          size="small"
          styling="text"
          disabled={showConfirmModal}
          onClick={() => backToPrevStep(step - 1)}
        >
          {text.get('actions.back')}
        </Button>
      )}
      <Button
        size="small"
        styling="cta"
        disabled={disabledRuleForNextBtn || showConfirmModal}
        onClick={handleSubmit}
      >
        {nextBtnText}
      </Button>
    </div>
  );
};

export default ModalFooter;
