import React from 'react';

export const Flashlight = (props) => (
  <svg
    fill="#000000"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.5,23.247l4.052-9.454a.751.751,0,0,0-.689-1.046H5.9A.749.749,0,0,1,5.21,11.7L9.8,1.2a.75.75,0,0,1,.687-.45h7.7a.75.75,0,0,1,.585,1.219l-4.05,5.063a.75.75,0,0,0,.586,1.218H18.69a.75.75,0,0,1,.53,1.281Z"
      //stroke="#000000"
      //stroke-linejoin="round"
      strokeWidth="1.5"
    ></path>
  </svg>
);
