import React from 'react';
import { Table, TableBody, TableRow, TableRowColumn } from 'material-ui/Table';
import 'react-dropzone-uploader/dist/styles.css';

import { Button } from 'shared/components/form';
import ModalFooter from '../modal/modalFooter';
import ListItem from '../listItem';
import '../styles.less';

/**
 * ThirdStepOfUploadingAccount component represents the third step of uploading accounts
 * @class ThirdStepOfUploadingAccount
 * @extends {React.Component}
 */
const ThirdStepOfUploadingAccount = ({
  text,
  step,
  importResult,
  accounts,
  newCompanies,
  showConfirm,
  showConfirmModal,
  toggleCheckedItems,
  backToPrevStep,
  handleSubmit,
  onToggleCheckBox,
  checkedAll,
}) => {
  const {
    data: { spots },
  } = importResult;

  //include spot labels in user object
  accounts.forEach((item) => {
    let spotList = spots.filter((spt) => spt.userId === item.user._id);
    let spotNames = [];
    spotList.forEach(async (spt) => {
      spotNames.push(spt.label);
    });
    item.user.spots = spotNames;
    item.companyName =
      newCompanies?.find((comp) => comp._id === item.user.companyId)?.name ||
      item.user?.companyName ||
      '-';
  });

  //sort by company name
  accounts.sort((a, b) => (a.user.companyName < b.user.companyName ? -1 : 1));

  return (
    <>
      <div className="modalSubHeader">
        <span className="validation-header">
          {text.get(
            'components.views.powerInquiries.importAccounts.sendInvitations'
          )}
        </span>
        {accounts.length > 1 && (
          <span lassName="validation-header">
            <Button onClick={toggleCheckedItems} size="small" styling="text">
              {checkedAll
                ? text.get('components.views.powerInquiries.uncheckAll')
                : text.get('components.views.powerInquiries.checkAll')}
            </Button>
          </span>
        )}
      </div>
      <div className="modalBody">
        <Table selectable={false}>
          <TableBody displayRowCheckbox={false}>
            <TableRow className="header-row">
              <TableRowColumn>
                {text.get(
                  'components.views.powerInquiries.importAccounts.companyName'
                )}
              </TableRowColumn>
              <TableRowColumn>
                {text.get(
                  'components.views.powerInquiries.importAccounts.email'
                )}
              </TableRowColumn>
              <TableRowColumn>
                {text.get(
                  'components.views.powerInquiries.importAccounts.spots'
                )}
              </TableRowColumn>
              <TableRowColumn>
                {text.get(
                  'components.views.powerInquiries.importAccounts.invite'
                )}
              </TableRowColumn>
            </TableRow>
            {accounts.length
              ? accounts.map((item, index) => (
                  <ListItem
                    index={index}
                    item={item}
                    checkedAll={checkedAll}
                    onToggleCheckbox={onToggleCheckBox}
                  />
                ))
              : ''}
          </TableBody>
        </Table>
      </div>
      <ModalFooter
        importBtn
        {...{ text, step, backToPrevStep, showConfirm, showConfirmModal }}
        handleSubmit={handleSubmit}
        disabledRuleForNextBtn={false}
      />
    </>
  );
};

export default ThirdStepOfUploadingAccount;
