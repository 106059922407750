// {object[]} DOCUMENT_TYPES - Available import document types
export const DOCUMENT_TYPES = [
  { value: 'Greener Default', label: 'Greener Default' },
];

// maps a role id to a role name string
export const ROLE_NAMES = {
  0: 'account',
  1: 'admin',
  2: 'eventCreator',
  4: 'powerSupplier',
  8: 'energyUser',
  16: 'inquiryManager',
};
