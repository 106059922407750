import chroma from 'chroma-js';

const primaryPalette = {
  'brand-color': '#6BA643', // brand green
  'brand-light': '#9cd871', //Reference: https://material.io/resources/color/#!/?view.left=0&view.right=1&primary.color=6ba643&secondary.color=c0c6d8,
  'brand-dark': '#3b7713',

  'primary-soft-color': '#4392a6', //not used yet

  'secondary-color': '#c0c6d8', // grey
  'secondary-light': '#f3f9ff',
  'secondary-dark': '#9095a7',

  'acc-color1': '#6BA643', // "#0091FF", //brand color
  'acc-color2': '#27c86a',
  'acc-color3': '#f2c610',

  'acc-purple': '#9374DD',
  'acc-dark-green': '#00A380',
  'acc-light-green': '#37C964',
  'acc-pink': '#DF5A9E',
  'acc-yellow': '#FFBF00', //=state warning
  'acc-blue': '#0091FF', //=state info
  'acc-light-blue': '#00abeb', //=state success

  'state-success': '#8cc948',
  'state-error': '#FF484E',
  'state-warning': '#FFBF00',
  'state-info': '#0091FF',

  'neutral-color-100': '#F8F9FB',
  'neutral-color-200': '#F0F3F7',

  title: '#5C637A',
  border: '#E9EAEC',
  label: '#B2C9D6',
};

// returns an object {var1: sd} on the palette
const extendedVariations = () => {
  const varObj = {};
  for (let i = 9; i >= 1; i -= 1) {
    varObj[`brand-color-${i * 10}`] = chroma(primaryPalette['brand-color'])
      .brighten(i)
      .css();
    varObj[`secondary-color-${i * 10}`] = chroma(
      primaryPalette['secondary-color']
    )
      .darken(i)
      .css();
  }
  return varObj;
};

export default { ...primaryPalette, ...extendedVariations() };
