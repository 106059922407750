import React from 'react';
import { Grid, InputAdornment, FormGroup, Tooltip } from '@material-ui/core';

import { useGetText } from 'shared/hooks';
import { CheckboxInput, TextInput } from 'shared/components/functionalForm';
import ConnectionSizeDetail from 'shared/components/ConnectionSizeDetail';

export default function ConnectionsList({ connections, control, formValues }) {
  const text = useGetText();
  return (
    <FormGroup style={{ marginTop: '1em' }}>
      {connections.map((item) => {
        return (
          <Grid item container direction="row" key={item.externalId}>
            <Grid item>
              <ConnectionSizeDetail item={item} key={item.externalId} />
            </Grid>
            <Grid item>
              <CheckboxInput
                control={control}
                name={`${item.externalId}.selected`}
              />
            </Grid>
            <Grid item>
              {formValues[item.externalId]?.selected && (
                <Tooltip title={text.getText('forms.connection.priceTooltip')}>
                  <TextInput
                    control={control}
                    type="number"
                    name={`${item.externalId}.price`}
                    label={text.getText('forms.connection.price')}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">€</InputAdornment>
                      ),
                    }}
                  />
                </Tooltip>
              )}
            </Grid>
          </Grid>
        );
      })}
    </FormGroup>
  );
}
