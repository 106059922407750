/**
 * Sums two values
 * @param {number} a - First number
 * @param {number} b - Second number
 * @return {number}
 */
export const add = (a, b) => a + b;

/**
 * Fixes the number of decimals in a number
 * @param {number} num - Number
 * @param {number} decimals - Amount of decimals
 */
export const decimals = (num, decimals = 0) =>
  !num ? 0 : parseFloat(num.toFixed(decimals));

/**
 * This constant is used to calculate the (average maximum) real rated power (in kW) that is estimated to be available
 * on a connection of a certain current rating in apparent power (kVA).
 * The power factor depends on the actual appliance used, but we assume that
 * lower power factors than this value are not very common on the field.
 * The apparent power can be derived from the current rating and the minimum expected voltage.
 */
const AVERAGE_MINIMUM_POWER_FACTOR = 0.85;
/**
 * This approximately converts kva's to Watts
 * Here we assume a powerFactor, which is an educated assumption
 * @param {*} number in KvA
 * @returns number in Watts
 */

export const aproxCalculateKvAToW = (kva) => {
  return kva * AVERAGE_MINIMUM_POWER_FACTOR * 1000;
};
