export default [
  'layout',
  'layout.connections',
  'layout.connections[].name',
  'layout.connections[].connectionSizeType',
  'layout.connections[].connectionSizeType.externalId',
  'layout.connections[].connectionSizeType.price',
  'layout.connections[].endDate',
  'layout.connections[].startDate',
  'layout.connections[].nightPower',
  'layout.connections[].upsNeeded',
  'layout.connections[].devices',
  'layout.connections[].devices[].name',
  'layout.connections[].devices[].brand',
  'layout.connections[].devices[].modelNumber',
  'layout.connections[].devices[].ratedPower',
  'layout.connections[].devices[].quantity',
  'layout.connections[].devices[].unit',
];
