import React, { useState } from 'react';

import ConnectionSizeForm from 'spots/components/connection/ConnectionSizeForm';
import { useGetCompanies } from 'companies/hooks';
import { Spinner } from 'shared/components';

import { EventDetailForm } from './EventDetailForm';
import './styles.less';

/**
 * EventForm class represents the event form component.
 * @class EventForm
 * @extends {React.Component}
 */
export const EventForm = ({ event, handleSubmit }) => {
  const { data: companies, loading: companiesLoading } = useGetCompanies();
  const [step, setStep] = useState(1);
  const [formValues, setFormValues] = useState({});

  const handleNextStep = (values) => {
    setFormValues(values);
    setStep((step) => step + 1);
  };

  const onSubmitConnectionForm = async (connectionArr) => {
    const updatedEvent = { ...formValues, availableConnections: connectionArr };
    handleSubmit(updatedEvent);
  };

  function stepBack() {
    //We do not set the formValues state here as well
    // Because the user presses 'cancel', so the user expects the current step values not to be saved.
    setStep((step) => step - 1);
  }

  return (
    <>
      <div className="EventForm">
        {(() => {
          switch (step) {
            case 1:
              if (!companiesLoading)
                return (
                  <EventDetailForm
                    onNextStep={handleNextStep}
                    onSuccess={handleSubmit}
                    values={event}
                    companies={companies}
                    companiesLoading={companiesLoading}
                    //editorOptions={editorOptions} //ToDo: make EventDetailForm functional https://gitlab.com/watt-now/tool-frontend/-/issues/387
                  />
                );
              else return <Spinner />;
            case 2: {
              return (
                <ConnectionSizeForm
                  onSuccess={onSubmitConnectionForm}
                  stepBack={stepBack}
                  initialValues={event?.availableConnections}
                />
              );
            }
            default:
              return <div>Unknown step</div>;
          }
        })()}
      </div>
    </>
  );
};
