import React from 'react';
import { getSpotStatusColor } from 'spots/utils';
import { Icon } from 'shared/components';

/**
 * SpotTitle class represents the spot title in te powerplan tree in the Powerzone overview page.
 * @export
 * @class SpotTitle
 * @extends {React.Component}
 */

const SpotTitle = ({ spot, companyName, activeSpotId, onClick }) => {
  const color = getSpotStatusColor(spot);
  return (
    <span>
      <div className="treeIcon spot" style={{ float: 'left' }}>
        <Icon
          name="spot"
          className={
            spot._id === activeSpotId
              ? 'rowIcon activeSpot'
              : activeSpotId === null
              ? 'rowIcon inActiveSpot'
              : 'rowIcon'
          }
          onClick={onClick}
        />
      </div>
      <span
        className={
          activeSpotId !== '' && spot._id !== activeSpotId
            ? 'nodeTitle clickable inActiveSpot'
            : spot._id === activeSpotId
            ? 'nodeTitle clickable activeSpot'
            : 'nodeTitle clickable'
        }
        onClick={onClick}
      >
        {companyName?.length ? companyName : '---'} {' • '}
        <span style={{ color }} className="power">
          {spot.totalPower.toFixed(1)} kW
        </span>
      </span>
    </span>
  );
};

export default SpotTitle;
