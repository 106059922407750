import { useCallback } from 'react';
import { useQueryClient, useMutation } from 'react-query';

import { Notifications } from 'shared/constants';
import { notify } from 'shared/actions';
import { api } from 'shared/utils';

import { putEventMapper } from '../mapper';
import { eventKeys } from 'shared/utils/api/queryKeys';

const doCreateEvent = async (event) =>
  await api.post('/events', putEventMapper(event));

export const useCreateEvent = () => {
  const queryClient = useQueryClient();
  const { mutateAsync, status, ...others } = useMutation(doCreateEvent, {
    onSuccess: ({ data, status }, variables) => {
      if (status === 201) {
        // 🥳 invalidate all the lists
        queryClient.invalidateQueries({
          queryKey: eventKeys.lists(),
        });

        //Notify the user
        notify({
          message: `Successfully created event: ${variables.festival}`,
          level: Notifications.Type.SUCCESS,
        });
      } else {
        notify({
          message: `Failed creating event: ${variables.festival}`,
          level: Notifications.Type.ERROR,
        });
      }

      return data;
    },
  });

  const createEvent = useCallback((event) => mutateAsync(event), [mutateAsync]);

  return {
    ...others,
    loading: status === 'loading',
    createEvent,
  };
};
