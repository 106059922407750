import React from 'react';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';

import { DialogModal, Spinner } from 'shared/components';
import { UserForm } from 'users/components';
import {
  loadUser,
  loadUsers,
  createNewUser,
  createOrUpdateUser,
} from 'users/actions';

const enhance = compose(inject('text', 'users'), observer);

/**
 * UsersEdit class represents the view with the form to modify an existing system user.
 * @class UsersEditPage
 * @extends {React.Component}
 */
export const UserFormModal = enhance(
  class extends React.Component {
    constructor(props) {
      super(props);
      this.onSuccess = this.onSuccess.bind(this);
    }
    /**
     * React lifecycle method - executed everytime the component is mounted in the view.
     * @return {void}
     */
    componentDidMount() {
      this.props.users.list.length === 0 && loadUsers();
    }

    /**
     * Form submission success handler.
     * @param {object} form - Form instance
     * @return {void}
     */
    onSuccess(form) {
      const { handleClickAdd } = this.props;
      const user = form.values();
      user.phones = [{ type: 'work', number: user?.phone }];
      delete user.phone;
      user.credits = parseInt(user.credits, 10);
      user.phones = user.phones || [];
      user.lastReminderSent =
        user.lastReminderSent?.length > 0 ? user.lastReminderSent : null;
      const result =
        user._id > 0
          ? createOrUpdateUser(user, true)
          : createNewUser(user, true);
      this.props.toggleAddModalOpen();
      result
        .then((userId) => loadUser(userId))
        .then((newUser) => handleClickAdd(newUser));
    }

    /**
     * Renders the component view.
     * @return {React.Component}
     */
    render() {
      const {
        userId,
        text,
        users,
        addModalOpen,
        userInput,
        currentCompany,
      } = this.props;

      const user = users.getUser(userId);
      const values = user || {
        ...userInput,
        role: 8,
        companyId: currentCompany?._id,
      };
      return (
        <DialogModal
          open={addModalOpen}
          bodyPadding={false}
          handleClose={this.toggleModal}
          title={text.get('forms.adminUsers.addUserModalTitle')}
          text={text}
        >
          {values ? (
            <UserForm onSuccess={this.onSuccess} values={values} isNew={true} />
          ) : (
            <Spinner />
          )}
        </DialogModal>
      );
    }
  }
);
