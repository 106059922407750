import { ALL_CONNECTIONS } from './constants';

const defaultConnection = {
  price: '',
  selected: true,
};

// select the connections that are initially available and set the default values
export const getDefaultValues = (initialConnections) => {
  const defaultValues = {};

  if (initialConnections) {
    const initialConnectionIds = initialConnections.map(
      (item) => item.externalId
    );

    ALL_CONNECTIONS.forEach((item) => {
      //
      initialConnectionIds.includes(item.externalId)
        ? // get data of existing connections
          (defaultValues[item.externalId] = {
            price: initialConnections.find(
              (savedConnection) =>
                savedConnection.externalId === item.externalId
            ).price,
            selected: true,
          })
        : // set default values for non existing connection, but do not select them
          (defaultValues[item.externalId] = {
            price: undefined,
            selected: false,
          });
    });
  } else {
    ALL_CONNECTIONS.forEach((item) => {
      // set default values for all connections, do select them all
      defaultValues[item.externalId] = defaultConnection;
    });
  }
  return defaultValues;
};
