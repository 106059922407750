import React from 'react';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';

import { goBack } from 'shared/actions';
import { Box, Icon, Spinner } from 'shared/components';
import { Button } from 'shared/components/form';

import { remindPowerInquiry, loadPowerInquiries } from 'powerinquiries/actions';
import { PowerInquiryReminderForm } from 'powerinquiries/components';

import { ListItem } from './ListItem';
import './styles.less';

const enhance = compose(
  inject('events', 'text', 'powerInquiries', 'spots'),
  observer
);

/**
 * PowerInquiriesSendReminderPage class represents the view which contains a form to add a new energy user
 * to the event.
 * @class PowerInquirySendsReminderPage
 * @extends {React.Component}
 */
export const PowerInquiriesSendReminderPage = enhance(
  class extends React.Component {
    state = {
      invites: [],
      inviteMessage: this.props.text.get(
        'forms.powerInquiries.reminderMessage'
      ),
      user: {},
      spotList: [],
      isDataLoaded: false,
      showForm: true,
      checkedAll: true,
      checked: true,
      messageSaved: true,
    };

    get invites() {
      return this.state.invites.map((invite, index) => (
        <ListItem
          key={index}
          checkedAll={this.state.checkedAll}
          onToggleCheckbox={this.onToggleInvite}
          inquiry={invite}
          showCheckBox={this.state.invites.length}
          onClickRemove={this.onClickRemoveInvite}
        />
      ));
    }

    UNSAFE_componentWillMount() {
      const {
        powerInquiries,
        params: { eventId, powerInquiryId },
        text,
        events,
      } = this.props;
      const emailTitle = text
        .get('forms.powerInquiries.emailTitle')
        .replace('{festival}', events.getEvent(eventId).festival);
      const message = (user) =>
        text
          .get('forms.powerInquiries.reminderMessage')
          .replace('{festival}', events.getEvent(eventId).festival)
          .replace('{name}', user.name.trim());
      const btnText = text.get('forms.powerInquiries.defaultBtn');

      loadPowerInquiries(eventId).then(() => {
        const items = powerInquiryId
          ? powerInquiries.list.filter(
              (item) => item._id === parseInt(powerInquiryId)
            )
          : powerInquiries.list;
        let invites = [];
        items.forEach((inquiry) => {
          if (inquiry.invitationSent === true) {
            let spots = [];
            let approved = true;
            inquiry.spots.forEach((item) => {
              if (!item.approved) {
                approved = false;
                return spots.push({ label: item.label });
              }
            });
            if (!approved) {
              let changes = {
                festival: events.getEvent(eventId).festival,
                message: message(inquiry),
                btnText,
                emailTitle,
                checked: true,
              };
              invites.push({ ...changes, ...inquiry });
            }
          }
        });
        this.setState({ invites, isDataLoaded: true, showForm: false });
      });
    }

    onToggleInvite = (inquiry) => {
      const { invites } = this.state;
      let updatedList = invites;
      invites.map((item, index) => {
        if (item._id.toString() === inquiry._id.toString()) {
          return (updatedList[index] = {
            ...updatedList[index],
            checked: inquiry.checked,
            isReminder: inquiry.checked,
          });
        }
        return item;
      });

      this.setState({ invites: updatedList });
    };

    onClickRemoveInvite = (invite) => {
      const invites = this.state.invites.filter(
        ({ email }) => invite.email !== email
      );
      this.setState({ invites });
      if (invites.length === 0) {
        this.setState({ showForm: true });
      }
    };

    onClickViewInvite = () => {
      this.setState({ showForm: true, messageSaved: false }, () => {
        const form = this.refs.powerInquiryForm.wrappedInstance;
        form.updateForm({ inviteMessage: this.state.inviteMessage });
      });
    };

    sendInvitations = () => {
      const {
        params: { eventId },
      } = this.props;
      const { invites } = this.state;
      const invitations = invites
        .map((invite) => ({
          ...invite,
          message: invite.message
            .replace('{festival}', invite.festival)
            .replace('{name}', invite.name),
        }))
        .map((invite) => {
          if (invite.checked) {
            return remindPowerInquiry(eventId, invite);
          } else return Promise.resolve();
        });
      Promise.all(invitations).then(this.allInvitesSent);
    };

    allInvitesSent = () => {
      if (this.state.invites.filter((item) => item.checked).length > 0) return;
      goBack();
    };

    toggleCheckedItems = () => {
      let updatedList = this.state.invites;
      this.state.invites.map((item, index) => {
        return (updatedList[index] = {
          ...updatedList[index],
          checked: !this.state.checkedAll,
          isReminder: !this.state.checkedAll,
        });
      });

      this.setState({
        checkedAll: !this.state.checkedAll,
        invites: updatedList,
      });
    };

    /**
     * Form submission success handler.
     * @param {string} message - Email template
     * @return {void}
     */
    onSuccess = (message) => {
      const { invites } = this.state;
      let updatedList = [];
      invites.map((item) => updatedList.push({ ...item, message }));

      this.setState({
        invites: updatedList,
        showForm: false,
        inviteMessage: message,
        messageSaved: true,
      });
    };

    /**
     * Renders the component view.
     * @return {React.Component}
     */
    render() {
      const {
        events,
        params: { eventId },
        text,
        spots,
        powerInquiries,
      } = this.props;
      const { spotList, user, showForm, invites, isDataLoaded } = this.state;
      if (powerInquiries.loading || spots.loading || !isDataLoaded)
        return <Spinner />;
      return (
        <div className="EventInvitePowerInquiry">
          <div className="buttonRow">
            <Button
              size="small"
              styling="text"
              onClick={goBack}
              children={text.get('actions.back')}
            >
              <Icon name="arrow-back" className={`icon`} />
              <span>{text.get('actions.back')}</span>
            </Button>
            <Button
              size="small"
              onClick={this.onClickViewInvite}
              disabled={!invites.length}
              styling="text"
            >
              <span>{text.get('actions.editTemplate')}</span>
              <Icon name="edit" className={`icon`} />
            </Button>
            <Button
              size="small"
              styling="cta"
              onClick={this.sendInvitations}
              disabled={
                !invites.filter((item) => item.checked).length ||
                !this.state.messageSaved
              }
            >
              <span>{text.get('actions.submit')}</span>
              <Icon name="mail-outline" className={`icon`} />
            </Button>
          </div>
          {showForm && (
            <Box
              title={text.get(
                'components.views.powerInquiries.addNewPowerInquiry'
              )}
            >
              <PowerInquiryReminderForm
                user={user}
                inviteMessage={this.state.inviteMessage}
                isReminder={true}
                spots={spotList}
                ref="powerInquiryForm"
                onSuccess={this.onSuccess}
                event={events.getEvent(eventId)}
              />
            </Box>
          )}
          <Box
            title={
              <div className="invites-wrapper">
                <div>
                  {text.get('components.views.powerInquiries.powerInquiryName')}
                </div>
                <div>{text.get('components.views.powerInquiries.spots')}</div>
                <div>
                  {this.state.invites.length && (
                    <span onClick={this.toggleCheckedItems}>
                      {this.state.checkedAll
                        ? text.get('components.views.powerInquiries.uncheckAll')
                        : text.get('components.views.powerInquiries.checkAll')}
                    </span>
                  )}
                </div>
              </div>
            }
          >
            {this.invites}
          </Box>
        </div>
      );
    }
  }
);
