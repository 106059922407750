import React from 'react';
import './styles.less';

/**
 * Spinner Component class represents the spinner component
 * @class Spinner
 * @extends {React.Component}
 */
export class Spinner extends React.Component {
  // {object} defaultProps - Default properties data
  static defaultProps = {
    color: {
      red: 0,
      green: 0,
      blue: 0,
      alpha: 98 / 255,
    },
    segments: 30,
    segmentWidth: 2,
    segmentLength: 3,
    spacing: 2,
    fadeTo: 31 / 98,
    fadeSteps: 6,
  };

  /**
   * Renders the spinner segments.
   * @return {React.Component[]}
   */
  renderSegments() {
    const { segmentWidth, segmentLength, spacing, fadeTo, fadeSteps } =
      this.props;
    const segmentCount = this.props.segments;
    const innerRadius = segmentWidth * 2 + spacing;
    const opacityDelta = (1 - fadeTo) / fadeSteps;

    const segments = [];
    for (let ii = 0; ii < segmentCount; ii++) {
      const opacity = 1 - Math.min(ii, fadeSteps) * opacityDelta;
      const rotation = (-ii * 360) / segmentCount;
      segments.push(
        <line
          key={ii}
          x1="0"
          x2="0"
          y1={innerRadius}
          y2={innerRadius + segmentLength}
          style={{ opacity }}
          transform={`rotate(${rotation})`}
        />
      );
    }
    return segments;
  }

  /**
   * Renders the component view.
   * @return {React.Component}
   */
  render() {
    const { className, color, segmentWidth, segmentLength, spacing, style } =
      this.props;
    const innerRadius = segmentWidth * 2 + spacing;

    const { red, green, blue, alpha } = color;
    const rgbaColor = `rgba(${red}, ${green}, ${blue}, ${alpha})`;
    const radius = innerRadius + segmentLength + Math.ceil(segmentWidth / 2);
    return (
      <svg
        className={`Spinner ${className || ''}`}
        style={style}
        width={radius * 2}
        height={radius * 2}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          stroke={rgbaColor}
          strokeWidth={segmentWidth}
          strokeLinecap="round"
          transform={`translate(${radius}, ${radius})`}
        >
          {this.renderSegments()}
        </g>
      </svg>
    );
  }
}
