import { getNodeState } from 'shared/actions';
import { api } from 'shared/utils';
import { disassociateNodes } from 'powerzones/utils';

import { areas as areasStore } from './store';
import areaMapper from './mapper';

/**
 * Takes an event id as input and downloads all the areas
 * in the current event from the API.
 * @param {string} eventId - Event id
 * @return {Promise}
 */
export const loadAreas = (eventId) =>
  Promise.resolve(areasStore.isLoading(true))
    .then(() => api.get(`/events/${eventId}/areas`))
    .then(({ status, data }) => {
      if (status === 200) {
        const areas = data.map(areaMapper(eventId)).map((area) => ({
          ...area,
          expanded: getNodeState(area),
        }));
        areasStore.registerAreas(areas);
      }
    })
    .then(() => areasStore.isLoading(false));

/**
 * Takes a area data object and sends it to the API to create in the database.
 * @param {object} area - Area instance
 * @param {string} eventId - Event id
 * @return {Promise}
 */
export const createArea = (area, eventId) =>
  Promise.resolve(areasStore.isLoading(true))
    .then(() => api.post(`/events/${eventId}/areas`, areaMapper(eventId)(area)))
    .then(({ status, data }) => {
      if (status === 201) {
        const newArea = areaMapper(eventId)({
          ...data,
          ...area,
        });
        areasStore.registerNewArea(newArea);
        return newArea;
      }
    })
    .then((area) => {
      areasStore.isLoading(false);
      return area;
    });

/**
 * Takes a area data object and sends it to the API to update in the database.
 * @param {object} area - Area instance
 * @return {Promise}
 */
export const updateArea = (area) =>
  Promise.resolve(areasStore.isLoading(true))
    .then(() => api.put(area.uri, areaMapper(area.eventId)(area)))
    .then(({ status, data }) => {
      if (status === 200) {
        const tmp = areaMapper(area.eventId)(area);
        areasStore.updateArea(tmp);
      }
    })
    .then(() => areasStore.isLoading(false));

/**
 * Takes a area data object and send a request with it's ID to the API to remove it from the database.
 * @param {object} area - Area instance
 * @return {Promise}
 */
export const removeArea = (area) =>
  Promise.resolve(areasStore.isLoading(true))
    .then(() => api.delete(area.uri))
    .then(({ status }) => {
      if (status === 200) {
        disassociateNodes(area.children);
        areasStore.removeArea(area);
      }
    })
    .then(() => areasStore.isLoading(false));
