import React from 'react';

import { Metadata } from 'shared/components';
import { useGetText } from 'shared/hooks';
import './styles.less';

/**
 * EventsBase component represents the view with the list of events available for the active user.
 * @returns {React.Component}
 */
export const EventsBase = ({ children }) => {
  const text = useGetText();
  return (
    <div className="EventsView">
      <Metadata meta={text.getText('components.views.overviewEvents.meta')} />
      <h1>{text.getText('components.views.overviewEvents.pageTitle')}</h1>
      <div>{children}</div>
    </div>
  );
};
