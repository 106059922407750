import React, { useState, useEffect } from 'react';

import { Tooltip, Button, Typography } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import HistoryOutlinedIcon from '@material-ui/icons/HistoryOutlined';
import { pushRoute } from 'shared/actions';
import { Roles } from 'shared/constants';
import { useGetText } from 'shared/hooks';
import { userHasPermission } from 'auth/actions';
import { useGetEvents } from 'events/hooks';
import { loadUser } from 'auth/actions';

import EventsTable from './EventsTable';

/**
 * EventsOverview component represents the view with the user registration form.
 * @returns {React.Component}
 */
export const EventsOverviewPage = () => {
  const me = loadUser();
  const text = useGetText();
  const initialYearSet = new Set([new Date().getFullYear()]);
  const [eventYears, setEventYears] = useState(initialYearSet);
  const [showAllYears, setShowAllYears] = useState(false);

  //Query all users for admin, query only the users that
  const eventQueryParam = userHasPermission(Roles.ADMIN) ? null : me._id;
  const { data, loading } = useGetEvents(eventQueryParam);

  useEffect(() => {
    if (!loading && showAllYears) {
      data.forEach((data) => {
        setEventYears(
          (prevState) => new Set([...prevState, getEventYear(data.event)])
        ); // recreate the set to trigger rerender
      });
    }
  }, [data, loading, showAllYears]);

  /**
   * Handles the click over a event item in the list.
   * @param {object} event - Event object containing 'relationship' and 'event' keys
   * @return {void}
   */
  function onClickEvent(eventId) {
    const path = `/event/${eventId}`;
    if (
      userHasPermission([
        Roles.ADMIN,
        Roles.INQUIRY_MANAGER,
        Roles.EVENT_CREATOR,
      ])
    ) {
      pushRoute(path);
    } else {
      pushRoute(`${path}/spots`);
    }
  }

  const getEventYear = (event) => {
    if (event?.showDates?.length === 0) return null;
    return new Date(event.showDates[0].startDate).getFullYear();
  };

  const toggleShowAllYears = () => {
    if (showAllYears) setEventYears(initialYearSet);
    setShowAllYears(!showAllYears);
  };
  const canAddEvent = userHasPermission([Roles.ADMIN]) || me?.credits > 0;
  const showAllYearsButtonText = showAllYears
    ? text.getText('components.views.overviewEvents.showCurrentYear')
    : text.getText('components.views.overviewEvents.showAllYears');
  return (
    <div>
      {/* NewEvent button */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {userHasPermission([Roles.ADMIN, Roles.EVENT_CREATOR]) && (
          <Tooltip
            title={
              canAddEvent ? '' : text.getText('actions.addNewEventTooltip')
            }
            placement="bottom-start"
          >
            <span>
              <Button
                onClick={() => pushRoute('/events/new')}
                disabled={!canAddEvent}
                variant={canAddEvent ? 'contained' : 'default'}
                size="small"
                startIcon={<AddCircleOutlineIcon />}
              >
                {text.getText('actions.addNewEvent')}
              </Button>
            </span>
          </Tooltip>
        )}
        <Button
          startIcon={<HistoryOutlinedIcon />}
          onClick={toggleShowAllYears}
          size="small"
        >
          {showAllYearsButtonText}
        </Button>
      </div>

      {/* EventsTables */}
      {loading ? (
        <>
          <Typography style={{ marginTop: '16px' }} variant="h6">
            {new Set([new Date().getFullYear()])}
          </Typography>
          <EventsTable
            loading={loading}
            onClickEvent={onClickEvent}
            events={[]}
          />
        </>
      ) : (
        [...eventYears]
          .sort((a, b) => b - a)
          .map((year) => (
            <div key={`events-overview-${year}`}>
              <Typography style={{ marginTop: '16px' }} variant="h6">
                {year}
              </Typography>
              <EventsTable
                onClickEvent={onClickEvent}
                events={data.filter(
                  (data) => getEventYear(data.event) === year
                )}
                loading={loading}
              />
            </div>
          ))
      )}
    </div>
  );
};
