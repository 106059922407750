import React, { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';

import { useGetText } from 'shared/hooks';

import EditorItem from './EditorListItem';
import './styles.less';

export default function Editors({
  editorOptions,
  onChange,
  selectedEditorIds,
}) {
  const text = useGetText();
  const [selectedEditors, setSelectedEditors] = useState([]);

  useEffect(() => {
    setSelectedEditors(
      editorOptions.filter((option) => selectedEditorIds.includes(option._id))
    );
  }, [editorOptions, selectedEditorIds]);

  function onSelectEditor(editor) {
    const newSelectedEditors = [...new Set([...selectedEditors, editor])];
    setSelectedEditors(newSelectedEditors);
    onChange(newSelectedEditors.map((ed) => ed._id));
  }

  function onRemoveEditor(editor) {
    const newSelectedEditors = selectedEditors.filter(
      (ed) => ed._id !== editor._id
    );
    setSelectedEditors(newSelectedEditors);
    onChange(newSelectedEditors.map((ed) => ed._id));
  }

  if (!editorOptions) return null;

  const selectedIds = selectedEditors.map((selectedEd) => selectedEd._id);
  const notSelectedList = editorOptions.filter(
    (ed) => !selectedIds.includes(ed._id)
  );
  const hasEditors = notSelectedList.length > 0;

  return (
    <div className="EditorsList">
      {!hasEditors && (
        <div className="noAvailableEditors">
          {text.getText('forms.event.noPowerPlaners')}
        </div>
      )}
      {hasEditors && (
        <>
          <Typography style={{ marginBottom: 12 }}>
            {text.getText('forms.event.availableEventEditors')}
          </Typography>
          {notSelectedList.map((editor) => (
            <EditorItem
              key={editor._id}
              editor={editor}
              onClick={onSelectEditor}
            />
          ))}
        </>
      )}
      {selectedEditors.length > 0 && (
        <div className="selectedList">
          <Typography style={{ marginBottom: 12 }}>
            {text.getText('forms.event.selectedEventEditors')}
          </Typography>
          {selectedEditors.map((editor) => (
            <EditorItem
              key={editor._id}
              isSelected
              showModalView
              editor={editor}
              onClick={onRemoveEditor}
            />
          ))}
        </div>
      )}
    </div>
  );
}
