import React from 'react';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { MuiThemeProvider as V0MuiThemeProvider } from 'material-ui';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { darken } from '@material-ui/core/styles';
import './form.less';
import './globals.less';
import './heading.less';
import './page.less';
import './fonts.less';

import colors from './colors';

const themeV0 = getMuiTheme({
  palette: {
    primary1Color: colors['acc-color1'],
    primary2Color: colors['brand-green'],
    accent1Color: colors['acc-color1'],
    accent2Color: '#fff',
    accent3Color: darken(colors['acc-color1'], 0.3),
    textColor: colors['prime-color'],
  },
  toggle: {
    thumbOnColor: '#fff',
    trackOnColor: colors['acc-color1'],
  },
});

export const V0ThemeProvider = (props) => (
  <V0MuiThemeProvider muiTheme={themeV0} {...props} />
);

const theme = createTheme({
  props: {
    // Name of the component ⚛️
    MuiButton: {
      // The default props to change
      disableElevation: true,
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    body2: {
      fontSize: 13,
    },
  },
  shape: {
    borderRadius: 12,
  },
  palette: {
    primary: {
      light: colors['brand-light'],
      main: colors['brand-color'],
      dark: colors['brand-dark'],
      contrastText: '#fff',
    },
    secondary: {
      light: colors['secondary-light'],
      main: colors['secondary-color'],
      dark: colors['secondary-dark'],
      contrastText: '#000',
    },
    error: {
      light: '#ff7e7a',
      main: colors['state-error'],
      dark: '#c50025',
    },
    warning: {
      light: '#fff14e',
      main: colors['state-warning'],
      dark: '#c78f00',
    },
    info: {
      light: '#69c1ff',
      main: colors['state-info'],
      dark: '#0064cb',
    },
    success: {
      light: '#bffc78',
      main: colors['state-success'],
      dark: '#5a9811',
    },
    purple: colors['acc-purple'],
    'dark-green': colors['acc-dark-green'],
    'light-green': colors['acc-light-green'],
    pink: colors['acc-pink'],
    yellow: colors['acc-yellow'],
    blue: colors['acc-blue'],
    'light-blue': colors['acc-light-blue'],
    neutral: {
      'secondary-color-90': colors['secondary-color-90'],
      'secondary-color-80': colors['secondary-color-80'],
      'secondary-color-70': colors['secondary-color-70'],
      'secondary-color-60': colors['secondary-color-60'],
      'secondary-color-50': colors['secondary-color-50'],
      'secondary-color-40': colors['secondary-color-40'],
      'secondary-color-30': colors['secondary-color-30'],
      'secondary-color-20': colors['secondary-color-20'],
      'secondary-color-10': colors['secondary-color-10'],
    },
  },
  toggle: {
    thumbOnColor: '#fff',
    trackOnColor: colors['acc-color1'],
  },
  overrides: {
    MuiInput: {
      underline: {
        '&:hover': {
          //backgroundColor: 'grey',
        },
      },
    },
    MuiDialogTitle: {
      root: {
        backgroundColor: colors['neutral-color-200'],
        display: 'flex',
        justifyContent: 'center',
      },
    },
    MuiButton: {
      root: {
        borderRadius: 30,
        textTransform: 'lowercase',
        fontWeight: 500,
        fontFamily: 'Source Sans Pro',
      },
      contained: {
        backgroundColor: colors['neutral-color-200'],
      },
    },
    MuiDialogActions: {
      root: {
        justifyContent: 'center',
      },
    },
  },
});

export const ThemeProvider = (props) => (
  <MuiThemeProvider theme={theme} {...props} />
);
