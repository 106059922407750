import React from 'react';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import './styles.less';

class DateTimeRangePicker extends React.Component {
  state = {
    startDate: null,
    endDate: null,
    startTime: null,
    endTime: null,
  };

  setData = () => {
    const { startDate, endDate, startTime, endTime } = this.props;
    this.setState({
      startDate: startDate ? moment(startDate) : null,
      endDate: endDate ? moment(endDate) : null,
      startTime: startTime
        ? moment(new Date(0, 0, 0, ...startTime.split(':'), 0, 0))
        : null,
      endTime: endTime
        ? moment(new Date(0, 0, 0, ...endTime.split(':'), 0, 0))
        : null,
    });
  };

  componentDidMount() {
    this.setData();
  }

  componentDidUpdate(prevPros) {
    if (prevPros !== this.props) {
      this.setData();
    }
  }

  static displayName = 'DatePicker';

  isInclusivelyAfterDay = (minDate, a, b) => {
    if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
    return !this.isBeforeDay(minDate, a, b);
  };

  isBeforeDay = (minDate, a, b) => {
    if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
    if (a > minDate) {
      const aYear = a.year();
      const aMonth = a.month();

      const bYear = b.year();
      const bMonth = b.month();

      const isSameYear = aYear === bYear;
      const isSameMonth = aMonth === bMonth;

      if (isSameYear && isSameMonth) return a.date() <= b.date();
      if (isSameYear) return aMonth < bMonth;
      return aYear < bYear;
    }
  };

  handleDatesChange = ({ startDate, endDate }) => {
    startDate && (startDate = startDate.utcOffset(0).startOf('day'));
    endDate && (endDate = endDate.utcOffset(0).startOf('day'));

    this.setState({
      startDate,
      endDate,
    });

    this.props.onDatesChange({
      startDate,
      endDate,
    });
  };

  handleStartTimeChange = (date) => {
    this.setState({
      startTime: date,
    });

    this.props.onDatesChange({
      startTime: date && moment(date).format('HH:mm'),
    });
  };

  handleEndTimeChange = (date) => {
    this.setState({
      endTime: date,
    });

    this.props.onDatesChange({
      endTime: date && moment(date).format('HH:mm'),
    });
  };

  render() {
    let {
      minDate,
      maxDate,
      startTimeText,
      endTimeText,
      startTime: st,
      endTime: et,
      ...rest
    } = this.props;
    const { startDate, endDate, startTime, endTime } = this.state;
    maxDate = maxDate ? moment(maxDate) : null;
    minDate = minDate ? moment(minDate) : null;

    return (
      <div className="DateRangeTimePicker">
        <div className="datesRangeBox">
          <DateRangePicker
            {...rest}
            onDatesChange={this.handleDatesChange}
            enableOutsideDays={false}
            startDate={startDate}
            endDate={endDate}
            minDate={minDate}
            maxDate={maxDate}
            isOutsideRange={(day) =>
              this.isInclusivelyAfterDay(minDate, day, maxDate)
            }
            displayFormat="DD/MM/YYYY"
            minimumNights={0}
            readOnly
            noBorder
          />
        </div>
        <span className="separator">/</span>
        <div className="timesBox">
          <TimePicker
            placeholder={startTimeText}
            showSecond={false}
            minuteStep={15}
            value={startTime}
            disabled={!startDate}
            onChange={this.handleStartTimeChange}
            defaultOpenValue={moment(new Date(0, 0, 0, 8, 0, 0, 0))}
            focusOnOpen
          />
          <span className="timeSeparator">-</span>
          <TimePicker
            placeholder={endTimeText}
            showSecond={false}
            minuteStep={15}
            value={endTime}
            disabled={!startDate}
            onChange={this.handleEndTimeChange}
            defaultOpenValue={moment(new Date(0, 0, 0, 18, 0, 0, 0))}
            focusOnOpen
          />
        </div>
      </div>
    );
  }
}

export { DateTimeRangePicker };
