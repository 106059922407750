import React, { useState, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Typography, Button } from '@material-ui/core';
import { useGetText } from 'shared/hooks/';
import moment from 'moment';
import {
  TextInput,
  AutocompleteMultipleInput,
  DateTimePicker,
} from 'shared/components/functionalForm';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { calcEventStartEndDates } from 'shared/utils';
import { POWER_SOURCES_ARR } from 'powerzones/constants';
import COLORS from 'shared/styles/colors';
import {
  getAnticipatedSimultaneityFactor,
  calcRatedSupplyPower,
} from 'powerzones/utils';
import schema from './schema';

/**
 * Returns the powerzone supply table component.
 * @return {React.Component}
 */
export default function PowerzoneForm({
  event,
  values,
  onSuccess,
  totalRatedPowerDemandKw,
}) {
  const [powerzoneFormIsEditable, setPowerzoneFormIsEditable] = useState(false);
  const text = useGetText();
  const {
    handleSubmit,
    control,
    formState: { isValid },
    reset,
    watch,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: values,
  });

  useEffect(() => {
    reset(values);
    setPowerzoneFormIsEditable(false);
  }, [values, reset]);

  function resetForm() {
    reset(values);
    setPowerzoneFormIsEditable(false);
  }

  /**
   * Form submission handler
   * @param {Object} form
   * @return {void}
   */
  function handleSuccess(powerzone) {
    toggleEditable();
    onSuccess(powerzone);
  }

  const eventDates = useMemo(() => calcEventStartEndDates(event), [event]);

  function toggleEditable() {
    setPowerzoneFormIsEditable(!powerzoneFormIsEditable);
  }

  const ratedSupplyPower = useMemo(
    () => calcRatedSupplyPower(watch('powerSources')),
    [watch, watch('powerSources')]
  );
  const anticipatedSimultaneityFactor = useMemo(
    () =>
      Math.round(
        getAnticipatedSimultaneityFactor(
          ratedSupplyPower,
          totalRatedPowerDemandKw
        )
      ),
    [ratedSupplyPower, totalRatedPowerDemandKw]
  );

  const renderAnticipatedSimultaneityFactor =
    anticipatedSimultaneityFactor > 100
      ? '∞'
      : anticipatedSimultaneityFactor || '-';

  const startDate = watch('powerOnDate');

  return (
    <>
      <form>
        <Grid container spacing={2} style={{ padding: '12px' }}>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              {text.getText('components.views.powerzones.powerOn')}
            </Typography>
            <DateTimePicker
              name="powerOnDate"
              control={control}
              minDate={eventDates.start}
              maxDate={eventDates.end}
              timeText="hh:mm"
              placeholder={text.getText('components.views.powerzones.date')}
              initialVisibleMonth={() => eventDates.start}
              disabled={!powerzoneFormIsEditable}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              {text.getText('components.views.powerzones.powerOff')}
            </Typography>
            <DateTimePicker
              name="powerOffDate"
              control={control}
              minDate={
                startDate ? moment(startDate).startOf('day') : eventDates.start
              }
              maxDate={eventDates.end}
              timeText="hh:mm"
              placeholder={text.getText('components.views.powerzones.date')}
              initialVisibleMonth={() => eventDates.end}
              disabled={!powerzoneFormIsEditable}
            />
          </Grid>
          <Grid item xs={12}>
            <AutocompleteMultipleInput
              name="powerSources"
              control={control}
              options={POWER_SOURCES_ARR}
              label={text.getText(
                `components.views.powerzones.supply.powerSources`
              )}
              groupOptionBy="type"
              renderGroupTextLocation="components.views.powerzones"
              getOptionLabel={(option) => option.label}
              disabled={!powerzoneFormIsEditable}
              getOptionSelected={(option, value) =>
                option.externalIdentifier === value.externalIdentifier
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              name="comment"
              label={text.getText('components.views.powerzones.comment')}
              control={control}
              className="power-sources-select"
              multiline={true}
              rows={2}
              type="text"
              readOnly={!powerzoneFormIsEditable}
            />
          </Grid>
          <Grid
            container
            item
            xs={12}
            style={{ justifyContent: 'space-between' }}
          >
            {powerzoneFormIsEditable ? (
              <>
                <Grid item>
                  <Button
                    onClick={resetForm}
                    children={text.getText('actions.cancel')}
                    startIcon={<CancelOutlinedIcon />}
                  />
                </Grid>
                <Grid item>
                  <Button
                    onClick={handleSubmit(handleSuccess)}
                    disabled={!isValid}
                    children={text.getText('actions.save')}
                    startIcon={<SaveOutlinedIcon />}
                    variant="contained"
                  />
                </Grid>
              </>
            ) : (
              <Button
                startIcon={<EditOutlinedIcon />}
                onClick={toggleEditable}
                children={text.getText('actions.edit')}
              />
            )}
          </Grid>
        </Grid>
      </form>
      <Grid container spacing={2} style={{ padding: '12px' }}>
        <Grid item container>
          <Grid item xs={10}>
            <Typography variant="body2">
              {text.getText('components.views.powerzones.supply.totalPower')}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              variant="body2"
              style={{ color: COLORS['acc-light-blue'] }}
            >
              {ratedSupplyPower.toFixed()} kW
            </Typography>
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item xs={10}>
            <Typography variant="body2" style={{}}>
              {text.getText('components.views.powerzones.supply.maxLoadFactor')}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              variant="body2"
              style={{ color: COLORS['acc-light-blue'] }}
            >
              {renderAnticipatedSimultaneityFactor} %
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
