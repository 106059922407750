import React from 'react';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import moment from 'moment';

import { getParentLeaves } from 'powerzones/utils';
import DetailsPane from 'powerzones/components/DetailsPane';

import './styles.less';
import { EventTable } from './EventTable';

const enhance = compose(inject('text'), observer);

/**
 * SummaryTable summary power zones overview.
 * @export
 * @class EventDataTable
 * @extends {React.Component}
 */
export const EventDataTable = enhance(
  class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        selectedNode: null,
        rowByConnections: false,
      };
    }

    handleRowClick = (event, rowData) => {
      //set selectednodestate
      this.setState({
        selectedNode: this.props.spots.getSpotById(rowData._id),
      });

      //set pane visibility in state
      this.refs.tablesidepane.toggleVisibility(true);
    };

    toggleRowByConnections = () => {
      this.setState({ rowByConnections: !this.state.rowByConnections });
    };

    getConnectionList(connections) {
      const result = {
        connectionInfo: [],
        connectionDates: [],
        nightPower: [],
        upsNeeded: [],
        hasArrived: [],
        isConnected: [],
        meterIds: [],
      };
      connections.forEach((item) => {
        const itemContent = `${item.quantity || 1}x ${
          item.connectionSizeType.externalId
        } • ${item.totalPower.toFixed(1)} kW`;
        const startDate = item.startDate
          ? moment(item.startDate).format('YYYY/MM/DD hh:mm')
          : null;
        result.connectionInfo.push(itemContent);
        result.connectionDates.push(startDate);
        result.nightPower.push(item.nightPower);
        result.upsNeeded.push(item.upsNeeded);
        result.hasArrived.push(item.hasArrived);
        result.isConnected.push(item.isConnected);
        result.meterIds.push(item.meterId);
      });
      return result;
    }

    getSpotObjects() {
      const { spots, areas, powerzones, companies } = this.props;
      const spotObjects = [];

      spots.list.forEach((spot) => {
        const parents = getParentLeaves(spot, areas.list, powerzones.list);
        let spotObjectA = {
          pzName: parents.pzName,
          areaNames: parents.areaNames,
        };
        const {
          connectionInfo,
          connectionDates,
          nightPower,
          upsNeeded,
          hasArrived,
          isConnected,
          meterIds,
        } = this.getConnectionList(spot.layout.connections);
        let spotObjectB = {
          ...spotObjectA,
          ...{
            userCompany: companies.getCompany(spot.companyId)?.name || '-',
            spotName: spot.label,
            coordinate: spot.coordinate,
            connections: connectionInfo,
            meterIds: meterIds,
            powerOn: connectionDates ? connectionDates : '-',
            totalPower: spot.totalPower.toFixed(1) + ' kW',
            nightPower: nightPower,
            upsNeeded: upsNeeded,
            hasArrived: hasArrived,
            isConnected: isConnected,
            estimated: spot.estimated,
            origin: spot.origin,
            approved: spot.approved,
            createdAt: spot.createdAt,
            updatedAt: spot.updatedAt,
            comment: spot.layout.comment,
            _id: spot._id,
            eventId: spot.eventId,
          },
        };
        spotObjects.push(spotObjectB);
      });
      return spotObjects;
    }

    getSpotConnectionObjects() {
      const { spots, areas, powerzones, companies } = this.props;
      const spotObjects = [];

      spots.list.forEach((spot) => {
        spot.layout.connections.forEach((connection) => {
          const parents = getParentLeaves(spot, areas.list, powerzones.list);
          let spotObjectA = {
            pzName: parents.pzName,
            areaNames: parents.areaNames,
          };
          let spotObjectB = {
            ...spotObjectA,
            ...{
              userCompany: companies.getCompany(spot.companyId)?.name || '-',
              spotName: spot.label,
              coordinate: spot.coordinate,
              connectionName: connection.name,
              connections: [
                `${connection.quantity || 1}x ${
                  connection.connectionSizeType.externalId
                }`,
              ],
              meterIds: [connection.meterId],
              powerOn: [
                moment(connection.startDate).format('YYYY/MM/DD hh:mm'),
              ],
              totalPower: [connection.totalPower.toFixed(1) + ' kW'],
              nightPower: [connection.nightPower],
              upsNeeded: [connection.upsNeeded],
              hasArrived: [connection.hasArrived],
              isConnected: [connection.isConnected],
              estimated: spot.estimated,
              origin: spot.origin,
              approved: spot.approved,
              createdAt: spot.createdAt,
              updatedAt: spot.updatedAt,
              comment: spot.layout.comment,
              _id: spot._id,
              eventId: spot.eventId,
            },
          };
          spotObjects.push(spotObjectB);
        });
      });
      return spotObjects;
    }

    renderEventTable = () => {
      const { text, eventLabel } = this.props;
      const { rowByConnections } = this.state;
      const data = rowByConnections
        ? this.getSpotConnectionObjects()
        : this.getSpotObjects();
      const title = `${eventLabel} ${text.get(
        'components.views.powerzones.detailedTable.overview'
      )}`;
      return (
        <EventTable
          title={title}
          data={data}
          text={text}
          handleRowClick={this.handleRowClick}
          toggleRowByConnections={this.toggleRowByConnections}
          rowByConnections={this.state.rowByConnections}
          rowByConnectionsAction={true}
        />
      );
    };

    /**
     * Renders the component view.
     * @return {React.Component}
     */
    render() {
      const { renderEvent, text } = this.props;
      const { selectedNode } = this.state;
      return (
        <div className="detailed-table-wrapper">
          {renderEvent ? this.renderEventTable() : this.renderPowerZones()}

          <DetailsPane
            showSpotForm={true}
            ref="tablesidepane"
            item={selectedNode}
            text={text}
          />
        </div>
      );
    }
  }
);
