import React, { useMemo } from 'react';
import {
  AreaChart,
  Area,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { TIME_COLUMN_NAME } from 'shared/constants/graphs';
import { determineXTicks, formatXAxisTicks } from './utils';
import colors from 'shared/styles/colors';
import { format, isValid } from 'date-fns';
import { useGetText } from 'shared/hooks';

export default function AreaGraph({
  data,
  fields = [],
  range,
  width = 800,
  height = 250,
}) {
  const xTicks = useMemo(() => determineXTicks(range), [range]);
  const text = useGetText();
  return (
    <ResponsiveContainer width={width} height={height}>
      <AreaChart
        height={height}
        data={data || []}
        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
      >
        <defs>
          <linearGradient id={fields[1]}>
            <stop
              offset="5%"
              stopColor={colors['brand-color']}
              stopOpacity={0.8}
            />
            <stop
              offset="95%"
              stopColor={colors['brand-color']}
              stopOpacity={0}
            />
          </linearGradient>
        </defs>
        <XAxis
          // style={{ userSelect: 'none' }} Do we need this?
          minTickGap={30}
          scale={'time'}
          dataKey={TIME_COLUMN_NAME}
          tickFormatter={(value) => {
            return formatXAxisTicks(value, range);
          }}
          ticks={xTicks}
          interval={0}
        />
        <YAxis
          label={{
            value: 'Vermogen (kW)',
            angle: -90,
            position: 'insideLeft',
            fontSize: 12,
          }}
          width={50}
        />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip
          filterNull
          offset={20}
          labelFormatter={(label) => {
            if (isValid(label)) {
              return format(label, 'dd MMM HH:mm');
            } else {
              console.error(`${label} is not a valid date`);
              return label;
            }
          }}
          formatter={(value, name, props) => {
            return [
              `${value} kW`,
              `${text.getText(`components.graphs.fields.${name}`)}`,
            ];
          }}
        />
        <Area
          type="monotone"
          dataKey={fields[0]}
          stroke={'#B5D2A1'}
          fill={'#B5D2A1'}
        />
        <Area
          type="monotone"
          dataKey={fields[1]}
          stroke="none"
          fill={`url(#${fields[1]})`}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}
