import React from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { Tooltip, Typography } from '@material-ui/core';

import { removeSpot } from 'spots/actions';
import { SpotList, UserFormModal, CompanyFormModal } from 'shared/components';
import { Button, AutoCompleteInput, Checkbox } from 'shared/components/form';
import { formCreator } from 'shared/form';
import { Roles } from 'shared/constants';
import { getCompanyOptions } from 'companies/utils';
import { getUserOptions } from 'users/utils';
import { SpotDetails, SpotModalForm } from 'spots/components';
import fields from './fields';

import './styles.less';
import { loadUser } from 'auth/actions';
import { EMPTY_POWER_INQUIRY } from 'powerinquiries/constants';

const enhance = compose(
  inject('events', 'text', 'users', 'companies'),
  observer,
  withRouter
);

/**
 * PowerInquiryForm class representes the energy user form component.
 * @class PowerInquiryForm
 * @extends {React.Component}
 */

export const PowerInquiryForm = enhance(
  class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        form: null,
        currentCompany: null,
        currentUser: null,
        currentOwner: null,
        invited: false,
        spotsIds: [],
        showLeaveMessage: true,
        addUserModalOpen: false,
        addCompanyModalOpen: false,
      };
      this.handleCompanyChange = this.handleCompanyChange.bind(this);
      this.handleUserChange = this.handleUserChange.bind(this);
      this.handleOwnerChange = this.handleOwnerChange.bind(this);
      this.toggleAddUserModalOpen = this.toggleAddUserModalOpen.bind(this);
      this.toggleAddCompanyModalOpen = this.toggleAddCompanyModalOpen.bind(
        this
      );
    }

    // {object} config - Form configuration getter
    get config() {
      const { onSuccess, onError, text } = this.props;
      const title = 'powerInquiryForm';
      //const labels = labelsConfig(text.get('forms.powerInquiries'));
      return { title, fields: fields(text), onSuccess, onError };
    }

    get canSubmit() {
      const { form } = this.state;
      const companyId = form.$('companyId').value;
      const userId = form.$('userId').value;
      return this.state.spots.length > 0 && (companyId || userId);
    }

    componentDidMount() {
      this.props.router.setRouteLeaveHook(
        this.props.routes[this.props.routes.length - 1],
        this.routerWillLeave.bind(this)
      );
      this.initForm(this.props);
    }

    componentDidUpdate() {
      const { companies, users } = this.props;
      const { currentCompany, currentUser, currentOwner, form } = this.state;
      if (!companies.loading && currentCompany === null) {
        const newCurrentCompany = getCompanyOptions(companies).find(
          (option) => option._id === form.$('companyId').value
        );
        newCurrentCompany &&
          this.setState({ currentCompany: newCurrentCompany });
      }
      if (!users.loading) {
        if (currentUser === null) {
          const newCurrentUser = getUserOptions(users).find(
            (option) => option._id === form.$('userId').value
          );
          newCurrentUser && this.setState({ currentUser: newCurrentUser });
        }
        if (currentOwner === null) {
          const newCurrentOwner = getUserOptions(users).find(
            (option) => option._id === form.$('owner').value
          );
          newCurrentOwner && this.setState({ currentOwner: newCurrentOwner });
        }
      }
    }

    /**
     * React lifecycle method - executed everytime the component is about to be mounted in the view.
     * @return {void}
     */

    initForm = (props) => {
      const { powerInquiryStatus } = props;
      const formValues = powerInquiryStatus
        ? {
            userId: powerInquiryStatus.userId,
            companyId: powerInquiryStatus.companyId,
            owner: powerInquiryStatus.owner,
            _id: powerInquiryStatus._id,
            canSeeConnectionPrices: powerInquiryStatus.canSeeConnectionPrices,
          }
        : {};
      const me = loadUser();
      const form = formCreator(this.config);
      const tmp = { ...EMPTY_POWER_INQUIRY, owner: me._id, ...formValues };
      form.init(tmp); // making sure everytime you select a new user, his data will appear
      const spots =
        (powerInquiryStatus?.spots && [...powerInquiryStatus.spots]) || [];
      this.setState({
        form,
        spots,
        invited: powerInquiryStatus && powerInquiryStatus.invitationSent,
      });
    };

    routerWillLeave(nextLocation) {
      // return false to prevent a transition w/o prompting the user,
      // or return a string to allow the user to decide:
      // return `null` or nothing to let other hooks to be executed
      //
      // NOTE: if you return true, other hooks will not be executed!
      if (this.state.form.isDirty && this.state.showLeaveMessage)
        return this.props.text.get('actions.confirmLeaveUnsaved');
      return true;
    }

    onClickNewSpot = () => {
      this.refs.spotModal.wrappedInstance.updateForm({});
      this.refs.spotModal.wrappedInstance.showForm();
    };

    //todo fix invite offline power inquiry functionality https://gitlab.com/watt-now/tool-frontend/-/issues/369
    // onClickInvite = () => {
    //     const { form, invited, spots } = this.state;

    //     if (!invited) {
    //         const { _id, name, email, companyName } = form.values();

    //         const { eventId, events, text } = this.props;
    //         const event = events.getEvent(eventId);
    //         const invite = {
    //             _id,
    //             name,
    //             email,
    //             companyName,
    //             spots,
    //         };
    //         const emailTitle = text.get('forms.powerInquiries.emailTitle').replace('{festival}', event.festival);
    //         const message = text.get('forms.powerInquiries.defaultMessage').replace('{festival}', event.festival).replace('{name}', invite.name);

    //         createPowerInquiry(eventId, {
    //             ...invite,
    //             emailTitle,
    //             message,
    //         }, true).then((response) => !response.type && this.setState({ invited: true }));
    //     }
    // };

    onSubmit = (e) => {
      this.setState({ showLeaveMessage: false });
      this.state.form.submit({
        onSuccess: (form) => {
          this.props.onSuccess({
            values: () => ({
              ...form.values(),
              spots: this.state.spots,
              //spots: this.state.spots.filter((spot) => !spot.uri), --> we do not need to filter out the existing spots
            }),
          });
        },
      });
    };

    removeSpot = (spot) => {
      removeSpot(spot, true);
      const spots = this.state.spots.filter((no) => no.uri !== spot.uri);
      this.setState({ spots });
    };

    onNewSpot = (form) => {
      const newSpot = form.values();
      this.refs.spotModal.wrappedInstance.hideForm();

      const { spots } = this.state;
      spots.push(newSpot);
      this.setState({ spots });
    };

    saveSpot = (spot, index) => {
      const { spots } = this.state;
      spots[index] = { ...spots[index], ...spot };
      this.setState({ spots });
      this.props.onSpotUpdate && this.props.onSpotUpdate(spot);
    };

    renderSpotView() {
      const { text } = this.props;
      const { spots, form } = this.state;
      const companyId = form.$('companyId').value;
      const userId = form.$('userId').value;

      return (
        <div>
          <span>
            <h1>{text.get('forms.powerInquiries.spots')}</h1>
            {
              //TODO add button to collapse all spots
              //<Button onClick={() => this.setState(!this.state.spotsCollapsed)}> Collapse Spots</Button>
            }
          </span>

          {spots.length > 0 ? (
            spots.map((spot, index) => (
              <SpotDetails
                historyResourceId={companyId || userId} //to do is company id always known or do we need user.companyId as well?
                showSpotForm={true}
                key={index}
                eventId={this.props.params.eventId}
                removeSpot={() => this.removeSpot(spot)}
                spot={spot}
                onSpotUpdate={(spot) => this.saveSpot(spot, index)}
              />
            ))
          ) : (
            <h3>{text.get('forms.powerInquiries.noSpots')}</h3>
          )}
        </div>
      );
    }

    handleCompanyChange(newCompany) {
      const { form } = this.state;
      this.setState({ currentCompany: newCompany || null });
      if (newCompany?._id === 0) {
        this.toggleAddCompanyModalOpen();
      } else {
        form.$('companyId').set('value', newCompany?._id || null);
      }
    }

    handleOwnerChange(owner) {
      const { form } = this.state;
      this.setState({ currentOwner: owner || null });
      form.$('owner').set('value', owner?._id || null);
    }

    handleUserChange(newUser) {
      const { companies } = this.props;
      const { form } = this.state;
      this.setState({ currentUser: newUser || null });
      form.$('userId').set('value', newUser?._id || null);
      if (newUser?._id === 0) {
        this.toggleAddUserModalOpen();
      } else {
        if (newUser) {
          const userCompany = getCompanyOptions(companies).find(
            (option) => option._id === newUser?.companyId
          );
          this.handleCompanyChange(userCompany || null);
        }
      }
    }

    getOwnerOptions(event, users, eventCompanyIds, companiesLoading) {
      if (companiesLoading) return [];
      return getUserOptions(
        users,
        [Roles.ADMIN, Roles.EVENT_CREATOR, Roles.INQUIRY_MANAGER],
        eventCompanyIds
      );
    }

    toggleAddUserModalOpen() {
      this.setState({ addUserModalOpen: !this.state.addUserModalOpen });
    }

    toggleAddCompanyModalOpen() {
      this.setState({ addCompanyModalOpen: !this.state.addCompanyModalOpen });
    }

    renderOwnerOption(option) {
      if (!option) return null;

      return (
        <div>
          {`${option.name} <${option.email || 'No email'}> ${
            option.companyName
          }`}
        </div>
      );
    }

    /**
     * Renders the component view.
     * @return {React.Component}
     */
    render() {
      const {
        text,
        companies,
        users,
        companiesLoading,
        event,
        usersLoading,
        params: { userId },
      } = this.props;
      const {
        form,
        invited,
        spots,
        currentCompany,
        currentUser,
        currentOwner,
        addUserModalOpen,
        addCompanyModalOpen,
      } = this.state;
      if (!form) return null;

      const companyDisabled = currentUser?.companyId > 0;

      const eventHasConnectionPrices =
        event.availableConnections &&
        event.availableConnections.filter((connection) => connection.price > 0)
          .length > 0;

      const connectionPricesTooltip = eventHasConnectionPrices
        ? text.get(
            'forms.powerInquiries.eventConnectionPricesTooltip.eventHasPrices'
          )
        : text.get(
            'forms.powerInquiries.eventConnectionPricesTooltip.noPrices'
          );

      const eventCompanyIds = event.eventCompanies.map((c) => c.companyId);

      return (
        <div>
          <form className="PowerInquiryForm">
            <div className="column scrollContent">
              <div className="row title">
                <span className="title">
                  {text.get('forms.powerInquiries.account')}
                </span>
                {
                  //Todo see same todo as above, relating to issue: https://gitlab.com/watt-now/tool-frontend/-/issues/369
                }
                {/* {form.$('userId').value && (
                                    <span className={`link ${invited && 'invited'}`} onClick={this.onClickInvite}>
                                        {invited ? 'Invited' : 'Invite'}
                                    </span>
                                )} */}
                <span className={`link ${invited && 'invited'}`}></span>
              </div>
              <div className="row">
                <Typography variant="h6">
                  {text.get('forms.powerInquiries.externalPartner')}
                </Typography>
                <AutoCompleteInput
                  field={form.$('userId')}
                  label={text.get('forms.powerInquiries.user')}
                  name={'userId'}
                  id="user"
                  optionsLoading={usersLoading}
                  getOptionLabel={(option) =>
                    option._id === 0
                      ? `+ ${text.get('forms.adminUsers.addUserOption')}: "${
                          option.name
                        }"`
                      : `${option.name} <${option.email || 'No email'}> ${
                          option.companyName
                        }`
                  }
                  options={getUserOptions(
                    users,
                    [Roles.ENERGY_USER],
                    currentCompany?._id
                  )}
                  value={currentUser}
                  onChange={this.handleUserChange}
                  addNewInputValueProp="name"
                />
                <Tooltip
                  title={
                    companyDisabled
                      ? 'Remove the selected user to change the company'
                      : ''
                  }
                >
                  <div>
                    <AutoCompleteInput
                      field={form.$('companyId')}
                      label={text.get('forms.powerInquiries.companyName')}
                      name={'companyId'}
                      id="company"
                      optionsLoading={companiesLoading}
                      getOptionLabel={(option) =>
                        option._id === 0
                          ? `+ ${text.get(
                              'forms.company.addCompanyOption'
                            )}: "${option.name}"`
                          : `${option.name}`
                      }
                      options={getCompanyOptions(companies)}
                      onChange={this.handleCompanyChange}
                      addNewInputValueProp="name"
                      disabled={companyDisabled}
                      value={currentCompany}
                    />
                  </div>
                </Tooltip>
                <div className="formItem">
                  <Typography variant="h6">
                    {text.get('forms.powerInquiries.owner')}
                  </Typography>
                  <AutoCompleteInput
                    field={form.$('owner')}
                    label={text.get('forms.powerInquiries.owner')}
                    name={'owner'}
                    id="owner"
                    optionsLoading={usersLoading}
                    getOptionLabel={(option) =>
                      `${option.name} <${option.email || 'No email'}> ${
                        option.companyName
                      }`
                    }
                    value={currentOwner}
                    options={this.getOwnerOptions(
                      event,
                      users,
                      eventCompanyIds,
                      companies.loading
                    )}
                    onChange={this.handleOwnerChange}
                  />
                </div>
              </div>
              <div className="formItem">
                <h3>{text.get('forms.powerInquiries.spots')}</h3>
                <SpotList spots={spots} text={text} />
                <span
                  className="link"
                  onClick={this.onClickNewSpot}
                  children={text.get('forms.powerInquiries.addSpot')}
                />
                <div className="formItem">
                  <Tooltip enterDelay={100} title={connectionPricesTooltip}>
                    <div>
                      <Checkbox
                        field={form.$('canSeeConnectionPrices')}
                        disabled={!eventHasConnectionPrices}
                        label={
                          <Typography>
                            {text.get(
                              'forms.powerInquiries.canSeeConnectionPrices'
                            )}
                          </Typography>
                        }
                      />
                    </div>
                  </Tooltip>
                </div>
              </div>
              <div className="buttonRow left">
                <Button
                  type="button"
                  size="small"
                  onClick={this.onSubmit}
                  children={text.get('actions.save')}
                  disabled={!this.canSubmit}
                />
                <Button
                  type="button"
                  size="small"
                  styling="borderless"
                  onClick={() => this.props.router.goBack()}
                  children={text.get('actions.cancel')}
                />
              </div>
            </div>
            <div className="details column">
              <div>
                <span>
                  <h1>{text.get('forms.powerInquiries.spots')}</h1>
                </span>
                {spots.length > 0 ? (
                  spots.map((spot, index) => (
                    <SpotDetails
                      userId={userId}
                      showSpotForm={true}
                      key={index}
                      eventId={this.props.params.eventId}
                      removeSpot={() => this.removeSpot(spot)}
                      spot={spot}
                      onSpotUpdate={(spot) => this.saveSpot(spot, index)}
                    />
                  ))
                ) : (
                  <h3>{text.get('forms.powerInquiries.noSpots')}</h3>
                )}
              </div>
            </div>
          </form>
          <SpotModalForm
            ref="spotModal"
            onSuccess={this.onNewSpot}
            onSpotUpdate={this.onSpotUpdate}
            cancelHandler={() => this.refs.spotModal.wrappedInstance.hideForm()}
          />
          <UserFormModal
            addModalOpen={addUserModalOpen}
            toggleAddModalOpen={this.toggleAddUserModalOpen}
            userInput={currentUser}
            currentCompany={currentCompany}
            handleClickAdd={this.handleUserChange}
          />
          <CompanyFormModal
            addModalOpen={addCompanyModalOpen}
            toggleAddModalOpen={this.toggleAddCompanyModalOpen}
            companyInput={currentCompany}
            handleClickAdd={this.handleCompanyChange}
          />
        </div>
      );
    }
  }
);
