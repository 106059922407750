import React from 'react';
import { observer, inject } from 'mobx-react';
import IconButton from 'material-ui/IconButton';
import IconMenu from 'material-ui/IconMenu';
import MenuItem from 'material-ui/MenuItem';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import { compose } from 'recompose';

import { pushRoute } from 'shared/actions';
import { userHasPermission } from 'auth/actions';
import { Roles } from 'shared/constants';

const enhance = compose(inject('auth', 'text'), observer);

/**
 * ProfileMenu Component class represents the profile menu which appears inside the
 * Header bar in the top of the application view.
 * @class ProfileMenu
 * @extends {React.Component}
 */
export default enhance(
  ({ auth: { profile }, text, ...props }) =>
    profile && (
      <div>
        <IconMenu
          {...props}
          iconButtonElement={
            <IconButton>
              <SettingsOutlinedIcon />
            </IconButton>
          }
          targetOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          {userHasPermission([Roles.ADMIN]) && (
            <>
              <MenuItem
                primaryText={text.get('actions.manageUsers')}
                onClick={() => pushRoute('/users/overview')}
              />
              <MenuItem
                primaryText={text.get('actions.manageCompanies')}
                onClick={() => pushRoute('/companies/overview')}
              />
            </>
          )}
          <MenuItem
            primaryText={text.get('actions.manageApplianceTemplates')}
            onClick={() => pushRoute('/appliance-templates/overview')}
          />
        </IconMenu>
      </div>
    )
);
