import { notify, pushRoute } from 'shared/actions';
import { Notifications } from 'shared/constants';
import { api } from 'shared/utils';
import { text as textStore } from 'shared/stores';

// eslint-disable-next-line import/no-cycle
import { logoff, registerLoggedInUser, registerToken } from './actions';
import { auth as authStore } from './store';

/**
 * Takes an email and password as input and attempts to login with the API.
 * @param {string} email - User email
 * @param {string} password - User password
 * @return {Promise}
 */
export const login = (email, password) =>
  Promise.resolve(authStore.isLoading(true))
    .then(() => api.post('/users/auth', { email, password }))
    .then(({ status, data }) => {
      if (status === 200) {
        const { token, user } = data;
        registerLoggedInUser(user, token.key);
        pushRoute('/events');
      }
    })
    .then(() => authStore.isLoading(false));

/**
 * Takes a token as input and attempts to refresh it with the API.
 * @param {string} token - User access token
 * @return {Promise}
 */
export const refreshToken = (token) =>
  Promise.resolve(authStore.isLoading(true))
    .then(() =>
      api.get('/users/auth/renew', { headers: { 'X-Auth-Token': token } })
    )
    .then((response) => {
      const { status, data } = response;
      if (status === 200) registerToken(data.token.key);
      else logoff();
    })
    .then(() => authStore.isLoading(false));

/**
 * Takes an email as input and attempts to recover the user's password with the API.
 * @param {string} email - User email
 * @return {Promise}
 */
export const recoverPassword = (email) =>
  Promise.resolve(authStore.isLoading(true))
    .then(() => api.post('/users/restorepass', { email }))
    .then(({ status }) => {
      if (status === 200)
        notify({
          message: textStore.get('components.views.login.formSuccess'),
          level: Notifications.Type.SUCCESS,
        });
      if (status === 404)
        notify({
          message: textStore.get('components.views.login.userUnknown'),
          level: Notifications.Type.ERROR,
        });
    })
    .then(() => authStore.isLoading(false));

/**
 * Takes an object with the password as input and attempts to update it with the API.
 * @param {object} data - Object with the 'password' property
 * @return {Promise}
 */
export const updatePassword = (data) =>
  Promise.resolve(authStore.isLoading(true))
    .then(() => api.patch('/users/makeprofile', data))
    .then(({ status }) => {
      if (status === 200) {
        notify({
          message: textStore.get('components.views.resetPassword.successful'),
          level: Notifications.Type.SUCCESS,
        });
        pushRoute('/events');
      }
    })
    .then(() => authStore.isLoading(false));

/**
 * Takes an uuid as input and checks with the API if the user is still pending.
 * If not, takes it to the events page.
 * @param {string} uuid - User uuid
 * @return {Promise}
 */
export const userPending = (uuid) =>
  Promise.resolve(authStore.isLoading(true))
    .then(() => api.get(`/users/pending/${uuid}`))
    .then(({ status, data }) => {
      if (status === 200 && !data.pending) pushRoute('/events');
    })
    .then(() => authStore.isLoading(false));

/**
 * Takes an uuid as input and returns the newly registered user
 * If not, takes it to the events page.
 * @param {string} uuid - User uuid
 * @return {Promise}
 */
export const getUserProfile = (uuid) =>
  Promise.resolve(authStore.isLoading(true))
    .then(() => api.get(`/users/profile/${uuid}`))
    .then(({ status, data }) => {
      if (status === 200) authStore.setRegisteredUser(data);
    })
    .then(() => authStore.isLoading(false));

/**
 * Takes an User profile as input and attempts to save it throught the API.
 * @param {string} profile - User profile data
 * @return {Promise}
 */
export const createUser = (profile) =>
  Promise.resolve(authStore.isLoading(true))
    .then(() => api.patch('/users/makeprofile', profile))
    .then(({ status, data }) => {
      if (status === 200) {
        const { token, user } = data;
        registerLoggedInUser(user, token);
        pushRoute('/events');
      }
    })
    .then(() => authStore.isLoading(false));
