import { ensureDate, ensureString } from 'shared/utils';

/**
 * Calculates the total 'ratedPower' of all the spot connections.
 * @param {object[]} connections - Connections list
 * @return {number}
 */
const getTotalPower = (connections) =>
  connections.reduce((acc, { totalPower }) => (acc += totalPower), 0);

/**
 * Maps the device fields to the current model.
 * @param {object} device - Device object
 * @return {object}
 */
const deviceMapper = (device = {}) => {
  device.ratedPower = device.ratedPower ? parseFloat(device.ratedPower, 10) : 0;
  device.quantity = device.quantity ? parseInt(device.quantity, 10) : 0;

  device.brand = device.brand || '';
  device.modelNumber = device.modelNumber || '';

  if (device.unit.toLowerCase() === 'w') {
    device.totalPower = (device.ratedPower * device.quantity) / 1000; // kW
  }

  if (device.unit.toLowerCase() === 'a') {
    device.totalPower = (device.ratedPower * device.quantity * 220 * 3) / 1000; // kW
  }
  return device;
};

/**
 * Maps the connection fields to the current model.
 * @param {object} connection - Connection object
 * @return {object}
 */
const connectionMapper = (connection = {}) => {
  if (
    connection.powerInquiryDevices &&
    (!connection.devices || connection.devices.length === 0)
  ) {
    connection.devices = connection.powerInquiryDevices || [];
    delete connection.powerInquiryDevices;
  }
  connection.startDate = connection.startDate || null;
  connection.endDate = connection.endDate || null;
  connection.devices = connection.devices
    ? connection.devices.map(deviceMapper)
    : [];
  connection.quantity = connection.quantity
    ? parseInt(connection.quantity, 10)
    : 0;
  connection.totalPower = connection.devices
    ? connection.devices.reduce(
        (acc, { totalPower }) => (acc += totalPower),
        0
      ) * connection.quantity
    : 0;
  return connection;
};

/**
 * Maps the spot layout legacy fields to the current model.
 * @param {object} layout - Layout object
 * @return {object}
 */
const layoutMapper = (layout) => {
  if (!layout) layout = { comment: '', connections: [] };
  if (
    layout.powerInquiryConnections &&
    (!layout.connections || layout.connections.length === 0)
  ) {
    layout.connections = layout.powerInquiryConnections;
    delete layout.powerInquiryConnections;
  }
  layout.connections = layout.connections
    ? layout.connections.map(connectionMapper)
    : [];
  return layout;
};

/**
 * Fills the URI field.
 * @param {object} spot - Spot object
 * @param {string} eventId - Event id
 * @return {string}
 */
const uriMapper = (spotId, eventId) =>
  spotId ? `/events/${eventId}/spots/${spotId}` : '';

/**
 * Maps the spot fields to guarantee consistency of the fields within the system.
 * @param {string} eventId - Event id
 * @return {function} (spot) => {object}
 */
export default (eventID) => (data) => {
  let eventId = eventID || data.eventId; //TODO make sure we use data.eventId or eventId consistanly
  delete data.subtitle;
  const approved = data.approved || false;
  const spot = JSON.parse(JSON.stringify(data)); // Copying to remove mobx bindings when applies
  const { _id } = spot;
  eventId = parseInt(eventId, 10);
  const uri = uriMapper(_id, eventId);
  const userId = spot.userId || 0;
  const companyId = spot.companyId || null;
  const powerInquiryId = spot.powerInquiryId || null;
  const label = ensureString(spot.label);
  const origin = ensureString(spot.origin);
  const historicItem = spot.historicItem || false;
  const estimated = spot.estimated || false;
  const layout = layoutMapper(spot.layout);
  const associatedResource = ensureString(spot.associatedResource);
  const createdAt = ensureDate(spot.createdAt);
  const updatedAt = ensureDate(spot.updatedAt);
  const cluster = ensureString(spot.cluster);
  const tags = spot.tags || [];
  const totalPower = layout.connections ? getTotalPower(layout.connections) : 0;
  const coordinate = spot.coordinate || null;
  const spotObject = {
    _id,
    uri,
    coordinate,
    approved,
    eventId,
    userId,
    companyId, // companyId is ignored in post requests
    powerInquiryId,
    label,
    layout,
    associatedResource,
    createdAt,
    updatedAt,
    historicItem,
    cluster,
    tags,
    totalPower,
    origin,
    estimated,
  };
  if (spot.companyName) spotObject.companyName = spot.companyName || '';
  return spotObject;
};
