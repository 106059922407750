import React from 'react';
import {
  TableRow,
  TableCell as MuiTableCell,
  IconButton,
  Tooltip,
  Chip,
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/';

import { useGetText } from 'shared/hooks';
import { useGetMe } from 'auth/hooks';
import { Icon } from 'shared/components';
import {
  PlaceholderTextInput,
  AutoCompleteInput,
} from 'shared/components/form';

import { useGetAppliances } from 'appliances/hooks';
import './styles.less';

const TableCell = withStyles({
  root: {
    borderBottom: 'none',
  },
})(MuiTableCell);

/**
 * DeviceRow function represents the view of the 'DeviceRow' component used in
 * the SpotForm's 'ConnectionRow'.
 * @function deviceRow
 */
export default function FormDeviceRow({
  field,
  removeDevice,
  disabled,
  updateDeviceState,
  onSubmit,
}) {
  const text = useGetText();
  const { me } = useGetMe();
  const { data: applianceSuggestions, loading: appliancesLoading } =
    useGetAppliances({ userId: me?._id || '' });
  /**
   * Handles the validation of the quantity field
   */
  const validatePowerInput = () => {
    field.$('ratedPower').value = Number(field.$('ratedPower').value);
    if (+field.$('ratedPower').value < 1) {
      field.$('ratedPower').value = '';
    }
    if (field.$('ratedPower').value.toString().length > 6) {
      field.$('ratedPower').value = field
        .$('ratedPower')
        .value.toString()
        .substr(0, field.$('ratedPower').value.toString().length - 1);
    }
  };

  /**
   * Handles the validation of the power field
   * @return {void}
   */
  const validateQuantityInput = () => {
    if (field.$('quantity').value < 1) {
      field.$('quantity').value = '';
    }
    if (field.$('quantity').value.toString().length > 2) {
      field.$('quantity').value = field
        .$('quantity')
        .value.toString()
        .substr(0, field.$('quantity').value.toString().length - 1);
    }
  };

  //Get the available modelNumber options
  function getModelNumberOptions() {
    const brand = field.$('brand').value;

    //filter by brand if a brand is selected
    if (brand) {
      return [
        ...new Set(
          applianceSuggestions
            ?.filter((device) => device['brand'] === brand)
            .map((device) => device['modelNumber'])
        ),
      ];
    } else {
      return [
        ...new Set(
          applianceSuggestions?.map((device) => device['modelNumber'])
        ),
      ];
    }
  }

  function handleApplianceNameChange(appliance) {
    field.$('name').set('value', appliance?.label || '');
    field.$('brand').set('value', appliance?.brand || '');
    field.$('modelNumber').set('value', appliance?.modelNumber || '');
    field.$('ratedPower').set('value', appliance?.ratedPower || '');
    updateDeviceState(); //update the form state so that the new values are reflected in the form fields
  }

  function handleBrandChange(brand) {
    field.$('brand').set('value', brand);
    updateDeviceState(); //update the form state so that the new values are reflected in the form fields
  }

  function renderApplianceNameOptionLabel(option) {
    if (!option) return null;
    const { label, brand, modelNumber, ratedPower } = option;

    return (
      <div>
        {`${label} ${brand} ${modelNumber ? `(${modelNumber})` : ''}`}
        <Chip variant="outlined" size="small" label={ratedPower + ' W'} />
      </div>
    );
  }

  function onInputFieldChange(input, fieldName) {
    field.$(fieldName).set(input);
  }

  return (
    <TableRow className="device-row">
      <TableCell style={{ minWidth: '180px' }}>
        <AutoCompleteInput
          placeholder={text.getText('forms.appliance.startTyping')}
          name={'name'}
          optionsLoading={
            appliancesLoading && applianceSuggestions.length === 0
          }
          renderOption={renderApplianceNameOptionLabel}
          options={applianceSuggestions || []}
          value={field.$('name').value}
          onChange={handleApplianceNameChange}
          onInputChange={(event, input) => onInputFieldChange(input, 'name')} //make sure that the field value is changed on input
          inputFieldProps={{
            autoFocus: true,
            type: 'search',
          }}
          freeSolo
          autoHighlight
          forcePopupIcon={false}
          disabled={disabled}
          widePopper
          fuzzySearch
          fuzzySearchKeys={['label', 'brand', 'modelNumber', 'ratedPower']}
        />
      </TableCell>
      <TableCell style={{ minWidth: '80px' }}>
        <AutoCompleteInput
          placeholder={text.getText('forms.appliance.brand')}
          name={'brand'}
          optionsLoading={appliancesLoading}
          options={[
            ...new Set(applianceSuggestions?.map((device) => device['brand'])),
          ]}
          onChange={handleBrandChange}
          onInputChange={(event, input) => onInputFieldChange(input, 'brand')} //make sure that the field value is changed on input
          inputFieldProps={{
            type: 'search',
          }}
          value={field.$('brand').value}
          widePopper
          freeSolo
          autoComplete
          disabled={disabled}
          forcePopupIcon={false}
          disableClearable
          fuzzySearch
        />
      </TableCell>
      <TableCell>
        <AutoCompleteInput
          placeholder={text.getText('forms.appliance.modelNumber')}
          name={'modelNumber'}
          optionsLoading={appliancesLoading}
          options={getModelNumberOptions()}
          onInputChange={(event, input) =>
            onInputFieldChange(input, 'modelNumber')
          } //make sure that the field value is changed on input
          inputFieldProps={{
            type: 'search',
          }}
          value={field.$('modelNumber').value}
          widePopper
          freeSolo
          disabled={disabled}
          forcePopupIcon={false}
          disableClearable
        />
      </TableCell>
      <TableCell>
        <PlaceholderTextInput
          field={field.$('ratedPower')}
          min={0}
          max={999999}
          onKeyUp={validatePowerInput}
          type="number"
          disabled={disabled}
        />
      </TableCell>
      <TableCell>
        <PlaceholderTextInput
          min={1}
          max={99}
          onKeyUp={validateQuantityInput}
          field={field.$('quantity')}
          type="number"
          disabled={disabled}
        />
      </TableCell>
      <TableCell>
        <Tooltip title={text.getText('actions.removeDevice')}>
          <IconButton onClick={() => removeDevice(field)}>
            <Icon name="close" />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
}
