import React from 'react';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';

import { Card, Metadata } from 'shared/components';

import { updatePassword, userPending } from 'auth/actions';
import { ResetPasswordForm } from 'auth/components';

import './styles.less';

const enhance = compose(inject('text'), observer);

/**
 * ResetPasswordPage class represents the view with the reset password form.
 * @class ResetPasswordPage
 * @extends {React.Component}
 */
export const ResetPasswordPage = enhance(
  class extends React.Component {
    /**
     * React lifecycle method - executed everytime the component is about to be mounted in the view.
     * @return {void}
     */
    UNSAFE_componentWillMount() {
      const {
        params: { uuid },
      } = this.props;
      userPending(uuid);
    }

    /**
     * Form submission success handler.
     * @param {object} form - Form instance
     * @return {void}
     */
    onSuccess(form) {
      const {
        params: { uuid },
      } = this.props;
      const { password } = form.values();
      updatePassword({ pendingUUID: uuid, password });
    }

    /**
     * Renders the component view.
     * @return {React.Component}
     */
    render() {
      const { text } = this.props;
      const Strings = text.get('components.views.resetPassword');
      return (
        <section className="ResetPasswordView">
          <Metadata meta={Strings.meta} />
          <Card>
            <h3>{Strings.pageTitle}</h3>
            <ResetPasswordForm onSuccess={(form) => this.onSuccess(form)} />
          </Card>
        </section>
      );
    }
  }
);
