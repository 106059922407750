/**
 * This is where the application is actually initialized. Application component
 * loads the state provider and the router for present the views structure.
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'mobx-react';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import {
  applyRouterMiddleware,
  IndexRedirect,
  Router,
  Route,
  browserHistory,
} from 'react-router';
import { useScroll } from 'react-router-scroll';
import { ReactQueryDevtools } from 'react-query/devtools';

import {
  RELEASE_TAG,
  API_URL,
  SENTRY_TRACING_ORIGINS_ENABLED,
  ENVIRONMENT,
  SENTRY_DSN,
} from './shared/constants/general';
import { ThemeProvider, V0ThemeProvider } from './shared/styles';
import {
  ADMIN,
  ENERGY_USER,
  EVENT_CREATOR,
  INQUIRY_MANAGER,
  ALL,
} from './shared/constants/roles';

import { allowedFor } from './shared/actions';

import * as AppliancesPages from './appliances/pages';

import * as AuthPages from './auth/pages';
import * as AuthStores from './auth/store';

import * as AreasStores from './areas/store';

import * as InquiryPages from './powerinquiries/pages';
import * as InquiryStores from './powerinquiries/store';

import * as EventPages from './events/pages';
import * as EventsStores from './events/store';

import * as ProfilePages from './profile/pages';

import * as PowerzonesPages from './powerzones/pages';
import * as PowerzonesStores from './powerzones/store';

import * as UsersPages from './users/pages';
import * as UsersStores from './users/store';

import * as CompaniesPages from './companies/pages';
import * as CompaniesStores from './companies/store';

import * as SpotsPages from './spots/pages';
import * as SpotsStores from './spots/store';

import * as SharedPages from './shared/pages';
import * as SharedStores from './shared/stores';

import './setup';
import 'react-dates/initialize';

const stores = {
  ...SharedStores,
  ...AuthStores,
  ...AreasStores,
  ...InquiryStores,
  ...EventsStores,
  ...UsersStores,
  ...CompaniesStores,
  ...PowerzonesStores,
  ...SpotsStores,
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const tracingConfig = {};
if (SENTRY_TRACING_ORIGINS_ENABLED) tracingConfig.tracingOrigins = [API_URL];

Sentry.init({
  dsn: SENTRY_DSN, //add dsn url used to send errors to Sentry. If empty then sentry will not be initialized
  integrations: [new BrowserTracing(tracingConfig)],
  release: RELEASE_TAG,
  environment: ENVIRONMENT,

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  // Reduce the tracing sample rate to avoid too many request being traced
  tracesSampleRate: 0.2,
});
/**
 * Main component with the routing configuration.
 * @return {React.Component}
 */

const Application = () => (
  <Sentry.ErrorBoundary showDialog>
    <QueryClientProvider client={queryClient}>
      <Provider {...stores}>
        <ThemeProvider>
          <V0ThemeProvider>
            <Router
              history={browserHistory}
              render={applyRouterMiddleware(useScroll())}
            >
              <Route path="/" component={SharedPages.BaseLayout}>
                <IndexRedirect to="events" />
                <Route
                  path="forget-password"
                  component={AuthPages.ForgetPasswordPage}
                />
                <Route
                  path="login"
                  component={AuthPages.LoginPage}
                  onEnter={allowedFor()}
                />
                <Route
                  path="register/:uuid/:eventId"
                  component={AuthPages.RegisterPage}
                />
                <Route
                  path="register/:uuid"
                  component={AuthPages.RegisterPage}
                />
                <Route
                  path="resetpassword/:uuid"
                  component={AuthPages.ResetPasswordPage}
                />
                <Route
                  path="appliance-templates"
                  component={AppliancesPages.AppliancesBase}
                  onEnter={allowedFor([ADMIN, EVENT_CREATOR, INQUIRY_MANAGER])}
                >
                  <IndexRedirect to="overview" />
                  <Route
                    path="overview"
                    component={AppliancesPages.AppliancesOverviewPage}
                  />
                </Route>
                <Route
                  path="companies"
                  component={CompaniesPages.CompaniesBase}
                  onEnter={allowedFor([ADMIN, EVENT_CREATOR, INQUIRY_MANAGER])}
                >
                  <IndexRedirect to="overview" />
                  <Route path="add" component={CompaniesPages.CompanyAddPage} />
                  <Route
                    path=":companyId/edit"
                    component={CompaniesPages.CompanyEditPage}
                  />
                  <Route
                    path="overview"
                    component={CompaniesPages.CompaniesOverviewPage}
                  />
                </Route>

                <Route path="events" component={EventPages.EventsBase}>
                  <IndexRedirect to="overview" />
                  <Route
                    path=":eventId/edit"
                    component={EventPages.EditEventPage}
                    onEnter={allowedFor([ADMIN, EVENT_CREATOR])}
                  />
                  <Route path="new" component={EventPages.NewEventPage} />
                  <Route
                    path="overview"
                    component={EventPages.EventsOverviewPage}
                    onEnter={allowedFor([ALL])}
                  />
                </Route>

                <Route path="event/:eventId" component={EventPages.EventBase}>
                  <IndexRedirect to="inquiries" />
                  <Route
                    path="inquiries"
                    component={InquiryPages.PowerInquiryBase}
                    onEnter={allowedFor([
                      ADMIN,
                      EVENT_CREATOR,
                      INQUIRY_MANAGER,
                    ])}
                  >
                    <IndexRedirect to="overview" />
                    <Route
                      path="add"
                      component={InquiryPages.PowerInquiryPage}
                    />
                    <Route
                      path=":powerInquiryId/edit"
                      component={InquiryPages.PowerInquiryEditPage}
                    />
                    <Route
                      path="invite"
                      component={InquiryPages.PowerInquiryInvitePage}
                    />
                    <Route
                      path=":powerInquiryId/sendReminder"
                      component={InquiryPages.PowerInquiriesSendReminderPage}
                    />
                    <Route
                      path="sendReminders"
                      component={InquiryPages.PowerInquiriesSendReminderPage}
                    />
                    <Route
                      path="overview"
                      component={InquiryPages.PowerInquiriesOverviewPage}
                    />
                  </Route>
                  <Route
                    path="overview"
                    component={EventPages.EventOverviewPage}
                    onEnter={allowedFor([
                      ADMIN,
                      INQUIRY_MANAGER,
                      EVENT_CREATOR,
                    ])}
                  />

                  <Route
                    path="powerzonetree"
                    component={PowerzonesPages.PowerzonesBase}
                    onEnter={allowedFor([ADMIN, EVENT_CREATOR])}
                  >
                    <IndexRedirect to="overview" />
                    <Route
                      path="overview"
                      component={PowerzonesPages.PowerzonesOverviewPage}
                    />
                  </Route>
                  <Route
                    path="powerzonesummary"
                    component={PowerzonesPages.PowerzoneSummaryPage}
                    onEnter={allowedFor([ADMIN, EVENT_CREATOR])}
                  />
                  <Route
                    path="powerzoneoverview"
                    component={PowerzonesPages.EventDataTablePage}
                    onEnter={allowedFor([ADMIN, EVENT_CREATOR])}
                  />
                  <Route
                    path="spots"
                    component={SpotsPages.SpotsBase}
                    onEnter={allowedFor([ADMIN, EVENT_CREATOR, ENERGY_USER])}
                  >
                    <Route
                      path=":spotId/connections"
                      component={SpotsPages.SpotConnectionsPage}
                    />
                    <Route
                      path=":spotId/extra"
                      component={SpotsPages.SpotExtraPage}
                    />
                    <Route
                      path=":spotId/revision"
                      component={SpotsPages.SpotRevisionPage}
                    />
                  </Route>
                </Route>

                <Route
                  path="profile"
                  component={ProfilePages.ProfilePage}
                  onEnter={allowedFor(ALL)}
                >
                  <IndexRedirect to="edit" />
                  <Route path="edit" component={ProfilePages.ProfileEditPage} />
                </Route>

                <Route
                  path="users"
                  component={UsersPages.UsersBase}
                  onEnter={allowedFor([ADMIN, EVENT_CREATOR, INQUIRY_MANAGER])}
                >
                  <IndexRedirect to="overview" />
                  <Route path="add" component={UsersPages.UsersAddPage} />
                  <Route
                    path=":userId/edit"
                    component={UsersPages.UsersEditPage}
                  />
                  <Route
                    path="overview"
                    component={UsersPages.UsersOverviewPage}
                  />
                </Route>
                <Route path="400" component={SharedPages.ForbiddenPage} />
                <Route path="*" component={SharedPages.NotFoundPage} />
              </Route>
            </Router>
          </V0ThemeProvider>
        </ThemeProvider>
      </Provider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  </Sentry.ErrorBoundary>
);

ReactDOM.render(<Application />, document.getElementById('root')); // Renders the React components to the DOM
