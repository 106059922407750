import React from 'react';

export const Calendar = (props) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 1600.000000 1600.000000"
    preserveAspectRatio="xMidYMid meet"
    {...props}
  >
    <g
      transform="translate(0.000000,1600.000000) scale(0.100000,-0.100000)"
      fill="#000000"
      stroke="none"
    >
      <path
        d="M3705 15986 c-189 -43 -355 -175 -441 -353 -58 -121 -64 -166 -64
            -560 l0 -353 -990 0 c-1110 0 -1076 2 -1228 -71 -167 -80 -287 -240 -326 -431
            -14 -70 -16 -713 -16 -6538 0 -5825 2 -6468 16 -6538 24 -122 83 -232 168
            -316 78 -79 152 -122 266 -158 l75 -23 6835 0 6835 0 75 23 c114 36 188 79
            265 157 78 77 121 151 157 265 l23 75 0 6520 0 6520 -27 80 c-59 172 -163 293
            -313 365 -149 72 -118 70 -1224 70 l-990 0 -3 383 -4 382 -26 73 c-37 103 -82
            175 -159 251 -76 76 -147 121 -251 159 l-73 27 -445 0 -445 0 -73 -27 c-105
            -38 -177 -83 -252 -159 -76 -77 -121 -148 -158 -251 l-27 -73 -3 -382 -3 -383
            -2879 0 -2879 0 -3 383 -4 382 -26 73 c-37 103 -82 175 -159 251 -76 76 -148
            122 -251 159 l-73 26 -420 3 c-322 1 -434 -1 -480 -11z m775 -1586 l0 -960
            -320 0 -320 0 0 960 0 960 320 0 320 0 0 -960z m7680 0 l0 -960 -320 0 -320 0
            0 960 0 960 320 0 320 0 0 -960z m-8958 -702 c4 -368 5 -386 27 -448 69 -197
            223 -351 421 -422 64 -23 70 -23 505 -23 434 0 441 0 510 23 171 57 312 179
            389 337 60 124 66 172 66 564 l0 351 2879 0 2880 0 3 -382 c4 -368 5 -386 27
            -448 69 -197 223 -351 421 -422 64 -23 70 -23 505 -23 434 0 441 0 510 23 171
            57 312 179 389 337 60 124 66 172 66 564 l0 351 960 0 960 0 0 -1120 0 -1120
            -6720 0 -6720 0 0 1120 0 1120 959 0 960 0 3 -382z m11518 -7458 l0 -4960
            -6720 0 -6720 0 0 4960 0 4960 6720 0 6720 0 0 -4960z"
      />
      <path
        d="M3200 6240 l0 -3680 4800 0 4800 0 0 3680 0 3680 -4800 0 -4800 0 0
            -3680z m2240 2240 l0 -800 -800 0 -800 0 0 800 0 800 800 0 800 0 0 -800z
            m2240 0 l0 -800 -800 0 -800 0 0 800 0 800 800 0 800 0 0 -800z m2240 0 l0
            -800 -800 0 -800 0 0 800 0 800 800 0 800 0 0 -800z m2240 0 l0 -800 -800 0
            -800 0 0 800 0 800 800 0 800 0 0 -800z m-6720 -2240 l0 -800 -800 0 -800 0 0
            800 0 800 800 0 800 0 0 -800z m2240 0 l0 -800 -800 0 -800 0 0 800 0 800 800
            0 800 0 0 -800z m2240 0 l0 -800 -800 0 -800 0 0 800 0 800 800 0 800 0 0
            -800z m2240 0 l0 -800 -800 0 -800 0 0 800 0 800 800 0 800 0 0 -800z m-6720
            -2240 l0 -800 -800 0 -800 0 0 800 0 800 800 0 800 0 0 -800z m2240 0 l0 -800
            -800 0 -800 0 0 800 0 800 800 0 800 0 0 -800z m2240 0 l0 -800 -800 0 -800 0
            0 800 0 800 800 0 800 0 0 -800z m2240 0 l0 -800 -800 0 -800 0 0 800 0 800
            800 0 800 0 0 -800z"
      />
    </g>
  </svg>
);
