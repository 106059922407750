import React from 'react';
import { flattenChildren } from 'powerzones/utils';
import DetailsTable from 'powerzones/components/NodeDetailsTable';

import AreaOverviewBox from './areaOverviewBox';
import './styles.less';
import { useGetText } from 'shared/hooks';

/**
 * AreaDetails functional component represents the view which presents the details
 * of the selected area in the sidepane.
 */
function AreaDetails({ item }) {
  const spots = flattenChildren(item.children);
  const text = useGetText();

  return (
    <div className="EventAreaDetails">
      <div className="areaHeading">
        <div className="left column">
          <span
            className="title"
            children={text.getText('forms.area.formTitle')}
          />
        </div>
      </div>
      <AreaOverviewBox area={item} spots={spots} />
      <DetailsTable className="details" spots={spots} />
    </div>
  );
}

export default AreaDetails;
