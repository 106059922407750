import { useCallback } from 'react';
import { useQueryClient, useMutation } from 'react-query';

import { api } from 'shared/utils';
import { notify } from 'shared/actions';
import { Notifications } from 'shared/constants';

import { putCompanyMapper } from '../mapper';
import { COMPANIES_QUERY_KEY } from './useGetCompanies';

const doUpdateCompany = async (company) => {
  const { data } = await api.put(
    `/companies/${company._id}`,
    putCompanyMapper(company)
  );
  return data;
};

export const useUpdateCompany = () => {
  const queryClient = useQueryClient();
  const { mutate, status, ...others } = useMutation(doUpdateCompany, {
    onSuccess: (newCompany) => {
      if (newCompany) {
        //Not adding new company to response to cache because endpoint does not return the new company
        queryClient.invalidateQueries(COMPANIES_QUERY_KEY);

        //Notify the user
        notify({
          message: `Successfully updated the company`,
          level: Notifications.Type.SUCCESS,
        });
      }
    },
    onError: (error) => {
      notify({
        message: `Error updating company: ${error.message}`,
        level: Notifications.Type.ERROR,
      });
    },
  });

  const updateCompany = useCallback((company) => mutate(company), [mutate]);

  return {
    ...others,
    loading: status === 'loading',
    updateCompany,
  };
};
